/**
 * Lecture Capture Admin helper functions
 *
 * Author: Simon Dvorak <sidvorak@ucdavis.edu>
 */

import { Dispatch } from 'redux';
import {
    CAMERA_PROXY_DOMAIN,
    RECORDING_OPTIONS_ENUM,
} from '../constants';
import { logger } from '../services/Logger';
import { Snack } from '../factories/Snackbar';
import { showSnackbar } from '../redux/application/applicationActions';
import { FetchError } from '../redux/application/applicationInterfaces';
import { logoutUser } from '../redux/user/userActions';
import { ICourse } from '../redux/course/courseInterfaces';
import { IRoom } from '../redux/room/roomInterfaces';
import { ITerm } from '../redux/term/termInterfaces';

export const capitalize = ([first, ...rest]: string): string => {
    return `${first.toLocaleUpperCase()}${rest.join('')}`;
};

export const padTimeString = (timeStr: string): string => {
    // Use a regular expression to match the time format
    const timePattern = /^(\d{1,2}):(\d{2})$/;
    const match = timeStr.match(timePattern);

    if (match) {
        let hour = match[1];
        const minutes = match[2];

        // Pad the hour with a leading zero if necessary
        if (hour.length === 1) {
            hour = '0' + hour;
        }

        return `${hour}:${minutes}`;
    } else {
        throw new Error('Invalid time format');
    }
}

export const convertTo12HourFormat = (time24: string): string | undefined => {
    if (!time24) return;

    const [hoursString, minutes] = time24.split(':');
    if (!minutes) return;

    let formattedTime;
    let suffix;

    const hours = parseInt(hoursString);

    if (hours < 12) {
        formattedTime = `${hours}:${minutes}`;
        suffix = 'AM';
    } else {
        const adjustedHours = hours % 12 || 12;
        formattedTime = `${adjustedHours}:${minutes}`;
        suffix = 'PM';
    }

    return `${formattedTime} ${suffix}`;
};

/**
 * Convert string to camelCase
 * @param str string
 * @returns string
 */
export const camelCase = (str: string): string => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

/**
 * Fetcher function for SWR
 * @param url
 * @returns
 */
export const fetcher = async <T>(url: string, includeCredentials = true): Promise<T> => {
    const requestInit: RequestInit = includeCredentials ? { credentials: 'include' } : {};
    const res = await fetch(url, requestInit);

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        throw new FetchError(res.statusText, res.url, res.status);
    }

    return res.json();
};

/**
 * Returns the most frequent value in an array of strings
 * @param array
 * @returns string
 */
export const getMostFrequentValue = (array: string[]): string => {
    const hashmap = array.reduce<{ [index: string]: number }>((acc, val: string) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
    }, {});
    return Object.keys(hashmap).reduce((a, b) => (hashmap[a] > hashmap[b] ? a : b));
};

/**
 * Returns the sort order index for a recording type
 * @param recordOption
 * @returns number
 */
export const getRecordOptionSortOrder = (recordOption: string | undefined): number => {
    if (!recordOption || recordOption === '' || !RECORDING_OPTIONS_ENUM[camelCase(recordOption)]) return 99;
    return RECORDING_OPTIONS_ENUM[camelCase(recordOption)].sortOrder;
};

export const handleSWRError = (error: FetchError, entity: string, dispatch: Dispatch): void => {
    if (error) {
        logger.error(error);
        if (error.status === 401) {
            dispatch(showSnackbar(Snack('Session expired. Please log in again.', 'error')));
            dispatch(logoutUser());
            return;
        }
        dispatch(showSnackbar(Snack(`Could not fetch ${entity}! Please check your network connection.`, 'error')));
    }
};

/**
 * Returns a formatted RTSP endpoint string from an IP address
 * @param extronIP
 * @returns
 */
export const formatRTSPEndpoint = (extronIP: string): string => `rtsp://${extronIP}/extron3`;

/**
 * Returns a URL for the Extron Admin UI
 * @param extronIP
 * @returns
 */
export const formatExtronAdminURL = (extronIP: string): string => `http://${extronIP}/www/index.html`;

/**
 * Returns a URL for the Camera Admin UI
 * @param cameraIP
 * @returns
 */
export const formatCameraAdminURL = (cameraIP: string): string => `http://${cameraIP}/live/`;

type CameraProxyParams = { cameraIP: string; path: string; preset?: string };
/**
 * Returns a formatted endpoint string for the LC camera proxy
 * @param CameraProxyParams
 * @returns
 */
export const formatCameraProxyURL = ({ cameraIP, path, preset }: CameraProxyParams): string => {
    const url = new URL(path, CAMERA_PROXY_DOMAIN);
    url.searchParams.append('ip', cameraIP);
    preset && url.searchParams.append('preset', preset);
    return url.toString();
};

/**
 * Returns the input value adjusted for a new range
 * @param val
 * @param oMin
 * @param oMax
 * @param min
 * @param max
 * @returns
 */
export const rescale = (val: number, oMin: number, oMax: number, min: number, max: number): number =>
    Math.max((oMin === oMax ? 0 : ((val - oMin) * (max - min)) / (oMax - oMin)) + min, 0);

/**
 * Returns the Extron recorder used storage percentage
 * @param storage
 * @returns
 */
export const parseExtronStorage = (storage: string): number | string => {
    if (!storage) return 'Offline';
    const storageArr = storage.split('*');
    if (storageArr.length < 6) return 'No drive';
    const used = storageArr[1].replace('MB', '');
    const total = storageArr[2].replace('MB', '');
    return Math.round((parseInt(used) / parseInt(total)) * 100);
};

/**
 * Returns the quarter termcode for the current date
 * @param {ITerm[]} terms
 * @returns {string} termcode
 */
export const getCurrentTermcode = (terms: ITerm[]): string => {
    const today = new Date();
    const currentTerms = terms.filter((t) => {
        const tStart = new Date(t.startDate);
        const tEnd = new Date(t.endDate);
        const in2Weeks = today.addDays(14);
        return (tStart <= today && today <= tEnd) || (tStart <= in2Weeks && tEnd >= today);
    });

    const quarterFilter = (t: ITerm) => ['02', '04', '06', '09'].indexOf(t.termCode.slice(-2)) === -1;

    // We could be in between terms, return the last in the full term list that is a quarter
    if (currentTerms.length === 0 && terms.length > 0) {
        const filteredTerms = terms.filter(quarterFilter);
        return filteredTerms[filteredTerms.length - 1].termCode;
    }

    // In case there are two current terms, return the one that is a quarter
    return currentTerms.length > 1 ? currentTerms.filter(quarterFilter)[0].termCode : currentTerms[0].termCode;
};

/**
 * Returns an request error message string
 *
 * @param errorMessage The error message
 * @param statusText Optional response statusText message
 * @returns An error message string
 */
export const requestErrorMessage = (errorMessage: string, statusText: string | undefined | null): string => {
    return [errorMessage, statusText].filter(Boolean).join('. ');
};

/**
 * Returns an array of room numbers for the pass building
 *
 * @param rooms An array of IRoom
 * @param building The building name
 * @returns string[] An array of room numbers for the building
 */
export const findRoomNumbersByBuilding = (rooms: IRoom[], building: string): string[] => {
    return rooms
        .filter((r) => r.building === building)
        .map((r) => r.roomNumber)
        .sort((a: string, b: string) => a.localeCompare(b, undefined, { numeric: true }));
};

/**
 * Returns an array of courseIDs where the building, room, days or times
 * differ between the confirmed and Banner courses
 *
 * @param courses
 * @returns an array of courseIDs
 */
export const getBannerSyncCandidates = (courses: ICourse[]): string[] => {
    const syncCandidates: string[] = [];

    const coursesByCourseID = courses.reduce((all, current: ICourse) => {
        const index = current.courseID as string;
        if (!Array.isArray(all[index])) {
            all[index] = [];
        }
        all[index].push(current);
        return all;
    }, {} as { [key: string]: ICourse[] });

    Object.keys(coursesByCourseID).forEach((i) => {
        if (coursesByCourseID[i].length === 2) {
            const { building: building1, roomNumber: roomNumber1, times: times1, days: days1 } = coursesByCourseID[
                i
            ][0];

            const { building: building2, roomNumber: roomNumber2, times: times2, days: days2 } = coursesByCourseID[
                i
            ][1];

            if (building1 != building2 || roomNumber1 != roomNumber2 || days1 != days2 || times1 != times2) {
                syncCandidates.push(i);
            }
        }
    });

    return syncCandidates;
};

export const isValidEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
};

/**
 * Returns a date string in format YYYY-mm-ddThh:mm
 * @param date
 * @returns a date string
 */
export const formatDateTime = (date: Date): string =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
        2,
        '0',
    )}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

/**
 * Returns a new date object representing the UTC date of the passed date
 * @param date
 * @returns Date
 */
export const parseRecurrenceDateUTC = (date: Date): Date => {
    return new Date(
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
        ),
    );
};

/**
 * Return one of the strings "morning", "afternoon" or "evening" based of the passed date parameter
 * @param date
 * @returns a string
 *
 */
export const timeOfDayString = (date: Date): string => {
    if (date.getHours() < 12) {
        return 'morning';
    } else if (date.getHours() < 18) {
        return 'afternoon';
    } else {
        return 'evening';
    }
};

/**
 * Returns a Date object from a string in the format "hh:mm"
 * @param timeString 
 * @returns 
 */
export const parseTimeStringToDate = (timeString: string): Date => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
};

/**
 * Rerturns the number of milliseconds between two times in the format "hh:mm-hh:mm"
 * @param timeRangeString
 * @returns number
 */
export const findMilliseconds = (timeRangeString: string): number => {
    // Split the input string by the dash to separate start and end times
    const [startTime, endTime] = timeRangeString.split('-');

    if (!startTime || !endTime) {
        return 0;
    }

    // Create a base date (can be any day, as we care only about the time difference)
    const baseDate = '1970-01-01';

    // Convert start and end times to Date objects
    const startDateTime = new Date(`${baseDate}T${startTime}:00`);
    const endDateTime = new Date(`${baseDate}T${endTime}:00`);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endDateTime.getTime() - startDateTime.getTime();

    // Return the difference
    return differenceInMilliseconds;
};

/**
 * Returns the current day boundaries in an array of two Date objects
 * @returns [Date, Date]
 */
export const getDayBoundaries = (): [Date, Date] => {
    const startDateTime = new Date();
    startDateTime.setHours(0, 0, 0, 0); // Set to midnight of the current day

    const endDateTime = new Date();
    endDateTime.setHours(23, 59, 59, 999); // Set to the last millisecond of the current day

    return [startDateTime, endDateTime];
};

declare global {
    interface Date {
        addDays(days: number): Date;
        addMinutes(minutes: number): Date;
    }
}

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.addMinutes = function (minutes) {
    return new Date(this.getTime() + minutes * 60000);
};
