import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

export const useStyles = makeStyles(() =>
    createStyles({
        cameraImageLoaded: {
            width: '100%',
            height: '100%',
            display: 'flex',
            margin: '0 auto',
            aspectRatio: '16/9',
            objectFit: 'contain',
            minHeight: '135px',
        },
        cameraImageLoading: {
            
        },
        cameraImageError: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            margin: '0 auto',
        },
        loadingProgress: {
            marginTop: 'auto',
        },
    }),
);
