import { END, EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import { call, cancelled, put, take } from 'redux-saga/effects';
import { fetchGoogleCalendarEventsSucccess } from './gcalActions';
import { FetchArgusEvents } from './gcalAPIs';
import { IGoogleCalendarEventItem, IGoogleCalendarEvents } from './gcalInterfaces';
import { logger } from '../../services/Logger';
import { GCAL_POLLING_INTERVAL } from '../../constants';

let eventChannelInstance: EventChannel<{ [key: string]: IGoogleCalendarEventItem }>;

/**
 * Emits Google Calendar events for ARGUS
 * @param emitter
 */
const googleCalendarEventInterval = async (emitter: (arg0: { [key: string]: IGoogleCalendarEvents }) => void) => {
    logger.debug('Polling for Google events');
    const events = await FetchArgusEvents();
    emitter(events);
};

/**
 * Returns an eventChannel for updating Google calendar events
 * @param calendars
 * @returns
 */
export const pollGoogleCalendar = (): EventChannel<{ [key: string]: IGoogleCalendarEvents }> => {
    return eventChannel((emitter) => {
        googleCalendarEventInterval(emitter);

        const pollingInterval = setInterval(googleCalendarEventInterval, GCAL_POLLING_INTERVAL, emitter);

        return () => {
            clearInterval(pollingInterval);
            emitter(END);
        };
    });
};

/**
 * Subscribes to the Google calendar event stream
 */
export function* gcalSubscribe(): SagaIterator {
    eventChannelInstance = yield call(pollGoogleCalendar);
    try {
        while (true) {
            const events = yield take(eventChannelInstance);
            yield put(fetchGoogleCalendarEventsSucccess(events));
        }
    } catch (error) {
        logger.error(error);
    } finally {
        if (yield cancelled()) {
            eventChannelInstance.close();
            logger.info('gcal channel closed');
        }
    }
}

/**
 * Unsubscribe to gcal event channel
 */
export function* gcalUnsubscribe(): SagaIterator {
    logger.debug('Unsubscribing gcal channel');
    if (eventChannelInstance) yield call(eventChannelInstance.close);
}
