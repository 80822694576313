import React from 'react';
import { createStyles, makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { ISnackbar } from '../redux/application/applicationInterfaces';
import { CLOSE_SNACKBAR } from '../redux/application/applicationActionTypes';
import { ApplicationActionTypes } from '../redux/application/applicationTypes';
import { AppState } from '../redux/AppState';
import { Dispatch } from 'redux';
import Typography from '@material-ui/core/Typography';

interface IShowSnackbarProps {
    messages: ISnackbar[];
    closeSnackbar: (message: ISnackbar) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        snackContainer: {
            minWidth: '400px',
            maxWidth: '30%',
            position: 'absolute',
            top: '65px',
            right: '10px',
            zIndex: 1400,
        },
        snackbar: {
            position: 'static',
            transform: 'none',
            margin: '.5em 0',
            display: 'block',
        },
    }),
);

const ShowSnackbar: React.FC<IShowSnackbarProps> = (props) => {
    const callCloseSnackbar = (snackbar: ISnackbar) => {
        props.closeSnackbar(snackbar);
    };

    // The Snackbar component has a 'helpful' clickaway listener
    // https://v4.mui.com/api/snackbar/#props
    // This disables the default behavior when passed to the ClickAwayListenerProps
    // Snackbar component prop.
    const onClickAway = () => {
        return false;
    };

    const classes = useStyles();

    return (
        <>
            {props.messages.length > 0 && (
                <div className={classes.snackContainer}>
                    {props.messages.map((message) => {
                        return (
                            <Snackbar
                                open={message.open}
                                onClose={() => callCloseSnackbar(message)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                autoHideDuration={4000}
                                disableWindowBlurListener={true}
                                key={message.messageID}
                                id={message.messageID as string}
                                className={classes.snackbar}
                                ClickAwayListenerProps={{ onClickAway }}
                                transitionDuration={300}
                            >
                                <Alert
                                    severity={message.messageType}
                                    key={message.messageID}
                                    onClose={() => callCloseSnackbar(message)}
                                    variant="filled"
                                    elevation={18}
                                >
                                    {<Typography>{message.message}</Typography>}

                                </Alert>
                            </Snackbar>
                        );
                    })}
                </div>
            )}
        </>
    );
};

const MapStateToProps = (state: AppState) => ({
    messages: state.app.snackbars,
});

const MapDispatchToProps = (dispatch: Dispatch<ApplicationActionTypes>) => ({
    closeSnackbar: (message: ISnackbar) => dispatch({ type: CLOSE_SNACKBAR, payload: message }),
});

export default connect(MapStateToProps, MapDispatchToProps)(ShowSnackbar);
