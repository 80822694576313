/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOG_LEVEL } from '../constants';

export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

export interface Logger {
    debug: LogFn;
    info: LogFn;
    log: LogFn;
    warn: LogFn;
    error: LogFn;
}
export type LogLevel = 'debug' | 'log' | 'warn' | 'info' | 'error';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NO_OP: LogFn = (_message?: any, ..._optionalParams: any[]) => {
    return;
};

export class ConsoleLogger implements Logger {
    readonly debug: LogFn;
    readonly log: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;
    readonly info: LogFn;

    constructor(options?: { logLevel?: LogLevel }) {
        const { logLevel } = options || {};

        this.error = console.error.bind(console);

        if (logLevel === 'error') {
            this.warn = NO_OP;
            this.debug = NO_OP;
            this.info = NO_OP;
            this.log = NO_OP;
            return;
        }

        this.warn = console.warn.bind(console);

        if (logLevel === 'warn') {
            this.debug = NO_OP;
            this.info = NO_OP;
            this.log = NO_OP;
            return;
        }

        this.info = console.info.bind(console);

        if (logLevel === 'info') {
            this.log = NO_OP;
            this.debug = NO_OP;
            return;
        }

        this.log = console.log.bind(console);

        if (logLevel === 'log') {
            this.debug = NO_OP;
            return;
        }

        this.debug = console.log.bind(console);
        return;
    }
}

export const logger = new ConsoleLogger({ logLevel: LOG_LEVEL });
