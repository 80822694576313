import { AppBar } from '@material-ui/core';
import { REACT_APP_VERSION } from '../constants';

export const PageFooter: React.VFC = () => {
    return (
        <AppBar
            position="fixed"
            color="default"
            style={{
                background: '#f4f5f7',
                marginTop: '2rem',
                padding: '.5rem',
                top: 'auto',
                bottom: 0,
                textAlign: 'right',
            }}
        >
            {REACT_APP_VERSION}
        </AppBar>
    );
};
