import { GridCellValue, GridColDef } from '@material-ui/data-grid';
import {
    renderCourseCell,
    renderServiceOptionsCell,
    renderServiceRecordType,
    renderServiceRequestComment,
    renderStatusCell,
} from './ServiceRequestGridRowRenderers';

export const ServiceRequestsDataGridColDef: GridColDef[] = [
    {
        field: 'courseID',
        headerName: 'Course',
        width: 175,
        sortable: true,
        disableColumnMenu: true,
        renderCell: renderCourseCell,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 140,
        sortable: true,
        disableColumnMenu: true,
        renderCell: renderStatusCell,
        sortComparator: (v1: GridCellValue, v2: GridCellValue): number => {
            if (v1 === v2) {
                return 0;
            }

            if (v1 === null || typeof v1 === 'undefined') {
                return 1;
            }

            if (v2 === null || typeof v2 === 'undefined') {
                return -1;
            }

            if (v1 !== v2 && v1 === 'PENDING') return -1;
            if (v1 !== v2 && v2 === 'PENDING') return 1;

            return v1 < v2 ? -1 : 1;
        },
    },
    { field: 'instructorID', headerName: 'Instructor', width: 100, sortable: false, disableColumnMenu: true },
    {
        field: 'recordingOption',
        headerName: 'Record Option',
        width: 160,
        sortable: true,
        disableColumnMenu: true,
        renderCell: renderServiceRecordType,
    },
    { field: 'autoPublish', headerName: 'Auto Pub', width: 100, sortable: false, disableColumnMenu: true, hide: true },
    {
        field: 'recordDiscussions',
        headerName: 'Discussions',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
    {
        field: 'serviceOptions',
        headerName: 'Options',
        width: 90,
        renderCell: renderServiceOptionsCell,
        sortable: false,
        disableColumnMenu: true,
    },
    {
        field: 'comment',
        headerName: 'Comments',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderServiceRequestComment,
    },
    {
        field: 'requestedByID',
        headerName: 'Requested By',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
    {
        field: 'requestedAt',
        headerName: 'Requested At',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
    {
        field: 'confirmedByID',
        headerName: 'Confirmed By',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
    {
        field: 'confirmedAt',
        headerName: 'Confirmed At',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
    {
        field: 'notifiedAt',
        headerName: 'Notified At',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
    {
        field: 'cancelledByID',
        headerName: 'Cancelled By',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
    {
        field: 'cancelledAt',
        headerName: 'Cancelled At',
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        hide: true,
    },
];
