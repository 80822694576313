import useSWR from 'swr';
import { BASE_URL, TERM_SELECT_PRIOR_TERM_COUNT } from '../constants';
import { fetcher, getCurrentTermcode } from '../utils/helpers';
import { FetchError } from '../redux/application/applicationInterfaces';
import { ITerm } from '../redux/term/termInterfaces';

type TermData = {
    terms: ITerm[] | undefined;
    isLoadingTerms: boolean;
    isTermsError: FetchError;
};

const filterPriorTerms = (terms: ITerm[] | undefined) => {
    if (!terms) return terms;

    return terms.slice(
        Math.max(0, terms.findIndex((t) => t.termCode === getCurrentTermcode(terms)) - TERM_SELECT_PRIOR_TERM_COUNT),
        terms.length,
    );
};

export const useTerms = (isLoggedIn: boolean): TermData => {
    const { data, error } = useSWR<ITerm[]>(isLoggedIn ? `${BASE_URL}/terms` : null, fetcher, {
        refreshInterval: 10 * 60 * 1000,
        fallbackData: [],
        revalidateOnMount: true,
    });

    return {
        terms: filterPriorTerms(data),
        isLoadingTerms: !error && !data,
        isTermsError: error,
    };
};
