import axios from 'axios';
import { BASE_URL, DEFAULT_REQUEST_PARAMS } from '../../constants';
import { IGoogleCalendarEventItem, IGoogleCalendarEvents, IGoogleCalendarListItem } from './gcalInterfaces';

/**
 * Fetches LC Google Calendars
 * @returns 
 */
export async function FetchGoogleCalendars(): Promise<IGoogleCalendarListItem[]> {
    const url = BASE_URL + `/gcal/calendars`;
    const response = await axios.get(url, { withCredentials: true, headers: DEFAULT_REQUEST_PARAMS });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error fetching Google calendars. Please check your network settings.';
        throw new Error(errorMessage);
    }

    return response.data.items;
}

/**
 * Fetches events for a specified Google Calendar
 * @param calendarID 
 * @returns 
 */
export async function FetchGoogleCalendarEvents(calendarID: string): Promise<IGoogleCalendarEventItem[]> {
    const url = BASE_URL + `/gcal/calendars/${calendarID}/events`;
    const response = await axios.get(url, { withCredentials: true, headers: DEFAULT_REQUEST_PARAMS });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error fetching Google calendar events. Please check your network settings.';
        throw new Error(errorMessage);
    }

    return response.data.items;
}

export async function FetchArgusEvents(): Promise<{[key: string]: IGoogleCalendarEvents}>{
    const url = BASE_URL + `/gcal/calendars/argus`;
    const response = await axios.get(url, { withCredentials: true, headers: DEFAULT_REQUEST_PARAMS });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error fetching Google calendar events. Please check your network settings.';
        throw new Error(errorMessage);
    }

    return response.data;
}
