import KurentoUtils, { WebRtcPeer } from 'kurento-utils';
import KurentoClient, { IceCandidate, MediaPipeline, WebRtcEndpoint } from 'kurento-client';
import { IKurentoService, IKurentoServiceProps } from './KurentoServiceInterfaces';
import { logger } from '../services/Logger';
import { KURENTO_WS_URI } from '../constants';

/**
 * Creates a webRTC stream from an rtsp stream
 * @name KurentoService
 * @constructor
 * @param {IKurentoServiceProps} config - a KurentoService config object
 */
export const KurentoService = ({ options, rtspURI, wsURI = KURENTO_WS_URI }: IKurentoServiceProps): IKurentoService => {
    let pipeline: MediaPipeline | null;
    let webRtcPeer: WebRtcPeer | null;
    let kurentoClient: KurentoClient.ClientInstance | null;
    let webRtcEndpoint: WebRtcEndpoint | null;

    start();

    async function start() {
        kurentoClient = await KurentoClient.getSingleton(wsURI, {
            failAfter: 2,
        });

        webRtcPeer = await KurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, (error) => {
            if (error) return onError(error);
            if (!webRtcPeer) {
                throw new Error('Could not create WebRTC peer receiver');
            }

            webRtcPeer.generateOffer(onOffer);
            webRtcPeer.peerConnection.addEventListener(
                'iceconnectionstatechange',
                function (this: RTCPeerConnection, event: Event) {
                    if (this) {
                        logger.debug(event);
                        logger.debug('oniceconnectionstatechange -> ' + this.iceConnectionState);
                        logger.debug('icegatheringstate -> ' + this.iceGatheringState);
                    }
                },
            );

            webRtcPeer.peerConnection.addEventListener('signalingstatechange', function (event: Event) {
                logger.debug('*********** signalingstatechange *******************');
                logger.debug(event);
            });
        });
    }

    async function onOffer(error: string | undefined, sdpOffer: string) {
        if (error) return onError(error);

        if (kurentoClient) {
            pipeline = await kurentoClient.create('MediaPipeline');
            logger.debug('************ MediaPipeline ***********');
            logger.debug(pipeline);
            const playerEndpoint = await pipeline.create('PlayerEndpoint', {
                uri: rtspURI,
            });

            playerEndpoint.on('EndOfStream', () => {
                logger.info('PlayerEndpoint received EndOfStream.');
                logger.info('Closing remote peer connection');
                killstream();
            });

            playerEndpoint.on('ElementConnected', (event) => {
                logger.debug(event);
            });

            webRtcEndpoint = await pipeline.create('WebRtcEndpoint');
            if (!webRtcPeer) throw new Error('WebRTCPeer is null!');

            await setIceCandidateCallbacks(webRtcEndpoint, webRtcPeer);

            const sdpAnswer = await webRtcEndpoint.processOffer(sdpOffer);
            logger.debug("*********** SD ANSWER ************");
            logger.debug(sdpAnswer);

            await webRtcEndpoint.gatherCandidates();
            if (webRtcPeer) {
                logger.debug('********* WebRTCPeer ************');
                logger.debug(webRtcPeer);

                webRtcPeer.processAnswer(sdpAnswer);
                await playerEndpoint.connect(webRtcEndpoint);
                logger.debug('PlayerEndpoint-->WebRtcEndpoint connection established');

                await playerEndpoint.play();
                logger.debug('PlayerEndpoint playing...');
            }
        }
    }

    async function setIceCandidateCallbacks(webRtcEndpoint: KurentoClient.WebRtcEndpoint, webRtcPeer: WebRtcPeer) {
        // webRtcPeer.on('icecandidate', async function (candidate: RTCIceCandidate) {
        //     logger.debug('Local IceCandidate ' + JSON.stringify(candidate));
        //     await webRtcEndpoint.addIceCandidate(candidate);
        // });

        webRtcEndpoint.on(
            'OnIceCandidate',
            async function (event: KurentoClient.Event<'OnIceCandidate', { candidate: IceCandidate }>) {
                const { candidate } = event;
                logger.debug('Remote IceCandidate ' + JSON.stringify(candidate));
                await webRtcPeer.addIceCandidate(candidate as RTCIceCandidate, onError);
            },
        );
    }

    async function changePlayerEndpoint(rtspURI: string) {
        if (kurentoClient && !pipeline) {
            try {
                pipeline = await kurentoClient.create('MediaPipeline');
            } catch (error) {
                throw new Error('Pipeline is null and could not create new instance');
            }
        }

        if (!pipeline) {
            throw new Error('No media pipeline!');
        }

        if (!webRtcEndpoint) {
            throw new Error('No WebRTCEndpoint!');
        }

        const playerEndpoint = await pipeline.create('PlayerEndpoint', {
            uri: rtspURI,
        });

        playerEndpoint.on('EndOfStream', () => {
            killstream();
        });

        playerEndpoint.on('ElementConnected', (event) => {
            logger.debug(event);
        });

        await playerEndpoint.connect(webRtcEndpoint);
        logger.debug('PlayerEndpoint-->WebRtcEndpoint connection established');
        await playerEndpoint.play();
        logger.debug('PlayerEndpoint playing...');
    }

    function onError(error: string | undefined) {
        if (error) {
            logger.error(error);
        }
    }

    function killstream() {
        logger.debug('Service shutdown...');
        if (webRtcPeer) {
            webRtcPeer.dispose();
            webRtcPeer = null;
            logger.debug('webRTC Peer disposed...');
        }
        if (pipeline) {
            pipeline.release();
            pipeline = null;
            logger.debug('pipeline released...');
        }
    }

    function disconnect() {
        killstream();
        if (kurentoClient) {
            kurentoClient.close();
            logger.info('Kurento client closed');
        }
    }

    return {
        killstream,
        start,
        disconnect,
        changePlayerEndpoint,
    };
};
