import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { AppState } from '../redux/AppState';
import { getPermissionsFromRoles, hasPermission, isAuthenticated } from '../services/AuthService';
import { logger } from '../services/Logger';

export interface PrivateRouteProps extends RouteProps {
    authenticationPath: string;
    unauthorizedPath: string;
    scopes: string[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const { authenticationPath, unauthorizedPath, scopes } = props;

    if (!isAuthenticated()) {
        logger.error('NOT AUTHENTICATED. No auth cookie.');
        return (
            <Route {...props} component={() => <Redirect to={{ pathname: authenticationPath }} />} render={undefined} />
        );
    }

    const { roles } = useSelector((state: AppState) => state.user, shallowEqual);

    const permissionGranted = hasPermission({ permissions: getPermissionsFromRoles(roles), scopes });

    if (!permissionGranted) {
        logger.error('Permission denied');
        return (
            <Route {...props} component={() => <Redirect to={{ pathname: unauthorizedPath }} />} render={undefined} />
        );
    }

    return <Route {...props} />;
};
