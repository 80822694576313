import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const CourseDetailsContainerStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        fixedHeight: {
            height: 240,
        },
        chipContainer: {
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: 0,
            margin: '0 0 1rem 0',
        },
        chip: {
            margin: theme.spacing(0.5),
            '&.arboretum': {
                backgroundColor: '#6CCA98',
            },
        },
        sidebarHead: {
            display: 'flex',
            '& button': {
                marginLeft: 'auto',
            },
        },
        success: {
            backgroundColor: '#6CCA98',
        },
        newItemFAB: {
            margin: '0 0 -1.2rem 0',
            zIndex: 99,
        },
        dataGrid: {
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
        },
    }),
);
