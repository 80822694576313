import { GridFilterModel, GridPageChangeParams, GridSortModel } from '@material-ui/data-grid';
import { ICourseMeetingData } from '../courseMeeting/courseMeetingInterfaces';
import {
    SHOW_SNACKBAR,
    CLOSE_SNACKBAR,
    SHOW_COURSE_MEETING_FORM,
    CLOSE_COURSE_MEETING_FORM,
    SHOW_POPOVER,
    CLOSE_POPOVER,
    SHOW_NEW_COURSE_FORM,
    CLOSE_NEW_COURSE_FORM,
    SET_ACTIVE_TAB,
    TOGGLE_APP_DRAWER,
    SET_DATAGRID_PAGE,
    SET_DATAGRID_FILTER_MODEL,
    SET_ARGUS_SORT_MODEL,
    SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL,
    SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL,
    SET_SERVICE_REQUESTS_DATAGRID_PAGE,
    SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE,
    SET_ARGUS_FILTER_MODEL,
} from './applicationActionTypes';

export interface IApplicationState {
    snackbars: ISnackbar[];
    popover: IPopoverProps;
    courseMeetingForm: ICourseMeetingForm;
    newCourseFormOpen: boolean;
    appDrawerOpen: boolean;
    activeCourseTab: number;
    courseDataGridState: DataGridState;
    argusGridState: ArgusDataGridState;
    serviceRequestsGridState: ServiceRequestsDataGridState;
}

export class FetchError extends Error {
    public name = 'FetchError';
    public info: string | undefined = undefined;
    public status: number | undefined = undefined;
    constructor(public message: string = '', info?: string, status?: number) {
        super(message);
        this.info = info;
        this.status = status;
    }
}

export interface ServiceRequestsDataGridState {
    sortModel: GridSortModel;
    filterModel: GridFilterModel | undefined;
    perPage: number;
    page: number;
}

export interface ArgusDataGridState {
    sortModel: GridSortModel;
    filterModel: GridFilterModel | undefined;
}

export interface DataGridState {
    perPage: number;
    page: number;
    filterModel: GridFilterModel | undefined;
}

export interface ISnackbar {
    messageType: 'error' | 'success' | 'info' | 'warning';
    message: string | undefined;
    messageID: string | number;
    open: boolean;
}

export interface ICourseMeetingForm {
    isOpen: boolean;
    initialValues: ICourseMeetingData;
    formType: 'new' | 'edit' | 'bulk';
    formHeading: string;
}

export interface SetServiceRequestDataGridFilterModelAction {
    type: typeof SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL;
    payload: GridFilterModel;
}

export interface SetServiceRequestDataGridSortModelAction {
    type: typeof SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL;
    payload: GridSortModel;
}

export interface SetServiceRequestsDataGridPageAction {
    type: typeof SET_SERVICE_REQUESTS_DATAGRID_PAGE;
    payload: GridPageChangeParams;
}

export interface SetServiceRequestsDataGridPerPageAction {
    type: typeof SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE;
    payload: GridPageChangeParams;
}

export interface SetArgusSortModel {
    type: typeof SET_ARGUS_SORT_MODEL;
    payload: GridSortModel;
}

export interface SetArgusFilterModel {
    type: typeof SET_ARGUS_FILTER_MODEL;
    payload: GridFilterModel;
}

export interface SetDataGridPage {
    type: typeof SET_DATAGRID_PAGE;
    payload: GridPageChangeParams;
}

export interface SetDataGridFilterModel {
    type: typeof SET_DATAGRID_FILTER_MODEL;
    payload: GridFilterModel;
}

export interface ShowSnackbarAction {
    type: typeof SHOW_SNACKBAR;
    payload: ISnackbar;
}

export interface CloseSnackbarAction {
    type: typeof CLOSE_SNACKBAR;
    payload: ISnackbar;
}

export interface ShowPopoverAction {
    type: typeof SHOW_POPOVER;
    payload: IPopoverProps;
}

export interface ClosePopoverAction {
    type: typeof CLOSE_POPOVER;
    payload: undefined;
}

export interface ShowCourseMeetingFormAction {
    type: typeof SHOW_COURSE_MEETING_FORM;
    payload: ICourseMeetingData;
    formType: 'new' | 'edit' | 'bulk';
    formHeading: string;
}

export interface CloseCourseMeetingFormAction {
    type: typeof CLOSE_COURSE_MEETING_FORM;
    payload: undefined;
}

export interface ShowNewCourseFormAction {
    type: typeof SHOW_NEW_COURSE_FORM;
    payload: undefined;
}

export interface CloseNewCourseFormAction {
    type: typeof CLOSE_NEW_COURSE_FORM;
    payload: undefined;
}

export interface IPopoverProps {
    title: string;
    top: number;
    left: number;
    open: boolean;
    handleClose: () => void;
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface SetActiveTab {
    type: typeof SET_ACTIVE_TAB;
    payload: number;
}

export interface ToggleAppDrawer {
    type: typeof TOGGLE_APP_DRAWER;
}
