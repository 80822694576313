import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const NewCourseFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        input: {
            minWidth: 200,
        },
        formControl: {
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(2),
            //minWidth: 200,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);
