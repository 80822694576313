import {
    FETCH_COURSES_SUCCESS,
    FETCH_COURSES_FAILURE,
    COURSE_CONFIRM_REQUEST,
    COURSE_CONFIRM_SUCCESS,
    COURSE_CONFIRM_FAILURE,
    CREATE_COURSE_REQUEST,
    CREATE_COURSE_SUCCESS,
    CREATE_COURSE_FAILURE,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    PUT_COURSE_REQUEST,
    PUT_COURSE_SUCCESS,
    PUT_COURSE_FAILURE,
} from './courseActionTypes';
import { ICoursesState } from './courseInterfaces';
import { CourseActionTypes } from './courseTypes';

const coursesInitialState: ICoursesState = {
    isFetchingCourses: false,
    isCreatingCourse: false,
    isDeletingCourses: false,
    isPuttingCourse: false,
    courses: [],
    bannerCourseIDs: [],
    error: undefined,
};

const courseReducer = (state: ICoursesState = coursesInitialState, action: CourseActionTypes): ICoursesState => {
    switch (action.type) {
        case CREATE_COURSE_REQUEST:
            return {
                ...state,
                isCreatingCourse: true,
                error: undefined,
            };

        case CREATE_COURSE_SUCCESS:
            return {
                ...state,
                isCreatingCourse: false,
                error: undefined,
                courses: [...new Set([...state.courses, action.payload])].sort((a, b) =>
                    a.subject.localeCompare(b.subject),
                ),
            };

        case CREATE_COURSE_FAILURE:
            return {
                ...state,
                isCreatingCourse: false,
                error: action.payload,
            };

        case PUT_COURSE_REQUEST:
            return {
                ...state,
                isPuttingCourse: true,
                error: undefined,
            };

        case PUT_COURSE_SUCCESS:
            return {
                ...state,
                isPuttingCourse: false,
                error: undefined,
                focusedCourse: action.payload,
            };

        case PUT_COURSE_FAILURE:
            return {
                ...state,
                isPuttingCourse: false,
                error: action.payload,
            };

        case COURSE_CONFIRM_REQUEST:
            return {
                ...state,
                error: undefined,
                isFetchingCourses: true,
            };

        case COURSE_CONFIRM_SUCCESS:
            return {
                ...state,
                error: undefined,
                isFetchingCourses: false,
                courses: [...new Set([...state.courses, action.payload])],
            };

        case COURSE_CONFIRM_FAILURE:
            return {
                ...state,
                isFetchingCourses: false,
                error: action.payload,
            };

        case FETCH_COURSES_SUCCESS:
            return {
                ...state,
                isFetchingCourses: false,
                error: undefined,
            };

        case FETCH_COURSES_FAILURE:
            return {
                ...state,
                isFetchingCourses: false,
                error: action.payload,
            };

        case DELETE_COURSE_REQUEST:
            return {
                ...state,
                isDeletingCourses: true,
                error: undefined,
            };

        case DELETE_COURSE_SUCCESS:
            return {
                ...state,
                isDeletingCourses: false,
                error: undefined,
                courses: state.courses.filter(
                    (c) => !(c.courseID === action.payload.courseID && c.entityType === action.payload.entityType),
                ),
            };

        default:
            return state;
    }
};

export default courseReducer;
