import React from 'react';
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/AppState';
import CourseMeetingCard from '../components/Meeting/CourseMeetingCard';
import { timeOfDayString } from '../utils/helpers';
import { SCOPES } from '../constants';
import { PermissionGate } from '../components/PermissionGate';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        coursesGridItem: {
            flexGrow: 1,
        },
        fixedHeight: {
            height: 240,
        },
    }),
);

const Dashboard: React.FC = () => {
    const { username } = useSelector((state: AppState) => state?.user);

    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container alignItems="stretch" direction="row" spacing={0}>
                    <Grid item lg={12} className={classes.coursesGridItem}>
                        <h1>Dashboard</h1>
                        <Typography align="left">
                            Good {timeOfDayString(new Date())}, {username}
                        </Typography>
                        <PermissionGate scopes={[SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete]}>
                            <CourseMeetingCard title="Scheduled Recordings" />
                        </PermissionGate>
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
};

export default Dashboard;
