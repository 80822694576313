import jwt_decode from 'jwt-decode';
import { persistor } from '../redux/store';
import { JWTToken, TokenResponse } from './AuthInterfaces';
import { AUTH_COOKIE_NAME, COOKIE_DOMAIN, PERMISSIONS } from '../constants';
import { logger } from '../services/Logger';

export const logout = async (): Promise<void> => {
    document.cookie = `${AUTH_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Domain=${COOKIE_DOMAIN}; path=/;`;
    await persistor.persist();
    await persistor.flush();
    await persistor.pause();
    await persistor.purge();
};

export const getTokenFromQueryString = (): TokenResponse => {
    const hashParams = window.location.hash.replace('#', '');
    const searchParams: URLSearchParams = new URLSearchParams(hashParams);
    const token: string = searchParams.get('access_token') || '';
    const expires: number = parseInt(searchParams.get('expires_in') || '');

    if (token.length > 0 && expires > 0) {
        return {
            token,
            expires,
        };
    }

    throw new Error('Error getting token from QueryString.');
};

export const decodeToken = (token: string): JWTToken | undefined => {
    try {
        return jwt_decode<JWTToken>(token);
    } catch (error) {
        logger.error(error);
        return undefined;
    }
};

/**
 * Checks if the authenticated cookie has been set by the backend
 * and compares the expiration to the current time
 * @returns boolean
 */
export const isAuthenticated = (): boolean => {
    const authExpires = document?.cookie
        ?.split('; ')
        ?.find((row) => row.startsWith(`${AUTH_COOKIE_NAME}=`))
        ?.split('=')[1];

    if (!authExpires) return false;

    const currentTime = Math.trunc(Date.now() / 1000);
    return parseInt(authExpires) > currentTime;
};

export const hasPermission = ({ permissions, scopes }: { permissions: string[]; scopes: string[] }): boolean => {
    const scopesMap: { [key: string]: boolean } = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });

    return permissions.some((permission) => scopesMap[permission]);
};

export const getPermissionsFromRoles = (roles: string[]): string[] => {
    return roles.flatMap((role) => PERMISSIONS[role]);
};
