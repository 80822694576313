import { Form } from 'react-final-form';
import { setIn } from 'final-form';
import { TextField, Autocomplete } from 'mui-rff';
import * as yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, DialogActions, MenuItem, Theme } from '@material-ui/core';
import { ALL_APPLICATION_ROLES, UC_EMPLOYEE_AFFILIATIONS } from '../../../constants';
import { IUser } from '../../../redux/user/userInterfaces';

interface IUserData {
    loginID: string;
    roles: string[];
    firstName: string;
    lastName: string;
    affiliation: string;
    email: string;
}
interface IUserFormProps {
    handleSubmit: (newUserProps: IUser) => void;
    handleClose: () => void;
    initialValues: IUser;
    isOpen: boolean;
    formTitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            minWidth: 200,
        },
        formControl: {
            marginBottom: theme.spacing(2),
            minWidth: 200,
        },
    }),
);

// yup form validation schema
const validationSchema: yup.SchemaOf<IUserData> = yup
    .object()
    .shape({
        loginID: yup.string().required('CAS Login ID is required').defined('CAS Login ID is required'),
        firstName: yup.string().required().defined(),
        lastName: yup.string().required().defined(),
        affiliation: yup.string().required().defined(),
        email: yup.string().email().required().defined(),
        roles: yup.array(yup.string()),
    })
    .defined();

const validateFormValues = (schema: typeof validationSchema) => async (values: never) => {
    try {
        await schema.validate(values, { abortEarly: false });
    } catch (err) {
        // Disabling this eslint rule because yup types formError as 'object'
        // eslint-disable-next-line @typescript-eslint/ban-types
        const errors = err.inner.reduce((formError: object, innerError: { path: string; message: never }) => {
            return setIn(formError, innerError.path, innerError.message);
        }, {});
        return errors;
    }
};

const validate = validateFormValues(validationSchema);

const roleOptions = ALL_APPLICATION_ROLES.map((role) => ({ label: role, value: role }));

export const UserForm: React.FC<IUserFormProps> = (props: IUserFormProps) => {
    const classes = useStyles();

    const { handleSubmit, handleClose, initialValues, formTitle, isOpen } = props;

    const tempHandleFormSubmit = (formData: IUserData) => {
        handleSubmit(formData);
    };

    return (
        <div>
            <Form
                onSubmit={tempHandleFormSubmit}
                initialValues={initialValues}
                validate={validate}
                render={(formProps) => {
                    const { handleSubmit, submitting } = formProps;

                    return (
                        <div>
                            <Dialog
                                open={isOpen}
                                onClose={handleClose}
                                aria-labelledby="form-dialog-title"
                                maxWidth="xs"
                            >
                                <DialogTitle id="form-dialog-title">{formTitle}</DialogTitle>
                                <DialogContent>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Grid container alignItems="flex-start" spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControl className={classes.formControl} fullWidth={true}>
                                                    <TextField
                                                        id="loginID"
                                                        name="loginID"
                                                        label="CAS Login ID"
                                                        type="text"
                                                        fullWidth={true}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth={true}>
                                                    <TextField
                                                        id="firstName"
                                                        name="firstName"
                                                        label="First Name"
                                                        type="text"
                                                        fullWidth={true}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth={true}>
                                                    <TextField
                                                        id="lastName"
                                                        name="lastName"
                                                        label="Last Name"
                                                        type="text"
                                                        fullWidth={true}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth={true}>
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        label="Email"
                                                        type="text"
                                                        placeholder="@ucdavis.edu"
                                                        fullWidth={true}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth={true}>
                                                    <TextField
                                                        id="affiliation"
                                                        name="affiliation"
                                                        label="Affiliation"
                                                        select
                                                        type="text"
                                                        fullWidth={true}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    >
                                                        {UC_EMPLOYEE_AFFILIATIONS.map((affl) => (
                                                            <MenuItem key={affl} value={affl}>
                                                                {affl}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                                <FormControl className={classes.formControl} fullWidth={true}>
                                                    <Autocomplete
                                                        label="Roles"
                                                        id="roles"
                                                        name="roles"
                                                        multiple={true}
                                                        options={roleOptions}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        disableCloseOnSelect={true}
                                                        helperText="Select user roles"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DialogActions>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        onClick={handleClose}
                                                        disabled={submitting}
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={submitting}
                                                    >
                                                        Submit
                                                    </Button>
                                                </DialogActions>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </DialogContent>
                            </Dialog>
                        </div>
                    );
                }}
            />
        </div>
    );
};
