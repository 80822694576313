import { withStyles } from '@material-ui/core';
import Rating, { IconContainerProps } from '@material-ui/lab/Rating';
import React from 'react';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import LabelIcon from '@material-ui/icons/Label';
import { rescale } from '../../utils/helpers';

const LabelImportantIconMemo = React.memo(LabelImportantIcon);
const LabelIconMemo = React.memo(LabelIcon);

const customStorageIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
    1: {
        icon: <LabelIconMemo className="storage20" />,
        label: '20%',
    },
    2: {
        icon: <LabelImportantIconMemo className="storage40" />,
        label: '40%',
    },
    3: {
        icon: <LabelImportantIconMemo className="storage60" />,
        label: '60%',
    },
    4: {
        icon: <LabelImportantIconMemo className="storage80" />,
        label: '80%',
    },
    5: {
        icon: <LabelImportantIconMemo className="storage100" />,
        label: '100%',
    },
};

const storageIconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return <span {...other}>{customStorageIcons[value].icon}</span>;
};

export const StyledRating = withStyles({
    iconFilled: {
        color: '#00B2E3',
        '& .storage20': {
            marginRight: '.094rem',
        },
        '& .storage60': {
            color: '#00C4B3',
        },
        '& .storage80': {
            color: '#F18A00',
        },
        '& .storage100': {
            color: '#C10230',
        },
    },
    icon: {
        margin: '0 -4px',
    },
})(Rating);

export interface IExtronStorageProps {
    value: number;
}

export const ExtronStorage: React.FC<IExtronStorageProps> = (props: IExtronStorageProps) => (
    <StyledRating
        value={rescale(props.value, 0, 100, 1, 5)}
        name="extron-storage"
        getLabelText={(value: number) => customStorageIcons[value].label}
        IconContainerComponent={storageIconContainer}
        readOnly
    />
);
