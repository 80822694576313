import { GridFilterModel, GridPageChangeParams, GridSortModel } from '@material-ui/data-grid';
import { ICourseMeetingData } from '../courseMeeting/courseMeetingInterfaces';
import {
    SHOW_SNACKBAR,
    CLOSE_SNACKBAR,
    SHOW_COURSE_MEETING_FORM,
    CLOSE_COURSE_MEETING_FORM,
    SHOW_POPOVER,
    CLOSE_POPOVER,
    SHOW_NEW_COURSE_FORM,
    CLOSE_NEW_COURSE_FORM,
    SET_ACTIVE_TAB,
    TOGGLE_APP_DRAWER,
    SET_DATAGRID_PAGE,
    SET_DATAGRID_FILTER_MODEL,
    SET_ARGUS_SORT_MODEL,
    SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL,
    SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL,
    SET_SERVICE_REQUESTS_DATAGRID_PAGE,
    SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE,
    SET_ARGUS_FILTER_MODEL,
} from './applicationActionTypes';
import { IPopoverProps, ISnackbar } from './applicationInterfaces';
import { ApplicationActionTypes } from './applicationTypes';

export function setServiceRequestsDataGridFilterModel(model: GridFilterModel): ApplicationActionTypes {
    return {
        type: SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL,
        payload: model,
    };
}

export function setServiceRequestsDataGridSortModel(model: GridSortModel): ApplicationActionTypes {
    return {
        type: SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL,
        payload: model,
    };
}

export function setServiceRequestsDataGridPage(page: GridPageChangeParams): ApplicationActionTypes {
    return {
        type: SET_SERVICE_REQUESTS_DATAGRID_PAGE,
        payload: page,
    };
}

export function setServiceRequestsDataGridPerPage(perPage: GridPageChangeParams): ApplicationActionTypes {
    return {
        type: SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE,
        payload: perPage,
    };
}

export function setArgusSortModel(model: GridSortModel): ApplicationActionTypes {
    return {
        type: SET_ARGUS_SORT_MODEL,
        payload: model,
    };
}

export function setArgusFilterModel(model: GridFilterModel): ApplicationActionTypes {
    return {
        type: SET_ARGUS_FILTER_MODEL,
        payload: model,
    }
}

export function setDataGridFilterModel(model: GridFilterModel): ApplicationActionTypes {
    return {
        type: SET_DATAGRID_FILTER_MODEL,
        payload: model,
    };
}

export function setDataGridPage(params: GridPageChangeParams): ApplicationActionTypes {
    return {
        type: SET_DATAGRID_PAGE,
        payload: params,
    };
}

export function setActiveTab(tabIndex: number): ApplicationActionTypes {
    return {
        type: SET_ACTIVE_TAB,
        payload: tabIndex,
    };
}

export function toggleAppDrawer(): ApplicationActionTypes {
    return {
        type: TOGGLE_APP_DRAWER,
    };
}

export function showSnackbar(message: ISnackbar): ApplicationActionTypes {
    return {
        type: SHOW_SNACKBAR,
        payload: message,
    };
}

export function closeSnackbar(message: ISnackbar): ApplicationActionTypes {
    return {
        type: CLOSE_SNACKBAR,
        payload: message,
    };
}

export function showPopover(props: IPopoverProps): ApplicationActionTypes {
    return {
        type: SHOW_POPOVER,
        payload: props,
    };
}

export function closePopover(): ApplicationActionTypes {
    return {
        type: CLOSE_POPOVER,
        payload: undefined,
    };
}

export function showCourseMeetingForm(
    initialValues: ICourseMeetingData,
    formType: 'new' | 'edit' | 'bulk',
    formHeading: string,
): ApplicationActionTypes {
    return {
        type: SHOW_COURSE_MEETING_FORM,
        payload: initialValues,
        formType,
        formHeading,
    };
}

export function closeCourseMeetingForm(): ApplicationActionTypes {
    return {
        type: CLOSE_COURSE_MEETING_FORM,
        payload: undefined,
    };
}

export function showNewCourseForm(): ApplicationActionTypes {
    return {
        type: SHOW_NEW_COURSE_FORM,
        payload: undefined,
    };
}

export function closeNewCourseForm(): ApplicationActionTypes {
    return {
        type: CLOSE_NEW_COURSE_FORM,
        payload: undefined,
    };
}
