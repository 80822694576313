// Google Calendar action types

// Fetch all Google calendars
export const FETCH_GOOGLE_CALENDARS_REQUEST = 'FETCH_GOOGLE_CALENDARS_REQUEST';
export const FETCH_GOOGLE_CALENDARS_SUCCESS = 'FETCH_GOOGLE_CALENDARS_SUCCESS';
export const FETCH_GOOGLE_CALENDARS_FAILURE = 'FETCH_GOOGLE_CALENDARS_FAILURE';

// Fetch events for a calendar
export const FETCH_GOOGLE_CALENDAR_EVENTS_REQUEST = 'FETCH_GOOGLE_CALENDAR_EVENTS_REQUEST';
export const FETCH_GOOGLE_CALENDAR_EVENTS_FAILURE = 'FETCH_GOOGLE_CALENDAR_EVENTS_FAILURE';
export const FETCH_GOOGLE_CALENDAR_EVENTS_SUCCESS = 'FETCH_GOOGLE_CALENDAR_EVENTS_SUCCESS';


// Subscribe to Google events channel
export const GOOGLE_CALENDAR_SUBSCRIBE = 'GOOGLE_CALENDAR_SUBSCRIBE';
export const GOOGLE_CALENDAR_UNSUBSCRIBE = 'GOOGLE_CALENDAR_UNSUBSCRIBE';