import { FETCH_TERMS_FAILURE, SELECT_TERM } from './termActionTypes';
import { IFetchTermsFailureAction, ISelectTermAction } from './termInterfaces';

export const fetchTermsFailure = (error: string): IFetchTermsFailureAction => {
    return {
        type: FETCH_TERMS_FAILURE,
        payload: error,
    };
};

export const selectTerm = (term: string): ISelectTermAction => {
    return {
        type: SELECT_TERM,
        payload: term,
    };
};
