import {
    FETCH_GOOGLE_CALENDARS_FAILURE,
    FETCH_GOOGLE_CALENDARS_REQUEST,
    FETCH_GOOGLE_CALENDARS_SUCCESS,
    FETCH_GOOGLE_CALENDAR_EVENTS_REQUEST,
    FETCH_GOOGLE_CALENDAR_EVENTS_SUCCESS,
    GOOGLE_CALENDAR_SUBSCRIBE,
    GOOGLE_CALENDAR_UNSUBSCRIBE,
} from './gcalActionTypes';
import {
    IFetchGoogleCalendarEventsRequestAction,
    IFetchGoogleCalendarEventsSuccessAction,
    IFetchGoogleCalendarsFailureAction,
    IFetchGoogleCalendarsRequestAction,
    IFetchGoogleCalendarsSuccessAction,
    IGoogleCalendarEvents,
    IGoogleCalendarListResponse,
    IGoogleCalendarSubscribeAction,
    IGoogleCalendarUnsubscribeAction,
} from './gcalInterfaces';

export const googleCalendarSubscribe = (): IGoogleCalendarSubscribeAction => {
    return {
        type: GOOGLE_CALENDAR_SUBSCRIBE,
    };
};

export const googleCalendarUnsubscribe = (): IGoogleCalendarUnsubscribeAction => {
    return {
        type: GOOGLE_CALENDAR_UNSUBSCRIBE,
    };
};

export const fetchGoogleCalendarsRequest = (): IFetchGoogleCalendarsRequestAction => {
    return {
        type: FETCH_GOOGLE_CALENDARS_REQUEST,
    };
};

export const fetchGoogleCalendarsSuccess = (
    calendarListResponse: IGoogleCalendarListResponse,
): IFetchGoogleCalendarsSuccessAction => {
    return {
        type: FETCH_GOOGLE_CALENDARS_SUCCESS,
        payload: calendarListResponse,
    };
};

export const fetchGoogleCalendarsFailure = (error: string): IFetchGoogleCalendarsFailureAction => {
    return {
        type: FETCH_GOOGLE_CALENDARS_FAILURE,
        payload: error,
    };
};

export const fetchGoogleCalendarEventsRequest = (): IFetchGoogleCalendarEventsRequestAction => {
    return {
        type: FETCH_GOOGLE_CALENDAR_EVENTS_REQUEST,
    };
};

export const fetchGoogleCalendarEventsSucccess = (events: {
    [key: string]: IGoogleCalendarEvents | undefined;
}): IFetchGoogleCalendarEventsSuccessAction => {
    return {
        type: FETCH_GOOGLE_CALENDAR_EVENTS_SUCCESS,
        payload: events,
    };
};
