import axios from 'axios';
import { BASE_URL, DEFAULT_REQUEST_PARAMS } from '../../constants';
import { ExtronCommandRequest } from './extronInterfaces';

export async function SendExtronCommand(command: ExtronCommandRequest): Promise<boolean> {
    const url = `${BASE_URL}/extron/command`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const response = await axios.post(url, command, { withCredentials: true, headers });
    if (response.status !== 200 || response.request.responseURL !== url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error issuing Extron command. Please check your network settings.';
        throw new Error(errorMessage);
    }

    return true;
}
