import React, { useCallback, ReactElement, JSXElementConstructor, useReducer, useEffect, MouseEvent } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { GridCellParams } from '@material-ui/data-grid';
import { Button, Chip, Tooltip, Typography } from '@material-ui/core';
import { AppState } from '../../redux/AppState';
import { ExtronStorage } from './ExtronStorage';
import { RECORDING_OPTIONS_ENUM } from '../../constants';

// Icons
import VolumeLevel from './VolumeLevel';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import FaceIcon from '@material-ui/icons/Face';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { capitalize, convertTo12HourFormat, formatCameraAdminURL, formatExtronAdminURL, getDayBoundaries } from '../../utils/helpers';
import { PersonChip } from '../User/PersonChip';
import { ArgusRecordingCell } from './ArgusRecordingCell';
import { useCourseMeetings } from '../../hooks/useCourseMeetings';

const VideocamIconMemo = React.memo(VideocamIcon);
const VideocamOffIconMemo = React.memo(VideocamOffIcon);
const VolumeLevelMemo = React.memo(VolumeLevel);
const FaceIconMemo = React.memo(FaceIcon);
const OpenInNewIconMemo = React.memo(OpenInNewIcon);

const PersonChipMemo = React.memo(PersonChip);

export const renderRoomNameCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const { building, roomNumber } = params.row;
    const handleClick = (event: MouseEvent) => {
        event.preventDefault();
        window.open(
            `/extron/${building}/${roomNumber}`,
            'extron',
            `height=325,width=440,toolbar=no,location=no",left=${window.screen.availWidth - 440},top=${
                window.screen.availHeight - 325
            }`,
        );
    };

    return (
        <Button style={{ textTransform: 'none', width: '100%' }} onClick={handleClick}>
            {params.value}
        </Button>
    );
};

export const renderExtronCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return (
        <>
            {params.value && (
                <Tooltip arrow title={params.value || ''}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        href={formatExtronAdminURL(params.value.toString())}
                        target="_blank"
                        endIcon={<OpenInNewIconMemo />}
                    >
                        Admin
                    </Button>
                </Tooltip>
            )}
        </>
    );
};

/**
 * Renders the Camera view button DataGrid cell
 * @param params
 * @returns
 */
export const renderCameraCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return params.row.camera?.ip ? (
        <Tooltip arrow title={params.row.camera.ip}>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                href={formatCameraAdminURL(params.row.camera.ip)}
                target="_blank"
                endIcon={<OpenInNewIconMemo />}
            >
                Admin
            </Button>
        </Tooltip>
    ) : (
        <VideocamOffIconMemo color="disabled" />
    );
};

/**
 * Renders the Camera stream view button DataGrid cell
 * @param params
 * @returns
 */
export const renderCameraViewCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return params.row.camera?.ip ? (
        <Button color="default" startIcon={<VideocamIconMemo color="primary" />}>
            View
        </Button>
    ) : (
        <VideocamOffIconMemo color="disabled" />
    );
};

/**
 * Renders the Extron used storage DataGrid cell
 * @param params
 * @returns
 */
export const renderStorageCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const { value } = params;
    if (value === -1) {
        return <Typography style={{ color: 'red' }}>Offline</Typography>;
    }

    return (
        <>
            <Tooltip title={`${value}%`} arrow>
                {(typeof value === 'number' && (
                    <div style={{ display: 'flex' }}>
                        <ExtronStorage value={value} />
                    </div>
                )) || <Typography style={{ color: 'red' }}>{value}</Typography>}
            </Tooltip>
        </>
    );
};

/**
 * Renders the Extron current recording state DataGrid cell
 * @param params
 * @returns
 */
export const renderRecordingCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const [, forceRender] = useReducer((c) => c + 1, 0);
    
    const recordingSelector = (state: AppState, host: string) => state.recorders.recorders[host];
    const recordingSelectorCallback = useCallback(recordingSelector, []);
    const recorderState = useSelector(
        (state: AppState) => recordingSelectorCallback(state, params.row.extron?.ip),
        shallowEqual,
    );

    const [startDateTime, endDateTime] = getDayBoundaries();
    const { courseMeetings } = useCourseMeetings(null, startDateTime, endDateTime);

    // Select meetings that are in this row's building and room number
    // and are scheduled for now or within one minute

    const currentMeeting = courseMeetings.find(
        (m) =>
            m.recordingOption != RECORDING_OPTIONS_ENUM.cancelled.value &&
            m.building === params.row.building &&
            m.roomNumber === params.row.roomNumber &&
            new Date(m.startDateTime) <= new Date(new Date().setMinutes(new Date().getMinutes() + 1)) &&
            new Date(m.endDateTime) >= new Date(),
    );

    useEffect(() => {
        const refresh = setInterval(forceRender, 10000);
        return () => {
            clearInterval(refresh);
        };
    }, []);

    return <ArgusRecordingCell recorderState={recorderState} currentMeeting={currentMeeting} />;
};

/**
 * Renders the Extron audio level DataGrid cell
 * @param params
 * @returns
 */
export const renderAudioCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return <VolumeLevelMemo volume={params.value?.toString()} />;
};

/**
 * Renders the Google Calendar staff assignment DataGrid cell
 * @param params
 * @returns
 */
export const renderStaffCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return (
        <div style={{ flexDirection: 'column', display: 'flex' }}>
            {(params?.getValue(params.id, 'staff') as string)?.split(',').map(
                (email: string) => {
                    return (
                        email && (
                            <PersonChipMemo
                                label={email}
                                key={`${email}-person-chip`}
                                icon={<FaceIconMemo style={{ color: '#022851' }} />}
                            />
                        )
                    );
                },
                [params.id],
            )}
        </div>
    );
};

export const renderGoogleCalendarNotesCell = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    return (
        <div>
            {params.value && (
                <Tooltip title={<p style={{ fontSize: '.9rem', lineHeight: '1.4rem' }}>{params.value}</p>} arrow>
                    <p>{params.value}</p>
                </Tooltip>
            )}
        </div>
    );
};

export const renderRecordTypeCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return params.value ? (
        <Chip label={capitalize(params.value.toString().replace(' and ', '/'))} size="small" variant="outlined" />
    ) : (
        <></>
    );
};

export const renderRecordTimeCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return params.value ? (
        <Typography variant="button">{convertTo12HourFormat(params.value.toString())}</Typography>
    ) : (
        <></>
    );
};
