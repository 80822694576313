import { JSXElementConstructor, ReactElement } from 'react';
import { GridCellParams, GridCellValue, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { MeetingTypeChip } from '../Course/MeetingTypeChip';
import { formatDateTime, renderCourseMeetingViewCourseDetails, renderLocationCell, renderStatusCell } from './CourseMeetingGridRowRenderers';
import { RECORDING_OPTIONS_ENUM } from '../../constants';
import { RecordTypeButtonGroup } from '../Course/RecordTypeButtonGroup';
import Typography from '@material-ui/core/Typography';

const courseMeetingStatusValueGetter = (params: GridValueGetterParams): GridCellValue | undefined => {
    const recordingOption = params.getValue(params.id, 'recordingOption') || null;
    const recorded = params.getValue(params.id, 'kalturaFTP') || null;
    const kalturaStatus = params.getValue(params.id, 'kalturaStatus') || null;
    const endDateTimeString = params.getValue(params.id, 'endDateTime') || null;

    let status =
        recordingOption && recordingOption !== RECORDING_OPTIONS_ENUM.cancelled.value
            ? 'scheduled'
            : RECORDING_OPTIONS_ENUM.cancelled.value;
    status = recorded ? 'recorded' : status;
    status = kalturaStatus ? 'processed' : status;
    if (endDateTimeString) {
        const endDateTime = new Date(endDateTimeString as string);
        const lateDateTime = new Date(endDateTime);
        lateDateTime.setHours(lateDateTime.getHours() + 1);
        if (lateDateTime < new Date() && !recorded) {
            status = 'not recorded';
        }
    }

    return status;
};

export const CourseMeetingsDataGridColDef: GridColDef[] = [
    {
        field: 'eventID',
        headerName: 'eventID',
        hide: true,
    },
    {
        field: 'meetingType',
        headerName: 'Type',
        type: 'string',
        align: 'center',
        disableColumnMenu: true,
        renderCell: (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
            const meetingType: string = params.value?.toString() || 'U';

            return <MeetingTypeChip meetingType={meetingType} />;
        },
        width: 88,
    },
    {
        field: 'location',
        headerName: 'Location',
        align: 'center',
        width: 138,
        renderCell: renderLocationCell,
    },
    {
        field: 'course',
        headerName: 'Course',
        width: 105,
        type: 'string',
        disableColumnMenu: true,
        renderCell: (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
            return (
                <Typography color={params.row.conflicts?.length > 0 ? 'error' : 'inherit'}>
                    {params.row.subject}-{params.row.course}
                </Typography>
            );
        },
    },
    { field: 'startDateTime', headerName: 'Start', width: 138, type: 'dateTime', renderCell: formatDateTime },
    { field: 'endDateTime', headerName: 'End', width: 145, type: 'dateTime', renderCell: formatDateTime },
    {
        field: 'recordingOption',
        headerName: 'Record Type',
        width: 200,
        align: 'center',
        renderCell: (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
            const recOption = params.value?.toString() || RECORDING_OPTIONS_ENUM.cancelled.value;
            return <RecordTypeButtonGroup handleOption={() => null} selectedOption={recOption} disabled={true} />;
        },
    },
    {
        field: 'kalturaFTP',
        headerName: 'Kaltura FTP',
        flex: 1,
        type: 'string',
        hide: true,
    },
    {
        field: 'kalturaStatus',
        headerName: 'Kaltura Status',
        flex: 1,
        type: 'string',
        hide: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        align: 'center',
        renderCell: renderStatusCell,
        valueGetter: courseMeetingStatusValueGetter,
        sortable: true,
    },
    {
        field: 'view',
        headerName: 'View',
        renderCell: renderCourseMeetingViewCourseDetails,
        flex: 0.5,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
    },
];
