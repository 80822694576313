import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { AppState } from './AppState';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { REACT_APP_ENV } from '../constants';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const routerMw = routerMiddleware(history);

const persistConfig = {
    key: `primary-${REACT_APP_ENV}`,
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: [`courses`, `courseMeetings`],
};

const pReducer = persistReducer<AppState>(persistConfig, rootReducer(history));

const initialState = undefined;

export const store = createStore(
    pReducer,
    initialState,
    composeWithDevTools(applyMiddleware(logger, routerMw, sagaMiddleware)),
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
