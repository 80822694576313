import { WithStyles, withStyles, Tooltip, Zoom, CircularProgress } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { styles } from '../../containers/styles/CaptureContainerStyles';

interface CameraPresetToggleButtonProps extends WithStyles<typeof styles> {
    value: string;
    description: string;
    icon: React.ReactElement;
    isIssuingPreset: boolean;
    preset: string | undefined;
}

export const CameraPresetToggleButton = withStyles(styles)((props: CameraPresetToggleButtonProps) => {
    const { value, description, icon, isIssuingPreset, preset, ...other } = props;
    return (
        <ToggleButton value={value} {...other}>
            <Tooltip
                disableFocusListener
                disableTouchListener
                title={`${description}`}
                key={value}
                arrow
                TransitionComponent={Zoom}
                PopperProps={{ style: { marginTop: '0.5rem' } }}
            >
                {isIssuingPreset && preset && preset === value ? <CircularProgress color="inherit" size={24} /> : icon}
            </Tooltip>
        </ToggleButton>
    );
});
