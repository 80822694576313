import React, { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    GridEditCellValueParams,
    GridFilterModel,
    GridFilterModelParams,
    GridPageChangeParams,
    GridRowData,
    GridRowId,
    GridRowParams,
    GridRowSelectedParams,
    GridSelectionModelChangeParams,
    GridSortModel,
    GridSortModelParams,
} from '@material-ui/data-grid';

import { ServiceRequest } from '../../redux/serviceRequest/serviceRequestTypes';
import { ServiceRequestsDataGridState, DataGridState } from '../../redux/application/applicationInterfaces';
import { ICourse } from '../../redux/course/courseInterfaces';
import ConfirmedCourseTabPanel from './ConfirmedCourseTabPanel';
import ServiceRequestTabPanel from './ServiceRequestTabPanel';
import BannerCourseTabPanel from './BannerCourseTabPanel';

function a11yProps(index: number) {
    return {
        id: `courses-tab-${index}`,
        'aria-controls': `courses-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        '& .lc-courses--bannerSync': {
            color: theme.palette.info.main,
            fontWeight: 'bold',
        },
        '& .lc-courses--bannerSync .MuiTypography-subtitle2': {
            color: theme.palette.info.main,
            fontWeight: 'bold',
        },
        '& .lc-courses--bannerSync:hover': {},
    },
    dataGrid: {
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
    },
}));

interface ICourseTabViewProps {
    courses: ICourse[];
    bannerCourses: ICourse[];
    confirmedCourses: ICourse[];
    isLoadingCourses: boolean;
    serviceRequests: ServiceRequest[];
    serviceRequestsDataGridState: ServiceRequestsDataGridState;
    isLoadingServiceRequests: boolean;
    bannerSyncCandidates: (string | undefined)[];
    activeCourseTab: number;
    dataGridState: DataGridState;
    confirmedCourseSelectionModel: string[];
    handleTabChange: (tabIndex: number) => void;
    handleCourseConfirm: (props: Map<GridRowId, GridRowData>) => void;
    handleCoursesDelete: (e: React.ChangeEvent<{ value: unknown }>) => void;
    handleCourseConfirmedSelect: (param: GridRowSelectedParams) => void;
    handleConfirmedCourseSelectionModelChange: (param: GridSelectionModelChangeParams) => void;

    handleServiceRequestConfirm: (props: Map<GridRowId, ServiceRequest>) => void;
    handleServiceRequestCancel: (props: Map<GridRowId, ServiceRequest>) => void;
    handleServiceRequestDataGridPageChange: (params: GridPageChangeParams) => void;
    handleServiceRequestDataGridFilterChange: (model: GridFilterModel) => void;
    handleServiceRequestDataGridSortChange: (model: GridSortModel) => void;
    handleServiceRequestDataGridPerPageChange: (params: GridPageChangeParams) => void;

    handleBannerCourseCellValueChange: (param: GridEditCellValueParams) => void;
    handleDataGridPageChange: (params: GridPageChangeParams) => void;
    handleDataGridFilterChange: (model: GridFilterModel) => void;
}

const CourseTabView: React.FC<ICourseTabViewProps> = (props) => {
    const classes = useStyles();

    const handleTabChange = (event: React.ChangeEvent<Record<string, never>>, newValue: number) => {
        props.handleTabChange(newValue);
    };

    const handleDataGridPageChange = (params: GridPageChangeParams) => {
        props.handleDataGridPageChange(params);
    };

    const handleFilterModelChange = (model: GridFilterModelParams) => {
        props.handleDataGridFilterChange(model.filterModel);
    };

    const handleServiceRequestsFilterModelChange = (params: GridFilterModelParams) => {
        props.handleServiceRequestDataGridFilterChange(params.filterModel);
    };

    const handleServiceRequestsSortModelChange = (params: GridSortModelParams) => {
        props.handleServiceRequestDataGridSortChange(params.sortModel);
    };

    const getRowClassName = useCallback(
        (params: GridRowParams) => {
            const courseID = params.row.courseID;
            const className = props.bannerSyncCandidates.includes(courseID) ? 'bannerSync' : 'row';
            return `lc-courses--${className}`;
        },
        [props.bannerSyncCandidates],
    );

    const tabLabels: string[] = ['Confirmed', 'Review', 'Banner'];

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={props.activeCourseTab} onChange={handleTabChange} aria-label="course tabs">
                    {tabLabels.map((label, index) => {
                        return <Tab label={label} {...a11yProps(index)} key={index} />;
                    })}
                </Tabs>
            </AppBar>

            <ConfirmedCourseTabPanel
                classes={classes}
                handleDataGridPageChange={handleDataGridPageChange}
                handleFilterModelChange={handleFilterModelChange}
                getRowClassName={getRowClassName}
                activeCourseTab={props.activeCourseTab}
                confirmedCourses={props.confirmedCourses}
                isLoadingCourses={props.isLoadingCourses}
                handleCoursesDelete={props.handleCoursesDelete}
                confirmedCourseSelectionModel={props.confirmedCourseSelectionModel}
                handleCourseConfirmedSelect={props.handleCourseConfirmedSelect}
                handleConfirmedCourseSelectionModelChange={props.handleConfirmedCourseSelectionModelChange}
            ></ConfirmedCourseTabPanel>

            <ServiceRequestTabPanel
                handleServiceRequestsFilterModelChange={handleServiceRequestsFilterModelChange}
                handleServiceRequestsSortModelChange={handleServiceRequestsSortModelChange}
                activeCourseTab={props.activeCourseTab}
                handleServiceRequestDataGridPageChange={props.handleServiceRequestDataGridPageChange}
                handleServiceRequestDataGridPerPageChange={props.handleServiceRequestDataGridPerPageChange}
                serviceRequests={props.serviceRequests}
                isLoadingServiceRequests={props.isLoadingServiceRequests}
                handleServiceRequestConfirm={props.handleServiceRequestConfirm}
                handleServiceRequestCancel={props.handleServiceRequestCancel}
            ></ServiceRequestTabPanel>

            <BannerCourseTabPanel
                classes={classes}
                handleDataGridPageChange={handleDataGridPageChange}
                handleFilterModelChange={handleFilterModelChange}
                getRowClassName={getRowClassName}
                activeCourseTab={props.activeCourseTab}
                bannerCourses={props.bannerCourses}
                isLoadingCourses={props.isLoadingCourses}
                handleCourseConfirm={props.handleCourseConfirm}
                handleBannerCourseCellValueChange={props.handleBannerCourseCellValueChange}
            ></BannerCourseTabPanel>
        </div>
    );
};

export default CourseTabView;
