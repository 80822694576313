import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';
import { JSXElementConstructor, memo, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { CONFIRMED_COURSE_PATH } from '../../constants';
import CourseMeetingRecordingStatus from './CourseMeetingRecordingStatus';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useRooms } from '../../hooks/useRooms';
import { IRoom } from '../../redux/room/roomInterfaces';

const OpenInNewIconMemo = memo(OpenInNewIcon);
const ArrowForwardIconMemo = memo(ArrowForwardIcon);

/**
 * Returns a formatted date string in the form mm/dd/yyy HH:mm
 * @param {GridCellParams} params
 * @returns {ReactFragment}
 */
export const formatDateTime = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
    const df = new Date(params.value as string);
    const formatedDate = `${df.toLocaleDateString()} ${df.getHours()}:${zeroPad(df.getMinutes(), 2)}`;
    return <Typography color={params.row.conflicts?.length > 0 ? 'error' : 'inherit'}>{formatedDate}</Typography>;
};

/**
 * Renders a toggle button for displaying meeting record status
 * @param {GridCellParams} params
 * @returns {ReactFragment}
 */
export const renderStatusCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return (
        <CourseMeetingRecordingStatus
            hasConflict={params.row.conflicts?.length > 0}
            recordingOption={params.row.recordingOption}
            kalturaFTP={params.row.kalturaFTP}
            kalturaStatus={params.row.kalturaStatus}
            endDateTimeString={params.row.endDateTime}
        />
    );
};

/**
 * Renders the room location cell for CourseMeetingCard
 * @param {GridCellParams} params 
 * @returns {ReactFragment}
 */
export const renderLocationCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const locationMap = parseLocation(params);
    const { rooms } = useRooms(true);

    const room = rooms?.filter(
        (room: IRoom) => room.building === locationMap['building'] && room.roomNumber === locationMap['roomNumber'],
    );
    const href = room && room[0]?.extron?.ip ? `http://${room[0]?.extron?.ip}/www/index.html` : '';

    return (
        <Button
            color="primary"
            variant="outlined"
            style={{flexGrow: 1}}
            size="small"
            href={href}
            target="_blank"
            endIcon={<OpenInNewIconMemo />}
        >
            {['building', 'roomNumber'].map((f: string): string => locationMap[f]).join('-')}
        </Button>
    );
};

/**
 *
 * @param {GridCellParams} params
 * @param {string[]} fields
 * @returns {ReactFragment}
 */
export const parseLocation = (params: GridCellParams): { [key: string]: string } => {
    const location = params.row.location;
    const locationMap: { [key: string]: string } = {};
    [
        locationMap.termcode,
        locationMap.building,
        locationMap.roomNumber,
        locationMap.days,
        locationMap.times,
    ] = location.split('-');

    return locationMap;
};

export const renderCourseMeetingViewCourseDetails = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    const history = useHistory();
    const { courseID } = params.row;

    const handleCourseView = () => {
        history.push(`${CONFIRMED_COURSE_PATH}${courseID}`);
    };

    return (
        <>
            <Tooltip title={<Typography variant="subtitle2">View course details</Typography>}>
                <IconButton aria-label="delete" onClick={handleCourseView}>
                    <ArrowForwardIconMemo color="action" />
                </IconButton>
            </Tooltip>
        </>
    );
};
