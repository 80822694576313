import { SagaIterator } from 'redux-saga';
import {
    IAutoFocusQueryAction,
    IIssueCameraPresetRequestAction,
    IIssueOtafRequestAction,
    IRebootCameraRequestAction,
    ISetAutoFocusRequestAction,
} from './cameraInterfaces';
import { call, put } from 'redux-saga/effects';
import {
    autoFocusQuerySuccessAction,
    issueCameraPresetFailureAction,
    issueCameraPresetSuccessAction,
    issueOtafFailureAction,
    issueOtafSuccessAction,
    rebootCameraFailureAction,
    rebootCameraSuccessAction,
    setAutoFocusFailureAction,
    setAutoFocusSuccessAction,
} from './cameraActions';
import {
    sendCameraAutoFocusQuery,
    sendCameraPresetRequest,
    sendCameraRebootRequest,
    sendOtafRequest,
    sendSetAutoFocusRequest,
} from './cameraAPIs';
import { logger } from '../../services/Logger';

export function* rebootCamera({ payload }: IRebootCameraRequestAction): SagaIterator {
    try {
        yield call(sendCameraRebootRequest, payload);
        yield put(rebootCameraSuccessAction());
    } catch (e) {
        logger.error(e);
        yield put(rebootCameraFailureAction(e.message));
    }
}

export function* sendAutoFocusQuery({payload}: IAutoFocusQueryAction): SagaIterator {
    try {
        const response = yield call(sendCameraAutoFocusQuery, payload);
        yield put(autoFocusQuerySuccessAction(response));
    } catch (e) {
        logger.error(e);
        yield put(setAutoFocusFailureAction(e.message));
    }
}

export function* issueCameraPreset({ payload }: IIssueCameraPresetRequestAction): SagaIterator {
    try {
        yield call(sendCameraPresetRequest, payload);
        yield put(issueCameraPresetSuccessAction());
    } catch (e) {
        yield put(issueCameraPresetFailureAction(e.message));
    }
}

export function* setCameraAutoFocus({ payload }: ISetAutoFocusRequestAction): SagaIterator {
    try {
        yield call(sendSetAutoFocusRequest, payload);
        yield put(setAutoFocusSuccessAction());
    } catch (e) {
        yield put(setAutoFocusFailureAction(e.message));
    }
}

export function* sendCameraOtafRequest({ payload }: IIssueOtafRequestAction): SagaIterator {
    try {
        yield call(sendOtafRequest, payload);
        yield put(issueOtafSuccessAction());
    } catch (e) {
        yield put(issueOtafFailureAction(e.message));
    }
}
