import React, { memo, useEffect } from 'react';
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import KurentoPlayer from '../components/Argus/KurentoPlayer';
import { KURENTO_WS_URI } from '../constants';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { formatRTSPEndpoint, handleSWRError } from '../utils/helpers';
import Alert from '@material-ui/lab/Alert';
import { useRooms } from '../hooks/useRooms';
import { IRoom } from '../redux/room/roomInterfaces';

const KurentoPlayerMemo = memo(KurentoPlayer);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        '@global': {
            '.MuiAppBar-root': {
                display: 'none',
            },
            '.MuiDrawer-root': {
                display: 'none',
            },
        },
        content: {
            flexGrow: 1,
            paddingTop: '1rem',
            height: '100vh',
            overflow: 'auto',
            background: 'linear-gradient(180deg, rgba(2,40,81,1) 50%, rgba(0,0,0,1) 100%)',
            color: '#fff',
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        roomHeading: {
            textShadow: '0 2px #000',
        },
        fixedHeight: {
            height: 240,
        },
    }),
);

type RouterProps = {
    building: string;
    roomNumber: string;
};

const KurentoPlayerContainer: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { building, roomNumber } = useParams<RouterProps>();

    const { rooms, isLoadingRooms, isRoomsError } = useRooms(true);

    useEffect(() => {
        if (isRoomsError) {
            handleSWRError(isRoomsError, 'rooms', dispatch);
        }
    }, [isRoomsError]);

    const selectedRoom:IRoom | undefined = rooms && rooms.find(
        (room) => room.building === building.toUpperCase() && room.roomNumber === roomNumber,
    );


    return (
        <main className={classes.content}>
            <Container maxWidth="lg">
                <Grid container spacing={3} alignItems="stretch" direction="row">
                    <Grid item xs={12}>
                        {selectedRoom && selectedRoom?.extron?.ip && (
                            <>
                                <Typography variant="h6" component="h1" className={classes.roomHeading}>
                                    {building.toLocaleUpperCase()}-{roomNumber}
                                </Typography>
                                <Typography variant="subtitle2" display="block" gutterBottom>
                                    Extron stream
                                </Typography>
                                <KurentoPlayerMemo
                                    rtspURI={formatRTSPEndpoint(selectedRoom?.extron?.ip)}
                                    wsURI={KURENTO_WS_URI}
                                />
                            </>
                        )}

                        {!selectedRoom && !isLoadingRooms && rooms?.length && (
                            <Alert variant="outlined" severity="warning" style={{ background: '#fff' }}>
                                Unknown Room! Please check that the build and room number are correct.
                            </Alert>
                        )}

                        {isRoomsError && (
                            <Alert variant="outlined" severity="error" style={{ background: '#fff' }}>
                                Error fetching rooms. Please check your network settings.
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
};

export default KurentoPlayerContainer;
