import {
    CREATE_COURSEMEETING_FAILURE,
    CREATE_COURSEMEETING_REQUEST,
    CREATE_COURSEMEETING_SUCCESS,
    FETCH_COURSEMEETINGS_BY_DATE_FAILURE,
    FETCH_COURSEMEETINGS_BY_DATE_SUCCESS,
    FETCH_COURSEMEETINGS_FAILURE,
    FETCH_COURSEMEETINGS_SUCCESS,
    FETCH_COURSEMEETING_CONFLICTS_FAILURE,
    FETCH_COURSEMEETING_CONFLICTS_SUCCESS,
    PUT_COURSEMEETING_FAILURE,
    PUT_COURSEMEETING_REQUEST,
    PUT_COURSEMEETING_SUCCESS,
} from './courseMeetingActionTypes';
import { ICourseMeetingsState } from './courseMeetingInterfaces';
import { CourseMeetingActionTypes } from './courseMeetingTypes';

const courseMeetingsInitialState: ICourseMeetingsState = {
    isLoadingMeetings: false,
    isPutingMeeting: false,
    isCreatingMeeting: false,
    isFetchingConflicts: false,
    courseMeetings: [],
    error: undefined,
};

const courseMeetingReducer = (
    state: ICourseMeetingsState = courseMeetingsInitialState,
    action: CourseMeetingActionTypes,
): ICourseMeetingsState => {
    switch (action.type) {
        case FETCH_COURSEMEETINGS_BY_DATE_SUCCESS:
            return {
                ...state,
                isLoadingMeetings: false,
                error: undefined,
                courseMeetings: action.payload,
            };

        case FETCH_COURSEMEETINGS_BY_DATE_FAILURE:
            return {
                ...state,
                isCreatingMeeting: false,
                error: action.payload,
            };

        case FETCH_COURSEMEETINGS_SUCCESS:
            return {
                ...state,
                isLoadingMeetings: false,
                error: undefined,
                courseMeetings: action.payload,
            };

        case FETCH_COURSEMEETINGS_FAILURE:
            return {
                ...state,
                isLoadingMeetings: false,
                error: action.payload,
            };

        case FETCH_COURSEMEETING_CONFLICTS_SUCCESS:
            return {
                ...state,
                error: undefined,
                courseMeetings: [
                    ...state.courseMeetings.filter((m) => m.eventID != action.payload.eventID),
                    action.payload,
                ].sort((a, b) => parseInt(a.eventIndex) - parseInt(b.eventIndex)),
                isFetchingConflicts: false,
            };

        case FETCH_COURSEMEETING_CONFLICTS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetchingConflicts: false,
            };

        case PUT_COURSEMEETING_REQUEST:
            return {
                ...state,
                isPutingMeeting: true,
                error: undefined,
            };

        case PUT_COURSEMEETING_SUCCESS:
            return {
                ...state,
                isPutingMeeting: false,
                error: undefined,
            };

        case PUT_COURSEMEETING_FAILURE:
            return {
                ...state,
                isPutingMeeting: false,
                error: action.payload,
            };

        case CREATE_COURSEMEETING_REQUEST:
            return {
                ...state,
                isCreatingMeeting: true,
                error: undefined,
            };

        case CREATE_COURSEMEETING_SUCCESS:
            return {
                ...state,
                isCreatingMeeting: false,
                courseMeetings: [
                    ...state.courseMeetings.filter((m) => m.eventID != action.payload.eventID),
                    action.payload,
                ].sort((a, b) => parseInt(a.eventIndex) - parseInt(b.eventIndex)),
            };

        case CREATE_COURSEMEETING_FAILURE:
            return {
                ...state,
                isCreatingMeeting: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default courseMeetingReducer;
