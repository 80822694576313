import { BASE_URL } from "../constants";
import { FetchError } from "../redux/application/applicationInterfaces";
import useSWR from "swr";
import { fetcher, formatDateTime } from "../utils/helpers";
import { ICourse } from "../redux/course/courseInterfaces";
import { ICourseMeeting } from "../redux/courseMeeting/courseMeetingInterfaces";

type CourseMeetingsData = {
    courseMeetings: ICourseMeeting[];
    isLoadingCourseMeetings: boolean;
    isCourseMeetingsError: FetchError;
    mutateCourseMeetings: (courseMeetings?: ICourseMeeting[], revalidate?: boolean) => void;
};


// This hook fetches course meetings for a given course
export const useCourseMeetings = (course?: ICourse | null, startDateTime?: Date, endDateTime?: Date): CourseMeetingsData => {
    let url = null;
    
    if (course?.courseID) {
        url = `${BASE_URL}/courses/${course?.courseID}/meetings?status=${course.getCourseStatus()}`;
    } else if (startDateTime && endDateTime) {
        url = `${BASE_URL}/meetings?startDateTime=${formatDateTime(startDateTime)}&endDateTime=${formatDateTime(endDateTime)}`;
    }

    //const url = course?.courseID ? `${BASE_URL}/courses/${course?.courseID}/meetings?status=${course.getCourseStatus()}` : null;

    const { data, error, isLoading, mutate } = useSWR<ICourseMeeting[]>(url, fetcher, {
        refreshInterval: 30 * 1000,
        fallbackData: [],
        revalidateOnMount: true,
    });

    return {
        courseMeetings: data && [...data].sort((a: ICourseMeeting, b: ICourseMeeting) => {
            return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime();
        }) || [],
        isLoadingCourseMeetings: isLoading,
        isCourseMeetingsError: error,
        mutateCourseMeetings: mutate,
    };
};