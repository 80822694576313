import { put, call } from 'redux-saga/effects';
import {
    COURSE_CONFIRM_REQUEST,
    COURSE_CONFIRM_SUCCESS,
    COURSE_CONFIRM_FAILURE,
    CREATE_COURSE_REQUEST,
    CREATE_COURSE_SUCCESS,
    CREATE_COURSE_FAILURE,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAILURE,
    PUT_COURSE_REQUEST,
    PUT_COURSE_FAILURE,
    PUT_COURSE_SUCCESS,
} from './courseActionTypes';
import { ICourse } from './courseInterfaces';
import { ConfirmCourse, CreateCourse, DeleteCourse, UpdateCourse } from './courseAPIs';
import { SagaIterator } from '@redux-saga/types';
import { SHOW_SNACKBAR } from '../application/applicationActionTypes';
import { Snack } from '../../factories/Snackbar';
import { BANNER_COURSE_PATH, CONFIRMED_COURSE_PATH } from '../../constants';

export function* createCourse({ payload }: { type: typeof CREATE_COURSE_REQUEST; payload: ICourse }): SagaIterator {
    try {
        const course: ICourse = yield call(CreateCourse, payload);
        const successMessage = Snack(`Created new course ${payload.courseID}`);
        yield put({ type: SHOW_SNACKBAR, payload: successMessage });
        yield put({ type: CREATE_COURSE_SUCCESS, payload: course });
    } catch (e) {
        const errorMessage = Snack(`${e.message}`);
        errorMessage.messageType = 'error';
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
        yield put({ type: CREATE_COURSE_FAILURE, payload: e.message });
    }
}

export function* updateCourse({ payload }: { type: typeof PUT_COURSE_REQUEST; payload: ICourse }): SagaIterator {
    try {
        const course: ICourse = yield call(UpdateCourse, payload);
        const successMessage = Snack(`Updated course ${course.courseID}`);
        yield put({ type: SHOW_SNACKBAR, payload: successMessage });
        yield put({ type: PUT_COURSE_SUCCESS, payload: payload });
    } catch (e) {
        const errorMessage = Snack(`${e.message}`);
        errorMessage.messageType = 'error';
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
        yield put({ type: PUT_COURSE_FAILURE, payload: e.message });
    }
}

export function* courseConfirmRequest({
    payload,
}: {
    type: typeof COURSE_CONFIRM_REQUEST;
    payload: ICourse;
}): SagaIterator {
    try {
        const course: ICourse = yield call(ConfirmCourse, payload);
        yield put({ type: COURSE_CONFIRM_SUCCESS, payload: course });
    } catch (e) {
        const errorMessage = Snack(`${e.message}`);
        errorMessage.messageType = 'error';
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
        yield put({
            type: COURSE_CONFIRM_FAILURE,
            payload: e.message,
        });
    }
}

export function* deleteCourseRequest({
    payload,
}: {
    type: typeof DELETE_COURSE_REQUEST;
    payload: ICourse;
}): SagaIterator {
    try {
        const course: ICourse = yield call(DeleteCourse, payload);
        const successMessage = Snack(`Cancelled course ${course.courseID}.`);
        yield put({ type: SHOW_SNACKBAR, payload: successMessage });
        yield put({ type: DELETE_COURSE_SUCCESS, payload: payload });
    } catch (e) {
        const errorMessage = Snack(`${e.message}`);
        errorMessage.messageType = 'error';
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
        yield put({
            type: DELETE_COURSE_FAILURE,
            payload: e.message,
        });
    }
}

export function* courseConfirmSuccess({
    payload,
}: {
    type: typeof COURSE_CONFIRM_SUCCESS;
    payload: ICourse;
}): SagaIterator {
    const successMessage = Snack(`Confirmed course ${payload.courseID}.`);
    yield put({ type: SHOW_SNACKBAR, payload: successMessage });
    // If we are on the banner page, redirect to the confirmed page
    if (window.location.pathname.includes(BANNER_COURSE_PATH))
        window.location.href = `${CONFIRMED_COURSE_PATH}${payload.courseID}`;
}
