import {
    Button,
    Container,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    makeStyles,
    Paper,
    Theme,
} from '@material-ui/core';
import { DataGrid, GridRowData, GridToolbar } from '@material-ui/data-grid';
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { UserListDataGridColDef } from '../components/User/UserListGridColDef';

import AddIcon from '@material-ui/icons/Add';
import { UserForm } from '../components/User/forms/UserForm';
import { AppState } from '../redux/AppState';
import { PermissionGate } from '../components/PermissionGate';
import { SCOPES } from '../constants';
import { useUsers } from '../hooks/useUsers';
import { handleSWRError } from '../utils/helpers';
import { createUserRequest, updateUserRequest, deleteUserRequest } from '../redux/user/userActions';
import { IUser } from '../redux/user/userInterfaces';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        newItemFAB: {
            margin: '0 0 -1.2rem 0',
            zIndex: 99,
        },
    }),
);

const newUserInitialValues: IUser = {
    loginID: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: ['USER'],
    affiliation: '',
};

export const PeopleContainer: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { username } = useSelector((state: AppState) => state.user, shallowEqual);

    const columns = useMemo(() => UserListDataGridColDef, []);

    const {users, isLoadingUsers, isUsersError, mutateUsers} = useUsers(!!username);

    // Need to use a ref here because we will be referencing the users state in
    // the handleUserDeleteCallbackcallbacks passed to the datagrid cell renderers
    const usersStateRef = useRef<IUser[]>();
    usersStateRef.current = users;

    const [userFormOpen, setUserFormOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const [edittingUser, setEdittingUser] = useState<IUser>(newUserInitialValues);

    const [userToDelete, setUserToDelete] = useState<IUser | null>(null);

    useEffect(() => {
        handleSWRError(isUsersError, 'users', dispatch);
    }, [isUsersError]);

    const handleNewUserFormOpen = () => {
        setEdittingUser(newUserInitialValues);
        setUserFormOpen(true);
    };

    const handleUserFormClose = () => {
        setUserFormOpen(false);
    };

    const handleNewUserFormSubmit = (newUserProps: IUser) => {
        dispatch(createUserRequest(newUserProps));
        setUserFormOpen(false);
        if(users) {
            mutateUsers([...users, addRowActions(newUserProps)].sort((a, b) => {
                return a.loginID.toUpperCase().localeCompare(b.loginID.toUpperCase());
            }), false);
        } 
    };

    const handleUserFormSubmit = (userFormProps: IUser) => {
        dispatch(updateUserRequest(userFormProps));
        setUserFormOpen(false);
        mutateUsers([...users.filter((user) => user.loginID !== userFormProps.loginID), addRowActions(userFormProps)].sort(
            (a, b) => {
                return a.loginID.toUpperCase().localeCompare(b.loginID.toUpperCase());
            },
        ), false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteDialogCancel = () => {
        handleDeleteDialogClose();
        setUserToDelete(null);
    };

    const handleDeleteDialogConfirm = () => {
        handleDeleteDialogClose();
        if (userToDelete) {
            dispatch(deleteUserRequest(userToDelete));
            if (usersStateRef.current && userToDelete.loginID !== username) {
                mutateUsers(usersStateRef.current.filter((user) => user.loginID !== userToDelete.loginID), false);
            }
        }
    };

    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true);
    };

    const handleUserDeleteCallback = useCallback(async (user: IUser) => {
        setUserToDelete(user);
        handleDeleteDialogOpen();
    }, []);

    const handleUserFormOpenCallback = useCallback((userID: string | undefined) => {
        if (userID && usersStateRef.current) {
            const selectedUser = usersStateRef.current.find((user) => user.loginID === userID);
            if (selectedUser) {
                setEdittingUser(selectedUser);
                setUserFormOpen(true);
            }
        }
    }, []);

    // Need to pass row action callbacks as properties on the row
    const addRowActions = (row: IUser) => ({
        ...row,
        handleUserDelete: handleUserDeleteCallback,
        handleUserFormOpen: handleUserFormOpenCallback,
    }); 

    return (
        <main className={classes.content}>
            {userFormOpen && (
                <Suspense fallback={<></>}>
                    <UserForm
                        formTitle={
                            edittingUser.loginID.length === 0 ? 'Add New User' : `Edit User '${edittingUser.loginID}'`
                        }
                        isOpen={userFormOpen}
                        handleClose={handleUserFormClose}
                        handleSubmit={
                            edittingUser.loginID.length === 0 ? handleNewUserFormSubmit : handleUserFormSubmit
                        }
                        initialValues={edittingUser}
                    />
                </Suspense>
            )}

            {deleteDialogOpen && (
                <Suspense fallback={<></>}>
                    <Dialog
                        open={deleteDialogOpen}
                        aria-labelledby="confirm-user-delete"
                        aria-describedby="confirm-user-delete-description"
                    >
                        <DialogTitle id="confirm-user-delete">Confirm User Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="confirm-user-delete-description">
                                Please confirm deletion of user '{userToDelete?.loginID}'
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogCancel} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleDeleteDialogConfirm} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Suspense>
            )}

            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container alignItems="stretch" direction="row" spacing={0}>
                    <Grid item sm={12}>
                        <h1>User Management</h1>
                        <div style={{ textAlign: 'right', paddingRight: '1rem', marginTop: '-2.4em' }}>
                            <PermissionGate
                                scopes={[SCOPES.canCreate]}
                                errorProps={{ disabled: true, onClick: () => null }}
                            >
                                <Fab
                                    color="secondary"
                                    aria-label="add"
                                    className={classes.newItemFAB}
                                    onClick={handleNewUserFormOpen}
                                >
                                    <AddIcon />
                                </Fab>
                            </PermissionGate>
                        </div>
                        <Paper className={classes.paper}>
                            <DataGrid
                                autoHeight
                                pagination
                                pageSize={20}
                                loading={isLoadingUsers}
                                checkboxSelection
                                disableSelectionOnClick={true}
                                rows={users.map((userRow) => {
                                    return addRowActions(userRow);
                                })}
                                columns={columns}
                                getRowId={(row: GridRowData) => {
                                    return row.loginID;
                                }}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
};
