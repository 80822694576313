import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { EXTRON_ERROR_CODES } from '../../constants';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const ErrorIconMemo = React.memo(ErrorIcon);

export interface IExtronErrorChipProps {
    extronErrorCode: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    errorChip: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
}));

export const ExtronErrorChip: React.FC<IExtronErrorChipProps> = ({ extronErrorCode }: IExtronErrorChipProps) => {
    const classes = useStyles();

    return (
        <Tooltip title={<Typography variant="subtitle2">{`${EXTRON_ERROR_CODES[extronErrorCode]}`}</Typography>}>
            <Chip
                icon={<ErrorIconMemo color="error" />}
                label={`Error: ${extronErrorCode}`}
                color="primary"
                className={classes.errorChip}
                variant="outlined"
                size="small"
            />
        </Tooltip>
    );
};
