import { RecordTypeButtonGroup } from './RecordTypeButtonGroup';
import React, { JSXElementConstructor, memo, ReactElement, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { GridCellParams, GridRowData, useGridSlotComponentProps } from '@material-ui/data-grid';

import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import { RECORDING_OPTIONS_ENUM } from '../../constants';
import { useRooms } from '../../hooks/useRooms';
import Alert from '@material-ui/lab/Alert';
import { Skeleton } from '@material-ui/lab';
import { showCourseMeetingForm } from '../../redux/application/applicationActions';
import { putCourseMeetingRequest } from '../../redux/courseMeeting/courseMeetingActions';
import { ICourseMeetingData, ICourseMeeting } from '../../redux/courseMeeting/courseMeetingInterfaces';
import { IRoom } from '../../redux/room/roomInterfaces';

const RecordTypeButtonGroupMemo = memo(RecordTypeButtonGroup);

export const renderEditControlCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const { row } = params;
    const {
        startDateTime,
        endDateTime,
        eventID,
        eventIndex,
        building,
        roomNumber,
        recordingOption,
        notes,
        location,
        meetingType,
        conflicts,
    } = row;
    const dispatch = useDispatch();
    const initialValues: ICourseMeetingData = {
        startDateTime,
        endDateTime,
        building,
        roomNumber,
        recordingOption,
        notes,
        eventID,
        eventIndex,
        location,
        meetingType,
        conflicts,
    };

    const handleEdit = () => {
        dispatch(showCourseMeetingForm(initialValues, 'edit', 'Edit Course Meeting'));
    };

    const meetingDisabled = new Date(startDateTime) < new Date();

    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            {meetingDisabled ? (
                <BlockIcon color="disabled" titleAccess="Meeting cannot be editted once started." />
            ) : (
                <IconButton aria-label="Edit" onClick={handleEdit} title="Edit course meeting">
                    <EditIcon color="action" />
                </IconButton>
            )}
        </div>
    );
};

/**
 * Renders a ToggleButtonGroupComponent for controlling recording option type
 * @param {GridCellParams} params
 * @returns {ReactFragment}
 */
export const renderRecordingOptionsCell = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    const { api } = params;
    const dispatch = useDispatch();
    const gridSlotComponentProps = useGridSlotComponentProps();

    const { rooms, isLoadingRooms, isRoomsError } = useRooms(true);

    const room:IRoom | undefined = rooms && rooms.find(
            (room) => room.building === params.row.building && room.roomNumber === params.row.roomNumber,
        );

    const disabledOptions =
        room?.camera === null
            ? [RECORDING_OPTIONS_ENUM.cameraAndAudio.value, RECORDING_OPTIONS_ENUM.fullService.value]
            : [];

    const currentOption = params.value?.toString() || RECORDING_OPTIONS_ENUM.cancelled.value;
    const [recOption, setRecOption] = useState<string>(currentOption);

    const meetingDisabled = new Date(params.row.startDateTime) < new Date();

    useEffect(() => {
        setRecOption(currentOption);
    }, [params.value]);

    const handleOption = (event: React.MouseEvent<HTMLElement>, newOption: string) => {
        const selectedMeetings: GridRowData[] = gridSlotComponentProps.rows.filter((r: GridRowData) =>
            [...api.getSelectedRows().keys()].includes(r.eventID),
        );

        if (newOption) {
            setRecOption(newOption);
            // Case of no selection, single meeting record option change
            if (selectedMeetings.length === 0) {
                selectedMeetings.push(params.row);
            }

            if (selectedMeetings.length > 0) {
                selectedMeetings.forEach((meeting) => {
                    const courseMeeting: ICourseMeeting = { ...meeting, recordingOption: newOption } as ICourseMeeting;
                    dispatch(putCourseMeetingRequest(courseMeeting));
                });
            }
        }
    };

    if (isLoadingRooms) {
        return <Skeleton variant="text" animation="wave" style={{width:'100%'}} />;
    }

    return (
        isRoomsError ? <Alert severity="error" variant='outlined' style={{borderColor:"#fff"}}>Unknown room</Alert> : <RecordTypeButtonGroupMemo
            handleOption={handleOption}
            selectedOption={recOption}
            disabledOptions={disabledOptions}
            disabled={meetingDisabled}
        />
    );
};
