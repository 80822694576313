// Redux Extron SSE action types

export const EXTRON_SSE_SUBSCRIBE = 'EXTRON_SSE_SUBSCRIBE';

export const EXTRON_SSE_UNSUBSCRIBE = 'EXTRON_SSE_UNSUBSCRIBE';

export const EXTRON_SSE_SET_ONLINE = 'EXTRON_SSE_SET_ONLINE';

export const EXTRON_SSE_SET_DATA = 'EXTRON_SSE_SET_DATA';

export const EXTRON_SSE_DISCONNECT_RECORDER = 'EXTRON_SSE_DISCONNECT_RECORDER';
