import {
    FETCH_USER_ACCESS_REQUEST,
    FETCH_USER_ACCESS_SUCCESS,
    FETCH_USER_ACCESS_FAILURE,
    SET_USERNAME,
    LOGOUT_USER,
    SET_TOKEN_COOKIES_REQUEST,
    SET_TOKEN_COOKIES_SUCCESS,
    SET_TOKEN_COOKIES_FAILURE,
} from './userActionTypes';
import { IUserState } from './userInterfaces';
import { UserActionTypes } from './userTypes';

const userInitialState: IUserState = {
    roles: [],
    username: '',
    isLoading: false,
    error: '',
};

const userReducer = (state: IUserState = userInitialState, action: UserActionTypes): IUserState => {
    switch (action.type) {
        case FETCH_USER_ACCESS_REQUEST:
            return {
                ...state,
                roles: [],
                isLoading: true,
            };

        case FETCH_USER_ACCESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                roles: action.payload.roles,
            };

        case FETCH_USER_ACCESS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SET_TOKEN_COOKIES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case SET_TOKEN_COOKIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case SET_TOKEN_COOKIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SET_USERNAME:
            return {
                ...state,
                isLoading: false,
                username: action.payload,
            };

        case LOGOUT_USER:
            return userInitialState;

        default:
            return state;
    }
};

export default userReducer;
