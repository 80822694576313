import { ICourse } from '../redux/course/courseInterfaces';
import { KEY_SEPARATOR } from '../constants';

/**
 * Factory function to return a course object
 *
 * @param courseObj
 * @returns
 */
export function Course(course?: ICourse): ICourse {
    const _course = {
        title: '',
        extronScheduled: true,
        mediasiteScheduled: false,
        hasCourseMeetingConflicts: false,
        courseID: '',
        entityType: 'course-confirmed-v0',
        location: '',
        termCode: '',
        building: '',
        roomNumber: '',
        days: '',
        times: '',
        instructor: '',
        instructorLoginID: '',
        instructorPreferred: '',
        notes: '',
        subject: '',
        course: '',
        seq: '',
        dist: 'unlisted',
        startdate: '',
        enddate: '',
        instructorEmail: '',
        canvasCourseName: '',
        canvasCourseSISID: '',
        canvasCourseID: '',
        crns: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        coEditors: [],
        coPublishers: [],
        publishesTo: [],
        recordingOption: '',
        createdBy: '',
        ...course,
    };

    return {
        ..._course,
        getCourseKey: function () {
            if (this.courseID) {
                return `${this.courseID}${KEY_SEPARATOR}${this.entityType}`;
            }
            return '';
        },
        getCourseStatus: function () {
            return this.entityType.split('-')[1];
        },
        setCourseStatus: function (courseStatus: string) {
            this.entityType = `course-${courseStatus}-v0`;
            return this;
        },
        setCourseID: function (courseID: string) {
            [this.termCode, this.subject, this.course, this.seq] = courseID.split('-');
            return this;
        },
        getStartDate: function (): Date {
            return new Date(this.startdate);
        },
        getEndDate: function (): Date {
            return new Date(this.enddate);
        },
        get courseID() {
            if (this.termCode && this.course && this.subject && this.seq) {
                return `${this.termCode}-${this.subject}-${this.course}-${this.seq}`;
            }
            return '';
        },
        get location() {
            if (this.termCode && this.building && this.roomNumber && this.days && this.times) {
                return `${this.termCode}-${this.building}-${this.roomNumber}-${this.days}-${this.times}`;
            }
            return '';
        },
    };
}
