import { ISnackbar } from "../redux/application/applicationInterfaces";

/**
 * Factory function that returns a Snackbar message object
 * 
 * @param message The message to display
 * @param messageType The snack type
 * @param open Is the snackbar open?
 * @returns ISnackbar message object
 */
export function Snack(
    message: string | undefined = undefined,
    messageType: 'error' | 'success' | 'info' | 'warning' = 'success',
    open = true
): ISnackbar {
    const uuid = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    };
    return {
        messageType,
        message,
        messageID: uuid(),
        open,
    };
}