import { ChangeEvent, JSXElementConstructor, memo, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, FormGroup, IconButton, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import { GridCellParams, GridEditCellValueParams } from '@material-ui/data-grid';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { RecordTypeButtonGroup } from './RecordTypeButtonGroup';
import { formatDateTime } from '../../utils/helpers';
import { RECORDING_OPTIONS_ENUM } from '../../constants';
import { useRooms } from '../../hooks/useRooms';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import { ICourseNote } from '../../redux/course/courseInterfaces';
import { IRoom } from '../../redux/room/roomInterfaces';

const RecordTypeButtonGroupMemo = memo(RecordTypeButtonGroup);

export const renderCourseDataCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const color = params.row.hasCourseMeetingConflicts ? 'error' : 'primary';
    return (
        <Typography color={color} variant='subtitle2'>{params.value}</Typography>
    );
}

export const renderCourseNotesCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    return (
        <>
            {params.value && params.value?.toString().length > 2
                ? JSON.parse(params.value.toString())
                      .map((n: ICourseNote) => `${formatDateTime(new Date(n.created))} (${n.user}): ${n.note}`)
                      .join(' ### ')
                : ''}
        </>
    );
};

export const renderCourseStatusCell = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    return (
        <>
            {params.row.hasCourseMeetingConflicts && (
                <EventBusyIcon color="error" titleAccess="Course meeting conflicts detected" />
            )}
            {params.row.hasCourseMeetingConflicts === false && (
                <EventAvailableIcon color="action" titleAccess="No meeting conflicts" style={{ color: 'green' }} />
            )}
        </>
    );
};

export const renderViewCourseCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const history = useHistory();
    const status = params.row.getCourseStatus();
    const courseID = params.row.courseID;

    const handleCourseView = () => {
        history.push(`/Courses/${status}/${courseID}`);
    };

    return (
        <>
            <Tooltip title={<Typography variant="subtitle2">View course details</Typography>}>
                <IconButton aria-label="delete" onClick={handleCourseView}>
                    <ArrowForwardIcon color="action" />
                </IconButton>
            </Tooltip>
        </>
    );
};

/**
 * Renders the record option select cell for Banner courses
 * @param params
 * @returns RecordTypeButtonGroup
 */
export const renderBannerCourseRecordTypeCell = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    const { rooms, isLoadingRooms, isRoomsError } = useRooms(true);

    const room:IRoom | undefined = rooms && rooms.find(
            (room) => room.building === params.row.building && room.roomNumber === params.row.roomNumber,
        );

    const disabledOptions =
        room?.camera === null
            ? [RECORDING_OPTIONS_ENUM.cameraAndAudio.value, RECORDING_OPTIONS_ENUM.fullService.value]
            : [];

    const currentOption = room?.camera === null ? 'content and audio' : params.value?.toString() || '';
    const [recOption, setRecOption] = useState<string>(currentOption);

    useEffect(() => {
        if (params.value && params.value !== currentOption) {
            // set the datagrid cell value to the supported default option
            params.api.setCellValue({
                id: params.id,
                field: 'recordingOption',
                value: currentOption,
            });
            setRecOption(currentOption);
        }
    }, []);

    const handleOption = (event: React.MouseEvent<HTMLElement>, newOption: string) => {
        if (newOption) {
            const GridEditCellValueParams: GridEditCellValueParams = {
                id: params.id,
                field: 'recordingOption',
                value: newOption,
            };

            params.api.setCellValue(GridEditCellValueParams);
            setRecOption(newOption);
        }
    };

    if (isLoadingRooms) {
        return <Skeleton variant="text" animation="wave" style={{width:'100%'}} />;
    }

    return ( isRoomsError ? <Alert severity="error" variant='outlined' style={{borderColor:"#fff"}}>Unknown room</Alert> :
        <RecordTypeButtonGroupMemo
            handleOption={handleOption}
            disabledOptions={disabledOptions}
            selectedOption={recOption}
        />
    );
};

/**
 * Renders the Auto/Manual Canvas publish toggle cell
 * @param params
 * @returns
 */
export const renderBannerCourseAutoPublish = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    const classes = makeStyles({
        label: {
            '& .MuiFormControlLabel-label': {
                fontSize: '1em',
            },
        },
    })();

    const [checkedState, setCheckedState] = useState(true);
    const [label, setLabel] = useState('Auto');

    const setCellValue = (field: string, value: string | Array<string>) => {
        const GridEditCellValueParams: GridEditCellValueParams = {
            id: params.id,
            field,
            value,
        };

        params.api.setCellValue(GridEditCellValueParams);
    };

    const handleCheck = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const publishesToCellValue = checked && params?.row?.canvasCourseID ? [params.row.canvasCourseID] : [];

        setCellValue('publishesTo', publishesToCellValue);
        setLabel(checked ? 'Auto' : 'Man.');
        setCheckedState(!checkedState);
    };

    return (
        <FormGroup row>
            <FormControlLabel
                control={<Switch onChange={handleCheck} name="autopublish" color="primary" />}
                checked={checkedState}
                label={label}
                labelPlacement="end"
                className={classes.label}
            />
        </FormGroup>
    );
};
