import { CLOSE_SNACKBAR } from '../application/applicationActionTypes';
import {
    REBOOT_CAMERA_REQUEST,
    REBOOT_CAMERA_SUCCESS,
    REBOOT_CAMERA_FAILURE,
    ISSUE_CAMERA_PRESET_REQUEST,
    ISSUE_CAMERA_PRESET_SUCCESS,
    ISSUE_CAMERA_PRESET_FAILURE,
    SELECT_CAMERA,
    DESELECT_CAMERA,
    ISSUE_OTAF_REQUEST,
    ISSUE_OTAF_FAILURE,
    ISSUE_OTAF_SUCCESS,
    SET_AUTO_FOCUS_REQUEST,
    SET_AUTO_FOCUS_FAILURE,
    SET_AUTO_FOCUS_SUCCESS,
    AUTO_FOCUS_QUERY_REQUEST,
    AUTO_FOCUS_QUERY_SUCCESS,
    AUTO_FOCUS_QUERY_FAILURE,
} from './cameraActionTypes';
import { ICaptureCameraState } from './cameraInterfaces';
import { CameraActionTypes } from './cameraTypes';

const cameraInitialState: ICaptureCameraState = {
    camera: null,
    cameraError: null,
    isCameraRebooting: false,
    isIssuingPreset: false,
    presetRequest: null,
    autoFocusEnabled: true,
};

const cameraReducer = (
    state: ICaptureCameraState = cameraInitialState,
    action: CameraActionTypes,
): ICaptureCameraState => {
    switch (action.type) {
        case CLOSE_SNACKBAR:
            return {
                ...state,
                cameraError: null,
            };
        case SELECT_CAMERA:
            return {
                ...state,
                camera: action.payload,
            };
        case AUTO_FOCUS_QUERY_REQUEST:
            return {
                ...state,
                cameraError: null,
            };
        case AUTO_FOCUS_QUERY_SUCCESS:
            return {
                ...state,
                autoFocusEnabled: action.payload.autofocus.includes('1'),
            };
        case AUTO_FOCUS_QUERY_FAILURE:
            return {
                ...state,
                cameraError: action.payload,
            };
        case DESELECT_CAMERA:
            return {
                ...state,
                camera: null,
                isCameraRebooting: false,
                isIssuingPreset: false,
                presetRequest: null,
            };
        case SET_AUTO_FOCUS_REQUEST:
            return {
                ...state,
                isIssuingPreset: true,
            };
        case SET_AUTO_FOCUS_SUCCESS:
            return {
                ...state,
                autoFocusEnabled: !state.autoFocusEnabled,
                isIssuingPreset: false,
            };
        case SET_AUTO_FOCUS_FAILURE:
            return {
                ...state,
                cameraError: action.payload,
                autoFocusEnabled: state.autoFocusEnabled,
                isIssuingPreset: false,
            };
        case ISSUE_OTAF_REQUEST:
            return {
                ...state,
                cameraError: null,
                isIssuingPreset: true,
            };
        case ISSUE_OTAF_SUCCESS:
            return {
                ...state,
                isIssuingPreset: false,
            };
        case ISSUE_OTAF_FAILURE:
            return {
                ...state,
                cameraError: action.payload,
                isIssuingPreset: false,
            };
        case REBOOT_CAMERA_REQUEST:
            return {
                ...state,
                cameraError: null,
                isCameraRebooting: true,
                presetRequest: null,
                camera: null,
            };
        case REBOOT_CAMERA_SUCCESS:
            return {
                ...state,
                cameraError: null,
                isCameraRebooting: false,
            };
        case REBOOT_CAMERA_FAILURE:
            return {
                ...state,
                cameraError: action.payload,
                isCameraRebooting: false,
            };
        case ISSUE_CAMERA_PRESET_REQUEST:
            return {
                ...state,
                cameraError: null,
                isIssuingPreset: true,
                presetRequest: action.payload,
            };
        case ISSUE_CAMERA_PRESET_SUCCESS:
            return {
                ...state,
                cameraError: null,
                isIssuingPreset: false,
            };
        case ISSUE_CAMERA_PRESET_FAILURE:
            return {
                ...state,
                cameraError: action.payload,
                isIssuingPreset: false,
                presetRequest: null,
            };
        default:
            return state;
    }
};

export default cameraReducer;
