import { createMuiTheme } from '@material-ui/core';

export const applicationTheme = createMuiTheme({
    palette: {
        common: {
            black: '#000',
            white: '#fff',
        },
        background: {
            paper: '#fff',
            default: '#fafafa',
        },
        primary: {
            light: 'rgba(205, 214, 224, 1)',
            main: 'rgba(2, 40, 81, 1)',
            dark: 'rgba(104, 132, 163, 1)',
            contrastText: '#fff',
        },
        secondary: {
            light: 'rgba(255, 249, 229, 1)',
            main: 'rgba(255, 191, 0, 1)',
            dark: 'rgba(255, 223, 128, 1)',
            contrastText: '#fff',
        },
        success: {
            main: '#239444',
            contrastText: '#fff',
        },
        info: {
            main: '#2196f3',
        },
        warning: {
            main: '#ff9800',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
    spacing: 8,
    overrides: {
        MuiButton: {
            root: {
                minWidth: 'fit-content',
            },
        },
    },
});
