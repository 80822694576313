import Box from '@material-ui/core/Box';
import { TabPanelProps } from '../../redux/application/applicationInterfaces';

/**
 * Returns a TabPanel component containing passed child components
 *
 * @param props
 * @returns A TabPanel
 */
const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`courses-tabpanel-${index}`}
            aria-labelledby={`courses-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};
export default TabPanel;
