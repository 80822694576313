import useSWR from 'swr';
import { BASE_URL } from '../constants';
import { Course } from '../factories/Course';
import { fetcher, getBannerSyncCandidates } from '../utils/helpers';
import { FetchError } from '../redux/application/applicationInterfaces';
import { ICourse } from '../redux/course/courseInterfaces';

type CoursesData = {
    courses: ICourse[];
    bannerCourses: ICourse[];
    confirmedCourses: ICourse[];
    isLoadingCourses: boolean;
    isCoursesError: FetchError;
    mutateCourses: (course?: ICourse[], revalidate?: boolean) => void;
};

export const useCourses = (termCode?: string, status = null, version = 'v0'): CoursesData => {
    const url: string | null = termCode ? `${BASE_URL}/courses/${termCode}?version=${version}${status ? `&status=${status}` : ''}` : null;

    const { data, error, isLoading, mutate } = useSWR<ICourse[]>(url, fetcher, {
        refreshInterval: 30 * 1000,
        fallbackData: [],
        revalidateOnMount: true,
    });

    const courses = data ? data.map((c: ICourse): ICourse => Course(c)) : [];
    const bannerSyncCandidates = getBannerSyncCandidates(courses);

    const bannerCourses = courses
        .filter((course) => course.getCourseStatus() === 'banner')
        .map((course) => {
            if (course.canvasCourseID) {
                course.publishesTo = [course.canvasCourseID];
            }
            return course;
        })
        .sort((a, b) => Number(b.hasCourseMeetingConflicts) - Number(a.hasCourseMeetingConflicts))
        .sort((a: ICourse, b: ICourse) => {
            if (bannerSyncCandidates.includes(a.courseID) && bannerSyncCandidates.includes(b.courseID)) {
                return 0;
            }

            if (bannerSyncCandidates.includes(a.courseID) && !bannerSyncCandidates.includes(b.courseID)) {
                return -1;
            }

            return 1;
        });

    const confirmedCourses = courses
        .filter((course) => course.getCourseStatus() === 'confirmed')
        .sort((a, b) => Number(b.hasCourseMeetingConflicts) - Number(a.hasCourseMeetingConflicts))
        .sort((a: ICourse, b: ICourse) => {
            if (bannerSyncCandidates.includes(a.courseID) && bannerSyncCandidates.includes(b.courseID)) {
                return 0;
            }

            if (bannerSyncCandidates.includes(a.courseID) && !bannerSyncCandidates.includes(b.courseID)) {
                return -1;
            }

            return 1;
        });

    return {
        courses,
        bannerCourses,
        confirmedCourses,
        isLoadingCourses: isLoading,
        isCoursesError: error,
        mutateCourses: mutate,
    };
};
