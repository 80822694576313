import { SEND_EXTRON_COMMAND_REQUEST, SEND_EXTRON_COMMAND_SUCCESS, SEND_EXTRON_COMMAND_FAILURE } from "./extronActionTypes";
import { ExtronCommandRequest, ISendExtronCommandRequestAction, ISendExtronCommandSuccessAction, ISendExtronCommandFailureAction } from "./extronInterfaces";

export const sendExtronCommandRequestAction = (extronCommandRequest: ExtronCommandRequest): ISendExtronCommandRequestAction => ({
    type: SEND_EXTRON_COMMAND_REQUEST,
    payload: extronCommandRequest,
});

export const sendExtronCommandSuccessAction = (): ISendExtronCommandSuccessAction => ({
    type: SEND_EXTRON_COMMAND_SUCCESS,
});

export const sendExtronCommandFailureAction = (error: string): ISendExtronCommandFailureAction => ({
    type: SEND_EXTRON_COMMAND_FAILURE,
    payload: error,
});