import { JSXElementConstructor } from 'react';
import {
    GridFooterContainer,
    GridPagination,
    GridSlotsComponentsProps,
    useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { CourseDeleteButton } from './CourseDeleteButton';
import { SCOPES } from '../../constants';
import { PermissionGate } from '../PermissionGate';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

interface ICourseConfirmedCustomGridFooterProps extends GridSlotsComponentsProps {
    handleCourseDelete: () => void;
    selectedCourses: string[];
}

const useStyles = makeStyles(() =>
    createStyles({
        courseDeleteButtonRenderError: {
            width: '179.45px',
            height: '36.5px',
            marginRight: 'auto',
        },
    }),
);

export const CourseConfirmedCustomGridFooter: JSXElementConstructor<ICourseConfirmedCustomGridFooterProps> = (
    props: ICourseConfirmedCustomGridFooterProps,
) => {
    const gridSlotComponentProps = useGridSlotComponentProps();
    const selectedCourses = gridSlotComponentProps.apiRef.current.getSelectedRows();
    const deleteButtonDisabled = selectedCourses.size === 0;
    const classes = useStyles();

    const handleCourseDelete = () => {
        props.handleCourseDelete();
    };

    const courseDeleteButtonRenderError = () => <Box m={1} className={classes.courseDeleteButtonRenderError}></Box>;

    return (
        <GridFooterContainer>
            <PermissionGate scopes={[SCOPES.canDelete]} RenderError={courseDeleteButtonRenderError}>
                <CourseDeleteButton
                    handleCourseDelete={handleCourseDelete}
                    deleteButtonDisabled={deleteButtonDisabled}
                    selectedCourses={props.selectedCourses}
                />
            </PermissionGate>
            <GridPagination />
        </GridFooterContainer>
    );
};
