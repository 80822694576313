import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createStyles, makeStyles, ThemeProvider } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { store, history, persistor } from './redux/store';

import { applicationTheme } from './theme/applicationTheme';
import Callback from './containers/CallbackContainer';
import PageHeader from './components/PageHeader';
import ShowMessage from './components/ShowSnackbar';
import { PrivateRoute } from './components/PrivateRoute';
import { Login } from './components/Login';
import Unauthorized from './components/Unauthorized';
import Dashboard from './containers/DashboardContainer';
import Courses from './containers/CoursesContainer';
import CourseDetails from './containers/CourseDetailsContainer';

import './App.css';
import Loading from './components/Loading';
import ArgusContainer from './containers/ArgusContainer';
import KurentoPlayerContainer from './containers/KurentoPlayerContainer';
import { SCOPES } from './constants';
import { PeopleContainer } from './containers/PeopleContainer';
import { Error404 } from './components/Error404';
import { PageFooter } from './components/PageFooter';
import { CaptureContainer } from './containers/CaptureContainer';
import { RoomViewerContainer } from './containers/RoomViewerContainer';

const authenticationPath = '/login';
const unauthorizedPath = '/unauthorized';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
        },
    }),
);

const App: React.VFC = () => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={applicationTheme}>
            <Provider store={store}>
                <PersistGate loading={<Loading />} persistor={persistor}>
                    <ConnectedRouter history={history}>
                        <div className={classes.root}>
                            {<PageHeader loginPath={authenticationPath} />}
                            <>
                                <Switch>
                                    <Route path="/Login" component={Login} />
                                    <Route path="/Callback" component={Callback} />
                                    <Route path="/Unauthorized" component={Unauthorized} />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/Dashboard"
                                        component={Dashboard}
                                        scopes={[SCOPES.canView]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/Courses/:courseStatus/:courseID"
                                        component={CourseDetails}
                                        scopes={[SCOPES.canEdit]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/Courses"
                                        component={Courses}
                                        scopes={[SCOPES.canEdit]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/ARGUS"
                                        component={ArgusContainer}
                                        scopes={[SCOPES.canViewArgus]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/Capture"
                                        component={CaptureContainer}
                                        scopes={[SCOPES.canViewStreams]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/RoomViewer"
                                        component={RoomViewerContainer}
                                        scopes={[SCOPES.canViewStreams]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/People"
                                        component={PeopleContainer}
                                        scopes={[SCOPES.canView]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/extron/:building/:roomNumber"
                                        component={KurentoPlayerContainer}
                                        scopes={[SCOPES.canView]}
                                    />
                                    <PrivateRoute
                                        unauthorizedPath={unauthorizedPath}
                                        authenticationPath={authenticationPath}
                                        path="/"
                                        component={Dashboard}
                                        scopes={[SCOPES.canView]}
                                    />
                                    <Route component={() => <Error404 />} />
                                </Switch>
                            </>
                            <PageFooter />
                            <ShowMessage />
                        </div>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    );
};

export default App;
