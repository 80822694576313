import {
    FETCH_GOOGLE_CALENDAR_EVENTS_SUCCESS,
    GOOGLE_CALENDAR_SUBSCRIBE,
    GOOGLE_CALENDAR_UNSUBSCRIBE,
} from './gcalActionTypes';
import { IGoogleCalendarState } from './gcalInterfaces';
import { gcalActionTypes } from './gcalTypes';

const gcalInitialState: IGoogleCalendarState = {
    events: {},
};

export const gcalReducer = (state = gcalInitialState, action: gcalActionTypes): IGoogleCalendarState => {
    switch (action.type) {
        case GOOGLE_CALENDAR_SUBSCRIBE:
            return { ...state, events: {} };

        case GOOGLE_CALENDAR_UNSUBSCRIBE:
            return { ...state, events: {} };

        case FETCH_GOOGLE_CALENDAR_EVENTS_SUCCESS:
            return {
                events: { ...state.events, ...action.payload },
            };

        default:
            return state;
    }
};
