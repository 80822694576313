import { TokenResponse } from '../../services/AuthInterfaces';
import {
    FETCH_USER_ACCESS_REQUEST,
    FETCH_USER_ACCESS_SUCCESS,
    FETCH_USER_ACCESS_FAILURE,
    SET_USERNAME,
    LOGOUT_USER,
    SET_TOKEN_COOKIES_REQUEST,
    SET_TOKEN_COOKIES_SUCCESS,
    SET_TOKEN_COOKIES_FAILURE,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    CREATE_USER_REQUEST,
    UPDATE_USER_REQUEST,
    DELETE_USER_REQUEST,
} from './userActionTypes';

import {
    IFetchUserAccessRequestAction,
    IFetchUserAccessSuccessAction,
    IFetchUserAccessFailureAction,
    IUserAccess,
    ISetUsernameAction,
    ILogoutUser,
    ISetTokenCookiesRequestAction,
    ISetTokenCookiesSuccessAction,
    ISetTokenCookiesFailureAction,
    IFetchUsersSuccessAction,
    IFetchUsersFailureAction,
    IUser,
    ICreateUserRequestAction,
    IUpdateUserRequestAction,
    IDeleteUserRequestAction,
} from './userInterfaces';

export const fetchUserAccessRequest = (username: string): IFetchUserAccessRequestAction => {
    return {
        type: FETCH_USER_ACCESS_REQUEST,
        payload: username,
    };
};

export const fetchUserAccessSuccess = (userAccess: IUserAccess): IFetchUserAccessSuccessAction => {
    return {
        type: FETCH_USER_ACCESS_SUCCESS,
        payload: userAccess,
    };
};

export const fetchUserAccessFailure = (error: string): IFetchUserAccessFailureAction => {
    return {
        type: FETCH_USER_ACCESS_FAILURE,
        payload: error,
    };
};

export const fetchUsersSuccess = (users: IUser[]): IFetchUsersSuccessAction => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: users,
    };
};

export const fetchUsersFailure = (error: string): IFetchUsersFailureAction => {
    return {
        type: FETCH_USERS_FAILURE,
        payload: error,
    };
};

export const createUserRequest = (user: IUser): ICreateUserRequestAction => {
    return {
        type: CREATE_USER_REQUEST,
        payload: user,
    };
};

export const updateUserRequest = (user: IUser): IUpdateUserRequestAction => {
    return {
        type: UPDATE_USER_REQUEST,
        payload: user,
    };
};

export const deleteUserRequest = (user: IUser): IDeleteUserRequestAction => {
    return {
        type: DELETE_USER_REQUEST,
        payload: user,
    };
};

export const setUsername = (username: string): ISetUsernameAction => {
    return {
        type: SET_USERNAME,
        payload: username,
    };
};

export const logoutUser = (): ILogoutUser => {
    return {
        type: LOGOUT_USER,
        payload: undefined,
    };
};

export const setTokenCookiesRequest = (tokenResponse: TokenResponse): ISetTokenCookiesRequestAction => {
    return {
        type: SET_TOKEN_COOKIES_REQUEST,
        payload: tokenResponse,
    };
};

export const setTokenCookiesSuccess = (username: string): ISetTokenCookiesSuccessAction => {
    return {
        type: SET_TOKEN_COOKIES_SUCCESS,
        payload: username,
    };
};

export const setTokenCookiesFailure = (error: string): ISetTokenCookiesFailureAction => {
    return {
        type: SET_TOKEN_COOKIES_FAILURE,
        payload: error,
    };
};
