import { Reducer, combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import storage from 'redux-persist/lib/storage';
import { AppState } from './AppState';
import applicationReducer from './application/applicationReducer';
import userReducer from './user/userReducer';
import termReducer from './term/termReducer';
import roomReducer from './room/roomReducer';
import courseReducer from './course/courseReducer';
import courseMeetingReducer from './courseMeeting/courseMeetingReducer';
import { persistReducer } from 'redux-persist';
import { extronSSEReducer } from './extronSSE/extronSseReducer';
import { gcalReducer } from './googleCalendar/gcalReducer';
import { REACT_APP_ENV } from '../constants';
import { serviceRequestReducer } from './serviceRequest/serviceRequestReducer';
import cameraReducer from './camera/cameraReducer';

// Course objects should not be persisted and instead fetched again from the server
const coursesPersistConfig = {
    key: `courses-${REACT_APP_ENV}`,
    storage,
    blacklist: ['courses'],
};

// CourseMeeting objects should not be persisted and instead fetched again from the server
const courseMeetingsPersistConfig = {
    key: `courseMeetings-${REACT_APP_ENV}`,
    storage,
    blacklist: ['courseMeetings'],
};

const rootReducer = (history: History): Reducer<AppState> =>
    combineReducers<AppState>({
        user: userReducer,
        app: applicationReducer,
        terms: termReducer,
        rooms: roomReducer,
        recorders: extronSSEReducer,
        camera: cameraReducer,
        gcal: gcalReducer,
        serviceRequests: serviceRequestReducer,
        courses: persistReducer(coursesPersistConfig, courseReducer),
        courseMeetings: persistReducer(courseMeetingsPersistConfig, courseMeetingReducer),
        router: connectRouter(history),
    });

export default rootReducer;
