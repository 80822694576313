import { FETCH_ROOMS_FAILURE, FETCH_ROOMS_SUCCESS } from './roomActionTypes';
import { IRoomsState } from './roomInterfaces';
import { RoomActionTypes } from './roomTypes';

const roomsInitialState: IRoomsState = {
    isLoadingRooms: false,
    rooms: [],
    error: undefined,
};

const roomReducer = (state: IRoomsState = roomsInitialState, action: RoomActionTypes): IRoomsState => {
    switch (action.type) {
        case FETCH_ROOMS_SUCCESS:
            return {
                ...state,
                error: undefined,
                isLoadingRooms: false,
            };

        case FETCH_ROOMS_FAILURE:
            return {
                ...state,
                isLoadingRooms: false,
                error: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};

export default roomReducer;
