import useSWR from 'swr';
import { ROOM_API_URL } from '../constants';
import { fetcher } from '../utils/helpers';
import { FetchError } from '../redux/application/applicationInterfaces';
import { IRoom } from '../redux/room/roomInterfaces';

type RoomData = {
    rooms: IRoom[] | undefined;
    isLoadingRooms: boolean;
    isRoomsError: FetchError;
};

const roomFetcher = async (url: string) => {
    return await fetcher<IRoom[]>(url, false);
};

export const useRooms = (isLoggedIn: boolean): RoomData => {
    const { data, error } = useSWR<IRoom[]>(isLoggedIn ? `${ROOM_API_URL}` : null, roomFetcher, {
        refreshInterval: 10 * 60 * 1000,
        fallbackData: [],
        revalidateOnMount: true,
    });

    const sortedData =
        data &&
        [...data]?.sort((a: IRoom, b: IRoom) => {
            const buildingComparison = a.building.localeCompare(b.building);
            if (buildingComparison !== 0) return buildingComparison;
            return parseInt(a.roomNumber) - parseInt(b.roomNumber);
        });

    return {
        rooms: sortedData,
        isLoadingRooms: !error && !data,
        isRoomsError: error,
    };
};
