import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { GridRowData, GridRowId } from '@material-ui/data-grid';

interface ICourseConfirmationButtonProps {
    handleConfirmation: () => void;
    confirmButtonDisabled: boolean;
    selectedCourses: Map<GridRowId, GridRowData>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        confirmButtonBox: {
            marginRight: 'auto',
            minWidth: '192px',
        },
        confirmButton: {
            '&:hover': {
                background: theme.palette.success.light,
            },
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
        },
    }),
);

export const CourseConfirmButton = (props: ICourseConfirmationButtonProps): JSX.Element => {
    const classes = useStyles();
    return (
        <Box m={1} className={classes.confirmButtonBox}>
            <Button
                variant="contained"
                onClick={props.handleConfirmation}
                disabled={props.confirmButtonDisabled}
                className={classes.confirmButton}
                startIcon={<ScheduleIcon color={props.confirmButtonDisabled ? 'disabled' : 'inherit'} />}
            >
                Confirm {props.selectedCourses.size > 0 ? props.selectedCourses.size : null} course
                {props.selectedCourses.size > 1 ? 's' : null}
            </Button>
        </Box>
    );
};
