import React, { ReactEventHandler, useEffect, useRef, useState } from 'react';
import { IMJPEGStreamViewerProps, IMJPEGViewState } from './MJPEGStreamInterfaces';
import { useStyles } from './MJPEGStreamViewerStyles';
import { logger } from '../../services/Logger';
import { LinearProgress } from '@material-ui/core';

/**
 * Sets the src attribute of an image element to and empty string
 *
 * See: Google Chrome bug https://bugs.chromium.org/p/chromium/issues/detail?id=73395
 * Setting the image src to a new mjpeg stream DOES NOT release the prior mjpeg stream
 * setting the src of the element to an empty string seems to work to release the previous mjpeg stream
 * before setting a new mjpeg src
 * @param imgElement
 */
const releaseMJPEGStream = (imgElement: HTMLImageElement | null) => {
    logger.debug('Releasing MJPEG stream...', imgElement);
    imgElement?.setAttribute('src', '');
};

const initialStreamState: IMJPEGViewState = {
    endpoint: '',
    loaded: false,
    loading: false,
    error: false,
};

export const MJPEGStreamViewer: React.FC<IMJPEGStreamViewerProps> = (params: IMJPEGStreamViewerProps) => {
    const { mjpegStreamEndpoint } = params;
    const classes = useStyles();
    const cameraImgRef = useRef<HTMLImageElement | null>(null);
    const [streamState, setStreamState] = useState<IMJPEGViewState>({
        ...initialStreamState,
        loading: true,
        endpoint: mjpegStreamEndpoint,
    });

    useEffect(() => {
        logger.debug('mjpegStreamEndpoint changed...', mjpegStreamEndpoint);
        if (!mjpegStreamEndpoint || mjpegStreamEndpoint.split('ip=')[1] === '') {
            logger.debug('No camera stream endpoint provided. Release current stream.');
            releaseMJPEGStream(cameraImgRef.current);
            setStreamState({
                ...initialStreamState,
                loading: true,
                loaded: false,
                endpoint: '/i/nocamera.png',
            });
        }

        if (mjpegStreamEndpoint !== undefined && !streamState.endpoint.includes(mjpegStreamEndpoint)) {
            logger.debug('New camera stream endpoint provided. Release current stream.');
            releaseMJPEGStream(cameraImgRef.current);
            setStreamState({
                error: false,
                loading: true,
                loaded: false,
                endpoint: mjpegStreamEndpoint,
            });
        }
    }, [mjpegStreamEndpoint]);

    useEffect(() => {
        logger.debug('Endpoint changed...');
        const interval = setInterval(() => {
            logger.debug('Checking if MJPEG stream is loaded...',cameraImgRef.current?.complete, streamState.endpoint);
            if (cameraImgRef.current?.complete && streamState.endpoint !== '/i/nocamera.png') {
                logger.debug(`MJPEG stream loaded from ${streamState.endpoint}`);
                clearInterval(interval);
                setStreamState({ ...streamState, loaded: true, loading: false });
            }
        }, 2);

        return () => {
            setStreamState({
                ...streamState,
                loading: false,
                loaded: false,
                endpoint: '/i/nocamera.png',
                error: true,
            });
            clearInterval(interval);
        };
    }, [streamState.endpoint]);

    // Cleanup
    useEffect(() => {
        let cfref: HTMLImageElement | null = null;
        if (cameraImgRef.current) {
            cfref = cameraImgRef.current;
        }
        return () => {
            releaseMJPEGStream(cfref);
        };
    }, [cameraImgRef]);

    const handleCameraImgError: ReactEventHandler<HTMLImageElement> = () => {
        logger.error('Could not load camera image!');
        setStreamState({
            ...streamState,
            loading: false,
            loaded: false,
            endpoint: '/i/nocamera.png',
            error: true,
        });
    };

    return (
        <>
            <img
                ref={cameraImgRef}
                className={
                    streamState.loaded
                        ? streamState.error
                            ? classes.cameraImageError
                            : classes.cameraImageLoaded
                        : classes.cameraImageLoading
                }
                key={streamState.endpoint}
                src={streamState.endpoint}
                onError={handleCameraImgError}
                
            />

            {streamState.loading && <LinearProgress color="secondary" className={classes.loadingProgress} />}
        </>
    );
};
