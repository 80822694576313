// Redux Course action types

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_FAILURE = 'CREATE_COURSE_FAILURE';

export const COURSE_CONFIRM_REQUEST = 'COURSE_CONFIRM_REQUEST';
export const COURSE_CONFIRM_SUCCESS = 'COURSE_CONFIRM_SUCCESS';
export const COURSE_CONFIRM_FAILURE = 'COURSE_CONFIRM_FAILURE';

export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';

export const PUT_COURSE_REQUEST = 'PUT_COURSE_REQUEST';
export const PUT_COURSE_SUCCESS = 'PUT_COURSE_SUCCESS';
export const PUT_COURSE_FAILURE = 'PUT_COURSE_FAILURE';

export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAILURE = 'DELETE_COURSE_FAILURE';

export const FOCUS_COURSE = 'FOCUS_COURSE';
export const DEFOCUS_COURSE = 'DEFOCUS_COURSE';