import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardContent, CardHeader, createStyles, IconButton, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CourseMeetingsDataGrid from './CourseMeetingsDataGrid';
import { CourseMeetingsDataGridColDef } from './CourseMeetingsDataGridColDef';
import EditDateRangeForm from './forms/CourseMeetingsDateRangeForm';

import EditIcon from '@material-ui/icons/Edit';
import { useCourseMeetings } from '../../hooks/useCourseMeetings';
import { getDayBoundaries, handleSWRError } from '../../utils/helpers';

const useStyles = makeStyles(() =>
    createStyles({
        meetingsCard: {
            margin: '1rem 0',
            overflow: 'scroll',
            maxWidth: '100%',
            width: '100%',
        },
        meetingsCardHeader: {
            marginBottom: 0,
            paddingBottom: 0,
            '& .MuiCardHeader-subheader': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        meetingsCardEditBtn: {
            marginLeft: '1rem',
        },
        form: {
            display: 'flex',
            alignItems: 'end',
            '& .MuiTextField-root': {
                margin: '1rem 1rem 0 0',
            },
        },
    }),
);

interface ICourseMeetingCard {
    title: string;
}

const CourseMeetingCard: React.FC<ICourseMeetingCard> = (props) => {
    const { title } = props;
    const dispatch = useDispatch();

    const [startDateTime, endDateTime] = getDayBoundaries();

    const [editMode, setEditMode] = useState(false);
    const [dateRange, setDateRange] = useState({ startDateTime, endDateTime });

    const classes = useStyles();

    const formatCardDateString = (date: Date) => {
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            year: 'numeric',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const { courseMeetings, isLoadingCourseMeetings, isCourseMeetingsError } = useCourseMeetings(
        null,
        dateRange.startDateTime,
        dateRange.endDateTime,
    );

    // Handle error fetching course meetings
    useEffect(() => {
        handleSWRError(isCourseMeetingsError, 'course meetings', dispatch);
    }, [isCourseMeetingsError]);

    const CurrentDateRange: React.FC = () => {
        return (
            <>
                {formatCardDateString(dateRange.startDateTime)} - {formatCardDateString(dateRange.endDateTime)}
                <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={handleEditMode}
                    className={classes.meetingsCardEditBtn}
                >
                    <EditIcon color="action" />
                </IconButton>
            </>
        );
    };

    const handleEditMode = () => {
        setEditMode(true);
    };

    const submitDateRange = (startDateTime: Date, endDateTime: Date) => {
        const newDateRange = { startDateTime: new Date(startDateTime), endDateTime: new Date(endDateTime) };
        setDateRange(newDateRange);
        setEditMode(false);
    };

    return (
        <Card className={classes.meetingsCard}>
            <CardHeader
                title={title}
                subheader={
                    editMode ? (
                        <EditDateRangeForm
                            startDateTime={dateRange.startDateTime}
                            endDateTime={dateRange.endDateTime}
                            handleSubmit={submitDateRange}
                        />
                    ) : (
                        <CurrentDateRange />
                    )
                }
                className={classes.meetingsCardHeader}
            />
            <CardContent>
                <CourseMeetingsDataGrid
                    isLoadingMeetings={isLoadingCourseMeetings}
                    courseMeetings={courseMeetings}
                    autoHeight={true}
                    columns={CourseMeetingsDataGridColDef}
                    showCancelledMeetings={false}
                />
            </CardContent>
        </Card>
    );
};

export default CourseMeetingCard;
