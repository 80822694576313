import { ICourse } from "../redux/course/courseInterfaces";
import { ICourseMeeting, ICourseMeetingData } from "../redux/courseMeeting/courseMeetingInterfaces";

/**
 * Factory function to generate a course meeting
 */
export const CourseMeetingFactory = (() => {
    const courseMeeting: ICourseMeeting = {
        eventIndex: '0',
        version: 0,
        totalVersions: 0,
        entityType: 'lecture-confirmed-v0',
        eventID: '',
        location: '',
        term: '',
        courseID: '',
        startDateTime: '',
        endDateTime: '',
        subject: '',
        course: '',
        seq: '',
        title: '',
        meetingType: '',
        building: '',
        roomNumber: '',
        conflicts: [],
        days: '',
        time: '',
        notes: '',
        recordingOption: '',
        recorded: null,
        kalturaFTP: null,
        kalturaXML: null,
        kalturaStatus: null,
        s3Uri: null,
        s3BackupTime: null,
    };

    /**
     *
     * @param startDateTime The start date time as a string eg. 2021-08-03T23:18Z
     * @returns
     */
    const generateEventIndex = (startDateTime: string): string => {
        return (new Date(startDateTime).getTime() / 1000).toString();
    };

    const generateLocation = (courseMeeting: ICourseMeeting): string => {
        return `${courseMeeting.term}-${courseMeeting.building}-${courseMeeting.roomNumber}-${courseMeeting.days}-${courseMeeting.time}`;
    };

    const generateEventID = (course: ICourse): string => {
        return `${course.termCode}-${course.subject}-${course.course}-${course.seq}-${courseMeeting.eventIndex}`;
    };

    const createFromCourse = (course: ICourse, newCourseMeetingProps: ICourseMeetingData) => {
        courseMeeting.courseID = course.courseID;
        courseMeeting.subject = course.subject;
        courseMeeting.course = course.course;
        courseMeeting.seq = course.seq;
        courseMeeting.title = course.title;
        courseMeeting.days = course.days;
        courseMeeting.time = course.times;
        courseMeeting.term = course.termCode;

        courseMeeting.meetingType = newCourseMeetingProps.meetingType || 'A';
        courseMeeting.building = newCourseMeetingProps.building;
        courseMeeting.roomNumber = newCourseMeetingProps.roomNumber;
        courseMeeting.startDateTime = newCourseMeetingProps.startDateTime.replace(/Z?$|\.\d{3}Z$/, '');
        courseMeeting.endDateTime = newCourseMeetingProps.endDateTime.replace(/Z?$|\.\d{3}Z$/, '');
        courseMeeting.recordingOption = newCourseMeetingProps.recordingOption;
        courseMeeting.notes = newCourseMeetingProps.notes;

        courseMeeting.location = generateLocation(courseMeeting);
        courseMeeting.eventIndex =
            newCourseMeetingProps.eventIndex || generateEventIndex(newCourseMeetingProps.startDateTime);
        courseMeeting.eventID = newCourseMeetingProps.eventID || generateEventID(course);
        return { ...courseMeeting };
    };

    return {
        createFromCourse,
    };
})();
