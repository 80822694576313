import { ISendExtronCommandRequestAction } from "./extronInterfaces";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { Snack } from "../../factories/Snackbar";
import { SHOW_SNACKBAR } from "../application/applicationActionTypes";
import { SendExtronCommand } from "./extronAPIs";

export function* sendExtronCommand({ payload }: ISendExtronCommandRequestAction): SagaIterator {
    try {
        yield call(SendExtronCommand, payload);
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
    }
}
