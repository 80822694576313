import { IconButton, Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { APPLICATION_NAME, SCOPES } from '../constants';
import { TOGGLE_APP_DRAWER } from '../redux/application/applicationActionTypes';
import { ApplicationActionTypes } from '../redux/application/applicationTypes';
import { AppState } from '../redux/AppState';
import { LOGOUT_USER } from '../redux/user/userActionTypes';
import { UserActionTypes } from '../redux/user/userTypes';
import AppDrawer, { INavigationItem } from './AppDrawer';
import { logger } from '../services/Logger';
import {
    CalendarToday as CalendarTodayIcon,
    Dashboard as DashboardIcon,
    MeetingRoom as MeetingRoomIcon,
    Menu as MenuIcon,
    PeopleAlt as PeopleAltIcon,
    Tune as TuneIcon,
    Visibility as VisibilityIcon,
    ExitToApp as ExitToAppIcon,
    SwitchVideo as SwitchVideoIcon,
    AspectRatio as AspectRatioIcon,
} from '@material-ui/icons';
import { PermissionGate } from './PermissionGate';

const CalendarTodayIconMemo = memo(CalendarTodayIcon);
const DashboardIconMemo = memo(DashboardIcon);
const MeetingRoomIconMemo = memo(MeetingRoomIcon);
const MenuIconMemo = memo(MenuIcon);
const PeopleAltIconMemo = memo(PeopleAltIcon);
const TuneIconMemo = memo(TuneIcon);
const VisibilityIconMemo = memo(VisibilityIcon);
const ExitToAppIconMemo = memo(ExitToAppIcon);
const SwitchVideoIconMemo = memo(SwitchVideoIcon);
const AspectRatioIconMemo = memo(AspectRatioIcon);

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        menuButtonHidden: {
            display: 'none',
        },
        hide: {
            display: 'none',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        toolbarButtons: {
            marginLeft: 'auto',
            color: theme.palette.common.white,
        },
        logoutButton: {
            padding: '8px 16px',
            color: theme.palette.common.white,
        },
        title: {
            marginLeft: '15px',
        },

        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        fixedHeight: {
            height: 240,
        },
        appBarSpacer: theme.mixins.toolbar,
    }),
);

const mainNavigationItems: INavigationItem[] = [
    { item: 'dashboard', route: '/Dashboard', requiredScopes: [SCOPES.canView], iconComponent: <DashboardIconMemo /> },
    { item: 'courses', route: '/Courses', requiredScopes: [SCOPES.canEdit], iconComponent: <CalendarTodayIconMemo /> },
    { item: 'argus', route: '/Argus', requiredScopes: [SCOPES.canViewArgus], iconComponent: <VisibilityIconMemo /> },
    { item: 'capture', route: '/Capture', requiredScopes: [SCOPES.canViewStreams], iconComponent: <SwitchVideoIconMemo /> },
    { item: 'room viewer', route: '/RoomViewer', requiredScopes: [SCOPES.canViewStreams], iconComponent: <AspectRatioIconMemo /> },
    { item: 'people', route: '/People', requiredScopes: [SCOPES.canEdit], iconComponent: <PeopleAltIconMemo /> },
    { item: 'rooms', route: '', requiredScopes: [SCOPES.canEdit], iconComponent: <MeetingRoomIconMemo /> },
    { item: 'settings', route: '', requiredScopes: [SCOPES.canAdmin], iconComponent: <TuneIconMemo /> },
];

export interface IPageHeaderProps {
    loginPath: string;
    roles: string[];
    username: string;
    isLoading: boolean;
    appDrawerOpen: boolean;
    logoutUser: () => void;
    toggleAppDrawer: () => void;
}

const PageHeader: React.FC<IPageHeaderProps> = (props) => {
    const classes = useStyles();

    const handleDrawerOpen = () => {
        props.toggleAppDrawer();
    };

    const handleDrawerClose = () => {
        props.toggleAppDrawer();
    };

    const handleLogout = () => {
        logger.debug('Logging user out');
        if (props.appDrawerOpen) {
            props.toggleAppDrawer();
        }
        props.logoutUser();
    };

    return (
        <>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, props.appDrawerOpen && classes.appBarShift)}>
                <Toolbar>
                    <PermissionGate scopes={[SCOPES.canView]}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, props.appDrawerOpen && classes.menuButtonHidden)}
                        >
                            <MenuIconMemo />
                        </IconButton>
                    </PermissionGate>

                    <Typography variant="h6" className={classes.title} noWrap>
                        {APPLICATION_NAME}
                    </Typography>
                    <div className={classes.toolbarButtons}>
                        <PermissionGate scopes={[SCOPES.canView]}>
                            <Button
                                className={classes.logoutButton}
                                color="inherit"
                                onClick={handleLogout}
                                endIcon={<ExitToAppIconMemo />}
                            >
                                Logout
                            </Button>
                        </PermissionGate>
                    </div>
                </Toolbar>
            </AppBar>

            <PermissionGate scopes={[SCOPES.canView]}>
                <AppDrawer
                    handleDrawerClose={handleDrawerClose}
                    open={props.appDrawerOpen}
                    appDrawerItems={mainNavigationItems}
                    userRoles={props.roles}
                />
            </PermissionGate>
        </>
    );
};

const MapStateToProps = (state: AppState) => ({
    roles: state.user.roles,
    username: state.user.username,
    isLoading: state.user.isLoading,
    appDrawerOpen: state.app.appDrawerOpen,
});

const MapDispatchToProps = (dispatch: Dispatch<UserActionTypes | ApplicationActionTypes>) => ({
    logoutUser: () => dispatch({ type: LOGOUT_USER, payload: undefined }),
    toggleAppDrawer: () => dispatch({ type: TOGGLE_APP_DRAWER }),
});

export default connect(MapStateToProps, MapDispatchToProps)(PageHeader);
