import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import {
    ICourseMeeting,
    ICreateCourseMeetingRequestAction,
    IPutCourseMeetingsRequestAction,
} from './courseMeetingInterfaces';
import {
    CreateCourseMeeting,
    PutCourseMeeting,
} from './courseMeetingAPIs';
import {
    createCourseMeetingFailure,
    createCourseMeetingSuccess,
    putCourseMeetingFailure,
    putCourseMeetingSuccess,
} from './courseMeetingActions';
import { Snack } from '../../factories/Snackbar';
import { showSnackbar } from '../application/applicationActions';

/**
 * Fetches course meetings for a course
 *
 * @param payloadObject FETCH_COURSEMEETINGS_REQUEST action payload
 */
// export function* fetchCourseMeetings({ payload }: IFetchCourseMeetingsRequestAction): SagaIterator {
//     try {
//         const courseMeetings: ICourseMeeting[] = yield call(FetchCourseMeetings, payload);
//         yield put(fetchCourseMeetingsSuccess(courseMeetings));
//     } catch (e) {
//         const errorMessage = Snack(`${e.message}`, 'error');
//         yield put(showSnackbar(errorMessage));
//         yield put(fetchCourseMeetingsFailure(e.message));
//     }
// }

/**
 * Fetches course meetings with conflicting assignment of room and time for a course meeting
 *
 * @param payloadObject FETCH_COURSEMEETING_CONFLICTS action payload
 */

// export function* fetchCourseMeetingConflicts({ payload }: IFetchCourseMeetingConflictsRequestAction): SagaIterator {
//     try {
//         const courseMeeting = { ...payload };
//         const meetingConflicts: ICourseMeeting[] = yield call(FetchCourseMeetingConflicts, courseMeeting);

//         courseMeeting.conflicts = meetingConflicts;
//         yield put(fetchCourseMeetingConflictsSuccess(courseMeeting));
//     } catch (e) {
//         const errorMessage = Snack(`${e.message}`, 'error');
//         yield put(showSnackbar(errorMessage));
//         yield put(fetchCourseMeetingConflictsFailure(e.message));
//     }
// }

/**
 * Fetches meetings by date range
 *
 * @param payloadObject FETCH_COURSEMEETINGS_BY_DATE_RANGE_REQUEST action payload
 */
// export function* fetchCourseMeetingsByDate({ payload }: IFetchCourseMeetingsByDateRequestAction): SagaIterator {
//     try {
//         const courseMeetings: ICourseMeeting[] = yield call(FetchCourseMeetingsByDate, payload);
//         yield put(fetchCourseMeetingsByDateSuccess(courseMeetings));
//     } catch (e) {
//         const errorMessage = Snack(`${e.message}`, 'error');
//         yield put(showSnackbar(errorMessage));
//         yield put(fetchCourseMeetingsByDateFailure(e.message));
//     }
// }

/**
 * Updates a course meeting
 *
 * @param payloadObject PUT_COURSEMEETING_REQUEST action payload
 */
export function* putCourseMeeting({ payload }: IPutCourseMeetingsRequestAction): SagaIterator {
    try {
        const updatedMeeting: ICourseMeeting = yield call(PutCourseMeeting, payload);
        const successMessage = Snack(`Updated ${updatedMeeting.eventID}`);
        yield put(putCourseMeetingSuccess(updatedMeeting));
        yield put(showSnackbar(successMessage));
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put(showSnackbar(errorMessage));
        yield put(putCourseMeetingFailure(e.message));
    }
}

/**
 * Creates a course meeting
 *
 * @param payloadObject CREATE_COURSEMEETING_REQUEST action payload
 */
export function* createCourseMeeting({ payload }: ICreateCourseMeetingRequestAction): SagaIterator {
    try {
        const newMeeting: ICourseMeeting = yield call(CreateCourseMeeting, payload);
        const successMessage = Snack(`Created course meeting ${newMeeting.eventID}`);
        yield put(showSnackbar(successMessage));
        yield put(createCourseMeetingSuccess(newMeeting));
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put(showSnackbar(errorMessage));
        yield put(createCourseMeetingFailure(e.message));
    }
}
