import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

interface ICourseDeleteButtonProps {
    handleCourseDelete: () => void;
    deleteButtonDisabled: boolean;
    selectedCourses: string[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        deleteButtonBox: {
            marginRight: 'auto',
        },
        deleteButton: {
            '&:hover': {
                background: theme.palette.error.main,
            },
            color: theme.palette.error.contrastText,
            background: theme.palette.error.dark,
        },
    }),
);

export const CourseDeleteButton = (props: ICourseDeleteButtonProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Box m={1} className={classes.deleteButtonBox}>
            <Button
                className={classes.deleteButton}
                onClick={props.handleCourseDelete}
                disabled={props.deleteButtonDisabled}
                color="default"
                variant="contained"
                startIcon={<DeleteIcon color={props.deleteButtonDisabled ? 'disabled' : 'inherit'} />}
            >
                Delete {props.selectedCourses.length > 0 ? props.selectedCourses.length : null} course
                {props.selectedCourses.length > 1 ? 's' : null}
            </Button>
        </Box>
    );
};
