import useSWR from 'swr';
import { BASE_URL } from '../constants';
import { fetcher } from '../utils/helpers';
import { FetchError } from '../redux/application/applicationInterfaces';
import { IUser } from '../redux/user/userInterfaces';


type TermData = {
    users: IUser[];
    isLoadingUsers: boolean;
    isUsersError: FetchError;
    mutateUsers: (users?: IUser[], revalidate?: boolean) => void;
};

export const useUsers = (isLoggedIn: boolean): TermData => {
    const { data, error, isLoading, mutate} = useSWR<IUser[]>(isLoggedIn ? `${BASE_URL}/users` : null, fetcher, {
        refreshInterval: 10 * 60 * 1000,
        fallbackData: [],
        revalidateOnMount: true,
    });

    return {
        users: data || [],
        isLoadingUsers: isLoading,
        isUsersError: error,
        mutateUsers: mutate,
    };
};
