import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { ADMIN_EMAIL } from '../constants';
import { isAuthenticated } from '../services/AuthService';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        paper: {
            padding: theme.spacing(4),
        },
        appBarSpacer: theme.mixins.toolbar,
    }),
);

const Login: React.VFC = () => {
    const classes = useStyles();
    const history = useHistory();

    if (!isAuthenticated()) {
        history.push(`/login`);
    }
    
    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="md" className={classes.container}>
                <Paper elevation={3} className={classes.paper}>
                    <Grid container spacing={2} alignItems="stretch" direction="row" justify="center">
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom color="primary">
                                Unauthorized Access
                            </Typography>
                            <Typography variant="h6">
                                You don't have permission to access this page. If you believe you have reached this page
                                by mistake, please email {ADMIN_EMAIL}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </main>
    );
};

export default Login;
