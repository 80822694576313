import { IPutCourseFailureAction, IPutCourseRequestAction, IPutCourseSuccessAction } from './courseInterfaces';
import {
    FETCH_COURSES_SUCCESS,
    FETCH_COURSES_FAILURE,
    FOCUS_COURSE,
    DEFOCUS_COURSE,
    COURSE_CONFIRM_REQUEST,
    COURSE_CONFIRM_FAILURE,
    COURSE_CONFIRM_SUCCESS,
    CREATE_COURSE_REQUEST,
    CREATE_COURSE_SUCCESS,
    CREATE_COURSE_FAILURE,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAILURE,
    PUT_COURSE_REQUEST,
    PUT_COURSE_SUCCESS,
    PUT_COURSE_FAILURE,
} from './courseActionTypes';
import {
    IFetchCoursesSuccessAction,
    IFetchCoursesFailureAction,
    ICourse,
    IFocusCourseAction,
    IDefocusCourseAction,
    IConfirmCourseRequestAction,
    IConfirmCourseSuccessAction,
    IConfirmCourseFailureAction,
    ICreateCourseRequestAction,
    ICreateCourseSuccessAction,
    ICreateCourseFailureAction,
    IDeleteCourseRequestAction,
    IDeleteCourseSuccessAciton,
    IDeleteCourseFailureAction,
} from './courseInterfaces';

export const createCourseRequest = (course: ICourse): ICreateCourseRequestAction => {
    return {
        type: CREATE_COURSE_REQUEST,
        payload: course,
    };
};

export const createCourseSuccess = (course: ICourse): ICreateCourseSuccessAction => {
    return {
        type: CREATE_COURSE_SUCCESS,
        payload: course,
    };
};

export const createCourseFailure = (error: string): ICreateCourseFailureAction => {
    return {
        type: CREATE_COURSE_FAILURE,
        payload: error,
    };
};

export const courseConfirmRequest = (course: ICourse): IConfirmCourseRequestAction => {
    return {
        type: COURSE_CONFIRM_REQUEST,
        payload: course,
    };
};

export const courseConfirmSuccess = (course: ICourse): IConfirmCourseSuccessAction => {
    return {
        type: COURSE_CONFIRM_SUCCESS,
        payload: course,
    };
};

export const courseConfirmFailure = (error: string): IConfirmCourseFailureAction => {
    return {
        type: COURSE_CONFIRM_FAILURE,
        payload: error,
    };
};

export const fetchCoursesSuccess = (courses: ICourse[]): IFetchCoursesSuccessAction => {
    return {
        type: FETCH_COURSES_SUCCESS,
        payload: courses,
    };
};

export const fetchCoursesFailure = (error: string): IFetchCoursesFailureAction => {
    return {
        type: FETCH_COURSES_FAILURE,
        payload: error,
    };
};

export const putCourseRequest = (course: ICourse): IPutCourseRequestAction => {
    return {
        type: PUT_COURSE_REQUEST,
        payload: course,
    };
};

export const putCourseSuccess = (course: ICourse): IPutCourseSuccessAction => {
    return {
        type: PUT_COURSE_SUCCESS,
        payload: course,
    };
};

export const putCourseFailure = (error: string): IPutCourseFailureAction => {
    return {
        type: PUT_COURSE_FAILURE,
        payload: error,
    };
};

export const deleteCourseRequest = (course: ICourse): IDeleteCourseRequestAction => {
    return {
        type: DELETE_COURSE_REQUEST,
        payload: course,
    };
};

export const deleteCourseSuccess = (course: ICourse): IDeleteCourseSuccessAciton => {
    return {
        type: DELETE_COURSE_SUCCESS,
        payload: course,
    };
};

export const deleteCourseFailure = (error: string): IDeleteCourseFailureAction => {
    return {
        type: DELETE_COURSE_FAILURE,
        payload: error,
    };
};

export const focusCourse = (courseID: string): IFocusCourseAction => {
    return {
        type: FOCUS_COURSE,
        payload: courseID,
    };
};

export const defocusCourse = (): IDefocusCourseAction => {
    return {
        type: DEFOCUS_COURSE,
    };
};
