// Redux CourseMeeting action types

// Fetch course meetings
export const FETCH_COURSEMEETINGS_SUCCESS = 'FETCH_COURSEMEETINGS_SUCCESS';
export const FETCH_COURSEMEETINGS_FAILURE = 'FETCH_COURSEMEETINGS_FAILURE';

// Fetch meetings by date range
export const FETCH_COURSEMEETINGS_BY_DATE_SUCCESS = 'FETCH_COURSEMEETINGS_BY_DATE_SUCCESS';
export const FETCH_COURSEMEETINGS_BY_DATE_FAILURE = 'FETCH_COURSEMEETINGS_BY_DATE_FAILURE';

// Update meeting record type
export const PUT_COURSEMEETING_REQUEST = 'PUT_COURSEMEETING_REQUEST';
export const PUT_COURSEMEETING_SUCCESS = 'PUT_COURSEMEETING_SUCCESS';
export const PUT_COURSEMEETING_FAILURE = 'PUT_COURSEMEETING_FAILURE';

// Create new course meeting
export const CREATE_COURSEMEETING_REQUEST = 'CREATE_COURSEMEETING_REQUEST';
export const CREATE_COURSEMEETING_SUCCESS = 'CREATE_COURSEMEETING_SUCCESS';
export const CREATE_COURSEMEETING_FAILURE = 'CREATE_COURSEMEETING_FAILURE';

// Fetch meeting conflicts
export const FETCH_COURSEMEETING_CONFLICTS_SUCCESS = 'FETCH_COURSEMEETING_CONFLICTS_SUCCESS';
export const FETCH_COURSEMEETING_CONFLICTS_FAILURE = 'FETCH_COURSEMEETING_CONFLICTS_FAILURE';