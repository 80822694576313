import { cloneElement, ReactElement } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { AppState } from '../redux/AppState';
import { getPermissionsFromRoles, hasPermission, isAuthenticated } from '../services/AuthService';

export interface IPermissionGateProps {
    children: ReactElement;
    scopes: string[];
    RenderError?: () => ReactElement;
    errorProps?: { [key: string]: unknown };
}

export const PermissionGate: React.FC<IPermissionGateProps> = ({
    children,
    RenderError = () => <></>,
    scopes = [],
    errorProps = null,
}): ReactElement => {
    if (!isAuthenticated()) return <></>;

    const { roles } = useSelector((state: AppState) => state.user, shallowEqual);

    const permissionGranted = hasPermission({ permissions: getPermissionsFromRoles(roles), scopes });

    if (!permissionGranted && !errorProps) return <RenderError />;

    if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps });

    return <>{children}</>;
};
