import { FETCH_TERMS_FAILURE, SELECT_TERM } from './termActionTypes';
import { ITermsState } from './termInterfaces';
import { TermActionTypes } from './termTypes';

const termsInitialState: ITermsState = {
    selectedTerm: '',
    error: '',
};

const termReducer = (state: ITermsState = termsInitialState, action: TermActionTypes): ITermsState => {
    switch (action.type) {
        case FETCH_TERMS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        case SELECT_TERM:
            return {
                ...state,
                selectedTerm: action.payload,
            };

        default:
            return state;
    }
};

export default termReducer;
