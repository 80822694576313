import { Button, Divider, Grid, List, ListItem, ListItemText, Paper, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import SyncIcon from '@material-ui/icons/Sync';
import { useCallback } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ICourse } from '../../../redux/course/courseInterfaces';

interface ICourseBannerSyncProps {
    handleClose: () => void;
    handleConfirm: (course: ICourse) => void;
    bannerCourse?: ICourse;
    confirmedCourse?: ICourse;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        syncDiffNew: {
            borderColor: `#fff #fff #fff ${theme.palette.success.main}`,
            border: 'solid',
            borderWidth: '0 0 0 3px',
            '& span': {},
            '& *': {
                fontWeight: 'bold',
            },
        },
        syncDiffCurrent: {
            borderColor: `#fff #fff #fff ${theme.palette.info.main}`,
            border: 'solid',
            borderWidth: '0 0 0 3px',
            '& span': {},
            '& *': {
                fontWeight: 'bold',
            },
        },
        noDiff: {
            backgroundColor: theme.palette.common.white,
        },
        paper: {
            //margin: '0 32px',
            marginTop: 0,
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(3),
            height: 'auto',
            width: '480px',
        },
        list: {
            marginBottom: theme.spacing(1),
        },
        listItem: {
            marginBottom: theme.spacing(0.5),
        },
    }),
);

const CourseBannerSyncForm: React.FC<ICourseBannerSyncProps> = (props) => {
    const { confirmedCourse, bannerCourse } = props;

    const classes = useStyles();

    const handleBannerSync = () => {
        if (props.bannerCourse) {
            props.handleConfirm(props.bannerCourse);
            props.handleClose();
        }
    };

    const getSyncClassName = (field: string, column: string): string => {
        if (confirmedCourse && bannerCourse) {
            switch (column) {
                case 'current':
                    return confirmedCourse[field as keyof ICourse] != bannerCourse[field as keyof ICourse]
                        ? classes.syncDiffCurrent
                        : classes.noDiff;
                    break;
                case 'new':
                    return confirmedCourse[field as keyof ICourse] != bannerCourse[field as keyof ICourse]
                        ? classes.syncDiffNew
                        : classes.noDiff;
            }
        }

        return classes.noDiff;
    };

    const memoizedGetSyncClassName = useCallback((field, column) => getSyncClassName(field, column), []);

    return (
        <>
            {confirmedCourse && bannerCourse && (
                <Paper className={classes.paper} elevation={0}>
                    <Grid container alignItems="flex-start" spacing={0}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Alert severity="info" style={{ marginBottom: '16px' }}>
                                    <AlertTitle style={{ fontWeight: 'bold' }}>
                                        This course has changes in Banner.
                                    </AlertTitle>
                                    <Typography variant="body2" paragraph>
                                        Review differences below, then click 'Sync Banner Changes' to sync the confirmed
                                        course with Banner.
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Note:</strong> Existing course meetings will be deleted.
                                    </Typography>
                                </Alert>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Divider />
                                {(props.confirmedCourse && (
                                    <List
                                        subheader={
                                            <Typography variant="button" component="h1">
                                                Confirmed Course
                                            </Typography>
                                        }
                                        component="nav"
                                        className={classes.list}
                                        aria-label="Confirmed course details"
                                        dense
                                        style={{
                                            border: 'solid',
                                            borderWidth: '0 1px 0 0',
                                            borderColor: '#ddd',
                                            marginRight: '16px',
                                        }}
                                    >
                                        <Divider />
                                        <ListItem
                                            className={[
                                                memoizedGetSyncClassName('building', 'current'),
                                                classes.listItem,
                                            ].join(' ')}
                                        >
                                            <ListItemText
                                                primary={props.confirmedCourse.building}
                                                secondary="Building"
                                            />
                                        </ListItem>
                                        <ListItem
                                            className={[
                                                memoizedGetSyncClassName('roomNumber', 'current'),
                                                classes.listItem,
                                            ].join(' ')}
                                        >
                                            <ListItemText primary={props.confirmedCourse.roomNumber} secondary="Room" />
                                        </ListItem>
                                        <ListItem
                                            className={[
                                                memoizedGetSyncClassName('days', 'current'),
                                                classes.listItem,
                                            ].join(' ')}
                                        >
                                            <ListItemText primary={props.confirmedCourse.days} secondary="Days" />
                                        </ListItem>
                                        <ListItem
                                            className={[
                                                memoizedGetSyncClassName('times', 'current'),
                                                classes.listItem,
                                            ].join(' ')}
                                        >
                                            <ListItemText primary={props.confirmedCourse.times} secondary="Times" />
                                        </ListItem>
                                    </List>
                                )) ||
                                    'Error'}
                            </Grid>
                            <Grid item xs={6}>
                                <Divider />
                                {(props.bannerCourse && (
                                    <List
                                        component="nav"
                                        aria-label="Confirmed course details"
                                        dense
                                        className={classes.list}
                                        subheader={
                                            <Typography variant="button" component="h1" color="primary">
                                                Banner Course
                                            </Typography>
                                        }
                                    >
                                        <Divider style={{ marginLeft: '-16px' }} />
                                        <ListItem
                                            className={[
                                                memoizedGetSyncClassName('building', 'new'),
                                                classes.listItem,
                                            ].join(' ')}
                                        >
                                            <ListItemText primary={props.bannerCourse.building} secondary="Building" />
                                        </ListItem>
                                        <ListItem
                                            className={[
                                                memoizedGetSyncClassName('roomNumber', 'new'),
                                                classes.listItem,
                                            ].join(' ')}
                                        >
                                            <ListItemText primary={props.bannerCourse.roomNumber} secondary="Room" />
                                        </ListItem>
                                        <ListItem
                                            className={[memoizedGetSyncClassName('days', 'new'), classes.listItem].join(
                                                ' ',
                                            )}
                                        >
                                            <ListItemText primary={props.bannerCourse.days} secondary="Days" />
                                        </ListItem>
                                        <ListItem
                                            className={[
                                                memoizedGetSyncClassName('times', 'new'),
                                                classes.listItem,
                                            ].join(' ')}
                                        >
                                            <ListItemText primary={props.bannerCourse.times} secondary="Times" />
                                        </ListItem>
                                    </List>
                                )) ||
                                    'Error'}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button type="button" variant="contained" onClick={props.handleClose}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    startIcon={<SyncIcon />}
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleBannerSync}
                                >
                                    Sync Banner Changes
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <p> </p>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Paper>
            )}
        </>
    );
};

export default CourseBannerSyncForm;
