import axios from 'axios';

import { BASE_URL, DEFAULT_REQUEST_PARAMS } from '../../constants';
import { ICourseMeeting } from './courseMeetingInterfaces';

import { requestErrorMessage } from '../../utils/helpers';

export async function PutCourseMeeting(courseMeeting: ICourseMeeting): Promise<ICourseMeeting> {
    const url = BASE_URL + `/courses/${courseMeeting.courseID}/meetings/${courseMeeting.eventID}`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const response = await axios.put(url, courseMeeting, { withCredentials: true, headers });

    if (response.status === 200 && response.request.responseURL === url) {
        return response.data;
    }

    throw new Error(requestErrorMessage(`Error updating course meeting ${courseMeeting.eventID}`, response.statusText));
}

export async function CreateCourseMeeting(courseMeeting: ICourseMeeting): Promise<ICourseMeeting> {
    const url = BASE_URL + `/courses/${courseMeeting.courseID}/meetings`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const response = await axios.post(url, courseMeeting, { withCredentials: true, headers });
    if (response.status === 200 && response.request.responseURL === url) {
        return response.data;
    }

    throw new Error(requestErrorMessage(`Error creating course meeting.`, response.statusText));
}

// export async function FetchCourseMeetingConflicts(courseMeeting: ICourseMeeting): Promise<ICourseMeeting[]> {
//     const url = BASE_URL + `/meetings/${courseMeeting.eventID}${KEY_SEPARATOR}${courseMeeting.entityType}/conflicts`;
//     const headers = DEFAULT_REQUEST_PARAMS;
//     const courseMeetingConflicts: ICourseMeeting[] = [];
//     const response = await axios.get(url, { withCredentials: true, headers });

//     if (response.status === 200 && response.request.responseURL === url) {
//         courseMeetingConflicts.push(...response.data);
//         return courseMeetingConflicts;
//     }

//     throw new Error(
//         requestErrorMessage(
//             'Error fetching meeting conflicts. Please check your network settings.',
//             response.statusText,
//         ),
//     );
// }
