// Redux camera action types

export const SELECT_CAMERA = 'SELECT_CAMERA';
export const DESELECT_CAMERA = 'DESELECT_CAMERA';

export const AUTO_FOCUS_QUERY_REQUEST = 'AUTO_FOCUS_QUERY_REQUEST';
export const AUTO_FOCUS_QUERY_SUCCESS = 'AUTO_FOCUS_QUERY_SUCCESS';
export const AUTO_FOCUS_QUERY_FAILURE = 'AUTO_FOCUS_QUERY_FAILURE';

export const SET_AUTO_FOCUS_REQUEST = 'SET_AUTO_FOCUS_REQUEST';
export const SET_AUTO_FOCUS_SUCCESS = 'SET_AUTO_FOCUS_SUCCESS';
export const SET_AUTO_FOCUS_FAILURE = 'SET_AUTO_FOCUS_FAILURE';

// Actions for sending a one-tap auto focus request to the camera when in manual focus mode
export const ISSUE_OTAF_REQUEST = 'ISSUE_OTAF_REQUEST';
export const ISSUE_OTAF_SUCCESS = 'ISSUE_OTAF_SUCCESS';
export const ISSUE_OTAF_FAILURE = 'ISSUE_OTAF_FAILURE';

export const REBOOT_CAMERA_REQUEST = 'REBOOT_CAMERA_REQUEST';
export const REBOOT_CAMERA_SUCCESS = 'REBOOT_CAMERA_SUCCESS';
export const REBOOT_CAMERA_FAILURE = 'REBOOT_CAMERA_FAILURE';

export const ISSUE_CAMERA_PRESET_REQUEST = 'ISSUE_CAMERA_PRESET_REQUEST';
export const ISSUE_CAMERA_PRESET_SUCCESS = 'ISSUE_CAMERA_PRESET_SUCCESS';
export const ISSUE_CAMERA_PRESET_FAILURE = 'ISSUE_CAMERA_PRESET_FAILURE';
