import { useEffect, useMemo } from "react";
import { debounce } from "./debounce";

export const useDebounce = <A = unknown, R = void>(fn: (args?: A | undefined) => R, ms: number): ((args?: A | undefined) => Promise<R>) => {
    const [debouncedFun, teardown] = useMemo(() => debounce(fn, ms), [fn, ms]);

    useEffect(() => () => teardown(), []);

    return debouncedFun;
};
