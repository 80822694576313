import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Input,
    InputLabel,
    MenuItem,
} from '@material-ui/core';

import { daysOfWeek, daySortMap, RECORDING_OPTIONS_ENUM } from '../../../constants';
import { findRoomNumbersByBuilding } from '../../../utils/helpers';
import { NewCourseFormStyles } from '../styles/newCourseFormStyles';
import { Course } from '../../../factories/Course';
import { Autocomplete } from '@material-ui/lab';
import { ICourse } from '../../../redux/course/courseInterfaces';
import { IRoom } from '../../../redux/room/roomInterfaces';
import { ITerm } from '../../../redux/term/termInterfaces';
import { IUserState } from '../../../redux/user/userInterfaces';

interface INewCourseFormProps {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    handleSave: (props: ICourse) => void;
    user: IUserState;
    rooms: IRoom[];
    terms: ITerm[];
    selectedTerm: string;
}

const NewCourseForm: React.FC<INewCourseFormProps> = (props) => {
    const classes = NewCourseFormStyles();

    const newCourseProps: ICourse = Course();
    newCourseProps.createdBy = props.user.username;
    newCourseProps.recordingOption = RECORDING_OPTIONS_ENUM.contentAndAudio.value;

    const buildings = Array.from(new Set(props.rooms.map((r) => r.building.toUpperCase()))).sort((a, b) =>
        a.localeCompare(b),
    );

    const recordingOptions = Object.values(RECORDING_OPTIONS_ENUM);

    interface FormFieldError {
        error: boolean;
        reason?: string;
    }

    interface NewCourseFormErrors {
        [key: string]: FormFieldError;
        termCode: FormFieldError;
        startdate: FormFieldError;
        enddate: FormFieldError;
        title: FormFieldError;
        subject: FormFieldError;
        course: FormFieldError;
        seq: FormFieldError;
        building: FormFieldError;
        roomNumber: FormFieldError;
        times: FormFieldError;
        instructorLoginID: FormFieldError;
        days: FormFieldError;
        dist: FormFieldError;
    }
    const formValidationErrors: NewCourseFormErrors = {
        termCode: { error: false, reason: undefined },
        startdate: { error: false, reason: undefined },
        enddate: { error: false, reason: undefined },
        title: { error: false, reason: undefined },
        subject: { error: false, reason: undefined },
        course: { error: false, reason: undefined },
        seq: { error: false, reason: undefined },
        building: { error: false, reason: undefined },
        roomNumber: { error: false, reason: undefined },
        times: { error: false, reason: undefined },
        instructor: { error: false, reason: undefined },
        instructorEmail: { error: false, reason: undefined },
        instructorLoginID: { error: false, reason: undefined },
        recordingOption: { error: false, reason: undefined },
        days: { error: false, reason: undefined },
        dist: { error: false, reason: undefined },
    };

    const [formErrors, setFormErrors] = React.useState<NewCourseFormErrors>(formValidationErrors);

    const [formState, setFormState] = React.useState<ICourse>(newCourseProps);
    const [roomNumbers, setRoomNumbers] = React.useState<string[]>([]);

    /**
     * Updates form state for multiple selection chip UI form elements
     * @param event
     * @param value
     */
    const handleFormChipsChange = (event: React.ChangeEvent<Record<string, never>>, value: string[]) => {
        setFormState({ ...formState, [event.target.name]: [...value.map((v) => v.trim())] });
    };

    /**
     * Updates form state
     * @param event
     */
    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldValue = event.target.type === 'checkbox' ? !!event.target.checked : event.target.value.trim();
        const fieldKey: string = event.target.name;
        const isUpdatingDaysOfWeek = daysOfWeek.includes(fieldKey);
        const newFormState: ICourse = isUpdatingDaysOfWeek
            ? { ...formState }
            : { ...formState, [fieldKey]: fieldValue };

        // The form allows for separate first and last name fields, but the final instructor name is in the for 'first, last'
        if (fieldKey === 'instructorFirstName')
            newFormState.instructor = `${newFormState.instructor.split(', ')[0]}, ${fieldValue}`;
        if (fieldKey === 'instructorLastName')
            newFormState.instructor = `${fieldValue}, ${newFormState.instructor.split(', ')[1]}`;

        // Update the room numbers after a building has been selected
        if (fieldKey === 'building') {
            const roomNumbers = findRoomNumbersByBuilding(props.rooms, event.target.value);
            newFormState.roomNumber = roomNumbers[0];
            setRoomNumbers(roomNumbers);
        }

        // If the user is updating a day of the week field
        if (isUpdatingDaysOfWeek) {
            if (fieldValue) {
                newFormState.days += event.target.value;
            } else {
                newFormState.days = newFormState.days.replace(event.target.value, '');
            }

            // Sort the days string. ie. we want MWF not FMW
            newFormState.days = newFormState.days
                .split('')
                .sort(function (x, y) {
                    return daySortMap[x] - daySortMap[y];
                })
                .join('');
        }
        setFormState(newFormState);
    };

    const validateTimesField = (times: string) => {
        const validation = {
            error: false,
            reason: '',
        };

        const timeStrings = times.split('-');

        if (timeStrings.length != 2) {
            validation.error = true;
            validation.reason = 'Format should match 00:00-00:00.';
            return validation;
        }

        if (timeStrings[0].length < 5 || timeStrings[1].length < 5) {
            validation.error = true;
            validation.reason = 'Format should match 00:00-00:00.';
            return validation;
        }

        const start = new Date('1970-01-01T' + timeStrings[0] + 'Z');
        const end = new Date('1970-01-01T' + timeStrings[1] + 'Z');
        if (isNaN(start.getTime())) {
            validation.error = true;
            validation.reason = 'Could not parse the start time.';
            return validation;
        }

        if (isNaN(end.getTime())) {
            validation.error = true;
            validation.reason = 'Could not parse the end time.';
            return validation;
        }

        if (start > end) {
            validation.error = true;
            validation.reason = 'Start time is after end time.';
            return validation;
        }

        return validation;
    };

    /**
     * Course form validation
     * @param formState
     * @returns
     */
    const validateNewCourseForm = (formState: ICourse) => {
        const errors = JSON.parse(JSON.stringify(formValidationErrors));

        if (!formState.termCode) {
            errors.termCode.error = true;
            errors.termCode.reason = 'Termcode is required.';
        }

        if (!formState.startdate) {
            errors.startdate.error = true;
            errors.startdate.reason = 'Start date is required.';
        }

        if (!formState.enddate) {
            errors.enddate.error = true;
            errors.enddate.reason = 'End date is required.';
        }

        if (!formState.title) {
            errors.title.error = true;
            errors.title.reason = 'The course title is required.';
        }

        if (!formState.subject) {
            errors.subject.error = true;
            errors.subject.reason = 'The course subject is required.';
        }

        if (!formState.course) {
            errors.course.error = true;
            errors.course.reason = 'The course number is required.';
        }

        if (!formState.seq) {
            errors.seq.error = true;
            errors.seq.reason = 'The course section is required.';
        }

        if (!formState.building) {
            errors.building.error = true;
            errors.building.reason = 'A building is required.';
        }

        if (!formState.roomNumber) {
            errors.roomNumber.error = true;
            errors.roomNumber.reason = 'A room number is required.';
        }

        if (!formState.times) {
            errors.times.error = true;
            errors.times.reason = 'The course times are required.';
        }

        // Validate that the 'times' string is valid ie. start time is before end time
        if (formState.times) {
            const validTimes = validateTimesField(formState.times);
            errors.times.error = validTimes.error;
            errors.times.reason = validTimes.reason;
        }

        if (!formState.instructorLoginID) {
            errors.instructorLoginID.error = true;
            errors.instructorLoginID.reason = 'The course instructor CAS ID is required.';
        }

        if (!formState.days) {
            errors.days.error = true;
            errors.days.reason = 'The course days are required.';
        }

        if (!formState.dist) {
            errors.dist.error = true;
            errors.dist.reason = 'The course distribution is required.';
        }

        const isValid = Object.keys(errors).find((k: keyof NewCourseFormErrors) => errors[k].error) ? false : true;

        if (isValid) {
            return isValid;
        }

        setFormErrors(errors);
        return false;
    };

    const resetNewCourseForm = () => {
        setFormState({ ...newCourseProps });
        setFormErrors({ ...formValidationErrors });
    };

    const handleFormClose = () => {
        resetNewCourseForm();
        props.handleClose();
    };

    const handleFormSubmit = () => {
        if (validateNewCourseForm(formState)) {
            resetNewCourseForm();
            props.handleSave(Course(formState));
        }
    };

    return (
        <div>
            {props.isOpen ? (
                <Dialog
                    open={props.isOpen}
                    onClose={props.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">Create New Course</DialogTitle>
                    <DialogContent>
                        <div>
                            <FormControl className={classes.formControl} error={formErrors.termCode.error}>
                                <TextField
                                    id="new-course-termcode"
                                    select
                                    label="Termcode"
                                    required
                                    value={formState.termCode}
                                    fullWidth={true}
                                    onChange={handleFormChange}
                                    className={classes.input}
                                    inputProps={{ name: 'termCode' }}
                                    error={formErrors.termCode.error}
                                >
                                    {props.terms.map((term) => (
                                        <MenuItem key={term.termCode} value={term.termCode}>
                                            {term.termCode}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText id="new-course-termcode-helper-text" error={formErrors.termCode.error}>
                                    {formErrors.termCode.reason || 'The termcode for the course.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} error={formErrors.startdate.error}>
                                <TextField
                                    id="new-course-startdate"
                                    label="Start date"
                                    required
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ name: 'startdate' }}
                                    error={formErrors.startdate.error}
                                />
                                <FormHelperText
                                    id="new-course-startdate-helper-text"
                                    error={formErrors.startdate.error}
                                >
                                    {formErrors.startdate.reason || 'The start date for the course.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} error={formErrors.enddate.error} required>
                                <TextField
                                    id="new-course-enddate"
                                    label="End date"
                                    required
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ name: 'enddate' }}
                                    error={formErrors.enddate.error}
                                />
                                <FormHelperText id="new-course-enddate-helper-text" error={formErrors.enddate.error}>
                                    {formErrors.enddate.reason || 'The end date for the course.'}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl} required error={formErrors.title.error}>
                                <InputLabel htmlFor="new-course-title">Course Title</InputLabel>
                                <Input
                                    id="new-course-title"
                                    required
                                    aria-describedby="new-course-title-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'title' }}
                                    error={formErrors.title.error}
                                />
                                <FormHelperText id="new-course-title-helper-text" error={formErrors.title.error}>
                                    {formErrors.title.reason || 'The title of the course.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} required error={formErrors.subject.error}>
                                <InputLabel htmlFor="new-course-title">Subject</InputLabel>
                                <Input
                                    id="new-course-subject"
                                    required
                                    aria-describedby="new-course-subject-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'subject' }}
                                    error={formErrors.subject.error}
                                />
                                <FormHelperText id="new-course-subject-helper-text" error={formErrors.subject.error}>
                                    {formErrors.subject.reason || 'The subject of the course.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} required error={formErrors.course.error}>
                                <InputLabel htmlFor="new-course-course">Course</InputLabel>
                                <Input
                                    id="new-course-course"
                                    required
                                    aria-describedby="new-course-course-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'course' }}
                                    error={formErrors.course.error}
                                />
                                <FormHelperText id="new-course-course-helper-text" error={formErrors.course.error}>
                                    {formErrors.course.reason || 'The title of the course.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} required error={formErrors.seq.error}>
                                <InputLabel htmlFor="new-course-section">Section</InputLabel>
                                <Input
                                    id="new-course-section"
                                    required
                                    aria-describedby="new-course-section-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'seq' }}
                                    error={formErrors.seq.error}
                                />
                                <FormHelperText id="new-course-section-helper-text" error={formErrors.seq.error}>
                                    {formErrors.seq.reason || 'The course section. ie. A01'}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl} error={formErrors.building.error}>
                                <TextField
                                    id="new-course-building"
                                    select
                                    label="Building"
                                    required
                                    value={formState.building}
                                    fullWidth={true}
                                    onChange={handleFormChange}
                                    className={classes.input}
                                    inputProps={{ name: 'building' }}
                                    error={formErrors.building.error}
                                >
                                    {buildings.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText id="new-course-building-helper-text" error={formErrors.building.error}>
                                    {formErrors.building.reason || ''}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} error={formErrors.roomNumber.error}>
                                <TextField
                                    id="new-course-roomNumber"
                                    select
                                    label="Room"
                                    required
                                    value={formState.roomNumber}
                                    fullWidth={true}
                                    onChange={handleFormChange}
                                    className={classes.input}
                                    inputProps={{ name: 'roomNumber' }}
                                    error={formErrors.roomNumber.error}
                                >
                                    {roomNumbers.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText
                                    id="new-course-roomNumber-helper-text"
                                    error={formErrors.roomNumber.error}
                                >
                                    {formErrors.roomNumber.reason || ''}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} required error={formErrors.times.error}>
                                <InputLabel htmlFor="new-course-times">Times</InputLabel>
                                <Input
                                    id="new-course-times"
                                    aria-describedby="new-course-times-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'times' }}
                                    error={formErrors.times.error}
                                />
                                <FormHelperText id="new-course-times-helper-text" error={formErrors.times.error}>
                                    {formErrors.times.reason || 'The course times.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={formErrors.recordingOption.error}
                            >
                                <TextField
                                    id="new-course-recordingOption"
                                    select
                                    label="Record Option"
                                    required
                                    value={formState.recordingOption}
                                    fullWidth={true}
                                    onChange={handleFormChange}
                                    className={classes.input}
                                    inputProps={{ name: 'recordingOption' }}
                                    error={formErrors.recordingOption.error}
                                >
                                    {recordingOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.html}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText
                                    id="new-course-recordingOption-helper-text"
                                    error={formErrors.recordingOption.error}
                                >
                                    {formErrors.recordingOption.reason || 'The recording option.'}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={formErrors.instructorLoginID.error}
                            >
                                <InputLabel htmlFor="new-course-instructorLoginID">Instructor ID</InputLabel>
                                <Input
                                    id="new-course-instructorLoginID"
                                    required
                                    aria-describedby="new-course-instructorLoginID-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    value={formState.instructorLoginID}
                                    inputProps={{ name: 'instructorLoginID' }}
                                    error={formErrors.instructorLoginID.error}
                                />
                                <FormHelperText
                                    id="new-course-instructorLoginID-helper-text"
                                    error={formErrors.instructorLoginID.error}
                                >
                                    {formErrors.instructorLoginID.reason || 'The instructor CAS login ID.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} required error={formErrors.instructor.error}>
                                <InputLabel htmlFor="new-course-instructorFirstName">Instructor First Name</InputLabel>
                                <Input
                                    id="new-course-instructorFirstName"
                                    required
                                    aria-describedby="new-course-instructorFirstName-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'instructorFirstName' }}
                                    error={formErrors.instructor.error}
                                />
                                <FormHelperText
                                    id="new-course-instructorFirstName-helper-text"
                                    error={formErrors.instructor.error}
                                >
                                    {formErrors.instructor.reason || 'The instructor first name.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl} required error={formErrors.instructor.error}>
                                <InputLabel htmlFor="new-course-instructorLastName">Instructor Last Name</InputLabel>
                                <Input
                                    id="new-course-instructorLastName"
                                    required
                                    aria-describedby="new-course-instructorLastName-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'instructorLastName' }}
                                    error={formErrors.instructor.error}
                                />
                                <FormHelperText
                                    id="new-course-instructorLastName-helper-text"
                                    error={formErrors.instructor.error}
                                >
                                    {formErrors.instructor.reason || 'The instructor last name.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                className={classes.formControl}
                                required
                                error={formErrors.instructorLoginID.error}
                            >
                                <InputLabel htmlFor="new-course-instructorLoginID">Instructor Email</InputLabel>
                                <Input
                                    id="new-course-instructorEmail"
                                    required
                                    aria-describedby="new-course-instructorEmail-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    value={formState.instructorEmail}
                                    inputProps={{ name: 'instructorEmail' }}
                                    error={formErrors.instructorEmail.error}
                                />
                                <FormHelperText
                                    id="new-course-instructorEmail-helper-text"
                                    error={formErrors.instructorEmail.error}
                                >
                                    {formErrors.instructorEmail.reason || 'The instructor email.'}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl
                                required
                                error={formErrors.days.error}
                                component="fieldset"
                                className={classes.formControl}
                                style={{ width: '416px', marginTop:'1rem' }}
                            >
                                <FormLabel component="legend">Days of the week</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formState.days.includes('M')}
                                                color="primary"
                                                name="monday"
                                                style={{ padding: '9px' }}
                                                onChange={handleFormChange}
                                                inputProps={{ name: 'monday' }}
                                                value="M"
                                            />
                                        }
                                        label="M"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formState.days.includes('T')}
                                                color="primary"
                                                name="tuesday"
                                                style={{ padding: '9px' }}
                                                onChange={handleFormChange}
                                                inputProps={{ name: 'tuesday' }}
                                                value="T"
                                            />
                                        }
                                        label="T"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formState.days.includes('W')}
                                                color="primary"
                                                name="wednesday"
                                                style={{ padding: '9px' }}
                                                onChange={handleFormChange}
                                                inputProps={{ name: 'wednesday' }}
                                                value="W"
                                            />
                                        }
                                        label="W"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formState.days.includes('R')}
                                                color="primary"
                                                name="thursday"
                                                style={{ padding: '9px' }}
                                                onChange={handleFormChange}
                                                inputProps={{ name: 'thursday' }}
                                                value="R"
                                            />
                                        }
                                        label="R"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formState.days.includes('F')}
                                                color="primary"
                                                name="friday"
                                                style={{ padding: '9px' }}
                                                onChange={handleFormChange}
                                                inputProps={{ name: 'friday' }}
                                                value="F"
                                            />
                                        }
                                        label="F"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formState.days.includes('S')}
                                                color="primary"
                                                name="saturday"
                                                style={{ padding: '9px' }}
                                                onChange={handleFormChange}
                                                inputProps={{ name: 'saturday' }}
                                                value="S"
                                            />
                                        }
                                        label="S"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formState.days.includes('X')}
                                                color="primary"
                                                name="sunday"
                                                style={{ padding: '9px' }}
                                                onChange={handleFormChange}
                                                inputProps={{ name: 'sunday' }}
                                                value="X"
                                            />
                                        }
                                        label="X"
                                    />
                                </FormGroup>
                                <FormHelperText id="new-course-days-helper-text" error={formErrors.days.error}>
                                    {formErrors.days.reason || 'The days of the week the course is held.'}
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="new-course-canvas-name">Canvas Course Name</InputLabel>
                                <Input
                                    id="new-course-canvas-name"
                                    aria-describedby="new-course-canvas-name-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'canvasCourseName' }}
                                />
                                <FormHelperText id="new-course-canvas-name-helper-text">
                                    The course name in Canvas.
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="new-course-canvas-courseID">Canvas Course ID</InputLabel>
                                <Input
                                    id="new-course-canvas-courseID"
                                    aria-describedby="new-course-canvas-courseID-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'canvasCourseID' }}
                                />
                                <FormHelperText id="new-course-canvas-courseID-helper-text">
                                    The Canvas course ID.
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="new-course-canvas-name">Canvas SIS ID</InputLabel>
                                <Input
                                    id="new-course-canvas-sis-id"
                                    aria-describedby="new-course-canvas-sis-id-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'canvasCourseSISID' }}
                                />
                                <FormHelperText id="new-course-canvas-sis-id-helper-text">
                                    The Canvas course SIS ID.
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    id="new-course-canvas-publishes-to"
                                    aria-describedby="new-course-canvas-sis-id-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChipsChange}
                                    freeSolo
                                    multiple
                                    options={[]}
                                    renderTags={(value: string[], getTagProps) =>
                                        value.map((option: string, index: number) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Other Canvas Course IDs" name="publishesTo" />
                                    )}
                                    disableCloseOnSelect={true}
                                />
                                <FormHelperText id="new-course-canvas-sis-id-helper-text">
                                    Publish to Canvas courses.
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    id="new-course-co-editors"
                                    aria-describedby="new-course-co-editors-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChipsChange}
                                    freeSolo
                                    multiple
                                    options={[]}
                                    renderTags={(value: string[], getTagProps) =>
                                        value.map((option: string, index: number) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Co-Editors" name="coEditors" />
                                    )}
                                    disableCloseOnSelect={true}
                                />
                                <FormHelperText id="new-course-co-editors-helper-text">Co-Editors.</FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    id="new-course-co-publishers"
                                    aria-describedby="new-course-co-publishers-helper-text"
                                    className={classes.input}
                                    onChange={handleFormChipsChange}
                                    freeSolo
                                    multiple
                                    options={[]}
                                    renderTags={(value: string[], getTagProps) =>
                                        value.map((option: string, index: number) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Co-Publishers" name="coPublishers" />
                                    )}
                                    disableCloseOnSelect={true}
                                />
                                <FormHelperText id="new-course-co-publishers-helper-text">
                                    Co-Publishers.
                                </FormHelperText>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl
                                className={classes.formControl}
                                fullWidth={true}
                                style={{ marginTop: '.5rem' }}
                            >
                                <TextField
                                    id="new-course-notes"
                                    label="Notes"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={handleFormChange}
                                    inputProps={{ name: 'notes' }}
                                />
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFormClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleFormSubmit} color="primary">
                            Save Course
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
        </div>
    );
};

export default NewCourseForm;
