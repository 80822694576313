import { SagaIterator } from 'redux-saga';
import { showSnackbar } from '../application/applicationActions';
import { postServiceRequestFailure, postServiceRequestSuccess } from './serviceRequestActions';
import { call, put } from 'redux-saga/effects';
import { createOrUpdateServiceRequests } from './serviceRequestAPIs';
import { IPostServiceRequestAction } from './serviceRequestInterfaces';
import { Snack } from '../../factories/Snackbar';

export function* putServiceRequestSaga({ payload }: IPostServiceRequestAction): SagaIterator {
    try {
        const serviceRequest = yield call(createOrUpdateServiceRequests, payload);
        yield put(postServiceRequestSuccess(serviceRequest));
        const successMessage = Snack(`Updated service request for ${payload.courseID}`);
        yield put(showSnackbar(successMessage));
    } catch (e) {
        yield put(showSnackbar(e.message));
        yield put(postServiceRequestFailure(e.message));
    }
}
