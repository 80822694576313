import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import { MouseEventHandler } from "react";
import Draggable from "react-draggable";

interface IMonitorContainerProps {
    handleContainerClose: MouseEventHandler<HTMLButtonElement>;
    title: string;
    paperClassName: string;
    children: JSX.Element;
}

/**
 * Returns a draggable Paper component
 * @param props
 * @returns
 */
const PaperComponent = (props: JSX.IntrinsicAttributes & PaperProps) => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper
                {...props}
                style={{
                    pointerEvents: 'all',
                    resize: 'both',
                }}
                className={props.className}
            />
        </Draggable>
    );
};


/**
 * Returns a draggable, resizable Dialog component
 * @param props 
 * @returns 
 */
export const MonitorContainer: React.FC<IMonitorContainerProps> = (props: IMonitorContainerProps) => {
    const { handleContainerClose, children, title, paperClassName } = props;
    return (
        <Dialog
            open={true}
            onClose={handleContainerClose}
            PaperComponent={PaperComponent}
            PaperProps={{ className: paperClassName }}
            maxWidth="md"
            fullWidth
            hideBackdrop
            disableEnforceFocus
            disableBackdropClick
            disableScrollLock
            disablePortal
            style={{ pointerEvents: 'none' }}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={handleContainerClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};