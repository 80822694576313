import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { GridOverlay } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    customGridOverlay: {
        top: '0!important',
        '& .linearProgress': {
            height: '6px',
            backgroundColor: '#eee',
            '& .MuiLinearProgress-barColorSecondary': {
                backgroundColor: theme.palette.info.light,
            },
        },
        '& .overlayContainer': {
            position: 'relative',
            top: '-1.5rem',
            width: '100%',
            zIndex: 99,
        },
    },
}));

const CustomLoadingOverlay: React.VFC = () => {
    const classes = useStyles();

    return (
        <GridOverlay className={classes.customGridOverlay}>
            <div className="overlayContainer">
                <LinearProgress color="secondary" className="linearProgress" />
            </div>
        </GridOverlay>
    );
};

export default CustomLoadingOverlay;
