import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { GridRowId, GridRowData } from '@material-ui/data-grid';
import ScheduleIcon from '@material-ui/icons/Schedule';

interface IServiceRequestConfirmButtonProps {
    handleConfirm: () => void;
    disabled: boolean;
    selectedRequests: Map<GridRowId, GridRowData>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        confirmButtonBox: {},
        confirmButton: {
            '&:hover': {
                background: theme.palette.success.light,
            },
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
        },
    }),
);

export const ServiceRequestConfirmButton = (props: IServiceRequestConfirmButtonProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Box m={1} className={classes.confirmButtonBox}>
            <Button
                className={classes.confirmButton}
                onClick={props.handleConfirm}
                disabled={props.disabled}
                color="default"
                variant="contained"
                startIcon={<ScheduleIcon color={props.disabled ? 'disabled' : 'inherit'} />}
            >
                Confirm {props.selectedRequests.size > 0 ? props.selectedRequests.size : null} Request
                {props.selectedRequests.size > 1 ? 's' : null}
            </Button>
        </Box>
    );
};
