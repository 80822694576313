import { Avatar } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { ICameraPreset } from "../../redux/camera/cameraInterfaces";
import HomeIcon from '@material-ui/icons/Home';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

export const CameraPresets = (classes: ClassNameMap<string>): ICameraPreset[] => {
    return [
        { preset: '1', description: 'Wide shot', icon: <HomeIcon /> },
        { preset: '2', description: 'Podium', icon: <RecordVoiceOverIcon /> },
        {
            preset: '3',
            description: 'Board left',
            icon: (
                <Avatar variant="rounded" className={classes.presetAvatars}>
                    BL
                </Avatar>
            ),
        },
        {
            preset: '4',
            description: 'Board middle',
            icon: (
                <Avatar variant="rounded" className={classes.presetAvatars}>
                    BM
                </Avatar>
            ),
        },
        {
            preset: '5',
            description: 'Board right',
            icon: (
                <Avatar variant="rounded" className={classes.presetAvatars}>
                    BR
                </Avatar>
            ),
        },
        {
            preset: '6',
            description: 'Screen left',
            icon: (
                <Avatar variant="rounded" className={classes.presetAvatars}>
                    SL
                </Avatar>
            ),
        },
        {
            preset: '7',
            description: 'Screen middle',
            icon: (
                <Avatar variant="rounded" className={classes.presetAvatars}>
                    SM
                </Avatar>
            ),
        },
        {
            preset: '8',
            description: 'Screen right',
            icon: (
                <Avatar variant="rounded" className={classes.presetAvatars}>
                    SR
                </Avatar>
            ),
        },
        {
            preset: '9',
            description: 'Custom',
            icon: (
                <Avatar variant="rounded" className={classes.presetAvatars}>
                    C
                </Avatar>
            ),
        },
    ];
};
