import { Theme } from '@material-ui/core';
import { StyleRules, createStyles } from '@material-ui/styles';

export const styles = (theme: Theme): StyleRules =>
    createStyles({
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            height: '556px',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        videoGrid: {
            height: '100%',
        },
        splitterCard: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#000000',
            justifyContent: 'center',
            position: 'relative',
        },
        gutter: {
            backgroundColor: '#fff',
        },
        dialog: {},
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        cameraHeading: {
            position: 'absolute',
            top: 0,
            color: '#fff',
            padding: '0.3rem 0.5rem',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        presetAvatars: {
            backgroundColor: 'transparent',
            color: 'inherit',
            width: '24px',
            height: '24px',
            fontSize: '1rem',
        },
        reboot_btn: {
            '&:hover': {
                background: theme.palette.error.main,
            },
            color: theme.palette.error.contrastText,
            background: theme.palette.error.dark,
        },
        roomAlert: { position: 'absolute', top: 0, left: '20%', right: '20%' },
        divider: {
            margin: '0 .5rem',
        },
    });
