import { SEND_EXTRON_COMMAND_REQUEST } from '../extron/extronActionTypes';
import {
    EXTRON_SSE_SUBSCRIBE,
    EXTRON_SSE_UNSUBSCRIBE,
    EXTRON_SSE_SET_DATA,
    EXTRON_SSE_SET_ONLINE,
    EXTRON_SSE_DISCONNECT_RECORDER,
} from './extronSseActionTypes';
import { IRecordersState } from './extronSseInterfaces';

import { ExtronSSEActionTypes } from './extronSseTypes';

const extronInitialState: IRecordersState = {
    recorders: {},
    sseOnline: false,
};

export const extronSSEReducer = (
    state: IRecordersState = extronInitialState,
    action: ExtronSSEActionTypes,
): IRecordersState => {
    switch (action.type) {
        case EXTRON_SSE_SUBSCRIBE:
            return { ...state };

        case EXTRON_SSE_SET_ONLINE:
            return {
                ...state,
                sseOnline: action.payload,
            };

        case EXTRON_SSE_UNSUBSCRIBE:
            return {
                ...state,
                sseOnline: false,
                recorders: {
                    ...Object.fromEntries(
                        Object.entries(state.recorders).map(([key, value]) => [
                            `${key}`,
                            {
                                ...value,
                                query_internal_storage: value.query_internal_storage,
                                view_record_status: undefined,
                                query_front_panel_audio_levels: undefined,
                                query_selected_input_status: undefined,
                            },
                        ]),
                    ),
                },
            };

        case EXTRON_SSE_SET_DATA:
            return {
                ...state,
                recorders: {
                    ...state.recorders,
                    [action.payload.data.host]: {
                        ...state.recorders[action.payload.data.host],
                        host: action.payload.data.host,
                        [action.payload.data.request]: action.payload.data.response,
                        lastUpdated: `${new Date().getTime()}`,
                    },
                },
            };

        case EXTRON_SSE_DISCONNECT_RECORDER:
            // eslint-disable-next-line no-case-declarations
            const newState = { ...state };
            delete newState.recorders[action.payload.data.host];
            return newState;

        case SEND_EXTRON_COMMAND_REQUEST:
            // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
            const { pip_preset, ppt_preset, teacher_preset, piplay_preset, ...newRecorderState } = state.recorders[
                action.payload.host
            ] || {};

            return {
                ...state,
                recorders: {
                    ...state.recorders,
                    [action.payload.host]: newRecorderState,
                },
            };

        default:
            return state;
    }
};
