import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { CAS_URL } from '../constants';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    createStyles,
    Grid,
    Grow,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { isAuthenticated } from '../services/AuthService';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        fixedHeight: {
            height: 240,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            textAlign: 'center',
        },
        pos: {
            marginBottom: 12,
        },
        card: {
            maxWidth: '400px',
            margin: '0 auto',
        },
        cardActions: {
            // display: 'flex',
            // flexDirection: 'column',
        },
        cardHeader: {
            background: theme.palette.primary.light,
        },
    }),
);

export const Login: React.VFC = () => {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated()) {
            history.push(`/Dashboard`);
        }
    });

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3} alignItems="stretch" direction="row">
                    <Grid item xs={12}>
                        <Grow in={true}>
                            <Card className={classes.card} elevation={24}>
                                <CardHeader className={classes.cardHeader} title="Lecture Capture Administration" />
                                <CardContent>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        gutterBottom
                                        variant="subtitle1"
                                    >
                                        Please login to continue.
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <Box mx="auto" my={2}>
                                        <Button variant="contained" color="primary" href={CAS_URL}>
                                            Login with UCD CAS
                                        </Button>
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grow>
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
};

//export default Login;
