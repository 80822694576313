import {
    FETCH_SERVICE_REQUESTS_FAILURE,
    POST_SERVICE_REQUEST,
    POST_SERVICE_REQUEST_FAILURE,
    POST_SERVICE_REQUEST_SUCCESS,
} from './serviceRequestActionTypes';
import {
    IFetchServiceRequestsFailureAction,
    IPostServiceRequestAction,
    IPostServiceRequestFailureAction,
    IPostServiceRequestSuccessAction,
} from './serviceRequestInterfaces';
import { ServiceRequest } from './serviceRequestTypes';


export const fetchServiceRequestsFailure = (error: string): IFetchServiceRequestsFailureAction => ({
    type: FETCH_SERVICE_REQUESTS_FAILURE,
    payload: error,
});

export const postServiceRequest = (serviceRequest: ServiceRequest): IPostServiceRequestAction => ({
    type: POST_SERVICE_REQUEST,
    payload: serviceRequest,
});

export const postServiceRequestSuccess = (serviceRequest: ServiceRequest): IPostServiceRequestSuccessAction => ({
    type: POST_SERVICE_REQUEST_SUCCESS,
    payload: serviceRequest,
});

export const postServiceRequestFailure = (error: string): IPostServiceRequestFailureAction => ({
    type: POST_SERVICE_REQUEST_FAILURE,
    payload: error,
});
