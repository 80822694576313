import axios from 'axios';
import { BASE_URL, DEFAULT_REQUEST_PARAMS } from '../../constants';
import { AuthorizedGetRequest, TokenResponse } from '../../services/AuthInterfaces';

import { IUser, IUserAccess } from './userInterfaces';

export async function FetchUserAccess(username: string): Promise<IUserAccess> {
    if (!username || username?.length <= 0) throw new Error(`Invalid username - ${username}`);

    const url = BASE_URL + `/users/${username}/roles`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const rolesObj: IUserAccess = { roles: [] };
    const response = await axios.get(url, { withCredentials: true, headers });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error fetching user roles. Please check your network settings.';
        throw new Error(errorMessage);
    }

    rolesObj.roles = response.data;
    return rolesObj;
}

export async function FetchUserByEmail(email: string): Promise<IUser> {
    if (!email || email?.length <= 0) throw new Error(`Invalid email - ${email}`);
    const url = `${BASE_URL}/users?email=${email}`;
    const response = await axios.get(url, { withCredentials: true, headers: DEFAULT_REQUEST_PARAMS });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error fetching user. Please check your network settings.';
        throw new Error(errorMessage);
    }
    return response.data[0];
}

export async function CreateUser(user: IUser): Promise<IUser> {
    const url = `${BASE_URL}/users`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const response = await axios.post(url, user, { withCredentials: true, headers });
     if (response.status !== 200 || response.request.responseURL !== url) {
         const errorMessage =
             response.statusText.length > 0
                 ? response.statusText
                 : 'Error creating user. Please check your network settings.';
         throw new Error(errorMessage);
     }

     return response.data;
}

export async function UpdateUser(user: IUser): Promise<IUser> {
    const url = `${BASE_URL}/users`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const response = await axios.post(url, user, { withCredentials: true, headers });
    if (response.status !== 200 || response.request.responseURL !== url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error updating user. Please check your network settings.';
        throw new Error(errorMessage);
    }

    return response.data;
}

export async function DeleteUser(userID: string): Promise<boolean> {
    const url = `${BASE_URL}/users/${userID}`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const response = await axios.delete(url, { withCredentials: true, headers });
    if (response.status !== 200 || response.request.responseURL !== url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Error deleting user. Please check your network settings.';
        throw new Error(errorMessage);
    }

    return JSON.parse(response.data);
}

/**
 * Calls the backend to set the JWT and authenticated cookies
 * @param tokenResponse
 * @returns boolean
 * @throws
 */
export async function SetTokenCookies(tokenResponse: TokenResponse): Promise<boolean> {
    const url = BASE_URL + '/callback';
    const headers: AuthorizedGetRequest = {
        ...DEFAULT_REQUEST_PARAMS,
        Authorization: 'Bearer ' + tokenResponse.token,
    };

    const response = await axios.get(url, {
        withCredentials: true,
        headers,
        params: {
            access_token: tokenResponse.token,
            expires_in: tokenResponse.expires,
        },
    });

    if (response.status != 200 || response.request.responseURL.split('?')[0] != url) {
        const errorMessage =
            response.statusText.length > 0
                ? response.statusText
                : 'Could not log the user in. Please check your network settings.';
        throw new Error(errorMessage);
    }

    return true;
}
