import { createStyles, LinearProgress, makeStyles } from '@material-ui/core';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridFilterModelParams,
    GridRowData,
    GridRowId,
    GridRowIdGetter,
    GridRowSelectedParams,
    GridSelectionModelChangeParams,
    GridSortModelParams,
    GridStateChangeParams,
    GridToolbar,
} from '@material-ui/data-grid';
import { memo, MouseEventHandler, ReactEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import { CAMERA_PROXY_MJPEG_PATH, KURENTO_WS_URI, SCOPES } from '../../constants';
import { AppState } from '../../redux/AppState';
import { ArgusDataGridColDef } from './ArgusDataGridColDef';
import KurentoPlayer from './KurentoPlayer';
import { logger } from '../../services/Logger';
import { googleCalendarSubscribe, googleCalendarUnsubscribe } from '../../redux/googleCalendar/gcalActions';
import { MonitorContainer } from './MonitorContainer';
import { useDebounce } from '../../hooks/useDebounce';
import { formatCameraProxyURL, getDayBoundaries, handleSWRError } from '../../utils/helpers';
import { getPermissionsFromRoles, hasPermission } from '../../services/AuthService';
import { useRooms } from '../../hooks/useRooms';
import { useCourseMeetings } from '../../hooks/useCourseMeetings';
import { setArgusSortModel, setArgusFilterModel } from '../../redux/application/applicationActions';
import { fetchCourseMeetingsByDateSuccess } from '../../redux/courseMeeting/courseMeetingActions';
import { extronSseSubscribe, extronSseUnsubscribe } from '../../redux/extronSSE/extronSseActions';

const KurentoPlayerMemo = memo(KurentoPlayer);
const MonitorContainerMemo = memo(MonitorContainer);

/**
 * Manually refreshes the DataGrid sort and filters in response to grid state changes
 * @param params
 */
const handleGridStateChange = (params: GridStateChangeParams) => {
    // If there is a filter model set, apply it
    // The community edition of Material-UI only allows one filtermodel to be set at a time
    //  so params.state.filter.items.length will only ever be 0 or 1
    if (params.state.filter.items.length) {
        if (params.state.filter.items[0].value && params.state.filter.items[0].columnField === 'recording') {
            params.api.applyFilters();
        }
    }

    // If there is a sort model set, apply it
    // The community edition of Material-UI only allows one sort model to be set at a time
    //  so params.state.sorting.sortModel.length will only ever be 0 or 1
    if (params.state.sorting.sortModel.length) {
        params.api.applySorting();
    }
};

/**
 * Returns a unique row ID for use by the DataGrid component
 * @param {GridRowData} row
 * @returns {GridRowId}
 */
const getArgusRowID: GridRowIdGetter = (row: GridRowData): GridRowId => {
    return row.id;
};

/**
 * Sets the src attribute of an image element to and empty string
 *
 * See: Google Chrome bug https://bugs.chromium.org/p/chromium/issues/detail?id=73395
 * Setting the image src to a new mjpeg stream DOES NOT release the prior mjpeg stream
 * setting the src of the element to an empty string seems to work to release the previous mjpeg stream
 * before setting a new mjpeg src
 * @param imgElement
 */
const releaseMJPEGStream = (imgElement: HTMLImageElement | null) => {
    imgElement?.setAttribute('src', '');
};

const useStyles = makeStyles(() =>
    createStyles({
        paperLoading: {
            maxHeight: '240px',
            minHeight: 'auto',
        },
        paperLoaded: {
            maxHeight: '650px',
            minHeight: '350px',
            minWidth: '385px',
        },
        cameraImageLoaded: {
            width: '100%',
            height: '100%',
            display: 'flex',
            margin: '0 auto',
            aspectRatio: '16/9',
            objectFit: 'contain',
            minHeight: '135px',
        },
        cameraImageLoading: {
            visibility: 'hidden',
            position: 'absolute',
            maxHeight: '240px',
        },
        cameraImageError: {
            width: '400px',
            height: 'auto',
            display: 'flex',
            margin: '0 auto',
        },
    }),
);

interface ICameraViewState {
    endpoint: string;
    roomName: string;
    loaded: boolean;
    loading: boolean;
    error: boolean;
    open: boolean;
}

const cameraInitialViewState: ICameraViewState = {
    endpoint: '',
    roomName: '',
    loaded: false,
    loading: false,
    error: false,
    open: false,
};

interface IExtronViewState {
    endpoint?: string;
    roomName?: string;
    open: boolean;
}

const extronInitialViewState: IExtronViewState = {
    endpoint: undefined,
    roomName: undefined,
    open: false,
};

const ARGUS: React.FC = () => {
    const [startDateTime, endDateTime] = getDayBoundaries();

    const { rooms, isLoadingRooms, isRoomsError } = useRooms(true);
    const { courseMeetings, isLoadingCourseMeetings, isCourseMeetingsError } = useCourseMeetings(
        null,
        startDateTime,
        endDateTime,
    );

    const { roles } = useSelector((state: AppState) => state.user, shallowEqual);
    const { sortModel, filterModel } = useSelector((state: AppState) => state.app.argusGridState);

    const dispatch = useDispatch();

    const classes = useStyles();

    const [cameraViewState, setCameraViewState] = useState<ICameraViewState>(cameraInitialViewState);

    const [extronViewState, setExtronViewState] = useState<IExtronViewState>(extronInitialViewState);

    const cameraImgRef = useRef<HTMLImageElement | null>(null);

    const columns = useMemo(() => ArgusDataGridColDef, []);

    const [ArgusColDef, setArgusColDef] = useState<GridColDef[]>(columns);

    const handleSortModelChange = (model: GridSortModelParams) => {
        dispatch(setArgusSortModel(model.sortModel));
    };

    const handleFilterModelChange = (model: GridFilterModelParams) => {
        dispatch(setArgusFilterModel(model.filterModel));
    };

    const handleCellClick = (params: GridCellParams) => {
        const { id, field, getValue, row } = params;
        //const cellValue = getValue(id, 'extron') + '';
        const cameraProxyURL = formatCameraProxyURL({
            cameraIP: getValue(id, 'camera_view') + '',
            path: CAMERA_PROXY_MJPEG_PATH,
        });
        switch (field) {
            case 'name':
                // if (formatRTSPEndpoint(cellValue) === extronViewState.endpoint) break;
                // setExtronViewState({
                //     endpoint: formatRTSPEndpoint(cellValue),
                //     roomName: row.name,
                //     open: true,
                // });
                break;

            case 'camera_view':
                if (cameraProxyURL === cameraImgRef.current?.src) break;
                releaseMJPEGStream(cameraImgRef.current);
                if (cameraProxyURL) {
                    setCameraViewState({
                        endpoint: cameraProxyURL,
                        roomName: row.name,
                        error: false,
                        loaded: false,
                        loading: true,
                        open: true,
                    });
                }

                break;
        }
    };

    const handleGridStateChangeCallback = useCallback(
        (params: GridStateChangeParams | undefined) => params && handleGridStateChange(params),
        [],
    );

    const debouncedHandleGridStateChange = useDebounce(handleGridStateChangeCallback, 1000);

    const handleCameraMonitorClose: MouseEventHandler<HTMLButtonElement> = () => {
        releaseMJPEGStream(cameraImgRef.current);
        setCameraViewState(cameraInitialViewState);
    };

    const handleExtronMonitorClose: MouseEventHandler<HTMLButtonElement> = () => {
        setExtronViewState(extronInitialViewState);
    };

    const handleRoomSelect = (params: GridRowSelectedParams) => {
        logger.debug('handleRoomSelect', params);
    };

    const handleRoomSelectionModelChange = (params: GridSelectionModelChangeParams) => {
        logger.debug('handleRoomSelectionModelChange', params);
    };

    const handleCameraImgError: ReactEventHandler<HTMLImageElement> = () => {
        logger.error('Could not load camera image!');
        setCameraViewState({
            ...cameraViewState,
            loading: false,
            loaded: false,
            endpoint: '/i/nocamera.png',
            error: true,
        });
    };

    useEffect(() => {
        handleSWRError(isRoomsError, 'rooms', dispatch);
    }, [isRoomsError]);

    useEffect(() => {
        handleSWRError(isCourseMeetingsError, 'course meetings', dispatch);
    }, [isCourseMeetingsError]);

    useEffect(() => {
        if (courseMeetings?.length > 0 && !isLoadingCourseMeetings) {
            logger.debug('courseMeetings:', courseMeetings);
            dispatch(fetchCourseMeetingsByDateSuccess(courseMeetings));
        }
    }, [courseMeetings, isLoadingCourseMeetings]);

    useEffect(() => {
        const permissionGranted = hasPermission({
            permissions: getPermissionsFromRoles(roles),
            scopes: [SCOPES.canAdmin],
        });

        if (!permissionGranted) {
            const ArgusDataGridColDefFiltered = ArgusColDef.map((colDef) => {
                if (['camera', 'extron'].includes(colDef.field)) {
                    colDef.renderCell = () => <></>;
                    colDef.hide = true;
                }
                return colDef;
            });
            setArgusColDef(ArgusDataGridColDefFiltered);
        }

        batch(() => {
            dispatch(extronSseSubscribe());
            dispatch(googleCalendarSubscribe());
        });

        // unsubscribe to event source while component unmount.
        return () => {
            batch(() => {
                dispatch(extronSseUnsubscribe());
                dispatch(googleCalendarUnsubscribe());
            });
        };
    }, []);

    useEffect(() => {
        logger.debug('Camera endpoint changed...');
        const interval = setInterval(() => {
            if (cameraImgRef.current?.complete) {
                logger.debug(`MJPEG stream loaded from ${cameraViewState.endpoint}`);
                clearInterval(interval);
                setCameraViewState({ ...cameraViewState, loaded: true, loading: false });
            }
        }, 2);
        return () => {
            clearInterval(interval);
        };
    }, [cameraViewState.endpoint]);

    return (
        <>
            {extronViewState.endpoint && (
                <MonitorContainerMemo
                    handleContainerClose={handleExtronMonitorClose}
                    title={`${extronViewState.roomName} Recorder Stream`}
                    paperClassName={classes.paperLoaded}
                >
                    <KurentoPlayerMemo rtspURI={extronViewState.endpoint} wsURI={KURENTO_WS_URI} />
                </MonitorContainerMemo>
            )}
            {cameraViewState.open && (
                <MonitorContainerMemo
                    handleContainerClose={handleCameraMonitorClose}
                    title={`${cameraViewState.roomName} Camera Stream`}
                    paperClassName={cameraViewState.loaded ? classes.paperLoaded : classes.paperLoading}
                >
                    <>
                        <img
                            ref={cameraImgRef}
                            className={
                                cameraViewState.loaded
                                    ? cameraViewState.error
                                        ? classes.cameraImageError
                                        : classes.cameraImageLoaded
                                    : classes.cameraImageLoading
                            }
                            key={cameraViewState.endpoint}
                            src={cameraViewState.endpoint}
                            onError={handleCameraImgError}
                        />

                        {cameraViewState.loading && <LinearProgress />}
                    </>
                </MonitorContainerMemo>
            )}

            <DataGrid
                columns={ArgusColDef}
                rows={rooms || []}
                getRowId={getArgusRowID}
                pageSize={20}
                autoHeight
                autoPageSize
                checkboxSelection
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
                sortingOrder={['desc', 'asc', null]}
                loading={isLoadingRooms}
                onCellClick={handleCellClick}
                onRowSelected={handleRoomSelect}
                onSelectionModelChange={handleRoomSelectionModelChange}
                onStateChange={debouncedHandleGridStateChange}
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </>
    );
};

export default ARGUS;
