import {
    Paper,
    Box,
    Typography,
    Avatar,
    FormControlLabel,
    Divider,
    Button,
    CircularProgress,
    StyleRules,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import VolumeLevel from '../Argus/VolumeLevel';
import { CameraPresetToggleButton } from './CameraPresetToggleButton';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import DvrIcon from '@material-ui/icons/Dvr';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';

import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import { ICameraPreset, ICameraProxyPresetRequestParams } from '../../redux/camera/cameraInterfaces';
import { ICamera, IRoom } from '../../redux/room/roomInterfaces';
import { IRecorderState } from '../../redux/extronSSE/extronSseInterfaces';
import { PermissionGate } from '../PermissionGate';
import { SCOPES } from '../../constants';

const styles = (theme: Theme): StyleRules =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        cameraHeading: {
            position: 'absolute',
            top: 0,
            color: '#fff',
            padding: '0.3rem 0.5rem',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        presetAvatars: {
            backgroundColor: 'transparent',
            color: 'inherit',
            width: '24px',
            height: '24px',
            fontSize: '1rem',
        },
        reboot_btn: {
            '&:hover': {
                background: theme.palette.error.main,
            },
            color: theme.palette.error.contrastText,
            background: theme.palette.error.dark,
        },
        divider: {
            margin: '0 .5rem',
        },
    });
const useStyles = makeStyles(styles);

interface ICaptureControlPanelProps {
    selectedRoom: IRoom;
    cameraPresets: ICameraPreset[];
    recorders: { [key: string]: IRecorderState };
    camera: ICamera | null;

    presetRequest: ICameraProxyPresetRequestParams | null;
    isIssuingPreset: boolean;
    recorderMode: string;
    recorderInput: string;
    autoFocusEnabled: boolean;
    isCameraRebooting: boolean;
    handleCameraPresetChange: (event: React.MouseEvent<HTMLElement>, newPreset: string | null) => void;
    handleRecorderModeChange: (event: React.MouseEvent<HTMLElement>, newMode: string | null) => void;
    handleRecorderInputChange: (event: React.MouseEvent<HTMLElement>, newInput: string | null) => void;
    handleCameraAutoFocus: () => void;
    handleCamerOTAFClick: () => void;
    handleCameraReboot: () => void;
}

export const CaptureControlPanel: React.FC<ICaptureControlPanelProps> = ({
    selectedRoom,
    cameraPresets,
    recorders,
    camera,
    presetRequest,
    isIssuingPreset,
    recorderMode,
    recorderInput,
    autoFocusEnabled,
    isCameraRebooting,
    handleCameraPresetChange,
    handleRecorderInputChange,
    handleCameraReboot,
    handleCameraAutoFocus,
    handleCamerOTAFClick,
    handleRecorderModeChange,
}) => {
    const classes = useStyles();
    return (
        <Paper
            style={{
                marginTop: '1rem',
                padding: '1rem',
            }}
        >
            <Grid container spacing={2}>
                {selectedRoom?.extron?.ip && recorders && recorders[selectedRoom.extron.ip] && (
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                            minWidth="100px"
                            maxWidth="600px"
                            margin="0 auto"
                        >
                            <VolumeLevel volume={recorders[selectedRoom.extron.ip].query_front_panel_audio_levels} />
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} sm={7} md={6} lg={6}>
                    {selectedRoom?.camera?.ip && (
                        <Box>
                            <Typography variant="subtitle2">Camera Presets</Typography>
                            <ToggleButtonGroup
                                onChange={handleCameraPresetChange}
                                exclusive
                                value={presetRequest?.preset || null}
                            >
                                {cameraPresets.map((preset: ICameraPreset) => (
                                    <CameraPresetToggleButton
                                        value={preset.preset}
                                        description={preset.description}
                                        icon={preset.icon}
                                        isIssuingPreset={isIssuingPreset}
                                        preset={presetRequest?.preset}
                                        key={preset.preset}
                                    />
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={5}
                    md={6}
                    lg={6}
                    style={{
                        display: 'flex',
                    }}
                >
                    <Box textAlign="left">
                        <Typography variant="subtitle2">Recorder Mode</Typography>
                        <ToggleButtonGroup value={recorderMode} onChange={handleRecorderModeChange} exclusive>
                            <ToggleButton value="teacher_preset" aria-label="Teacher mode" title="Teacher mode">
                                <EmojiPeopleIcon />
                            </ToggleButton>
                            <ToggleButton value="ppt_preset" aria-label="Content mode" title="Content mode">
                                <DvrIcon />
                            </ToggleButton>
                            <ToggleButton
                                value="piplay_preset"
                                aria-label="Picture-in-picture mode"
                                title="Picture-in-picture mode"
                            >
                                <PictureInPictureIcon />
                            </ToggleButton>
                            <ToggleButton value="pip_preset" aria-label="Switch PIP" title="Switch PIP">
                                <SwitchVideoIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box textAlign="center" marginLeft="auto" marginRight="1rem">
                        <Typography variant="subtitle2">Input</Typography>
                        <ToggleButtonGroup value={recorderInput} onChange={handleRecorderInputChange} exclusive>
                            <ToggleButton value="input1">
                                <Avatar variant="rounded" className={classes.presetAvatars}>
                                    1
                                </Avatar>
                            </ToggleButton>
                            {selectedRoom?.dual && (
                                <ToggleButton value="input2">
                                    <Avatar variant="rounded" className={classes.presetAvatars}>
                                        2
                                    </Avatar>
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {camera?.ip && (
                        <>
                            <Grid
                                container
                                alignItems="center"
                                spacing={1}
                                style={{
                                    width: 'fit-content',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={autoFocusEnabled}
                                            onChange={handleCameraAutoFocus}
                                            name="cameraAutoFocus"
                                            color="primary"
                                        />
                                    }
                                    label="Auto Focus"
                                />
                                <Divider orientation="vertical" flexItem className={classes.divider} />

                                <Button
                                    disabled={autoFocusEnabled}
                                    variant="outlined"
                                    size="small"
                                    startIcon={<CenterFocusStrongIcon />}
                                    onClick={handleCamerOTAFClick}
                                >
                                    O.T. AF
                                </Button>

                                <Divider orientation="vertical" flexItem className={classes.divider} />
                                <PermissionGate scopes={[SCOPES.canAdmin]}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        className={classes.reboot_btn}
                                        startIcon={
                                            isCameraRebooting ? (
                                                <CircularProgress
                                                    style={{
                                                        color: '#fff',
                                                    }}
                                                    size={18}
                                                />
                                            ) : (
                                                <SyncProblemIcon />
                                            )
                                        }
                                        onClick={handleCameraReboot}
                                    >
                                        Reboot Camera
                                    </Button>
                                </PermissionGate>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};
