import { Chip, Tooltip } from '@material-ui/core';
import React from 'react';
import { COURSE_MEETING_TYPES } from '../../constants';


interface MeetingTypeChipProps {
    meetingType: string;
}

export const MeetingTypeChip: React.FC<MeetingTypeChipProps> = (props) => {
    let avatarColor = '#ddd';
    const { meetingType } = props;

    switch (meetingType) {
        case 'A':
            avatarColor = '#6FCFEB';
            break;
        case 'B':
            avatarColor = '#AADA91';
            break;
        case 'C':
            avatarColor = '#AADA91';
            break;
        case 'D':
            avatarColor = '#FFDC00';
            break;
    }
    return (
        <Tooltip title={COURSE_MEETING_TYPES[meetingType]}>
            <Chip
                label={meetingType}
                size="small"
                style={{
                    backgroundColor: `${avatarColor}`,
                }}
            />
        </Tooltip>
    );
};