import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { getTokenFromQueryString, isAuthenticated } from '../services/AuthService';
import Loading from '../components/Loading';
import { AppState } from '../redux/AppState';
import { LOGOUT_USER, SET_TOKEN_COOKIES_REQUEST } from '../redux/user/userActionTypes';
import { logoutUser, setTokenCookiesRequest } from '../redux/user/userActions';
import { UserActionTypes } from '../redux/user/userTypes';
import { TokenResponse } from '../services/AuthInterfaces';
import { logger } from '../services/Logger';

interface ICallbackContainerProps {
    roles: string[];
    username: string;
    isLoading: boolean;
    error: string;
}

const CallbackContainer: React.FC<ICallbackContainerProps> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        let newToken: TokenResponse;

        if (!props.username || !isAuthenticated()) {
            try {
                newToken = getTokenFromQueryString();
            } catch (e) {
                logger.error(e.message);
                dispatch(logoutUser());
                return;
            }
            dispatch(setTokenCookiesRequest(newToken));
        }

        if (isAuthenticated() && props.roles.length > 0) {
            history.push('/Dashboard');
        }

        if (props.error) {
            logger.error('Encountered error logging in!');
            dispatch(logoutUser());
        }
    }, [props.isLoading, props.roles]);

    return <Loading />;
};

const MapStateToProps = (state: AppState) => ({
    roles: state.user.roles,
    username: state.user.username,
    isLoading: state.user.isLoading,
    error: state.user.error,
});

const MapDispatchToProps = (dispatch: Dispatch<UserActionTypes>) => ({
    setTokenCookiesRequest: (tokenResponse: TokenResponse) =>
        dispatch({ type: SET_TOKEN_COOKIES_REQUEST, payload: tokenResponse }),
    logoutUser: () => dispatch({ type: LOGOUT_USER, payload: undefined }),
});

export default connect(MapStateToProps, MapDispatchToProps)(CallbackContainer);
