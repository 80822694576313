import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { KALTURA_STATUSES, RECORDING_OPTIONS_ENUM } from '../../constants';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const SystemUpdateAltIconMemo = React.memo(SystemUpdateAltIcon);
const CloudUploadIconMemo = React.memo(CloudUploadIcon);
const CloudQueueIconMemo = React.memo(CloudQueueIcon);
const CloudOffIconMemo = React.memo(CloudOffIcon);
const CloudDoneIconMemo = React.memo(CloudDoneIcon);
const EventAvailableIconMemo = React.memo(EventAvailableIcon);
const EventBusyIconMemo = React.memo(EventBusyIcon);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scheduled: {
            color: theme.palette.success.main,
        },
        unscheduled: {
            color: theme.palette.grey[400],
        },
        recorded: {
            color: theme.palette.success.main,
        },
        conflict: {
            color: theme.palette.error.main,
        },
        notRecorded: {
            color: theme.palette.grey[400],
        },
        lateRecording: {
            color: theme.palette.error.main,
        },
        processedSuccess: {
            color: theme.palette.success.main,
        },
        processedError: {
            color: theme.palette.error.main,
        },
        processing: {
            color: theme.palette.info.main,
        },
        notProcessed: {
            color: theme.palette.grey[400],
        },
    }),
);

interface iCourseMeetingRecordingStatusProps {
    hasConflict: boolean;
    recordingOption: string;
    kalturaFTP: string | null;
    kalturaStatus: string | null;
    endDateTimeString: string;
}

const CourseMeetingRecordingStatus: React.FC<iCourseMeetingRecordingStatusProps> = (props) => {
    const classes = useStyles();

    const { recordingOption, kalturaFTP = null, kalturaStatus = null, endDateTimeString, hasConflict } = props;

    const scheduledClassName =
        recordingOption && recordingOption === RECORDING_OPTIONS_ENUM.cancelled.value
            ? classes.unscheduled
            : hasConflict
            ? classes.conflict
            : classes.scheduled;

    let recordedClassName = classes.notRecorded;

    const endDateTime = new Date(endDateTimeString);
    const kalturaFTPLateDateTime = new Date(endDateTime);
    const kalturaStatusLateDateTime = new Date(endDateTime);
    // If the recording hasn't been FTPed within 1 hour after the end time, Houston has a problem
    kalturaFTPLateDateTime.setHours(kalturaFTPLateDateTime.getHours() + 1);

    // If Kaltura hasn't started processing the recording within 2 hours, Houston has a problem
    kalturaStatusLateDateTime.setHours(kalturaStatusLateDateTime.getHours() + 1);

    if (kalturaFTP) {
        recordedClassName = classes.recorded;
    }

    let currentKalturaStatus = -1;
    let currentKalturaStatusHTML = 'Not uploaded';
    let kalturaStatusClassName = classes.notProcessed;
    let kalturaStatusIcon = <CloudQueueIconMemo className={kalturaStatusClassName} />;

    if (kalturaStatus) {
        const kalturaStatusObj = JSON.parse(kalturaStatus);
        currentKalturaStatus = parseInt(kalturaStatusObj.status);
        currentKalturaStatusHTML = KALTURA_STATUSES[currentKalturaStatus].html;
    }

    switch (currentKalturaStatus) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 8:
        case 14:
            kalturaStatusClassName = classes.processing;
            kalturaStatusIcon = <CloudUploadIconMemo className={kalturaStatusClassName} />;
            break;
        case 5:
            kalturaStatusClassName = classes.processedSuccess;
            kalturaStatusIcon = <CloudDoneIconMemo className={kalturaStatusClassName} />;
            break;
        case 6:
        case 7:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
            kalturaStatusClassName = classes.processedError;
            kalturaStatusIcon = <CloudOffIconMemo className={kalturaStatusClassName} />;
            break;
        default:
            break;
    }

    // Check for possible late recording
    if (recordingOption != RECORDING_OPTIONS_ENUM.cancelled.value && kalturaFTPLateDateTime < new Date()) {
        // Update Kaltura FTP status indicator
        if (!kalturaFTP) recordedClassName = classes.lateRecording;

        // Update Kaltura processing status indicator
        if (!kalturaStatus) {
            kalturaStatusClassName = classes.processedError;
            kalturaStatusIcon = <CloudOffIconMemo className={kalturaStatusClassName} />;
        }
    }

    return (
        <>
            <Box m={0.5} style={{ lineHeight: 0 }}>
                {hasConflict ? (
                    <EventBusyIconMemo titleAccess="Meeting conflict" className={scheduledClassName} />
                ) : (
                    <EventAvailableIconMemo color="disabled" titleAccess="Scheduled" className={scheduledClassName} />
                )}
            </Box>
            <Box m={0.5} style={{ lineHeight: 0 }}>
                <Tooltip title={kalturaFTP ? 'Kaltura FTP @ ' + kalturaFTP : 'Not recorded'}>
                    <SystemUpdateAltIconMemo color="disabled" className={recordedClassName} />
                </Tooltip>
            </Box>
            <Box m={0.5} style={{ lineHeight: 0 }}>
                <Tooltip title={`Kaltura status: ${currentKalturaStatusHTML}`}>{kalturaStatusIcon}</Tooltip>
            </Box>
        </>
    );
};

export default CourseMeetingRecordingStatus;
