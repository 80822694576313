import axios from 'axios';
import { BASE_URL, DEFAULT_REQUEST_PARAMS } from '../../constants';
import { ServiceRequest } from './serviceRequestTypes';
import { requestErrorMessage } from '../../utils/helpers';


export const createOrUpdateServiceRequests = async (serviceRequest: ServiceRequest): Promise<ServiceRequest> => {
    const url = `${BASE_URL}/servicerequests`;
    const headers = DEFAULT_REQUEST_PARAMS;
    const response = await axios.post(url, serviceRequest, { withCredentials: true, headers });
    if (response.status === 200 && response.request.responseURL === url) {
        return response.data;
    }

    throw new Error(requestErrorMessage(`Error updating service request ${serviceRequest.courseID}`, response.statusText));
}
