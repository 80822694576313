import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { ReactElement, useEffect, useState } from 'react';
import { FetchUserByEmail } from '../../redux/user/userAPIs';
import { isValidEmail } from '../../utils/helpers';

export interface IPersonChipProps {
    label: string;
    tooltipTitle?: string;
    icon: ReactElement;
}

export const PersonChip: React.FC<IPersonChipProps> = ({ label, tooltipTitle, icon = <></> }) => {
    const [chipLabel, setChipLabel] = useState(label);
    const [tooltip, setTooltip] = useState(tooltipTitle ?? chipLabel);

    const fetchUserByEmail = async (email: string) => {
        const user = await FetchUserByEmail(email).catch(console.warn);

        if (!user) {
            setChipLabel(email.split('@')[0]);
        }

        if (user?.firstName && user?.lastName) {
            setChipLabel(user.firstName);
            setTooltip(`${user.lastName}, ${user.firstName}`);
        }
    };

    useEffect(() => {
        // Attempt to get user details like name based on the label prop
        if(isValidEmail(label)) fetchUserByEmail(label);
    }, []);

    return (
        <Tooltip title={tooltip}>
            <Chip
                key={chipLabel}
                size="small"
                variant="outlined"
                color="primary"
                style={{
                    color: '#022851',
                    marginBottom: '1px',
                    justifyContent: 'left',
                    width: 'max-content',
                }}
                label={chipLabel}
                icon={icon}
            />
        </Tooltip>
    );
};
