import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridColDef, GridRowData, GridRowId, GridRowIdGetter, GridToolbar } from '@material-ui/data-grid';

import { RECORDING_OPTIONS_ENUM } from '../../constants';
import { ICourseMeeting } from '../../redux/courseMeeting/courseMeetingInterfaces';

const useStyles = makeStyles(() =>
    createStyles({
        dataGrid: {
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },
        },
    }),
);

/**
 * Returns a unique row ID for use by the DataGrid component
 * @param {GridRowData} row
 * @returns {GridRowId}
 */
const getRowID: GridRowIdGetter = (row: GridRowData): GridRowId => {
    return row.eventID;
};

interface ICourseMeetingsDataGridProps {
    isLoadingMeetings: boolean;
    courseMeetings: ICourseMeeting[];
    autoHeight: boolean;
    columns: GridColDef[];
    showCancelledMeetings: boolean;
}

const CourseMeetingsDataGrid: React.FC<ICourseMeetingsDataGridProps> = (props) => {
    const classes = useStyles();
    const { courseMeetings, isLoadingMeetings, columns, autoHeight, showCancelledMeetings } = props;
    let filteredCourseMeetings: ICourseMeeting[] = [];

    if (!showCancelledMeetings)
        filteredCourseMeetings = courseMeetings.filter(
            (m) => m.recordingOption !== RECORDING_OPTIONS_ENUM.cancelled.value,
        );

    return (
        <DataGrid
            getRowId={getRowID}
            rows={filteredCourseMeetings}
            columns={columns}
            pageSize={20}
            autoHeight={autoHeight}
            autoPageSize
            checkboxSelection
            loading={isLoadingMeetings}
            disableSelectionOnClick={true}
            className={classes.dataGrid}
            components={{
                Toolbar: GridToolbar,
            }}
        />
    );
};

export default CourseMeetingsDataGrid;
