import Link from '@material-ui/core/Link';
import { GridCellParams } from '@material-ui/data-grid';
import { JSXElementConstructor, ReactElement } from 'react';
import { RECORDING_OPTIONS_ENUM, ServiceStatus } from '../../constants';
import { Chip, Tooltip, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { camelCase } from '../../utils/helpers';
import { StatusChipProps } from '../../redux/serviceRequest/serviceRequestTypes';


/**
 * Renders the course ID with a link to the course detail page
 * @param params 
 * @returns 
 */
export const renderCourseCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const href =
        '/Courses/' +
        (params.row.status.includes(ServiceStatus.scheduled.value) ? 'confirmed/' : 'banner/') +
        params.value?.toString().replaceAll('#', '-');
    return (
        <Link variant="button" href={href}>
            {params.value?.toString().replaceAll('#', ' ')}
        </Link>
    );
};

/**
 * Renders the Service Request status as a chip
 * @param params 
 * @returns 
 */
export const renderStatusCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const statusToChipProps: Record<string, StatusChipProps> = {
        [ServiceStatus.cancelPending.value]: {
            label: ServiceStatus.cancelled.value,
            color: 'secondary',
            textColor: '#000000',
        },
        [ServiceStatus.pending.value]: {
            label: ServiceStatus.pending.value,
            color: 'secondary',
            textColor: '#000000',
        },
        [ServiceStatus.cancelled.value]: {
            label: ServiceStatus.cancelled.value,
            color: 'default',
            textColor: '#999999',
        },
        [ServiceStatus.scheduled.value]: {
            label: ServiceStatus.scheduled.value,
            color: 'primary',
            textColor: '#ffffff',
        },
    };

    if (params.value) {
        const defaultChip: StatusChipProps = {
            label: params.value.toString(),
            color: 'default',
            textColor: '#ffffff',
        };
        const { label, color, textColor } = statusToChipProps[params.value.toString()] || defaultChip;
        return <Chip label={label} size="small" color={color} style={{ color: textColor, width: '100%' }} />;
    }

    return <></>;
};

/**
 * Renders the Service Request selected options as icons with a tooltip
 * @param params 
 * @returns 
 */
export const renderServiceOptionsCell = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    const autoPublishOpt =
        params.row.autoPublish === true ? (
            <Tooltip title="Auto-publish recordings">
                <CloudUploadIcon style={{ color: '#2196f3' }} />
            </Tooltip>
        ) : null;
    const recordDiscussionsOpt =
        params.row.recordDiscussions === true ? (
            <Tooltip title="Discussion recording requested">
                <RecordVoiceOverIcon style={{ color: '#2196f3' }} />
            </Tooltip>
        ) : null;
    return (
        <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row', gap: '1em', width: '100%' }}>
            {autoPublishOpt} {recordDiscussionsOpt}
        </div>
    );
};

/**
 * Renders the Service Request recording type
 * @param params 
 * @returns 
 */
export const renderServiceRecordType = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    return params.value ? (
        <Typography variant="subtitle2">{RECORDING_OPTIONS_ENUM[camelCase(params.value.toString())].html}</Typography>
    ) : (
        <></>
    );
};

/**
 * Renders Service Request comments with a tooltip containing the comment
 * @param params 
 * @returns 
 */
export const renderServiceRequestComment = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    return params.value ? (
        <Tooltip title={<Typography variant="subtitle2">{params.value}</Typography>} placement="bottom-start">
            <Typography variant="subtitle2" style={{ cursor: 'default' }}>
                {params.value}
            </Typography>
        </Tooltip>
    ) : (
        <></>
    );
};
