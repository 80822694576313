//Redux Application action types

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const SHOW_COURSE_MEETING_FORM = 'SHOW_COURSE_MEETING_FORM';
export const CLOSE_COURSE_MEETING_FORM = 'CLOSE_COURSE_MEETING_FORM';

export const SHOW_NEW_COURSE_FORM = 'SHOW_NEW_COURSE_FORM';
export const CLOSE_NEW_COURSE_FORM = 'CLOSE_NEW_COURSE_FORM';

export const SHOW_POPOVER = 'SHOW_POPOVER';
export const CLOSE_POPOVER = 'CLOSE_POPOVER';

export const TOGGLE_APP_DRAWER = 'EXPAND_APP_DRAWER';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const SET_DATAGRID_PAGE = 'SET_DATAGRID_PAGE';
export const SET_DATAGRID_PER_PAGE = 'SET_DATAGRID_PER_PAGE';
export const SET_DATAGRID_FILTER_MODEL = 'SET_DATAGRID_FILTER_MODEL';

export const SET_SERVICE_REQUESTS_DATAGRID_PAGE = 'SET_SERVICE_REQUESTS_DATAGRID_PAGE';
export const SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE = 'SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE';
export const SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL = 'SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL';
export const SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL = 'SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL';

export const SET_ARGUS_SORT_MODEL = 'SET_ARGUS_SORT_MODEL';
export const SET_ARGUS_FILTER_MODEL = 'SET_ARGUS_FILTER_MODEL';
