import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { selectTerm } from '../redux/term/termActions';
import { CourseActionTypes } from '../redux/course/courseTypes';
import {
    closeNewCourseForm,
    setActiveTab,
    setServiceRequestsDataGridFilterModel,
    setServiceRequestsDataGridPage,
    setServiceRequestsDataGridPerPage,
    setServiceRequestsDataGridSortModel,
    showNewCourseForm,
    showSnackbar,
} from '../redux/application/applicationActions';

import { AppState } from '../redux/AppState';
import {
    Container,
    createStyles,
    Fab,
    FormControl,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Theme,
} from '@material-ui/core';

import {
    COURSE_CONFIRM_REQUEST,
    CREATE_COURSE_REQUEST,
    DELETE_COURSE_REQUEST,
} from '../redux/course/courseActionTypes';
import { ApplicationActionTypes } from '../redux/application/applicationTypes';

import CourseTabView from '../components/Course/CourseTabView';
import {
    GridFilterModel,
    GridPageChangeParams,
    GridRowData,
    GridRowId,
    GridRowSelectedParams,
    GridSelectionModelChangeParams,
    GridSortModel,
} from '@material-ui/data-grid';
import { getBannerSyncCandidates, getCurrentTermcode, handleSWRError } from '../utils/helpers';
import { Course } from '../factories/Course';

import AddIcon from '@material-ui/icons/Add';
import NewCourseForm from '../components/Course/forms/newCourseForm';
import { RoomActionTypes } from '../redux/room/roomTypes';
import { SET_DATAGRID_FILTER_MODEL, SET_DATAGRID_PAGE } from '../redux/application/applicationActionTypes';
import { PermissionGate } from '../components/PermissionGate';
import { BANNER_COURSE_ENTITYTYPE, CONFIRMED_COURSE_ENTITYTPE, SCOPES, ServiceStatus } from '../constants';
import { ServiceRequest, ServiceRequestActionTypes } from '../redux/serviceRequest/serviceRequestTypes';
import { useTerms } from '../hooks/useTerms';
import { useServiceRequests } from '../hooks/useServiceRequests';
import { Snack } from '../factories/Snackbar';
import { useCourses } from '../hooks/useCourses';
import { useDebounce } from '../hooks/useDebounce';
import { logger } from '../services/Logger';
import { useRooms } from '../hooks/useRooms';
import { ServiceRequestsDataGridState, DataGridState } from '../redux/application/applicationInterfaces';
import { createCourseRequest } from '../redux/course/courseActions';
import { ICourse, ICourseNote } from '../redux/course/courseInterfaces';
import { postServiceRequest } from '../redux/serviceRequest/serviceRequestActions';
import { IUserState } from '../redux/user/userInterfaces';

interface ICoursesContainerProps {
    user: IUserState;
    selectedTerm: string;
    isDeletingCourses: boolean;
    serviceRequestsDataGridState: ServiceRequestsDataGridState;
    newCourseFormOpen: boolean;
    coursesLoadingError?: string;
    activeCourseTab: number;
    dataGridState: DataGridState;
    setServiceRequestsDataGridPage: (params: GridPageChangeParams) => void;
    setServiceRequestsDataGridPerPage: (params: GridPageChangeParams) => void;
    setServiceRequestsDataGridFilterModel: (model: GridFilterModel) => void;
    setServiceRequestsDataGridSortModel: (model: GridSortModel) => void;
    postServiceRequest: (serviceRequest: ServiceRequest) => void;
    setActiveCourseTab: (tabIndex: number) => void;
    showNewCourseForm: () => void;
    closeNewCourseForm: () => void;
    deleteCourseRequest: (course: ICourse) => void;
    courseConfirmRequest: (course: ICourse) => void;
    setDataGridPageProps: (params: GridPageChangeParams) => void;
    setDataGridFilterModel: (model: GridFilterModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            marginTop: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
        },
        newItemFAB: {
            margin: '0 0 -1.2rem 0',
            zIndex: 99,
        },
        hideRightSeparator: {
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
        },
    }),
);

const CoursesContainer: React.FC<ICoursesContainerProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { terms, isLoadingTerms, isTermsError } = useTerms(!!props.user.username);
    const { rooms, isLoadingRooms, isRoomsError } = useRooms(!!props.user.username);

    const {
        serviceRequests,
        isLoadingServiceRequests,
        isServiceRequestsError,
        mutateServiceRequests,
    } = useServiceRequests(props.selectedTerm);

    const {
        courses,
        bannerCourses: banner,
        confirmedCourses,
        isLoadingCourses,
        isCoursesError,
        mutateCourses,
    } = useCourses(props.selectedTerm);

    const bannerSyncCandidates = useMemo(() => {
        return getBannerSyncCandidates(courses);
    }, [courses]);

    const debouncedMutateCourses = useDebounce(
        useCallback(() => mutateCourses(), []),
        1000,
    );

    const debouncedMutateServiceRequests = useDebounce(
        useCallback(() => mutateServiceRequests(), []),
        1000,
    );

    // Local state to store course selections for Confirmed tab views
    const [pendingDeleteCourses, setPendingDeleteCourses] = useState(new Set(''));

    // If the page is refreshed the courses will be re-fetched
    // This useEffect will re-populate the Banner courses state
    // and will force a refresh of service requests
    React.useEffect(() => {
        debouncedMutateServiceRequests();
        debouncedMutateCourses();
    }, []);

    useEffect(() => {
        handleSWRError(isTermsError, 'terms', dispatch);
        handleSWRError(isCoursesError, 'courses', dispatch);
        handleSWRError(isServiceRequestsError, 'service requests', dispatch);
        handleSWRError(isRoomsError, 'rooms', dispatch);
    }, [isCoursesError, isTermsError, isRoomsError, isServiceRequestsError]);

    useEffect(() => {
        if (Array.isArray(terms) && terms.length > 0 && !props.selectedTerm) {
            dispatch(selectTerm(getCurrentTermcode(terms)));
        }
    }, [isLoadingTerms, terms, props.selectedTerm]);

    // Store selected term
    const handleTermChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        dispatch(selectTerm(e.target.value as string));
    };

    // Dispatch course confirmation Saga
    const handleCourseConfirm = (selectedCourses: Map<GridRowId, GridRowData>) => {
        selectedCourses.forEach((sc) => {
            props.courseConfirmRequest(Course(sc as ICourse));
            debouncedMutateCourses();
            debouncedMutateServiceRequests();
        });
    };

    const handleServiceRequestConfirm = (selectedServiceRequests: Map<GridRowId, ServiceRequest>) => {
        selectedServiceRequests.forEach((request) => {
            const course = courses.filter(
                (course) =>
                    course.courseID === request.courseID.replaceAll('#', '-') &&
                    course.entityType.includes(BANNER_COURSE_ENTITYTYPE),
            );

            if (course.length === 0) {
                dispatch(
                    showSnackbar(
                        Snack(
                            `Confirmation Failed! Could not find a Banner course with ID ${request.courseID}.`,
                            'error',
                        ),
                    ),
                );
                return;
            }

            if (course.length > 1) {
                dispatch(
                    showSnackbar(
                        Snack(
                            `Confirmation Failed! Found multiple Banner courses with ID ${request.courseID}.`,
                            'error',
                        ),
                    ),
                );
                return;
            }

            // If there is a banner and a confirmed course skip confirmation as it is already scheduled
            if (course.length === 1) {
                const courseToConfirm = course[0];
                courseToConfirm.recordingOption = request.recordingOption;
                courseToConfirm.publishesTo = request.autoPublish ? [courseToConfirm.canvasCourseID] : [];

                if (request.comment) {
                    const newNote: ICourseNote = {
                        user: props.user.username,
                        created: new Date().toISOString(),
                        note: request.comment,
                    };
                    courseToConfirm.notes = JSON.stringify([newNote]);
                }
                props.courseConfirmRequest({ ...courseToConfirm });
                debouncedMutateServiceRequests();
                debouncedMutateCourses();
            }
        });
    };

    const handleServiceRequestCancel = (selectedServiceRequests: Map<GridRowId, ServiceRequest>) => {
        selectedServiceRequests.forEach((request) => {
            if (request.status === ServiceStatus.scheduled.value) {
                const course = courses.find(
                    (course) =>
                        course.entityType === CONFIRMED_COURSE_ENTITYTPE &&
                        course.courseID === request.courseID.replaceAll('#', '-'),
                );

                if (!course) {
                    dispatch(
                        showSnackbar(
                            Snack(
                                `Cancellation Failed! Could not find a confirmed course with ID ${request.courseID}.`,
                                'error',
                            ),
                        ),
                    );
                    return;
                }

                props.deleteCourseRequest(course);
                debouncedMutateServiceRequests();
                debouncedMutateCourses();
                return;
            }

            request.status = ServiceStatus.cancelled.value;
            props.postServiceRequest(request);
            debouncedMutateServiceRequests();
            debouncedMutateCourses();
        });
    };

    // Dispatch course delete Saga
    const handleCoursesDelete = () => {
        logger.debug('DELETING COURSES');
        const coursesToDelete = courses.filter((c) => [...pendingDeleteCourses].includes(c.getCourseKey()));
        const coursesToRemain = courses.filter((c) => ![...pendingDeleteCourses].includes(c.getCourseKey()));
        coursesToDelete.map((c) => props.deleteCourseRequest(c));
        setPendingDeleteCourses(new Set(''));
        mutateCourses([...coursesToRemain], false);
        debouncedMutateServiceRequests();
    };

    const handleConfirmedCourseSelectionModelChange = (params: GridSelectionModelChangeParams) => {
        pendingDeleteCourses.clear();
        const newSelectedCourseSet = new Set(pendingDeleteCourses);
        params.selectionModel.map((r) => newSelectedCourseSet.add(r.toString()));
        setPendingDeleteCourses(newSelectedCourseSet);
    };

    const handleCourseConfirmedSelection = (params: GridRowSelectedParams) => {
        const newCourseSet = new Set(pendingDeleteCourses);
        const courseKey = Course(params.data as ICourse).getCourseKey();
        if (params.isSelected) {
            newCourseSet.add(courseKey);
        } else {
            newCourseSet.delete(courseKey);
        }
        setPendingDeleteCourses(newCourseSet);
    };

    const handleNewCourseFormOpen = () => {
        props.showNewCourseForm();
    };

    const handleNewCourseFormClose = () => {
        props.closeNewCourseForm();
    };

    const handleNewCourseFormSave = (newCourseProps: ICourse) => {
        if (newCourseProps.notes) {
            const newNote: ICourseNote = {
                user: props.user.username,
                created: new Date().toISOString(),
                note: newCourseProps.notes,
            };
            newCourseProps.notes = JSON.stringify([newNote]);
        }

        dispatch(createCourseRequest(newCourseProps));
        mutateCourses([...courses, newCourseProps], false);
        debouncedMutateServiceRequests();
        handleNewCourseFormClose();
    };

    return (
        <main className={classes.content}>
            <Suspense fallback={<></>}>
                <NewCourseForm
                    isOpen={props.newCourseFormOpen}
                    handleOpen={handleNewCourseFormOpen}
                    handleClose={handleNewCourseFormClose}
                    handleSave={handleNewCourseFormSave}
                    user={props.user}
                    rooms={rooms || []}
                    terms={terms || []}
                    selectedTerm={props.selectedTerm}
                />
            </Suspense>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <h1>Courses</h1>
                <h2>
                    Viewing term:
                    <FormControl className={classes.formControl}>
                        <Select
                            value={terms ? props.selectedTerm : ''}
                            onChange={handleTermChange}
                            label="Term"
                            inputProps={{
                                name: 'term',
                                id: 'term-select',
                            }}
                        >
                            <MenuItem value="" disabled>
                                Select Term
                            </MenuItem>
                            {terms
                                ? terms.map((term) => {
                                      return (
                                          <MenuItem aria-label="None" key={term.termCode} value={term.termCode}>
                                              {term.termCode}
                                          </MenuItem>
                                      );
                                  })
                                : null}
                        </Select>
                    </FormControl>
                </h2>
                <div style={{ textAlign: 'right', paddingRight: '1rem', marginTop: '-2.4em' }}>
                    <PermissionGate scopes={[SCOPES.canCreate]} errorProps={{ disabled: true, onClick: () => null }}>
                        {!isLoadingRooms ? (
                            <Fab
                                color="secondary"
                                aria-label="add"
                                className={classes.newItemFAB}
                                onClick={handleNewCourseFormOpen}
                            >
                                <AddIcon />
                            </Fab>
                        ) : (
                            <></>
                        )}
                    </PermissionGate>
                </div>
                <Paper>
                    <div style={{ width: '100%' }}>
                        <CourseTabView
                            courses={courses}
                            bannerCourses={banner}
                            confirmedCourses={confirmedCourses}
                            bannerSyncCandidates={bannerSyncCandidates}
                            isLoadingCourses={isLoadingCourses}
                            serviceRequests={serviceRequests}
                            serviceRequestsDataGridState={props.serviceRequestsDataGridState}
                            isLoadingServiceRequests={isLoadingServiceRequests}
                            handleServiceRequestConfirm={handleServiceRequestConfirm}
                            handleServiceRequestCancel={handleServiceRequestCancel}
                            handleServiceRequestDataGridFilterChange={props.setServiceRequestsDataGridFilterModel}
                            handleServiceRequestDataGridPageChange={props.setServiceRequestsDataGridPage}
                            handleServiceRequestDataGridPerPageChange={props.setServiceRequestsDataGridPerPage}
                            handleServiceRequestDataGridSortChange={props.setServiceRequestsDataGridSortModel}
                            handleCourseConfirmedSelect={handleCourseConfirmedSelection}
                            handleCourseConfirm={handleCourseConfirm}
                            handleCoursesDelete={handleCoursesDelete}
                            handleConfirmedCourseSelectionModelChange={handleConfirmedCourseSelectionModelChange}
                            confirmedCourseSelectionModel={Array.from(pendingDeleteCourses)}
                            activeCourseTab={props.activeCourseTab}
                            dataGridState={props.dataGridState}
                            handleDataGridPageChange={props.setDataGridPageProps}
                            handleDataGridFilterChange={props.setDataGridFilterModel}
                            handleTabChange={props.setActiveCourseTab}
                            handleBannerCourseCellValueChange={() => null}
                        />
                    </div>
                </Paper>
            </Container>
        </main>
    );
};

const MapStateToProps = (state: AppState) => ({
    user: state.user,
    selectedTerm: state.terms.selectedTerm,
    serviceRequestsDataGridState: state.app.serviceRequestsGridState,
    serviceRequestsError: state.serviceRequests.error,
    isDeletingCourses: state.courses.isDeletingCourses,
    newCourseFormOpen: state.app.newCourseFormOpen,
    activeCourseTab: state.app.activeCourseTab,
    dataGridState: state.app.courseDataGridState,
});

const MapDispatchToProps = (
    dispatch: Dispatch<CourseActionTypes | ApplicationActionTypes | RoomActionTypes | ServiceRequestActionTypes>,
) => ({
    courseConfirmRequest: (course: ICourse) => dispatch({ type: COURSE_CONFIRM_REQUEST, payload: course }),
    postServiceRequest: (serviceRequest: ServiceRequest) => dispatch(postServiceRequest(serviceRequest)),
    deleteCourseRequest: (course: ICourse) => dispatch({ type: DELETE_COURSE_REQUEST, payload: course }),
    createCourse: (course: ICourse) => dispatch({ type: CREATE_COURSE_REQUEST, payload: course }),
    showNewCourseForm: () => dispatch(showNewCourseForm()),
    closeNewCourseForm: () => dispatch(closeNewCourseForm()),
    setActiveCourseTab: (tabIndex: number) => dispatch(setActiveTab(tabIndex)),
    setDataGridPageProps: (params: GridPageChangeParams) => dispatch({ type: SET_DATAGRID_PAGE, payload: params }),
    setDataGridFilterModel: (model: GridFilterModel) => dispatch({ type: SET_DATAGRID_FILTER_MODEL, payload: model }),
    setServiceRequestsDataGridFilterModel: (model: GridFilterModel) =>
        dispatch(setServiceRequestsDataGridFilterModel(model)),
    setServiceRequestsDataGridSortModel: (model: GridSortModel) => dispatch(setServiceRequestsDataGridSortModel(model)),
    setServiceRequestsDataGridPage: (params: GridPageChangeParams) => dispatch(setServiceRequestsDataGridPage(params)),
    setServiceRequestsDataGridPerPage: (params: GridPageChangeParams) =>
        dispatch(setServiceRequestsDataGridPerPage(params)),
});

export default connect(MapStateToProps, MapDispatchToProps)(CoursesContainer);
