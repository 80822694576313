import {
    SHOW_SNACKBAR,
    CLOSE_SNACKBAR,
    SHOW_COURSE_MEETING_FORM,
    CLOSE_COURSE_MEETING_FORM,
    SHOW_POPOVER,
    CLOSE_POPOVER,
    SHOW_NEW_COURSE_FORM,
    CLOSE_NEW_COURSE_FORM,
    TOGGLE_APP_DRAWER,
    SET_ACTIVE_TAB,
    SET_DATAGRID_PAGE,
    SET_DATAGRID_FILTER_MODEL,
    SET_ARGUS_SORT_MODEL,
    SET_SERVICE_REQUESTS_DATAGRID_PAGE,
    SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE,
    SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL,
    SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL,
    SET_ARGUS_FILTER_MODEL,
} from './applicationActionTypes';
import { ApplicationActionTypes } from './applicationTypes';
import { IApplicationState } from './applicationInterfaces';
import { RECORDING_OPTIONS_ENUM, ServiceStatus } from '../../constants';
import { GridLinkOperator } from '@material-ui/data-grid';
import { ICourseMeetingData } from '../courseMeeting/courseMeetingInterfaces';

const newCourseMeetingInitialValues: ICourseMeetingData = {
    startDateTime: new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }) + ' GMT-0000')
        .toISOString()
        .slice(0, -8),
    endDateTime: new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }) + ' GMT-0000')
        .toISOString()
        .slice(0, -8),
    building: '',
    roomNumber: '',
    recordingOption: RECORDING_OPTIONS_ENUM.fullService.value,
    notes: '',
    meetingType: 'A',
    eventID: undefined,
    eventIndex: undefined,
    conflicts: [],
};

const applicationInitialState: IApplicationState = {
    snackbars: [],
    popover: {
        top: 0,
        left: 0,
        title: '',
        handleClose: () => null,
        open: false,
    },
    courseMeetingForm: {
        isOpen: false,
        formType: 'new',
        formHeading: 'New Course Meeting',
        initialValues: newCourseMeetingInitialValues,
    },
    newCourseFormOpen: false,
    appDrawerOpen: false,
    activeCourseTab: 0,
    courseDataGridState: {
        perPage: 20,
        page: 0,
        filterModel: undefined,
    },
    argusGridState: {
        sortModel: [
            {
                field: 'record_type',
                sort: 'desc',
            },
        ],
        filterModel: undefined,
    },
    serviceRequestsGridState: {
        sortModel: [{ field: 'status', sort: 'asc' }],
        perPage: 20,
        page: 0,
        filterModel: {
            items: [
                { columnField: 'status', operatorValue: 'contains', value: ServiceStatus.pending.value },
                { columnField: 'status', operatorValue: 'contains', value: ServiceStatus.cancelPending.value },
            ],
            linkOperator: GridLinkOperator.Or,
        },
    },
};

const applicationReducer = (
    state: IApplicationState = applicationInitialState,
    action: ApplicationActionTypes,
): IApplicationState => {
    switch (action.type) {
        case SHOW_SNACKBAR:
            return {
                ...state,
                snackbars: [...state.snackbars, action.payload],
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                snackbars: [...state.snackbars.filter((sb) => sb.messageID != action.payload.messageID)],
            };

        case SHOW_POPOVER:
            return {
                ...state,
                popover: action.payload,
            };

        case CLOSE_POPOVER:
            return {
                ...state,
                popover: applicationInitialState.popover,
            };

        case SHOW_COURSE_MEETING_FORM:
            return {
                ...state,
                courseMeetingForm: {
                    isOpen: true,
                    formType: action.formType,
                    formHeading: action.formHeading,
                    initialValues: action.payload,
                },
            };

        case CLOSE_COURSE_MEETING_FORM:
            return {
                ...state,
                courseMeetingForm: {
                    isOpen: false,
                    formType: 'new',
                    formHeading: 'New Course Meeting',
                    initialValues: newCourseMeetingInitialValues,
                },
            };

        case SHOW_NEW_COURSE_FORM:
            return {
                ...state,
                newCourseFormOpen: true,
            };

        case CLOSE_NEW_COURSE_FORM:
            return {
                ...state,
                newCourseFormOpen: false,
            };

        case TOGGLE_APP_DRAWER:
            return {
                ...state,
                appDrawerOpen: !state.appDrawerOpen,
            };

        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeCourseTab: action.payload,
            };

        case SET_DATAGRID_PAGE:
            return {
                ...state,
                courseDataGridState: {
                    ...state.courseDataGridState,
                    page: action.payload.page,
                    perPage: action.payload.pageSize,
                },
            };

        case SET_DATAGRID_FILTER_MODEL:
            return {
                ...state,
                courseDataGridState: {
                    ...state.courseDataGridState,
                    filterModel: action.payload,
                },
            };

        case SET_ARGUS_SORT_MODEL:
            return {
                ...state,
                argusGridState: {
                    ...state.argusGridState,
                    sortModel: action.payload,
                },
            };
        case SET_ARGUS_FILTER_MODEL:
            return {
                ...state,
                argusGridState: {
                    ...state.argusGridState,
                    filterModel: action.payload,
                },
            };
        case SET_SERVICE_REQUESTS_DATAGRID_PER_PAGE:
            return {
                ...state,
                serviceRequestsGridState: {
                    ...state.serviceRequestsGridState,
                    perPage: action.payload.pageSize,
                },
            };
        case SET_SERVICE_REQUESTS_DATATGRID_FILTER_MODEL:
            return {
                ...state,
                serviceRequestsGridState: {
                    ...state.serviceRequestsGridState,
                    filterModel: action.payload,
                },
            };
        case SET_SERVICE_REQUESTS_DATAGRID_SORT_MODEL:
            return {
                ...state,
                serviceRequestsGridState: {
                    ...state.serviceRequestsGridState,
                    sortModel: action.payload,
                },
            };
        case SET_SERVICE_REQUESTS_DATAGRID_PAGE:
            return {
                ...state,
                serviceRequestsGridState: {
                    ...state.serviceRequestsGridState,
                    page: action.payload.page,
                },
            };

        default:
            return state;
    }
};

export default applicationReducer;
