import { JSXElementConstructor } from 'react';
import {
    GridFooterContainer,
    GridPagination,
    GridRowData,
    GridRowId,
    GridSlotsComponentsProps,
    useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { CourseConfirmButton } from './CourseConfirmButton';
import { PermissionGate } from '../PermissionGate';
import { SCOPES } from '../../constants';

interface ICourseBannerCustomGridFooterProps extends GridSlotsComponentsProps {
    handleConfirmation: (props: Map<GridRowId, GridRowData>) => void;
}

export const CourseBannerCustomGridFooter: JSXElementConstructor<ICourseBannerCustomGridFooterProps> = (
    props: ICourseBannerCustomGridFooterProps,
) => {
    const gridSlotComponentProps = useGridSlotComponentProps();
    const selectedCourses = gridSlotComponentProps.apiRef.current.getSelectedRows();
    const confirmButtonDisabled = selectedCourses.size === 0;

    const handleCourseConfirm = () => {
        props.handleConfirmation(gridSlotComponentProps.apiRef.current.getSelectedRows());
        // Reset Banner course selection
        gridSlotComponentProps.apiRef.current.setSelectionModel([]);
    };
    return (
        <GridFooterContainer>
            <PermissionGate scopes={[SCOPES.canCreate]}>
                <CourseConfirmButton
                    handleConfirmation={handleCourseConfirm}
                    confirmButtonDisabled={confirmButtonDisabled}
                    selectedCourses={selectedCourses}
                />
            </PermissionGate>
            <GridPagination />
        </GridFooterContainer>
    );
};
