import {
    EXTRON_SSE_SUBSCRIBE,
    EXTRON_SSE_UNSUBSCRIBE,
    EXTRON_SSE_SET_ONLINE,
    EXTRON_SSE_SET_DATA,
    EXTRON_SSE_DISCONNECT_RECORDER
} from './extronSseActionTypes';

import {
    IExtronSSEEvent,
    IExtronSSESetOnlineAction,
    IExtronSSESetDataAction,
    IExtronSSESubscribeAction,
    IExtronSSEUnsubscribeAction,
    IExtronSSEDisconnectRecorderAction,
} from './extronSseInterfaces';

export const extronSseSubscribe = (): IExtronSSESubscribeAction => {
    return {
        type: EXTRON_SSE_SUBSCRIBE,
    };
};

export const extronSseUnsubscribe = (): IExtronSSEUnsubscribeAction => {
    return {
        type: EXTRON_SSE_UNSUBSCRIBE,
    };
};

export const extronSseSetOnline = (online: boolean): IExtronSSESetOnlineAction => {
    return {
        type: EXTRON_SSE_SET_ONLINE,
        payload: online,
    };
};

export const extronSseSetData = (data: IExtronSSEEvent): IExtronSSESetDataAction => {
    return {
        type: EXTRON_SSE_SET_DATA,
        payload: data,
    };
};

export const extronSseDisconnectRecorder = (data: IExtronSSEEvent): IExtronSSEDisconnectRecorderAction => {
    return {
        type: EXTRON_SSE_DISCONNECT_RECORDER,
        payload: data,
    }
};
