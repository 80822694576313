import { ICamera } from '../room/roomInterfaces';
import {
    AUTO_FOCUS_QUERY_FAILURE,
    AUTO_FOCUS_QUERY_REQUEST,
    AUTO_FOCUS_QUERY_SUCCESS,
    DESELECT_CAMERA,
    ISSUE_CAMERA_PRESET_FAILURE,
    ISSUE_CAMERA_PRESET_REQUEST,
    ISSUE_CAMERA_PRESET_SUCCESS,
    ISSUE_OTAF_FAILURE,
    ISSUE_OTAF_REQUEST,
    ISSUE_OTAF_SUCCESS,
    REBOOT_CAMERA_FAILURE,
    REBOOT_CAMERA_REQUEST,
    REBOOT_CAMERA_SUCCESS,
    SELECT_CAMERA,
    SET_AUTO_FOCUS_FAILURE,
    SET_AUTO_FOCUS_REQUEST,
    SET_AUTO_FOCUS_SUCCESS,
} from './cameraActionTypes';
import {
    IAutoFocusQueryAction,
    IAutoFocusQueryFailureAction,
    IAutoFocusQueryResponse,
    IAutoFocusQuerySuccessAction,
    ICameraProxyAutoFocusRequestParams,
    ICameraProxyPresetRequestParams,
    ICameraProxyRequestParams,
    IDeselectCameraAction,
    IIssueCameraPresetFailureAction,
    IIssueCameraPresetRequestAction,
    IIssueCameraPresetSuccessAction,
    IIssueOtafFailureAction,
    IIssueOtafRequestAction,
    IIssueOtafSuccessAction,
    IRebootCameraFailureAction,
    IRebootCameraRequestAction,
    IRebootCameraSuccessAction,
    ISelectCameraAction,
    ISetAutoFocusFailureAction,
    ISetAutoFocusRequestAction,
    ISetAutoFocusSuccessAction,
} from './cameraInterfaces';

export const selectCameraAction = (camera: ICamera): ISelectCameraAction => ({
    type: SELECT_CAMERA,
    payload: camera,
});

export const deselectCameraAction = (): IDeselectCameraAction => ({ type: DESELECT_CAMERA });

export const autoFocusQueryRequestAction = (params: ICameraProxyRequestParams): IAutoFocusQueryAction => ({
    type: AUTO_FOCUS_QUERY_REQUEST,
    payload: params,
});

export const autoFocusQuerySuccessAction = (autoFocusState: IAutoFocusQueryResponse): IAutoFocusQuerySuccessAction => ({
    type: AUTO_FOCUS_QUERY_SUCCESS,
    payload: autoFocusState,
});

export const autoFocusQueryFailureAction = (error: string): IAutoFocusQueryFailureAction => ({
    type: AUTO_FOCUS_QUERY_FAILURE,
    payload: error,
});

export const setAutoFocusRequestAction = (params: ICameraProxyAutoFocusRequestParams): ISetAutoFocusRequestAction => ({
    type: SET_AUTO_FOCUS_REQUEST,
    payload: params,
});

export const setAutoFocusSuccessAction = (): ISetAutoFocusSuccessAction => ({
    type: SET_AUTO_FOCUS_SUCCESS,
});

export const setAutoFocusFailureAction = (error: string): ISetAutoFocusFailureAction => ({
    type: SET_AUTO_FOCUS_FAILURE,
    payload: error,
});

export const issueOtafRequestAction = (params: ICameraProxyRequestParams): IIssueOtafRequestAction => ({
    type: ISSUE_OTAF_REQUEST,
    payload: params,
});

export const issueOtafSuccessAction = (): IIssueOtafSuccessAction => ({ type: ISSUE_OTAF_SUCCESS });

export const issueOtafFailureAction = (error: string): IIssueOtafFailureAction => ({
    type: ISSUE_OTAF_FAILURE,
    payload: error,
});

export const rebootCameraRequestAction = (params: ICameraProxyRequestParams): IRebootCameraRequestAction => ({
    type: REBOOT_CAMERA_REQUEST,
    payload: params,
});

export const rebootCameraSuccessAction = (): IRebootCameraSuccessAction => ({
    type: REBOOT_CAMERA_SUCCESS,
});

export const rebootCameraFailureAction = (error: string): IRebootCameraFailureAction => ({
    type: REBOOT_CAMERA_FAILURE,
    payload: error,
});

export const issueCameraPresetRequestAction = (
    params: ICameraProxyPresetRequestParams,
): IIssueCameraPresetRequestAction => ({
    type: ISSUE_CAMERA_PRESET_REQUEST,
    payload: params,
});

export const issueCameraPresetSuccessAction = (): IIssueCameraPresetSuccessAction => ({
    type: ISSUE_CAMERA_PRESET_SUCCESS,
});

export const issueCameraPresetFailureAction = (error: string): IIssueCameraPresetFailureAction => ({
    type: ISSUE_CAMERA_PRESET_FAILURE,
    payload: error,
});
