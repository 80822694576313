import IconButton from '@material-ui/core/IconButton';
import { GridCellParams } from '@material-ui/data-grid';
import { JSXElementConstructor, ReactElement } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SchoolIcon from '@material-ui/icons/School';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FaceIcon from '@material-ui/icons/Face';
import TimerIcon from '@material-ui/icons/Timer';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Chip, Tooltip } from '@material-ui/core';
import { capitalize } from '../../utils/helpers';
import { PermissionGate } from '../PermissionGate';
import { SCOPES } from '../../constants';

export const renderActionsCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const handleDelete = () => {
        params.row.handleUserDelete(params.row);
    };

    const handleEdit = () => {
        params.row.handleUserFormOpen(params.id);
    };

    return (
        <>
            <PermissionGate
                scopes={[SCOPES.canEdit]}
                errorProps={{ disabled: true, style: { color: '#ccc' }, onClick: () => null }}
            >
                <Tooltip title={`Edit user '${params.id}'`}>
                    <IconButton color="inherit" style={{ color: '#757575' }} aria-label="edit" onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </PermissionGate>

            <PermissionGate
                scopes={[SCOPES.canDelete]}
                errorProps={{ disabled: true, style: { color: '#ccc' }, onClick: () => null }}
            >
                <Tooltip title={`Delete user '${params.id}'`}>
                    <IconButton color="inherit" style={{ color: '#d32f2f' }} aria-label="delete" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </PermissionGate>
        </>
    );
};

const userRoleIconMap: { [key: string]: ReactElement } = {
    USER: (
        <Tooltip
            children={<AccountCircleIcon fontSize="small" htmlColor="#00B2E3" style={{ marginRight: '.2rem' }} />}
            title={'USER'}
            key="user"
        ></Tooltip>
    ),
    ADMIN: (
        <Tooltip children={<LockOpenIcon fontSize="small" htmlColor="#00B2E3" />} key="admin" title={'ADMIN'}></Tooltip>
    ),
    VIEWER: (
        <Tooltip
            children={<VisibilityIcon fontSize="small" htmlColor="#00B2E3" />}
            key="viewer"
            title={'VIEWER'}
        ></Tooltip>
    ),
};

export const renderUserRolesCell = (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
    const { value = [] } = params;
    const roles = [...(value as string[])].sort((a, b) => {
        return b.toUpperCase().localeCompare(a.toUpperCase());
    });

    return <>{roles.map((v: string) => userRoleIconMap[v] || '')}</>;
};

const userAffiliationIconMap = (affiliation: string): ReactElement => {
    const icons: { [key: string]: ReactElement } = {
        faculty: <Chip color="primary" label={capitalize(affiliation)} icon={<SchoolIcon />} size="small" />,
        staff: <Chip color="primary" label={capitalize(affiliation)} icon={<AssignmentIndIcon />} size="small" />,
        student: <Chip color="primary" label={capitalize(affiliation)} icon={<FaceIcon />} size="small" />,
        taf: <Chip color="primary" label={affiliation.toLocaleUpperCase()} icon={<TimerIcon />} size="small" />,
        other: (
            <Chip
                color="primary"
                label={capitalize(affiliation)}
                icon={<AccountBoxIcon fontSize="small" />}
                size="small"
            />
        ),
    };
    return icons[affiliation];
};

export const renderUserAffiliationCell = (
    params: GridCellParams,
): ReactElement<string | JSXElementConstructor<string>> => {
    return params.value ? userAffiliationIconMap(params.value.toString()) : <></>;
};
