import axios from 'axios';
import { BASE_URL, DEFAULT_REQUEST_PARAMS } from '../../constants';
import { Course } from '../../factories/Course';
import { requestErrorMessage } from '../../utils/helpers';

import { ICourse } from '../course/courseInterfaces';

export async function CreateCourse(course: ICourse): Promise<ICourse> {
    const url = `${BASE_URL}/courses`;
    const headers = { ...DEFAULT_REQUEST_PARAMS };
    const response = await axios.post(url, course, { validateStatus: ()=> true, withCredentials: true, headers });
    if (response.status === 200 && response.request.responseURL === url) {
        return Course(response.data);
    }

    throw new Error(requestErrorMessage(`Error creating course`, response.data ? response.data : response.statusText ));
}

export async function ConfirmCourse(course: ICourse): Promise<ICourse> {
    const url = `${BASE_URL}/courses/confirm/${course.courseID}`;
    const headers = { ...DEFAULT_REQUEST_PARAMS };
    const response = await axios.put(url, course, {withCredentials: true, headers});

    if (response.status === 200 && response.request.responseURL === url) {
        return Course(response.data);
    }
    
    throw new Error(requestErrorMessage(`Error confirming courses. Please check your network settings.`, response.statusText));
}

export async function DeleteCourse(course: ICourse): Promise<ICourse> {
    const url = `${BASE_URL}/courses/${course.courseID}/${course.getCourseStatus()}/v0`;
    const headers ={ ...DEFAULT_REQUEST_PARAMS };
    const response = await axios.delete(url, { withCredentials: true, headers });

    if(response.status === 200 && response.request.responseURL === url) {
        return Course(course);
    }

    throw new Error(requestErrorMessage(`Error deleting course. Please check your network settings.`, response.statusText));
}

export async function UpdateCourse(course: ICourse): Promise<ICourse> {
    const url = `${BASE_URL}/courses/${course.courseID}/${course.getCourseStatus()}/v0`;
    const headers ={ ...DEFAULT_REQUEST_PARAMS };
    const response = await axios.put(url, course, { withCredentials: true, headers });

    if(response.status === 200 && response.request.responseURL === url) {
        return Course(course);
    }

    throw new Error(requestErrorMessage(`Error updating course. Please check your network settings.`, response.statusText));
}
