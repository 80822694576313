import { CourseConfirmButton } from './CourseConfirmButton';
import {
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridSlotsComponentsProps,
    useGridSlotComponentProps,
    GridRowData,
    GridRowId,
} from '@material-ui/data-grid';
import { createStyles, makeStyles } from '@material-ui/core';
import { JSXElementConstructor } from 'react';
import { PermissionGate } from '../PermissionGate';
import { SCOPES } from '../../constants';

interface ICourseBannerCustomGridToolbarProps extends GridSlotsComponentsProps {
    handleConfirmation: (props: Map<GridRowId, GridRowData>) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        gridToolbarContainer: {
            display: 'inline-flex',
            gap: '.75em',
            padding: '0 8px 0 0!important',
            justifyContent: 'right',
        },
    }),
);

/**
 * Returns a custom DataGrid toolbar
 *
 * @param props The toolbar props
 * @returns A custom DataGrid toolbar
 */
export const CoursesBannerCustomGridToolbar: JSXElementConstructor<ICourseBannerCustomGridToolbarProps> = (
    props: ICourseBannerCustomGridToolbarProps,
) => {
    const classes = useStyles();

    const gridSlotComponentProps = useGridSlotComponentProps();
    const selectedCourses = gridSlotComponentProps.apiRef.current.getSelectedRows();
    const confirmButtonDisabled = selectedCourses.size === 0;

    const handleCourseConfirm = () => {
        props.handleConfirmation(gridSlotComponentProps.apiRef.current.getSelectedRows());
        // Reset Banner course selection
        gridSlotComponentProps.apiRef.current.setSelectionModel([]);
    };

    return (
        <>
            <GridToolbarContainer className={classes.gridToolbarContainer}>
                <PermissionGate scopes={[SCOPES.canCreate]}>
                    <CourseConfirmButton
                        handleConfirmation={handleCourseConfirm}
                        confirmButtonDisabled={confirmButtonDisabled}
                        selectedCourses={selectedCourses}
                    />
                </PermissionGate>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        </>
    );
};
