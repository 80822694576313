import { CoursesConfirmedCustomGridToolbar } from './CoursesConfirmedCustomGridToolbar';
import { CourseConfirmedCustomGridFooter } from './CourseConfirmedCustomGridFooter';
import {
    DataGrid,
    GridFilterModelParams,
    GridPageChangeParams,
    GridRowParams,
    GridRowSelectedParams,
    GridSelectionModelChangeParams,
} from '@material-ui/data-grid';
import { ConfirmedCourseGridColumns, getCourseGridRowID } from './CourseGridColumns';
import { dataGridRowsPerPage } from '../../constants';
import TabPanel from './TabPanel';
import { ICourse } from '../../redux/course/courseInterfaces';
import CustomLoadingOverlay from '../CustomLoadingOverlay';
import { AppState } from '../../redux/AppState';
import { useSelector } from 'react-redux';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface IConfirmedCourseTabPanelProps {
    activeCourseTab: number;
    confirmedCourses: ICourse[];
    getRowClassName: (params: GridRowParams) => string;
    handleDataGridPageChange: (params: GridPageChangeParams) => void;
    classes: ClassNameMap;
    isLoadingCourses: boolean;
    handleCoursesDelete: (e: React.ChangeEvent<{ value: unknown }>) => void;
    confirmedCourseSelectionModel: string[];
    handleCourseConfirmedSelect: (params: GridRowSelectedParams) => void;
    handleConfirmedCourseSelectionModelChange: (params: GridSelectionModelChangeParams) => void;
    handleFilterModelChange: (model: GridFilterModelParams) => void;
}

/**
 * Renders a tabbed view of courses
 *
 * @param props
 * @returns A tabbed view component
 */

const ConfirmedCourseTabPanel: React.FC<IConfirmedCourseTabPanelProps> = ({
    activeCourseTab,
    confirmedCourses,
    getRowClassName,
    handleDataGridPageChange,
    classes,
    isLoadingCourses,
    handleCoursesDelete,
    confirmedCourseSelectionModel,
    handleCourseConfirmedSelect,
    handleConfirmedCourseSelectionModelChange,
    handleFilterModelChange,
}) => {
    const { perPage, page, filterModel } = useSelector((state: AppState) => state.app.courseDataGridState);
    return (
        <TabPanel value={activeCourseTab} index={0}>
            {activeCourseTab === 0 && (
                <DataGrid
                    getRowId={getCourseGridRowID}
                    rows={confirmedCourses}
                    getRowClassName={getRowClassName}
                    columns={ConfirmedCourseGridColumns}
                    pageSize={perPage}
                    page={page}
                    onPageChange={handleDataGridPageChange}
                    onPageSizeChange={handleDataGridPageChange}
                    rowsPerPageOptions={dataGridRowsPerPage}
                    pagination
                    className={classes.dataGrid}
                    loading={isLoadingCourses}
                    autoHeight={true}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    components={{
                        Toolbar: CoursesConfirmedCustomGridToolbar,
                        LoadingOverlay: CustomLoadingOverlay,
                        Footer: CourseConfirmedCustomGridFooter,
                    }}
                    componentsProps={{
                        toolbar: {
                            handleCourseDelete: handleCoursesDelete,
                            selectedCourses: confirmedCourseSelectionModel,
                        },
                        footer: {
                            handleCourseDelete: handleCoursesDelete,
                            selectedCourses: confirmedCourseSelectionModel,
                        },
                        panel: {
                            className: classes.filterPanel,
                        },
                    }}
                    onRowSelected={handleCourseConfirmedSelect}
                    selectionModel={confirmedCourseSelectionModel}
                    onSelectionModelChange={handleConfirmedCourseSelectionModelChange}
                    onFilterModelChange={handleFilterModelChange}
                    filterModel={filterModel}
                />
            )}
        </TabPanel>
    );
};

export default ConfirmedCourseTabPanel;
