import {
    FETCH_SERVICE_REQUESTS_FAILURE,
    POST_SERVICE_REQUEST,
    POST_SERVICE_REQUEST_FAILURE,
    POST_SERVICE_REQUEST_SUCCESS,
} from './serviceRequestActionTypes';
import { IServiceRequestsState } from './serviceRequestInterfaces';
import { ServiceRequestActionTypes } from './serviceRequestTypes';

const serviceRequestsInitialState: IServiceRequestsState = {
    isPosting: false,
    error: null,
};

export const serviceRequestReducer = (
    state: IServiceRequestsState = serviceRequestsInitialState,
    action: ServiceRequestActionTypes,
): IServiceRequestsState => {
    switch (action.type) {
        case POST_SERVICE_REQUEST:
            return {
                ...state,
                isPosting: true,
            };
        case POST_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                isPosting: false,
            };
        case POST_SERVICE_REQUEST_FAILURE:
            return {
                ...state,
                isPosting: false,
            };
        case FETCH_SERVICE_REQUESTS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
