import { GridColDef } from '@material-ui/data-grid';
import { renderActionsCell, renderUserAffiliationCell, renderUserRolesCell } from './UserListGridRowRenderers';

export const UserListDataGridColDef: GridColDef[] = [
    {
        field: 'loginID',
        headerName: 'CAS ID',
        hide: false,
        flex: 1,
        editable: true,
    },
    {
        field: 'lastName',
        headerName: 'Last',
        flex: 1,
        editable: true,
    },
    {
        field: 'firstName',
        headerName: 'First',
        flex: 1,
        editable: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
    },
    {
        field: 'affiliation',
        headerName: 'Affiliation',
        flex: 1,
        renderCell: renderUserAffiliationCell,
        align: 'center',
    },
    {
        field: 'roles',
        headerName: 'Roles',
        flex: 1,
        renderCell: renderUserRolesCell,
    },
    {
        field: 'actions',
        type: 'Actions',
        renderCell: renderActionsCell,
        flex: 1,
    },
];
