import useSWR, {mutate} from 'swr';
import { BASE_URL } from '../constants';
import { ServiceRequest } from '../redux/serviceRequest/serviceRequestTypes';
import { fetcher } from '../utils/helpers';
import { FetchError } from '../redux/application/applicationInterfaces';

type ServiceRequestData = {
    serviceRequests: ServiceRequest[];
    isLoadingServiceRequests: boolean;
    isServiceRequestsError: FetchError;
    mutateServiceRequests: () => void;
};

export const useServiceRequests = (termcode: string): ServiceRequestData => {
    const { data, error } = useSWR<ServiceRequest[]>(
        termcode ? `${BASE_URL}/servicerequests?termCode=${termcode}` : null,
        fetcher,
        {
            refreshInterval: 60 * 1000,
            fallbackData: [],
            revalidateOnMount: true,
        },
    );

    return {
        serviceRequests: data || [],
        isLoadingServiceRequests: !error && !data,
        isServiceRequestsError: error,
        mutateServiceRequests: () => mutate(`${BASE_URL}/servicerequests?termCode=${termcode}`),
    };
};
