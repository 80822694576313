import { Box, createStyles, makeStyles } from '@material-ui/core';
import {
    GridRowData,
    GridRowId,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { JSXElementConstructor } from 'react';
import { SCOPES, ServiceStatus } from '../../constants';
import { PermissionGate } from '../PermissionGate';
import { ServiceRequestCancelButton } from './ServiceRequestCancelBtn';
import { ServiceRequestConfirmButton } from './ServiceRequestConfirmBtn';

interface IServiceRequestCustomGridToolbarProps {
    handleServiceRequestConfirm: (props: Map<GridRowId, GridRowData>) => void;
    handleServiceRequestCancel: (props: Map<GridRowId, GridRowData>) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        serviceRequestButtonRenderError: {
            width: '179.45px',
            height: '36.5px',
            marginRight: 'auto',
        },
    }),
);

export const ServiceRequestCustomGridToolbar: JSXElementConstructor<IServiceRequestCustomGridToolbarProps> = (
    props: IServiceRequestCustomGridToolbarProps,
) => {
    const gridSlotComponentProps = useGridSlotComponentProps();
    const selectedServiceRequests = gridSlotComponentProps.apiRef.current.getSelectedRows();
    const confirmBtnDisabled = !Array.from(selectedServiceRequests.values()).some((req) =>
        [ServiceStatus.cancelled.value, ServiceStatus.pending.value].includes(
            req.status,
        ),
    );

    const cancelBtnDisabled = !Array.from(selectedServiceRequests.values()).some((req) =>
        [ServiceStatus.scheduled.value, ServiceStatus.pending.value, ServiceStatus.cancelPending.value].includes(
            req.status,
        ),
    );
    const classes = useStyles();

    const serviceRequestButtonRenderError = () => <Box m={1} className={classes.serviceRequestButtonRenderError}></Box>;

    const handleRequestConfirm = () => {
        props.handleServiceRequestConfirm(gridSlotComponentProps.apiRef.current.getSelectedRows());
        // Reset Banner course selection
        gridSlotComponentProps.apiRef.current.setSelectionModel([]);
    };

    const handleRequestCancel = () => {
        props.handleServiceRequestCancel(gridSlotComponentProps.apiRef.current.getSelectedRows());
        gridSlotComponentProps.apiRef.current.setSelectionModel([]);
    };

    return (
        <GridToolbarContainer>
            <PermissionGate scopes={[SCOPES.canDelete]} RenderError={serviceRequestButtonRenderError}>
                <ServiceRequestConfirmButton
                    handleConfirm={handleRequestConfirm}
                    disabled={confirmBtnDisabled}
                    selectedRequests={selectedServiceRequests}
                />
            </PermissionGate>
            <PermissionGate scopes={[SCOPES.canDelete]} RenderError={serviceRequestButtonRenderError}>
                <ServiceRequestCancelButton
                    handleCancel={handleRequestCancel}
                    disabled={cancelBtnDisabled}
                    selectedRequests={selectedServiceRequests}
                />
            </PermissionGate>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
};
