import { END, EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import { call, cancelled, put, take } from 'redux-saga/effects';
import { EXTRON_ACTIVE_AUDIO_THRESHOLD, EXTRON_ERROR_CODES, EXTRON_SSE_URL } from '../../constants';
import { store } from '../store';
import { extronSseDisconnectRecorder, extronSseSetData, extronSseSetOnline } from './extronSseActions';
import { IRecorderState } from './extronSseInterfaces';
import { logger } from '../../services/Logger';

let eventChannelInstance: EventChannel<MessageEvent<string>>;

// Normalize audio values to on or off based on a threshold
// Audio event values strings like '-500*-500'
const parseAudioLevels = (audioLevels: string): string => {
    if(!audioLevels) return 'No Audio';
    // If the audio level query response is an Extron error code return it
    if (Object.keys(EXTRON_ERROR_CODES).includes(audioLevels)) return audioLevels;

    return audioLevels.split('*', 2).map((audioLevels: string) => parseInt(audioLevels))[0] >
        EXTRON_ACTIVE_AUDIO_THRESHOLD
        ? 'Audio'
        : 'No Audio';
};

// Transform Extron recording ID to meeting ID format
// Extron recording IDs are in the format:
// 202301#AAS#180#001#1674846900@lecturecapture.ucdavis.edu
const parseExtronRecordingID = (recorderID: string): string => {
    if (!recorderID || recorderID === '') return recorderID;

    return recorderID.split('@')[0].replaceAll('#', '-');
};

/**
 * Emits SSE events
 * @param eventSrc
 * @returns
 */
export function subscribeToExtronSSE(eventSrc: EventSource): EventChannel<MessageEvent<string>> | EventChannel<Event> {
    return eventChannel((emitter) => {
        eventSrc.onopen = (ev: Event) => {
            logger.debug('connection is established');
            emitter(ev);
        };

        eventSrc.onerror = (err: Event) => {
            logger.error(err);
            emitter(err);
        };

        eventSrc.onmessage = (event: MessageEvent<string>) => {
            emitter(event);
        };

        return () => {
            logger.debug('closing connection...');
            eventSrc.close();
            emitter(END);
        };
    });
}

/**
 * Subscribs to the Extron SSE stream
 */
export function* subscribe(): SagaIterator {
    logger.debug('Subscribing to Extron SSE stream');
    const sseEventSource = new EventSource(EXTRON_SSE_URL, { withCredentials: true });
    const channel = yield call(subscribeToExtronSSE, sseEventSource);
    eventChannelInstance = channel;

    try {
        while (true) {
            const event = yield take(channel);
            const state = store.getState().recorders.recorders;

            if (event.type === 'open') {
                yield put(extronSseSetOnline(true));
            }

            if (event.type === 'error') {
                yield put(extronSseSetOnline(false));
            }

            if (typeof event.data !== 'undefined') {
                const eventData = JSON.parse(event.data);

                // Handle a recorder disconnect event 
                if (eventData.data.request === 'disconnect') {
                    yield put(extronSseDisconnectRecorder(eventData));
                    continue;
                }

                if (eventData.data.request === 'query_front_panel_audio_levels')
                    eventData.data.response = parseAudioLevels(eventData.data.response);

                if (eventData.data.request === 'query_recording_id')
                    eventData.data.response = parseExtronRecordingID(eventData.data.response);

                if (typeof state[eventData.data.host] === 'undefined') {
                    yield put(extronSseSetData(eventData));
                }

                if (state[eventData.data.host]) {
                    // Typically empty string response can be discarded
                    // However, we want these passed through to state for the recording metadata cases
                    if (
                        eventData.data.response === '' &&
                        eventData.data.request !== 'query_recording_id' &&
                        eventData.data.request !== 'query_recording_title'
                    ) {
                        continue;
                    }

                    if (
                        typeof state[eventData.data.host][eventData.data.request as keyof IRecorderState] ===
                            'undefined' ||
                        state[eventData.data.host][eventData.data.request as keyof IRecorderState] !==
                            eventData.data.response
                    ) {
                        yield put(extronSseSetData(eventData));
                    }
                }
            }
        }
    } catch (error) {
        logger.error(error);
    } finally {
        if (yield cancelled()) {
            channel.close();
            logger.debug('Channel closed');
            yield put(extronSseSetOnline(false));
        }
    }
}

/**
 * Unsubscribes to Extron SSE stream
 */
export function* unsubscribe(): SagaIterator {
    logger.debug('Unsubscribing Extron SSE channel');
    yield put(extronSseSetOnline(false));
    if (eventChannelInstance) yield call(eventChannelInstance.close);
}
