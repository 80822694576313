import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    GridSlotsComponentsProps,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { JSXElementConstructor } from 'react';
import { SCOPES } from '../../constants';
import { PermissionGate } from '../PermissionGate';
import { CourseMeetingBulkEditButton } from './CourseMeetingsBulkEditButton';

interface ICourseMeetingsCustomGridToolbarProps extends GridSlotsComponentsProps {
    handleMeetingBulkEdit: (selectedCourseMeetingIDs: string[]) => void;
    selectedCourseMeetingIDs: string[];
}

const useStyles = makeStyles(() =>
    createStyles({
        gridToolbarContainer: {
            display: 'inline-flex',
            gap: '.75em',
            justifyContent: 'left',
        },
    }),
);

export const CourseMeetingsCustomGridToolbar: JSXElementConstructor<ICourseMeetingsCustomGridToolbarProps> = (
    props: ICourseMeetingsCustomGridToolbarProps,
) => {
    const classes = useStyles();

    const handleBulkEditClick = () => {
        props.handleMeetingBulkEdit(props.selectedCourseMeetingIDs);
    };

    return (
        <GridToolbarContainer className={classes.gridToolbarContainer}>
            {props.selectedCourseMeetingIDs?.length > 0 && (
                <PermissionGate scopes={[SCOPES.canEdit]}>
                    <CourseMeetingBulkEditButton handleClick={handleBulkEditClick} />
                </PermissionGate>
            )}
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
};
