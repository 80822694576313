import React, { Dispatch, lazy, memo, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { Container, Fab, Grid, Paper, Typography } from '@material-ui/core';
import {
    DataGrid,
    GridRowData,
    GridRowId,
    GridRowIdGetter,
    GridRowSelectedParams,
    GridSelectionModelChangeParams,
} from '@material-ui/data-grid';

import AddIcon from '@material-ui/icons/Add';

import { AppState } from '../redux/AppState';

import { COURSE_CONFIRM_REQUEST, PUT_COURSE_REQUEST } from '../redux/course/courseActionTypes';
import { CourseActionTypes } from '../redux/course/courseTypes';
import { createCourseMeetingRequest, putCourseMeetingRequest } from '../redux/courseMeeting/courseMeetingActions';
import {
    CREATE_COURSEMEETING_REQUEST,
    PUT_COURSEMEETING_REQUEST,
} from '../redux/courseMeeting/courseMeetingActionTypes';
import { CourseMeetingActionTypes } from '../redux/courseMeeting/courseMeetingTypes';
import { CourseDetailsGridColumnsFactory } from '../components/Course/CourseDetailsGridColumns';

import CourseDetailsSidebar from '../components/Course/CourseDetailsSidebar';
import { ApplicationActionTypes } from '../redux/application/applicationTypes';
import {
    CLOSE_COURSE_MEETING_FORM,
    CLOSE_POPOVER,
    SHOW_COURSE_MEETING_FORM,
    SHOW_POPOVER,
} from '../redux/application/applicationActionTypes';
import { RoomActionTypes } from '../redux/room/roomTypes';
import { Course } from '../factories/Course';
import { CourseMeetingFactory } from '../factories/CourseMeeting';
import { CONFIRMED_COURSE_ENTITYTPE, RECORDING_OPTIONS_ENUM, SCOPES } from '../constants';
import { CourseMeetingsCustomGridToolbar } from '../components/Meeting/CourseMeetingsCustomGridToolbar';
import { formatDateTime, getBannerSyncCandidates, handleSWRError } from '../utils/helpers';
import { CourseMeetingsCustomGridFooter } from '../components/Meeting/CourseMeetingsCustomGridFooter';
import { CourseDetailsContainerStyles } from './styles/CourseDetailsContainerStyles';
import { PermissionGate } from '../components/PermissionGate';
import { Error404 } from '../components/Error404';
import { useCourse } from '../hooks/useCourse';
import { useRooms } from '../hooks/useRooms';
import { useCourses } from '../hooks/useCourses';
import { Skeleton } from '@material-ui/lab';
import { useCourseMeetings } from '../hooks/useCourseMeetings';
import { useDebounce } from '../hooks/useDebounce';
import {
    showPopover,
    closePopover,
    showCourseMeetingForm,
    closeCourseMeetingForm,
} from '../redux/application/applicationActions';
import { ICourseMeetingForm, IPopoverProps } from '../redux/application/applicationInterfaces';
import { ICourse, ICourseCanvasData, ICourseMetaData } from '../redux/course/courseInterfaces';
import { ICourseMeeting, ICourseMeetingData } from '../redux/courseMeeting/courseMeetingInterfaces';
import { IUserState } from '../redux/user/userInterfaces';

// Lazy load course meeting form
const CourseMeetingForm = lazy(() => import('../components/Meeting/forms/CourseMeetingForm'));
const BulkCourseMeetingForm = lazy(() => import('../components/Meeting/forms/BulkCourseMeetingForm'));

// Memoize heavy components
const MemoizedCourseMeetingsCustomGridToolbar = memo(CourseMeetingsCustomGridToolbar);
const MemoizedCourseMeetingsCustomFooter = memo(CourseMeetingsCustomGridFooter);
const MemoizedCourseDetailsSidebar = memo(CourseDetailsSidebar);

/**
 * Returns a unique row ID for use by the DataGrid component
 * @param {GridRowData} row
 * @returns {GridRowId}
 */
const getRowID: GridRowIdGetter = (row: GridRowData): GridRowId => {
    return row.eventID;
};

interface RouterProps {
    courseID: string;
    courseStatus: string;
}

interface ICourseDetailsContainerProps extends RouteComponentProps<RouterProps> {
    isLoadingMeetings: boolean;
    courseMeetings: ICourseMeeting[];
    focusedCourseID?: string;
    focusedCourseObj?: ICourse;
    courseMeetingForm: ICourseMeetingForm;
    popoverProps: IPopoverProps;
    user: IUserState;
    coursesError?: string;
    putCourseRequest: (course: ICourse) => void;
    courseConfirmRequest: (course: ICourse) => void;
    fetchCoursesRequest: (selectedTerm: string) => void;
}

const CourseDetailsContainer: React.FC<ICourseDetailsContainerProps> = ({
    courseMeetingForm,
    popoverProps,
    user,
    putCourseRequest,
    courseConfirmRequest,
    match,
}) => {
    const classes = CourseDetailsContainerStyles();
    const dispatch = useDispatch();

    const [selectedCourseMeetingIDs, setSelectedCourseMeetingIDs] = useState<string[]>([]);

    const routeParamCourseID = match?.params?.courseID;
    const routeParamCourseStatus = match?.params?.courseStatus;

    const { rooms, isLoadingRooms, isRoomsError } = useRooms(!!user.username);

    const { course, isLoadingCourse, isCourseError, mutateCourse } = useCourse(
        routeParamCourseID,
        routeParamCourseStatus,
    );

    const { bannerCourses, confirmedCourses, isLoadingCourses, isCoursesError, mutateCourses } = useCourses(
        course?.termCode,
    );

    const bannerSyncCandidates = useMemo(() => {
        return getBannerSyncCandidates([...bannerCourses, ...confirmedCourses]);
    }, [bannerCourses, confirmedCourses, isLoadingCourse]);

    const focusedCourse = course ? Course(course) : null;

    const { courseMeetings, isLoadingCourseMeetings, isCourseMeetingsError, mutateCourseMeetings } = useCourseMeetings(
        focusedCourse,
    );

    const debouncedMutateCourseMeetings = useDebounce(
        useCallback(() => mutateCourseMeetings(), []),
        1000,
    );

    const debouncedMutateCourses = useDebounce(
        useCallback(() => mutateCourses(), []),
        500,
    );

    const hasConflicts = useMemo(() => courseMeetings.some((m: ICourseMeeting) => m.conflicts.length > 0), [
        courseMeetings,
    ]);

    const handleCourseMeetingSelection = (params: GridRowSelectedParams) => {
        const newCourseMeetingSet = new Set<string>(selectedCourseMeetingIDs);
        const courseMeetingID = (params.data as ICourseMeeting).eventID;
        if (params.isSelected) {
            newCourseMeetingSet.add(courseMeetingID);
        } else {
            newCourseMeetingSet.delete(courseMeetingID);
        }
        params.api.current.setState(params.api.current.state);
        setSelectedCourseMeetingIDs([...newCourseMeetingSet]);
    };

    const handleCourseMeetingSelectionModelChange = (params: GridSelectionModelChangeParams) => {
        const newSelectedCourseSet = new Set('');
        params.selectionModel.map((r) => newSelectedCourseSet.add(r.toString()));
        setSelectedCourseMeetingIDs([...newSelectedCourseSet]);
    };

    const newCourseMeetingInitialValues: ICourseMeetingData = {
        startDateTime: new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }) + ' GMT-0000')
            .toISOString()
            .slice(0, -8),
        endDateTime: new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }) + ' GMT-0000')
            .toISOString()
            .slice(0, -8),
        building: focusedCourse?.building || '',
        roomNumber: focusedCourse?.roomNumber || '',
        recordingOption: RECORDING_OPTIONS_ENUM.fullService.value,
        notes: '',
        eventID: undefined,
        eventIndex: undefined,
        meetingType: 'A',
        conflicts: [],
    };

    const handleShowPopover = (popoverProps: IPopoverProps) => {
        dispatch(showPopover(popoverProps));
    };

    const handleClosePopover = () => {
        dispatch(closePopover());
    };

    const handleMeetingFormOpen = () => {
        dispatch(showCourseMeetingForm(newCourseMeetingInitialValues, 'new', 'New Course Meeting'));
    };

    const handleMeetingFormCancel = () => {
        dispatch(closeCourseMeetingForm());
    };

    const handleMeetingFormSave = (courseMeetingProps: ICourseMeetingData) => {
        if (focusedCourse) {
            const courseMeeting: ICourseMeeting = CourseMeetingFactory.createFromCourse(
                focusedCourse,
                courseMeetingProps,
            );

            switch (courseMeetingForm.formType) {
                case 'new':
                    dispatch(createCourseMeetingRequest(courseMeeting));
                    break;
                case 'edit':
                    dispatch(putCourseMeetingRequest(courseMeeting));
                    break;
            }

            dispatch(closeCourseMeetingForm());
            mutateCourseMeetings(
                [...courseMeetings.filter((meeting) => meeting.eventID !== courseMeeting.eventID), courseMeeting].sort(
                    (a: ICourseMeeting, b: ICourseMeeting) => {
                        return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime();
                    },
                ),
                false,
            );
        }
    };

    /**
     * Dispatches sagas on bulk course meeting edit form save
     * @param courseMeetingProps
     */
    const handleBulkMeetingEditFormSave = (courseMeetingProps: ICourseMeetingData) => {
        const { building, roomNumber, startDateTime, endDateTime, recordingOption, notes } = courseMeetingProps;

        const findSelectedCourseMeetings = (selectedMeetingIDs: string[], meetings: ICourseMeeting[]) =>
            meetings.filter((meeting) => selectedMeetingIDs.includes(meeting.eventID));

        const generateLocation = (courseMeeting: ICourseMeeting): string => {
            return `${courseMeeting.term}-${courseMeeting.building}-${courseMeeting.roomNumber}-${courseMeeting.days}-${courseMeeting.time}`;
        };

        if (building || roomNumber || startDateTime || endDateTime || recordingOption || notes) {
            const selectedCourseMeetings = findSelectedCourseMeetings(
                Array.from(selectedCourseMeetingIDs),
                courseMeetings,
            );

            // Update each selected course meeting with values chosen in bulk edit form
            selectedCourseMeetings.forEach((m) => {
                const updatedCourseMeeting = {
                    ...m,
                };

                if (building) updatedCourseMeeting.building = building;

                if (roomNumber) updatedCourseMeeting.roomNumber = roomNumber;

                if (recordingOption) updatedCourseMeeting.recordingOption = recordingOption;

                if (notes) updatedCourseMeeting.notes = [notes, m.notes].filter((n) => n).join(', ');

                if (startDateTime) {
                    const newStartDate = new Date(m.startDateTime);
                    const [startDateTimeHours, startDateTimeMin] = startDateTime.split(':').map((e) => parseInt(e));
                    newStartDate.setHours(startDateTimeHours, startDateTimeMin);
                    updatedCourseMeeting.startDateTime = formatDateTime(newStartDate);
                }

                if (endDateTime) {
                    const newEndDate = new Date(m.endDateTime);
                    const [endDateTimeHours, endDateTimeMin] = endDateTime.split(':').map((e) => parseInt(e));
                    newEndDate.setHours(endDateTimeHours, endDateTimeMin);
                    updatedCourseMeeting.endDateTime = formatDateTime(newEndDate);
                }

                updatedCourseMeeting.location = generateLocation(updatedCourseMeeting);

                debouncedMutateCourseMeetings();

                dispatch(putCourseMeetingRequest(updatedCourseMeeting));
            });
        }

        dispatch(closeCourseMeetingForm());

        // Clear selected course meetings
        setSelectedCourseMeetingIDs([]);
    };

    /**
     * Dispatches sagas on course form save
     * @param formProps
     */
    const handleEditCourseFormSave = (formProps: ICourseCanvasData | ICourseMetaData) => {
        const updateCourseProps = { ...focusedCourse, ...formProps } as ICourse;
        const updatedCourse = Course(updateCourseProps);
        putCourseRequest(updatedCourse);
        mutateCourse(updatedCourse, false);
        mutateCourses([...confirmedCourses, updatedCourse], true);
        handleClosePopover();
    };

    /**
     * Return initial form data for the course meeting bulk edit form
     * @param selectedCourseMeetingIDs
     * @returns ICourseMeetingData - the initial form data
     */
    const bulkEditCourseMeetingInitialValues = (selectedCourseMeetingIDs: string[]): ICourseMeetingData => {
        const selectedCourseMeetings = courseMeetings.filter((m) => selectedCourseMeetingIDs.includes(m.eventID));

        const valueDifferenceCount = (values: string[]) => {
            return Array.from(new Set(values)).length;
        };

        const initialValues: { [key: string]: string | undefined | ICourseMeeting[] } = {
            startDateTime: '',
            endDateTime: '',
            notes: '',
            conflicts: selectedCourseMeetings.flatMap((m) => m.conflicts),
            building: undefined,
            roomNumber: undefined,
            recordingOption: undefined,
            meetingType: undefined,
        };

        Object.keys(initialValues).forEach((p) => {
            if (typeof initialValues[p] === 'undefined') {
                if (valueDifferenceCount(selectedCourseMeetings.map((m) => m[p]?.toString() || '')) === 1) {
                    initialValues[p] = selectedCourseMeetings[0][p]?.toString();
                    return;
                }
                initialValues[p] = '';
            }
        });

        return initialValues as ICourseMeetingData;
    };

    /**
     * Displays bulk course meeting edit form
     * @param selectedCourseMeetingIDs
     */
    const handleMeetingBulkEdit = (selectedCourseMeetingIDs: string[]) => {
        dispatch(
            showCourseMeetingForm(
                bulkEditCourseMeetingInitialValues(selectedCourseMeetingIDs),
                'bulk',
                'Bulk Edit Course Meetings',
            ),
        );
    };

    const handleCourseConfirmRequest = (courseToConfirm: ICourse) => {
        courseConfirmRequest(courseToConfirm);
        mutateCourse(
            {
                ...courseToConfirm,
                entityType: CONFIRMED_COURSE_ENTITYTPE,
                extronScheduled: true,
                createdBy: course?.createdBy || ''
            },
            false,
        );
        debouncedMutateCourses();
        debouncedMutateCourseMeetings();
    };

    // Error handling for SWR fetches
    useEffect(() => {
        handleSWRError(isRoomsError, 'rooms', dispatch);
        handleSWRError(isCoursesError, 'courses', dispatch);
        handleSWRError(isCourseMeetingsError, 'course meetings', dispatch);
        handleSWRError(isCourseError, 'course', dispatch);
    }, [isRoomsError, isCourseMeetingsError, isCoursesError, isCourseError]);

    // If the course is not found, return 404 page
    if (isCourseError) {
        return <Error404 />;
    }

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {focusedCourse ? (
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={2} alignItems="stretch" direction="row">
                        <Grid item xs={12}>
                            <Grid item container xs spacing={1} alignItems="center" direction="row">
                                <Grid item>
                                    <Typography variant="h5" component="h2">
                                        {focusedCourse?.courseID}: {focusedCourse?.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {isLoadingCourse ? (
                            <Grid item xs={3}>
                                <Skeleton
                                    variant="rect"
                                    width={330}
                                    height={900}
                                    animation="wave"
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </Grid>
                        ) : (
                            <MemoizedCourseDetailsSidebar
                                focusedCourse={focusedCourse}
                                bannerCourse={bannerCourses.find((c) => c.courseID === focusedCourse?.courseID)}
                                confirmedCourse={confirmedCourses.find((c) => c.courseID === focusedCourse?.courseID)}
                                isLoadingCourses={isLoadingCourses}
                                showPopover={handleShowPopover}
                                popoverProps={popoverProps}
                                handlePopoverClose={handleClosePopover}
                                handleEditCanvasFormSave={handleEditCourseFormSave}
                                bannerSyncCandidates={bannerSyncCandidates.includes(focusedCourse?.courseID as string)}
                                handleCourseConfirmRequest={handleCourseConfirmRequest}
                                meetingConflicts={hasConflicts}
                            />
                        )}

                        <Grid item xs={9}>
                            <Paper className={classes.paper} elevation={3}>
                                <Typography variant="h6" component="h3" gutterBottom color="primary">
                                    Meetings
                                </Typography>
                                {focusedCourse?.createdBy != 'banner' && (
                                    <div style={{ textAlign: 'right', paddingRight: '1rem' }}>
                                        <PermissionGate
                                            scopes={[SCOPES.canCreate]}
                                            errorProps={{ disabled: true, onClick: () => null }}
                                        >
                                            {!isLoadingRooms ? (
                                                <Fab
                                                    color="secondary"
                                                    aria-label="add"
                                                    className={classes.newItemFAB}
                                                    onClick={handleMeetingFormOpen}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            ) : (
                                                <></>
                                            )}
                                        </PermissionGate>
                                    </div>
                                )}
                                <Suspense fallback={<></>}>
                                    {courseMeetingForm.isOpen && courseMeetingForm.formType != 'bulk' && (
                                        <CourseMeetingForm
                                            isOpen={courseMeetingForm.isOpen}
                                            handleOpen={handleMeetingFormOpen}
                                            handleSave={handleMeetingFormSave}
                                            handleClose={handleMeetingFormCancel}
                                            rooms={rooms || []}
                                            selectedCourse={focusedCourse}
                                            initialValues={{ ...courseMeetingForm.initialValues }}
                                            formTitle={courseMeetingForm.formHeading}
                                        />
                                    )}
                                    {courseMeetingForm.isOpen && courseMeetingForm.formType === 'bulk' && (
                                        <BulkCourseMeetingForm
                                            isOpen={courseMeetingForm.isOpen}
                                            handleOpen={handleMeetingFormOpen}
                                            handleSave={handleBulkMeetingEditFormSave}
                                            handleClose={handleMeetingFormCancel}
                                            rooms={rooms || []}
                                            selectedMeetingCount={selectedCourseMeetingIDs.length}
                                            initialValues={{ ...courseMeetingForm.initialValues }}
                                            formTitle={courseMeetingForm.formHeading}
                                        />
                                    )}
                                </Suspense>
                                <div style={{ width: '100%' }}>
                                    <DataGrid
                                        getRowId={getRowID}
                                        rows={courseMeetings}
                                        columns={CourseDetailsGridColumnsFactory(focusedCourse?.createdBy)}
                                        pageSize={20}
                                        autoHeight={true}
                                        autoPageSize
                                        checkboxSelection
                                        loading={isLoadingCourseMeetings}
                                        disableSelectionOnClick={true}
                                        className={classes.dataGrid}
                                        components={{
                                            Toolbar: MemoizedCourseMeetingsCustomGridToolbar,
                                            Footer: MemoizedCourseMeetingsCustomFooter,
                                        }}
                                        componentsProps={{
                                            toolbar: {
                                                selectedCourseMeetingIDs: selectedCourseMeetingIDs,
                                                handleMeetingBulkEdit: handleMeetingBulkEdit,
                                            },
                                            footer: {
                                                selectedCourseMeetingIDs: selectedCourseMeetingIDs,
                                                handleMeetingBulkEdit: handleMeetingBulkEdit,
                                            },
                                        }}
                                        onRowSelected={handleCourseMeetingSelection}
                                        selectionModel={selectedCourseMeetingIDs}
                                        onSelectionModelChange={handleCourseMeetingSelectionModelChange}
                                    />
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <></>
            )}
        </main>
    );
};

const MapStateToProps = (state: AppState) => ({
    courseMeetingForm: state.app.courseMeetingForm,
    popoverProps: state.app.popover,
    user: state.user,
});

const MapDispatchToProps = (
    dispatch: Dispatch<CourseActionTypes | CourseMeetingActionTypes | RoomActionTypes | ApplicationActionTypes>,
) => ({
    putCourseRequest: (course: ICourse) => dispatch({ type: PUT_COURSE_REQUEST, payload: course }),
    putCourseMeetingsRequest: (courseMeeting: ICourseMeeting) =>
        dispatch({ type: PUT_COURSEMEETING_REQUEST, payload: courseMeeting }),
    createCourseMeetingRequest: (courseMeeting: ICourseMeeting) =>
        dispatch({ type: CREATE_COURSEMEETING_REQUEST, payload: courseMeeting }),
    showNewCourseMeetingForm: (
        initialValues: ICourseMeetingData,
        formType: 'new' | 'edit' | 'bulk',
        formHeading: string,
    ) => dispatch({ type: SHOW_COURSE_MEETING_FORM, payload: initialValues, formType, formHeading }),
    closeNewCourseMeetingFrom: () => dispatch({ type: CLOSE_COURSE_MEETING_FORM, payload: undefined }),
    showPopover: (popoverProps: IPopoverProps) => dispatch({ type: SHOW_POPOVER, payload: popoverProps }),
    closePopover: () => dispatch({ type: CLOSE_POPOVER, payload: undefined }),
    courseConfirmRequest: (course: ICourse) => dispatch({ type: COURSE_CONFIRM_REQUEST, payload: course }),
});

export default connect(MapStateToProps, MapDispatchToProps)(CourseDetailsContainer);
