import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { JSXElementConstructor, ReactElement } from 'react';
import { renderRecordingOptionsCell, renderEditControlCell } from './CourseDetailsGridRowRenderers';
import { parseLocation, formatDateTime, renderStatusCell } from '../Meeting/CourseMeetingGridRowRenderers';
import { MeetingTypeChip } from './MeetingTypeChip';
import Typography from '@material-ui/core/Typography';

// DataGrid column definitions
/**
 * Material-UI DataGrid column definition for confirmed course meetings
 */
export const ConfirmedCourseDetailsGridColumns: GridColDef[] = [
    {
        field: 'eventID',
        headerName: 'Event ID',
        hide: true,
        flex: 2,
    },
    {
        field: 'meetingType',
        headerName: 'Type',
        type: 'string',
        renderCell: (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
            const meetingType: string = params.value?.toString() || 'U';

            return <MeetingTypeChip meetingType={meetingType} />;
        },
        width: 50,
        align: 'center',
    },
    { field: 'startDateTime', headerName: 'Start', width: 135, type: 'dateTime', renderCell: formatDateTime },
    { field: 'endDateTime', headerName: 'End', width: 135, type: 'dateTime', renderCell: formatDateTime },
    {
        field: 'location',
        headerName: 'Location',
        width: 132,
        align: 'center',
        renderCell: (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
            const locationMap = parseLocation(params);
            return (
                <Typography color={params.row.conflicts?.length > 0 ? 'error' : 'inherit'}>
                    {['building', 'roomNumber'].map((f: string): string => locationMap[f]).join('-')}
                </Typography>
            );
        },
    },
    {
        field: 'recordingOption',
        headerName: 'Record Type',
        width: 200,
        align: 'center',
        renderCell: renderRecordingOptionsCell,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        flex: 1,
    },
    { field: 'status', headerName: 'Status', flex: 1, align: 'center', renderCell: renderStatusCell, sortable: false },
    {
        field: 'edit',
        headerName: ' ',
        renderCell: renderEditControlCell,
        flex: 0.5,
        align: 'right',
        disableColumnMenu: true,
        sortable: false,
    },
];

/**
 * Material-UI DataGrid column definition for banner course meetings
 */
export const BannerCourseDetailsGridColumns: GridColDef[] = [
    {
        field: 'meetingType',
        headerName: 'Type',
        type: 'string',
        renderCell: (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
            const meetingType: string = params.value?.toString() || 'U';

            return <MeetingTypeChip meetingType={meetingType} />;
        },
        flex: 0.5,
    },
    {
        field: 'startDateTime',
        headerName: 'Start',
        type: 'dateTime',
        renderCell: formatDateTime,
        cellClassName: 'bannerStartTime',
        flex: 1,
    },
    {
        field: 'endDateTime',
        headerName: 'End',
        type: 'dateTime',
        renderCell: formatDateTime,
        cellClassName: 'bannerEndTime',
        flex: 1,
    },
    {
        field: 'location',
        headerName: 'Location',
        renderCell: (params: GridCellParams): ReactElement<string | JSXElementConstructor<string>> => {
            const locationMap = parseLocation(params);
            return (
                <Typography color={params.row.conflicts?.length > 0 ? 'error' : 'inherit'}>
                    {['building', 'roomNumber'].map((f: string): string => locationMap[f]).join('-')}
                </Typography>
            );
        },
        cellClassName: 'bannerLocation',
        flex: 1,
    },
];

/**
 * Returns a GridColDef[] for a specific course entity type
 *
 * @param entityType
 * @returns
 */
export const CourseDetailsGridColumnsFactory = (entityType: string | undefined): GridColDef[] => {
    switch (entityType) {
        case 'banner':
            return BannerCourseDetailsGridColumns;

        default:
            return ConfirmedCourseDetailsGridColumns;
    }
};
