import React, { ReactElement, useEffect } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme, Theme, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useHistory } from 'react-router-dom';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import { PermissionGate } from './PermissionGate';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(8),
            },
        },
    }),
);

export interface INavigationItem {
    item: string;
    route: string;
    requiredScopes: string[];
    iconComponent: ReactElement;
}

export interface IAppDrawerProps {
    handleDrawerClose: () => void;
    open: boolean;
    appDrawerItems: INavigationItem[];
    userRoles: string[];
}

const AppDrawer: React.FC<IAppDrawerProps> = (props) => {
    const { open, appDrawerItems, handleDrawerClose } = props;

    const classes = useStyles();

    const historyHook = useHistory();

    const [selectedRoute, setSelectedRoute] = React.useState(historyHook.location.pathname);

    const theme = useTheme();

    const handleListItemClick = (route: string): void => {
        if (route?.length > 0) {
            setSelectedRoute(route);
            historyHook.push(`${route}`);
        }
    };

    useEffect(() => {
        // The base route displays the dashboard
        // this makes sure the navbar Dashboard link is selected in that case
        if (selectedRoute === '/') {
            setSelectedRoute('/Dashboard');
        }
    });

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                {appDrawerItems.map((navItem) => {
                    const { item, route, iconComponent, requiredScopes } = navItem;
                    return (
                        <PermissionGate scopes={requiredScopes} key={item}>
                            <ListItem
                                button
                                key={item}
                                onClick={() => handleListItemClick(route)}
                                selected={selectedRoute === route}
                            >
                                <ListItemIcon>{iconComponent}</ListItemIcon>
                                <ListItemText primary={item.toLocaleUpperCase()} />
                            </ListItem>
                        </PermissionGate>
                    );
                })}
            </List>
        </Drawer>
    );
};

export default AppDrawer;
