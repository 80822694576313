//Redux User action types

export const FETCH_USER_ACCESS_REQUEST = 'FETCH_USER_ACCESS_REQUEST';
export const FETCH_USER_ACCESS_SUCCESS = 'FETCH_USER_ACCESS_SUCCESS';
export const FETCH_USER_ACCESS_FAILURE = 'FETCH_USER_ACCESS_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UNAUTHORIZED_USER = 'UNAUTHORIZED_USER';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_TOKEN_COOKIES_REQUEST = 'SET_TOKEN_COOKIES_REQUEST';
export const SET_TOKEN_COOKIES_SUCCESS = 'SET_TOKEN_COOKIES_SUCCESS';
export const SET_TOKEN_COOKIES_FAILURE = 'SET_TOKEN_COOKIES_FAILURE';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
