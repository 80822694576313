import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { GridRowData, GridRowId } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';

interface IServiceRequestCancelButtonProps {
    handleCancel: () => void;
    disabled: boolean;
    selectedRequests: Map<GridRowId, GridRowData>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cancelButtonBox: {
            marginRight: 'auto',
        },
        cancelButton: {
            '&:hover': {
                background: theme.palette.error.main,
            },
            color: theme.palette.error.contrastText,
            background: theme.palette.error.dark,
        },
    }),
);

export const ServiceRequestCancelButton = (props: IServiceRequestCancelButtonProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Box m={1} className={classes.cancelButtonBox}>
            <Button
                className={classes.cancelButton}
                onClick={props.handleCancel}
                disabled={props.disabled}
                color="default"
                variant="contained"
                startIcon={<DeleteIcon color={props.disabled ? 'disabled' : 'inherit'} />}
            >
                Cancel {props.selectedRequests.size > 0 ? props.selectedRequests.size : null} Request
                {props.selectedRequests.size > 1 ? 's' : null}
            </Button>
        </Box>
    );
};
