import React, { forwardRef, ReactElement } from 'react';

import { Tooltip } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import PermCameraMicIcon from '@material-ui/icons/PermCameraMic';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import BlockIcon from '@material-ui/icons/Block';

import { RECORDING_OPTIONS_ENUM } from '../../constants';

interface IRecordTypeButtonGroupProps {
    handleOption: (event: React.MouseEvent<HTMLElement>, newOption: string) => void;
    disabledOptions?: string[];
    selectedOption: string;
    disabled?: boolean;
}

export const RecordTypeIcon = forwardRef(
    (
        props: {
            recordType: string;
            color: 'action' | 'disabled' | 'inherit' | 'primary' | 'secondary';
        },
        ref,
    ) => {
        const { recordType, color } = props;
        const recordTypeIconMap: { [key: string]: ReactElement } = {
            cancelled: <BlockIcon color={color} innerRef={ref} />,
            'audio only': <GraphicEqIcon color={color} innerRef={ref} />,
            'camera and audio': <PermCameraMicIcon color={color} innerRef={ref} />,
            'content and audio': <RecordVoiceOverIcon color={color} innerRef={ref} />,
            'full service': <PlaylistAddCheckIcon color={color} innerRef={ref} />,
        };
        return (
            (
                <Tooltip title={recordType.toLocaleUpperCase()} key={`${recordType}-tooltip`}>
                    {recordTypeIconMap[recordType]}
                </Tooltip>
            ) || <></>
        );
    },
);

export const RecordTypeButtonGroup: React.FC<IRecordTypeButtonGroupProps> = (props) => {
    const defaultOptions = Object.keys(RECORDING_OPTIONS_ENUM).map((option) => RECORDING_OPTIONS_ENUM[option].value);
    const { handleOption, disabledOptions = [], selectedOption, disabled = false } = props;

    const iconColor = (disabled: boolean) => (disabled ? 'disabled' : 'action');

    const handleOptionChange = (event: React.MouseEvent<HTMLElement>, newOption: string) => {
        if (disabled) return;
        handleOption(event, newOption);
    };

    return (
        <ToggleButtonGroup
            color="default"
            size="small"
            exclusive
            aria-label="Recording type button group"
            onChange={handleOptionChange}
            value={selectedOption}
        >
            {defaultOptions.map((option) => (
                <ToggleButton
                    key={`${option}-toggleButton`}
                    value={option}
                    aria-label={option}
                    disabled={disabledOptions.includes(option) || disabled}
                >
                    <RecordTypeIcon
                        recordType={option}
                        color={iconColor(disabledOptions.includes(option) || disabled)}
                    />
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};
