import { createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { KurentoService } from '../../services/KurentoService';
import { IKurentoService, IKurentoServiceProps } from '../../services/KurentoServiceInterfaces';
import { logger } from '../../services/Logger';

interface IKurentoPlayerProps {
    rtspURI: string;
    wsURI: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        kurentoVideoEl: {
            display: 'flex',
            margin: '0 auto',
            background: 'white',
            maxWidth: '100%',
            height: 'auto',
        },
    }),
);

const KurentoPlayer: React.FC<IKurentoPlayerProps> = (props: IKurentoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const isKurentoStarted = useRef(false);
    let ks: IKurentoService;

    const { rtspURI, wsURI } = props;

    const classes = useStyles();

    useEffect(() => {
        if (!ks && videoRef.current) {
            const kurentoServiceConfig: IKurentoServiceProps = {
                options: {
                    remoteVideo: videoRef.current,
                    mediaConstraints: {
                        audio: true,
                        video: {
                            width: 848,
                            framerate: 15,
                        },
                    },
                },
                rtspURI,
                wsURI,
            };
            logger.info('Init Kurento Service');
            ks = KurentoService(kurentoServiceConfig);
            isKurentoStarted.current = true;
        }

        return () => {
            isKurentoStarted.current = false;
        };
    }, [rtspURI, videoRef.current]);

    // Release the MediaPipeline before nav or window close
    useEffect(() => {
        const onBeforeUnload = () => {
            ks.killstream();
            isKurentoStarted.current = false;
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    return (
        <>
            <video
                ref={videoRef}
                key={rtspURI}
                autoPlay={true}
                muted={false}
                controls
                width="896px"
                height="524px"
                className={classes.kurentoVideoEl}
            />
        </>
    );
};

export default KurentoPlayer;
