import { CaptureControlPanel } from './../components/Capture/CaptureControlPanel';
import { Card, CircularProgress, Dialog, FormControl, TextField, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import { CAMERA_PROXY_MJPEG_PATH, KURENTO_WS_URI } from '../constants';
import { formatCameraProxyURL, formatRTSPEndpoint, handleSWRError } from '../utils/helpers';
import React, { memo, useEffect, useRef, useState } from 'react';
import KurentoPlayer from '../components/Argus/KurentoPlayer';
import { useRooms } from '../hooks/useRooms';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab';
import { extronSseSubscribe, extronSseUnsubscribe } from '../redux/extronSSE/extronSseActions';
import { AppState } from '../redux/AppState';
import { MJPEGStreamViewer } from '../components/MJPEGStreamViewer/MJPEGStreamViewer';
import { IRoom } from '../redux/room/roomInterfaces';
import VideocamIcon from '@material-ui/icons/Videocam';
import { CameraPresets } from '../components/Capture/CameraPresets';

import { styles } from './styles/CaptureContainerStyles';
import {
    autoFocusQueryRequestAction,
    deselectCameraAction,
    issueCameraPresetRequestAction,
    issueOtafRequestAction,
    rebootCameraRequestAction,
    selectCameraAction,
    setAutoFocusRequestAction,
} from '../redux/camera/cameraActions';
import { logger } from '../services/Logger';
import { showSnackbar } from '../redux/application/applicationActions';
import { Snack } from '../factories/Snackbar';
import { DialogTitle } from '../components/Capture/DialogTitle';
import { IRecorderState } from '../redux/extronSSE/extronSseInterfaces';
import { ExtronCommandRequest } from '../redux/extron/extronInterfaces';
import { sendExtronCommandRequestAction } from '../redux/extron/extronActions';

const KurentoPlayerMemo = memo(KurentoPlayer);

const useStyles = makeStyles(styles);

export const CaptureContainer: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { recorders, sseOnline } = useSelector((state: AppState) => state.recorders);
    const { camera, isCameraRebooting, isIssuingPreset, cameraError, presetRequest, autoFocusEnabled } = useSelector(
        (state: AppState) => state.camera,
    );

    const { rooms, isLoadingRooms, isRoomsError } = useRooms(true);
    const [selectedRoom, setSelectedRoom] = useState<IRoom | null>(null);
    const [recorderMode, setRecorderMode] = useState('');
    const [recorderInput, setRecorderInput] = useState('input1');

    const roomSelectInputRef = useRef<HTMLInputElement>(null);

    const [spitterSizes, setSplitterSizes] = useState([50, 50]);

    useEffect(() => {
        // subscribe to event source when component mounts.
        dispatch(extronSseSubscribe());
        // unsubscribe to event source while component unmount.
        return () => {
            dispatch(extronSseUnsubscribe());
        };
    }, []);

    // Handle SWR room error
    useEffect(() => {
        handleSWRError(isRoomsError, 'rooms', dispatch);
    }, [isRoomsError]);

    // Handle camera API errors
    useEffect(() => {
        if (cameraError) {
            logger.error(`Camera error: ${cameraError}`);
            dispatch(showSnackbar(Snack(`${cameraError}`, 'error')));
        }
    }, [cameraError]);

    // Set the splitter sizes based on if the selected room has a camera or not.
    useEffect(() => {
        if (selectedRoom?.camera) {
            setSplitterSizes([75, 25]);
        }
        if (selectedRoom && !selectedRoom.camera) {
            setSplitterSizes([10, 90]);
        }
    }, [selectedRoom]);

    // When a camera has been selected, issue the auto focus query
    useEffect(() => {
        if (camera) {
            dispatch(autoFocusQueryRequestAction({ cameraIP: camera.ip }));
        }
    }, [camera]);

    /**
     * Handles the room change event.
     * @param event
     * @param value
     * @param reason
     */
    const handleRoomChange = (event: React.ChangeEvent<unknown>, value: IRoom | null, reason: string) => {
        // If the user clears the room selection, reset the splitter sizes.
        if (reason === 'clear') {
            setSplitterSizes([50, 50]);
        }
        const selectedRoom = rooms?.find((room) => room.id === value?.id);
        if (selectedRoom?.camera) {
            dispatch(selectCameraAction(selectedRoom.camera));
        }
        setSelectedRoom(selectedRoom || null);
    };

    /**
     * Handles the recorder mode change.
     * @param event
     * @param newMode
     */
    const handleRecorderModeChange = (event: React.MouseEvent<HTMLElement>, newMode: string | null) => {
        if (newMode) {
            handleExtronCommandRequest(newMode);
            setRecorderMode(newMode);
        } else {
            handleExtronCommandRequest(recorderMode);
        }
    };

    /**
     * Handles the recorder input change.
     * @param event
     * @param newInput
     */
    const handleRecorderInputChange = (event: React.MouseEvent<HTMLElement>, newInput: string | null) => {
        logger.debug(newInput);
        if (newInput) {
            handleExtronCommandRequest(newInput);
            setRecorderInput(newInput);
        }
    };

    /**
     * Handles creating the ExtronCommandRequest and dispatching an sendExtronCommandRequestAction action.
     * @param request
     * @param type
     */
    const handleExtronCommandRequest = (request: string, type = 'extronCommandRequest') => {
        if (selectedRoom?.extron?.ip) {
            const extronCommandRequest: ExtronCommandRequest = {
                request,
                type,
                host: selectedRoom.extron.ip,
                recorderName: selectedRoom.extron.name,
            };
            dispatch(sendExtronCommandRequestAction(extronCommandRequest));
        }
    };

    /**
     * Handles the camera preset change event.
     * @param event
     * @param newPreset
     */
    const handleCameraPresetChange = (event: React.MouseEvent<HTMLElement>, newPreset: string | null) => {
        if (newPreset && camera) {
            logger.debug(`Issuing camera preset: ${newPreset}`);
            dispatch(issueCameraPresetRequestAction({ cameraIP: camera.ip, preset: newPreset }));
        }
    };

    /**
     * Handles the camera reboot event.
     */
    const handleCameraReboot = () => {
        if (camera && confirm('Are you sure you want to reboot the camera?')) {
            logger.debug('Rebooting camera...');
            dispatch(rebootCameraRequestAction({ cameraIP: camera.ip }));
            if (selectedRoom?.camera) {
                dispatch(deselectCameraAction());
            }
            setTimeout(() => {
                logger.debug('Setting selected camera');
                dispatch(selectCameraAction(camera));
            }, 40000);
        }
    };

    const handleDialogClose = () => {
        dispatch(deselectCameraAction());
        setSelectedRoom(null);
    };

    /**
     * Handles the dialog close event.
     * @param event
     * @param reason
     */
    const onDialogClose = (event: React.ChangeEvent<unknown>, reason: string) => {
        if (reason !== 'backdropClick') {
            dispatch(deselectCameraAction());
            setSelectedRoom(null);
        }
    };

    /**
     * Handles the camera auto focus event.
     */
    const handleCameraAutoFocus = () => {
        if (camera) {
            dispatch(setAutoFocusRequestAction({ cameraIP: camera.ip, autoFocus: !autoFocusEnabled }));
        }
    };

    /**
     * Handles the camera One-touch autofocus event.
     */
    const handleCamerOTAFClick = () => {
        if (camera) {
            dispatch(issueOtafRequestAction({ cameraIP: camera.ip }));
        }
    };

    /**
     * Returns true if the room is currently recording.
     * @param room
     * @param recorders
     * @returns boolean
     */
    const getRoomRecordingStatus = (
        room: IRoom | null,
        recorders: { [key: string]: IRecorderState } | null,
    ): boolean => {
        return (
            (room && recorders && recorders[room.extron.ip] && recorders[room.extron.ip].view_record_status === '1') ||
            false
        );
    };

    /**
     * The hardware controller is divided into 3 groups of 10 cameras each.
     * @param cameraDescription
     * @returns
     */
    const getCameraGroup = (cameraDescription: string) => {
        const cameraNumber = parseInt(cameraDescription);
        return cameraNumber > 0 ? Math.ceil(cameraNumber / 10) : null;
    };

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1>Capture</h1>
                        <FormControl variant="outlined" style={{ marginBottom: '1rem' }}>
                            <Autocomplete
                                blurOnSelect
                                id="room-select"
                                aria-label="Select a Room"
                                options={rooms || []}
                                groupBy={(option) => option.building}
                                getOptionLabel={(room) => room.name}
                                value={selectedRoom || null}
                                onChange={handleRoomChange}
                                style={{ width: 200 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a room"
                                        variant="outlined"
                                        inputRef={roomSelectInputRef}
                                    />
                                )}
                            />
                        </FormControl>
                        <Dialog
                            fullWidth={true}
                            maxWidth="lg"
                            open={selectedRoom !== null}
                            className={classes.dialog}
                            onClose={onDialogClose}
                            PaperProps={{ style: { backgroundColor: '#fff' } }}
                        >
                            <>
                                <DialogTitle onClose={handleDialogClose} id="capture-dialog">
                                    {selectedRoom?.name}
                                </DialogTitle>
                                <Paper className={classes.paper} elevation={6}>
                                    {getRoomRecordingStatus(selectedRoom, recorders) && (
                                        <Alert
                                            severity="error"
                                            iconMapping={{ error: <VideocamIcon fontSize="inherit" /> }}
                                            className={classes.roomAlert}
                                        >
                                            Recording in Progress
                                        </Alert>
                                    )}

                                    {!sseOnline && (
                                        <Alert
                                            severity="info"
                                            iconMapping={{ info: <CircularProgress size={22} /> }}
                                            className={classes.roomAlert}
                                        >
                                            <Typography variant="subtitle2">Connecting to Extron events...</Typography>
                                        </Alert>
                                    )}

                                    <Splitter
                                        direction={SplitDirection.Horizontal}
                                        gutterClassName={classes.gutter}
                                        minWidths={[300, 300]}
                                        initialSizes={spitterSizes}
                                        onResizeFinished={(pairIdx, sizes) => setSplitterSizes(sizes)}
                                    >
                                        <Card elevation={0} className={classes.splitterCard}>
                                            {!selectedRoom || isLoadingRooms ? (
                                                <Skeleton
                                                    animation={false}
                                                    variant="rect"
                                                    width={300}
                                                    height={507}
                                                    style={{ margin: '0 auto' }}
                                                ></Skeleton>
                                            ) : (
                                                <>
                                                    {selectedRoom.camera && (
                                                        <Typography variant="button" className={classes.cameraHeading}>
                                                            {`Camera ${
                                                                selectedRoom.camera.description
                                                            }, Group ${getCameraGroup(
                                                                selectedRoom.camera.description,
                                                            )}`}
                                                        </Typography>
                                                    )}
                                                    <MJPEGStreamViewer
                                                        mjpegStreamEndpoint={formatCameraProxyURL({
                                                            cameraIP: camera ? camera.ip : '',
                                                            path: CAMERA_PROXY_MJPEG_PATH,
                                                        })}
                                                    />
                                                </>
                                            )}
                                        </Card>
                                        <Card elevation={0} className={classes.splitterCard}>
                                            {!selectedRoom || isLoadingRooms ? (
                                                <Skeleton
                                                    animation={false}
                                                    variant="rect"
                                                    width={896}
                                                    height={507}
                                                    style={{ margin: '0 auto' }}
                                                ></Skeleton>
                                            ) : (
                                                <KurentoPlayerMemo
                                                    rtspURI={formatRTSPEndpoint(selectedRoom.extron.ip)}
                                                    wsURI={KURENTO_WS_URI}
                                                />
                                            )}
                                        </Card>
                                    </Splitter>
                                </Paper>
                                {selectedRoom && (
                                    <CaptureControlPanel
                                        selectedRoom={selectedRoom}
                                        cameraPresets={CameraPresets(classes)}
                                        recorders={recorders}
                                        camera={camera}
                                        presetRequest={presetRequest}
                                        isIssuingPreset={isIssuingPreset}
                                        recorderMode={recorderMode}
                                        recorderInput={recorderInput}
                                        autoFocusEnabled={autoFocusEnabled}
                                        isCameraRebooting={isCameraRebooting}
                                        handleCameraAutoFocus={handleCameraAutoFocus}
                                        handleCamerOTAFClick={handleCamerOTAFClick}
                                        handleRecorderInputChange={handleRecorderInputChange}
                                        handleRecorderModeChange={handleRecorderModeChange}
                                        handleCameraPresetChange={handleCameraPresetChange}
                                        handleCameraReboot={handleCameraReboot}
                                    />
                                )}
                            </>
                        </Dialog>
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
};
