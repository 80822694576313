import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, makeStyles } from '@material-ui/styles';
import KurentoPlayer from '../components/Argus/KurentoPlayer';
import { KURENTO_WS_URI } from '../constants';
import { formatRTSPEndpoint } from '../utils/helpers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useRooms } from '../hooks/useRooms';
import Skeleton from '@material-ui/lab/Skeleton';
import { handleSWRError } from '../utils/helpers';

const KurentoPlayerMemo = memo(KurentoPlayer);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        newItemFAB: {
            margin: '0 0 -1.2rem 0',
            zIndex: 99,
        },
    }),
);

export const RoomViewerContainer: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { rooms, isLoadingRooms, isRoomsError } = useRooms(true);
    const [selectedRoomIP, setSelectedRoomIP] = useState<string>('0');

    useEffect(() => {
        handleSWRError(isRoomsError, 'rooms', dispatch);
    }, [isRoomsError]);

    const handleRoomChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (selectedRoomIP) setSelectedRoomIP(`${event.target.value}`);
    };

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container alignItems="stretch" direction="row" spacing={0}>
                    <Grid item sm={12}>
                        <h1>All Room Viewer</h1>
                        <FormControl variant="outlined" style={{ marginBottom: '1rem' }}>
                            <Select
                                labelId="room-select-label"
                                id="room-select"
                                value={selectedRoomIP}
                                onChange={handleRoomChange}
                            >
                                <MenuItem value="0">Select a Room</MenuItem>
                                {rooms?.map((room) => (
                                    <MenuItem value={room.extron.ip} key={room.id}>{room.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Paper className={classes.paper}>
                            <Grid container spacing={0}>
                                <Grid item sm={12}>
                                    {selectedRoomIP === '0' || isLoadingRooms ? (
                                        <Skeleton
                                            animation={false}
                                            variant="rect"
                                            width={896}
                                            height={507}
                                            style={{ margin: '0 auto' }}
                                        />
                                    ) : (
                                        <KurentoPlayerMemo
                                            rtspURI={formatRTSPEndpoint(selectedRoomIP)}
                                            wsURI={KURENTO_WS_URI}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
};
