import { Avatar, createStyles, IconButton, List, ListItem, makeStyles, Theme, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FormEvent, useState } from 'react';
import { AppState } from '../../redux/AppState';
import { useSelector } from 'react-redux';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { ICourseNote } from '../../redux/course/courseInterfaces';

interface ICourseNotesProps {
    notes: string;
    handleNotesSave: (notes: string) => void;
}

interface IRenderNotesProps {
    notes: ICourseNote[];
    handleDelete: (noteCreated: string) => void;
}

interface NoteListFormElements extends HTMLFormControlsCollection {
    noteCreated: HTMLInputElement;
}

interface NotesListFormElement extends HTMLFormElement {
    readonly elements: NoteListFormElements;
}

interface NewNoteFormElements extends HTMLFormControlsCollection {
    note: HTMLInputElement;
}

interface NewNoteFormElement extends HTMLFormElement {
    readonly elements: NewNoteFormElements;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notesContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            margin: '0 0 1.2rem 0',
        },
        listItemBox: {
            padding: '0',
            minHeight: '1rem',
            width: '100%',
            display: 'block',
        },
        submitBtn: {
            color: theme.palette.info.main,
        },
        deleteBtn: {
            color: theme.palette.grey[400],
            '&:hover': {
                color: theme.palette.error.main,
            },
        },
        noteBox: {
            margin: '0 0 0 1rem',
            flex: '2',
        },
        noteTextfield: {
            flex: '2',
        },
        addNoteForm: {
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            marginRight: '-.5rem',
        },
        avatar: {
            width: '1.5rem',
            height: '1.5rem',
        },
        hiddenField: {
            visibility: 'hidden',
            position: 'absolute',
        },
    }),
);

const RenderNotes: React.FC<IRenderNotesProps> = (props) => {
    const classes = useStyles();
    const { notes, handleDelete } = props;

    const handleDeleteEvent = (event: FormEvent<NotesListFormElement>) => {
        event.preventDefault();
        const { noteCreated } = event.currentTarget.elements;
        handleDelete(noteCreated.value);
    };

    return (
        <>
            {notes.map((note: ICourseNote, index: number) => (
                <Box className={classes.notesContainer} component="form" onSubmit={handleDeleteEvent} key={index}>
                    <Tooltip title={note.user}>
                        <Avatar alt={note.user} className={classes.avatar}>
                            {note.user[0].toUpperCase()}
                        </Avatar>
                    </Tooltip>
                    <Box className={classes.noteBox}>
                        <Typography variant="subtitle2">
                            {new Date(note.created).toLocaleDateString('en-us')}
                        </Typography>
                        <Typography>{note.note}</Typography>
                    </Box>
                    <TextField name="noteCreated" value={note.created} className={classes.hiddenField} />
                    <IconButton color="default" className={classes.deleteBtn} size="small" type="submit">
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            ))}
        </>
    );
};

const NewNoteForm: React.FC<{ handleSubmitNewNote: (event: FormEvent<NewNoteFormElement>) => void }> = ({
    handleSubmitNewNote,
}) => {
    const classes = useStyles();
    return (
        <Box component="form" className={classes.addNoteForm} onSubmit={handleSubmitNewNote}>
            <TextField variant="outlined" size="small" className={classes.noteTextfield} name="note" />
            <IconButton color="primary" className={classes.submitBtn} size="medium" type="submit" edge="start">
                <RateReviewIcon />
            </IconButton>
        </Box>
    );
};

export const CourseNotes: React.FC<ICourseNotesProps> = (props) => {
    const { notes, handleNotesSave } = props;

    const noteData = JSON.parse(notes);

    const classes = useStyles();

    const [noteState, setNoteState] = useState(noteData);

    const userName = useSelector((state: AppState) => state?.user?.username);

    const handleSubmitNewNote = (event: FormEvent<NewNoteFormElement>) => {
        event.preventDefault();
        const { note } = event.currentTarget.elements;
        const newNote: ICourseNote = {
            user: userName,
            created: new Date().toISOString(),
            note: note.value,
        };
        note.value = '';
        setNoteState([...noteState, newNote]);
        handleNotesSave(JSON.stringify([...noteState, newNote]));
    };

    const handleDeleteNote = (noteDate: string) => {
        const newNoteState = noteState.filter((note: ICourseNote) => note.created != noteDate);
        setNoteState(newNoteState);
        handleNotesSave(JSON.stringify(newNoteState));
    };

    return (
        <>
            <Box pt={2}>
                <Typography variant="h6" component="h3" gutterBottom={false} color="primary">
                    Notes
                </Typography>
                <List>
                    <ListItem style={{ margin: 'inherit 0' }}>
                        <Box className={classes.listItemBox}>
                            <RenderNotes notes={noteState} handleDelete={handleDeleteNote} />
                            <NewNoteForm handleSubmitNewNote={handleSubmitNewNote} />
                        </Box>
                    </ListItem>
                </List>
            </Box>
        </>
    );
};
