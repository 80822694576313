import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

interface ICourseMeetingBulkEditButtonProps {
    handleClick: () => void;
}

export const CourseMeetingBulkEditButton: React.FC<ICourseMeetingBulkEditButtonProps> = (props) => {
    const { handleClick } = props;
    return (
        <Button startIcon={<EditIcon />} size="small" color="primary" onClick={handleClick}>
            Bulk Edit
        </Button>
    );
};
