import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { EXTRON_ERROR_CODES } from '../../constants';
import { Theme } from '@material-ui/core/styles';
import { ExtronErrorChip } from './ExtronErrorChip';

interface IVolumeLevelProps {
    volume?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    blocker: {
        height: '100%',
        marginLeft: 'auto',
        background: '#f1f1f1',
        transitionProperty: 'width',
        transitionDuration: '.5s',
        transitionTimingFunction: 'ease',
        transformOrigin: 'right',
        transform: 'scaleX(1)',
    },
    blockerOn: {
        animation: `$audioOn 783ms ease-in-out`,
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        width: '65%',
    },
    blockerOff: {
        transition: 'width .5s ease-in-out',
        width: '100%',
    },
    errorChip: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    '@keyframes audioOn': {
        '0%': {
            transform: `scaleX(.22)`,
        },
        '27%': {
            transform: `scaleX(.20)`,
        },
        '100%': {
            transform: `scaleX(.28)`,
        },
    },
    '@keyframes audioOff': {
        '0%': {
            transform: 'scaleX(.23)',
        },
        '100%': {
            transform: 'scaleX(1)',
        },
    },
}));

const VolumeLevel: React.FC<IVolumeLevelProps> = (params: IVolumeLevelProps) => {
    const { volume } = params;

    const classes = useStyles();

    if (!volume || volume === 'Offline') {
        return <Typography style={{ color: 'red' }}>Offline</Typography>;
    }

    if (volume && Object.keys(EXTRON_ERROR_CODES).includes(volume)) {
        return (
            <ExtronErrorChip extronErrorCode={volume} />
        );
    }

    return (
        <div className="wrapper" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div
                className="volumeContainer"
                style={{
                    flex: 1,
                    height: '1.2rem',
                    border: '1px solid #c0d5e4',
                    background:
                        'linear-gradient(90deg, rgba(36,217,0,1) 0%, rgba(255,231,0,1) 80%, rgba(233,0,0,1) 100%)',
                }}
            >
                <div className={`${classes.blocker} ${volume === 'Audio' ? classes.blockerOn : classes.blockerOff}`} />
            </div>
        </div>
    );
};

export default VolumeLevel;
