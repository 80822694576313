import { ServiceStatuses } from './redux/serviceRequest/serviceRequestTypes';
import { LogLevel } from './services/Logger';

export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'dev';
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
export const LOG_LEVEL: LogLevel = (process.env.REACT_APP_LOG_LEVEL as LogLevel) || 'debug';
export const APPLICATION_NAME = process.env.REACT_APP_APP_NAME || 'LOCAL IET Lecture Capture Administration';
export const BASE_URL = process.env.REACT_APP_LC_ADMIN_API_URL || '';
export const CAS_URL = process.env.REACT_APP_CASURL || '';
export const ROOM_API_URL = process.env.REACT_APP_ROOM_API_URL || '';
export const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL || '';
export const KURENTO_WS_URI =
    process.env.REACT_APP_KURENTO_WS_URI || 'wss://devkurento.lecturecapture.ucdavis.edu:8443/kurento';

export const CAMERA_PROXY_DOMAIN = process.env.REACT_APP_CAMERA_PROXY_DOMAIN || 'localhost';
export const CAMERA_PROXY_MJPEG_PATH = process.env.REACT_APP_CAMERA_PROXY_MJPEG_PATH || '/mjpeg';
export const CAMERA_PROXY_PRESET_PATH = process.env.REACT_APP_CAMERA_PROXY_PRESET_PATH || '/preset';
export const CAMERA_PROXY_REBOOT_PATH = process.env.REACT_APP_CAMERA_PROXY_REBOOT_PATH  || '/reboot';
export const CAMERA_PROXY_AUTOFOCUS_PATH = process.env.REACT_APP_CAMERA_PROXY_AUTOFOCUS_PATH || '/autofocus';

export const EXTRON_SSE_URL = process.env.REACT_APP_EXTRON_SSE_URL || 'https://localhost:8443/sse/extron';
export const GCAL_POLLING_INTERVAL = 60 * 1000; //google calendar endpoint polling interval in ms

export const AUTH_COOKIE_NAME = 'edu.ucdavis.lecturecapture.lecturecaptureadmin.authenticated';
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || 'localhost';
export const TERM_SELECT_PRIOR_TERM_COUNT = 4;
export const KEY_SEPARATOR = ':';
export const CONFIRMED_COURSE_ENTITYTPE = 'course-confirmed-v0';
export const BANNER_COURSE_ENTITYTYPE = 'course-banner-v0';
export const BANNER_COURSE_PATH = '/Courses/banner/';
export const CONFIRMED_COURSE_PATH = '/Courses/confirmed/';

export const DEFAULT_REQUEST_PARAMS = {
    'Content-Type': 'application/json; charset=UTF-8',
};

export const RECORDING_OPTIONS_ENUM: { [key: string]: { value: string; html: string; sortOrder: number } } = {
    cancelled: { value: 'cancelled', html: 'Cancelled', sortOrder: 99 },
    audioOnly: { value: 'audio only', html: 'Audio only', sortOrder: 4 },
    cameraAndAudio: { value: 'camera and audio', html: 'Camera & Audio', sortOrder: 2 },
    contentAndAudio: { value: 'content and audio', html: 'Content & Audio', sortOrder: 3 },
    fullService: { value: 'full service', html: 'Full service', sortOrder: 1 },
};

export const USER_ROLE = 'USER';
export const ADMIN_ROLE = 'ADMIN';
export const VIEWER_ROLE = 'VIEWER';

export const ALL_APPLICATION_ROLES = [ADMIN_ROLE, USER_ROLE, VIEWER_ROLE];

export const ROLES = {
    admin: ADMIN_ROLE,
    user: USER_ROLE,
    viewer: VIEWER_ROLE,
};

export const SCOPES = {
    canCreate: 'can-create',
    canView: 'can-view',
    canViewArgus: 'can-view-argus',
    canEdit: 'can-edit',
    canDelete: 'can-delete',
    canAdmin: 'can-admin',
    canViewStreams: 'can-view-streams',
};

export const PERMISSIONS = {
    [ROLES.admin]: [
        SCOPES.canAdmin,
        SCOPES.canCreate,
        SCOPES.canDelete,
        SCOPES.canEdit,
        SCOPES.canView,
        SCOPES.canViewArgus,
        SCOPES.canViewStreams,
    ],
    [ROLES.user]: [SCOPES.canView, SCOPES.canViewStreams, SCOPES.canViewArgus],
    [ROLES.viewer]: [SCOPES.canViewStreams, SCOPES.canView],
};

export const UC_EMPLOYEE_AFFILIATIONS = ['faculty', 'student', 'staff', 'taf', 'other'];

/**
 * Maps Banner meeting type to readable description
 */
export const COURSE_MEETING_TYPES: { [key: string]: string } = {
    A: 'Lecture',
    B: 'Lecture/Discussion',
    C: 'Laboratory',
    D: 'Discussion',
    E: 'Seminar',
    L: 'Lecture/Lab',
};

export const KALTURA_STATUSES: { [key: number]: { value: string; html: string } } = {
    0: { value: 'PENDING', html: 'Pending' },
    1: { value: 'QUEUED', html: 'Queued' },
    2: { value: 'PROCESSING', html: 'Processing' },
    3: { value: 'PROCESSED', html: 'Processed' },
    4: { value: 'MOVEFILE', html: 'Moving file' },
    5: { value: 'FINISHED', html: 'Finished' },
    6: { value: 'FAILED', html: 'Failed' },
    7: { value: 'ABORTED', html: 'Aborted' },
    8: { value: 'ALMOST_DONE', html: 'Almost done' },
    9: { value: 'RETRY', html: 'Retry' },
    10: { value: 'FATAL', html: 'Fatal error' },
    11: { value: 'DONT_PROCESS', html: 'Don&apos;t process' },
    12: { value: 'FINISHED_PARTIALLY', html: 'Finished partially' },
    13: { value: 'SUSPEND', html: 'Suspended job' },
    14: { value: 'SUSPEND_ALMOST_DONE', html: 'Suspended job almost done' },
};

export const daysOfWeek: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

// Sort order for characters in UCD Registar course days code.  ie. MWF, TR
export const daySortMap: { [key: string]: number } = { M: 1, T: 2, W: 3, R: 4, F: 5, S: 6, X: 7 };

export const dataGridRowsPerPage = [20, 40, 60, 100];

// The query_front_panel_audio_levels should return values > than this value to be
// considered having an active speaker
export const EXTRON_ACTIVE_AUDIO_THRESHOLD = -450;

// Extron ssh command error code mapping
// pg 102, smp_300_series_68-2238-01_I.pdf
export const EXTRON_ERROR_CODES: { [key: string]: string } = {
    E10: 'Unrecognized command',
    E12: 'Invalid port number',
    E13: 'Invalid parameter (number is out of range)',
    E14: 'Not valid for this configuration',
    E17: 'Invalid command for signal type',
    E18: 'System timed out',
    E22: 'Busy',
    E24: 'Privilege violation',
    E26: 'Maximum connections exceeded',
    E28: 'Bad file name or file not found',
};

export const ServiceStatus: ServiceStatuses = {
    unscheduled: { value: 'UNSCHEDULED' },
    pending: { value: 'PENDING' },
    scheduled: { value: 'SCHEDULED' },
    cancelPending: { value: 'CANCELPENDING' },
    cancelled: { value: 'CANCELLED' },
};
