import { ServiceRequest } from "../../redux/serviceRequest/serviceRequestTypes";
import { AppState } from "../../redux/AppState";
import { ServiceRequestsDataGridColDef } from "../ServiceRequest/ServiceRequestGridColDef";
import { useSelector } from 'react-redux';
import { DataGrid, GridFilterModelParams, GridPageChangeParams, GridRowData, GridRowId, GridSortModelParams } from "@material-ui/data-grid";
import TabPanel from "./TabPanel";
import CustomLoadingOverlay from "../CustomLoadingOverlay";
import { ServiceRequestCustomGridFooter } from "../ServiceRequest/ServiceRequestCustomGridFooter";
import { ServiceRequestCustomGridToolbar } from "../ServiceRequest/ServiceRequestCustomGridToolbar";
import { makeStyles } from "@material-ui/core";

interface IServiceRequestTabPanelProps {
    activeCourseTab: number;
    serviceRequests: ServiceRequest[];
    isLoadingServiceRequests: boolean;
    handleServiceRequestDataGridPageChange: (params: GridPageChangeParams) => void;
    handleServiceRequestDataGridPerPageChange: (params: GridPageChangeParams) => void;
    handleServiceRequestsFilterModelChange: (params: GridFilterModelParams) => void;
    handleServiceRequestsSortModelChange: (params: GridSortModelParams) => void;
    handleServiceRequestConfirm: (props: Map<GridRowId, ServiceRequest>) => void;
    handleServiceRequestCancel: (props: Map<GridRowId, ServiceRequest>) => void;
};

const useStyles = makeStyles(() => ({
    root: {
        
    },
    filterPanel: {
        marginTop: '-4.5rem',
    },
}));

const ServiceRequestTabPanel:React.FC<IServiceRequestTabPanelProps> = (props) => {
    const classes = useStyles();
    const {perPage, page, filterModel, sortModel} = useSelector((state: AppState) => state.app.serviceRequestsGridState);

    return (
        <TabPanel value={props.activeCourseTab} index={1}>
            {props.activeCourseTab === 1 && (
                <DataGrid
                    autoHeight
                    pagination
                    pageSize={perPage}
                    page={page}
                    onPageChange={props.handleServiceRequestDataGridPageChange}
                    onPageSizeChange={props.handleServiceRequestDataGridPerPageChange}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    rows={props.serviceRequests}
                    loading={props.isLoadingServiceRequests}
                    columns={ServiceRequestsDataGridColDef}
                    onFilterModelChange={props.handleServiceRequestsFilterModelChange}
                    onSortModelChange={props.handleServiceRequestsSortModelChange}
                    getRowId={(row: GridRowData) => {
                        return row.courseID;
                    }}
                    filterModel={filterModel}
                    sortModel={sortModel}
                    components={{
                        Toolbar: ServiceRequestCustomGridToolbar,
                        LoadingOverlay: CustomLoadingOverlay,
                        Footer: ServiceRequestCustomGridFooter,
                    }}
                    componentsProps={{
                        toolbar: {
                            handleServiceRequestConfirm: props.handleServiceRequestConfirm,
                            handleServiceRequestCancel: props.handleServiceRequestCancel,
                        },
                        footer: {
                            handleServiceRequestConfirm: props.handleServiceRequestConfirm,
                            handleServiceRequestCancel: props.handleServiceRequestCancel,
                        },
                        panel: {
                            className: classes.filterPanel,
                        },
                    }}
                />
            )}
        </TabPanel>
    );
}

export default ServiceRequestTabPanel;
