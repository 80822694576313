import React, { lazy, Suspense, useState } from 'react';
import {
    Box,
    Button,
    Chip,
    createStyles,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import CourseBannerSyncForm from './forms/CourseBannerSyncForm';
import { CourseNotes } from './CourseNotes';
import { PermissionGate } from '../PermissionGate';
import { SCOPES } from '../../constants';
import { IPopoverProps } from '../../redux/application/applicationInterfaces';
import { ICourse, ICourseCanvasData, ICourseMetaData } from '../../redux/course/courseInterfaces';
import { Course } from '../../factories/Course';

// Lazy load forms and popover modals
const EditCourseForm = lazy(() => import('./forms/EditCourseForm'));
const EditCourseCanvasForm = lazy(() => import('./forms/EditCourseCanvasForm'));
const CourseMeetingDetailsPopover = lazy(() => import('../Meeting/CourseMeetingDetailsPopover'));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        fixedHeight: {
            height: 240,
        },
        chipContainer: {
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: 0,
            margin: '0 0 1rem 0',
        },
        chip: {
            margin: theme.spacing(1),
            marginLeft: 0,
            '&.arboretum': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.success.main,
                borderColor: theme.palette.success.main,
                '& > *': {
                    color: theme.palette.success.main,
                },
            },
            '&.error': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.error.main,
                borderColor: theme.palette.error.main,
                '& > *': {
                    color: theme.palette.error.main,
                },
            },
        },
        sidebarHead: {
            display: 'flex',
            '& button': {
                marginLeft: 'auto',
            },
        },
        success: {
            backgroundColor: theme.palette.success.main,
        },
        action: {
            backgroundColor: theme.palette.info.main,
        },
        bannerSyncChip: {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main,
            backgroundColor: theme.palette.common.white,
            '& > *': {
                color: theme.palette.info.main,
            },
        },
    }),
);

interface ICourseDetailsSidebarProps {
    focusedCourse?: ICourse;
    bannerCourse?: ICourse;
    confirmedCourse?: ICourse;
    popoverProps: IPopoverProps;
    bannerSyncCandidates: boolean;
    meetingConflicts: boolean;
    isLoadingCourses: boolean;
    handlePopoverClose: () => void;
    handleEditCanvasFormSave: (props: ICourseCanvasData | ICourseMetaData) => void;
    showPopover: (props: IPopoverProps) => void;
    handleCourseConfirmRequest: (course: ICourse) => void;
}

const CourseDetailsSidebar: React.FC<ICourseDetailsSidebarProps> = (props) => {
    const classes = useStyles();

    const [popoverState, setPopoverState] = useState({
        title: '',
        open: false,
        top: 0,
        left: 0,
        child: <></>,
    });

    const notes = props.focusedCourse?.notes || '[]';

    const getCourseCanvasInitialValues = (): ICourseCanvasData => {
        const courseCanvasInitialValues: ICourseCanvasData = {
            canvasCourseID: props.focusedCourse?.canvasCourseID || '',
            canvasCourseName: props.focusedCourse?.canvasCourseName || '',
            canvasCourseSISID: props.focusedCourse?.canvasCourseSISID || '',
            coEditors: props.focusedCourse?.coEditors || [],
            coPublishers: props.focusedCourse?.coPublishers || [],
            publishesTo: props.focusedCourse?.publishesTo || [],
        };
        return courseCanvasInitialValues;
    };

    const getCourseMetaInitialValues = (): ICourse => {
        if (!props.focusedCourse) return Course();

        return props.focusedCourse;
    };

    // Returns popover coordinates based on event click location
    interface PopoverCoordinates {
        top: number;
        left: number;
    }
    const calcPopoverCoords = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>): PopoverCoordinates => {
        let popoverTop = event.clientY - 150;
        let popoverLeft = event.clientX + 25;

        if (event.target instanceof Element) {
            const targetBoundingRect = event.target.getBoundingClientRect();
            popoverTop = event.clientY + 600 < window.innerHeight ? event.clientY - 150 : window.innerHeight - 625;
            popoverLeft = targetBoundingRect.right + 32;
        }

        return {
            top: popoverTop,
            left: popoverLeft,
        };
    };

    /**
     * Displays the Canvas metadata edit form
     * @param event
     */
    const handleCanvasEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
        const coords = calcPopoverCoords(event);
        setPopoverState({
            title: 'Edit Canvas Meta',
            top: coords.top,
            left: coords.left,
            open: true,
            child: (
                <EditCourseCanvasForm
                    handleClose={handlePopoverClose}
                    handleSave={props.handleEditCanvasFormSave}
                    initialValues={getCourseCanvasInitialValues()}
                />
            ),
        });
    };

    /**
     * Displays the Course Details Edit Form
     * @param event
     */
    const handleCourseEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
        const coords = calcPopoverCoords(event);
        setPopoverState({
            title: 'Edit Course Details',
            top: coords.top,
            left: coords.left,
            open: true,
            child: (
                <EditCourseForm
                    handleClose={handlePopoverClose}
                    handleSave={props.handleEditCanvasFormSave}
                    initialValues={getCourseMetaInitialValues()}
                />
            ),
        });
    };

    /**
     * Displays the Banner sync form
     * @param event
     */
    const handleBannerSync = (event: React.MouseEvent<HTMLDivElement>) => {
        const coords = calcPopoverCoords(event);
        setPopoverState({
            title: 'Banner Sync Review',
            top: coords.top,
            left: coords.left,
            open: true,
            child: (
                <CourseBannerSyncForm
                    handleClose={handlePopoverClose}
                    handleConfirm={props.handleCourseConfirmRequest}
                    bannerCourse={props.bannerCourse}
                    confirmedCourse={props.confirmedCourse}
                />
            ),
        });
    };

    const handleCourseConfirm = () => {
        if (props.focusedCourse) {
            props.handleCourseConfirmRequest(props.focusedCourse);
        }
    };

    /**
     * Close the popover
     */
    const handlePopoverClose = () => {
        setPopoverState({ ...popoverState, open: false });
    };

    /**
     * Saves course notes
     * @param notes
     */
    const handleNotesSave = (notes: string) => {
        props.handleEditCanvasFormSave({ notes } as ICourseMetaData);
    };

    return (
        <Grid item xs={3}>
            {popoverState.open && (
                <Suspense fallback={<></>}>
                    <CourseMeetingDetailsPopover
                        open={popoverState.open}
                        top={popoverState.top}
                        left={popoverState.left}
                        handleClose={handlePopoverClose}
                        title={popoverState.title}
                        children={popoverState.child}
                    />
                </Suspense>
            )}
            <Paper className={classes.paper}>
                <Typography variant="h6" component="h3" gutterBottom color="primary" className={classes.sidebarHead}>
                    Status
                </Typography>
                <Box className={classes.chipContainer}>
                    {props.focusedCourse?.extronScheduled && (
                        <>
                            <Chip
                                label="Confirmed"
                                color="default"
                                variant="outlined"
                                disabled={!props.focusedCourse?.extronScheduled}
                                className={`${classes.chip} arboretum`}
                                icon={<EventAvailableIcon />}
                            />
                            {props.bannerSyncCandidates && !props.isLoadingCourses && (
                                <Chip
                                    variant="outlined"
                                    className={`${classes.bannerSyncChip} ${classes.chip}`}
                                    label="Banner"
                                    onClick={handleBannerSync}
                                    onDelete={handleBannerSync}
                                    deleteIcon={<SyncIcon />}
                                />
                            )}
                            {props.meetingConflicts && (
                                <Chip
                                    label="Meeting Conflicts"
                                    icon={<ErrorOutlineIcon />}
                                    color="default"
                                    variant="outlined"
                                    className={`${classes.chip} error`}
                                />
                            )}
                        </>
                    )}

                    {!props.focusedCourse?.extronScheduled && (
                        <>
                            {props.bannerSyncCandidates ? (
                                <Chip
                                    variant="outlined"
                                    className={`${classes.bannerSyncChip} ${classes.chip}`}
                                    label="Banner"
                                    onClick={handleBannerSync}
                                    onDelete={handleBannerSync}
                                    deleteIcon={<SyncIcon />}
                                />
                            ) : (
                                <Chip label="Banner" color="default" className={`${classes.chip}`} />
                            )}
                        </>
                    )}
                </Box>
                {!props.focusedCourse?.extronScheduled && (
                    <PermissionGate scopes={[SCOPES.canCreate]} errorProps={{ disabled: true, onClick: () => null }}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={`${classes.action}`}
                            onClick={handleCourseConfirm}
                            style={{ marginBottom: '1em' }}
                        >
                            Confirm Course
                        </Button>
                    </PermissionGate>
                )}
                <Divider />
                <Box pt={2}>
                    <Typography
                        variant="h6"
                        component="h3"
                        gutterBottom
                        color="primary"
                        className={classes.sidebarHead}
                    >
                        Course Details
                        {props.focusedCourse?.createdBy != 'banner' && (
                            <PermissionGate scopes={[SCOPES.canEdit]}>
                                <IconButton aria-label="edit" size="small" onClick={handleCourseEdit}>
                                    <EditIcon color="action" />
                                </IconButton>
                            </PermissionGate>
                        )}
                    </Typography>

                    {!props.focusedCourse ? (
                        <p>Error!</p>
                    ) : (
                        <List aria-label="Course details" dense={true}>
                            <ListItem>
                                <ListItemText
                                    disableTypography={true}
                                    primary={
                                        <>
                                            <Typography color="primary" display="block" component="p" variant="body1">
                                                {`${props.focusedCourse.termCode} ${props.focusedCourse.subject} ${props.focusedCourse.course} ${props.focusedCourse.seq}`}
                                            </Typography>
                                            <Typography color="primary" display="block" component="h4" variant="body1">
                                                <Box fontStyle="italic">
                                                    &lsquo;{`${props.focusedCourse.title}`}&rsquo;
                                                </Box>
                                            </Typography>
                                            <Typography component="p" variant="body1">
                                                {`${new Date(props.focusedCourse.startdate).toLocaleDateString('en', {
                                                    timeZone: 'UTC',
                                                })} - ${new Date(props.focusedCourse.enddate).toLocaleDateString('en', {
                                                    timeZone: 'UTC',
                                                })}`}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    disableTypography={true}
                                    primary={
                                        <Typography color="primary" display="block" component="h4" variant="button">
                                            Location
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography component="p" variant="body1">
                                                {`${props.focusedCourse.building} ${props.focusedCourse.roomNumber}, ${props.focusedCourse.days}, ${props.focusedCourse.times}`}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    disableTypography={true}
                                    primary={
                                        <Typography color="primary" display="block" component="h4" variant="button">
                                            Instructor
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            {props.focusedCourse.instructor && (
                                                <Typography component="p" variant="body1">
                                                    {`${props.focusedCourse.instructor}`}
                                                </Typography>
                                            )}
                                            {props.focusedCourse.instructorEmail && (
                                                <Typography component="p" variant="body1">
                                                    {`${props.focusedCourse.instructorEmail}`}
                                                </Typography>
                                            )}
                                            {props.focusedCourse.instructorLoginID && (
                                                <Typography component="p" variant="body1">
                                                    {`${props.focusedCourse.instructorLoginID}`}
                                                </Typography>
                                            )}
                                        </>
                                    }
                                />
                            </ListItem>
                        </List>
                    )}
                </Box>

                {props.focusedCourse?.createdBy != 'banner' && (
                    <>
                        <Divider />
                        <CourseNotes notes={notes} handleNotesSave={handleNotesSave} />
                    </>
                )}
                <Divider />
                <Box pt={2}>
                    <Typography
                        variant="h6"
                        component="h3"
                        gutterBottom={false}
                        color="primary"
                        className={classes.sidebarHead}
                    >
                        Publishing
                        {props.focusedCourse?.createdBy != 'banner' && (
                            <PermissionGate scopes={[SCOPES.canEdit]}>
                                <IconButton aria-label="edit" size="small" onClick={handleCanvasEdit}>
                                    <EditIcon color="action" />
                                </IconButton>
                            </PermissionGate>
                        )}
                    </Typography>
                    {!props.focusedCourse ? (
                        <p>Error!</p>
                    ) : (
                        <List aria-label="main mailbox folders" dense={true}>
                            {props.focusedCourse.canvasCourseID ? (
                                <ListItem>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography color="primary" display="block" component="h4" variant="button">
                                                Canvas Course ID
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography component="p" variant="body1">
                                                {props.focusedCourse.canvasCourseID}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ) : null}
                            {props.focusedCourse.canvasCourseName ? (
                                <ListItem>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography color="primary" display="block" component="h4" variant="button">
                                                Canvas Course Name
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography component="p" variant="body1">
                                                {props.focusedCourse.canvasCourseName}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ) : null}
                            {props.focusedCourse.canvasCourseSISID ? (
                                <ListItem>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography color="primary" display="block" component="h4" variant="button">
                                                Canvas Course SISID
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography component="p" variant="body1">
                                                {props.focusedCourse.canvasCourseSISID}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ) : null}
                            {props.focusedCourse.publishesTo && props.focusedCourse.publishesTo.length > 0 ? (
                                <ListItem>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography color="primary" display="block" component="h4" variant="button">
                                                Canvas IDs Published To
                                            </Typography>
                                        }
                                        secondary={
                                            <div className={classes.chipContainer}>
                                                {props.focusedCourse.publishesTo.map((ce) => (
                                                    <Chip size="small" className={classes.chip} label={ce} key={ce} />
                                                )) || 'None'}
                                            </div>
                                        }
                                    />
                                </ListItem>
                            ) : null}
                            {props.focusedCourse.coEditors && props.focusedCourse.coEditors?.length > 0 ? (
                                <ListItem>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography color="primary" display="block" component="h4" variant="button">
                                                MediaSpace Co-Editors
                                            </Typography>
                                        }
                                        secondary={
                                            <div className={classes.chipContainer}>
                                                {props.focusedCourse.coEditors
                                                    .sort((a: string, b: string) => a.localeCompare(b))
                                                    .map((ce) => (
                                                        <Chip
                                                            size="small"
                                                            className={classes.chip}
                                                            icon={<AccountCircleIcon />}
                                                            label={ce}
                                                            variant="outlined"
                                                            key={ce}
                                                        />
                                                    )) || 'None'}
                                            </div>
                                        }
                                    />
                                </ListItem>
                            ) : null}
                            {props.focusedCourse.coPublishers && props.focusedCourse.coPublishers?.length > 0 ? (
                                <ListItem>
                                    <ListItemText
                                        disableTypography={true}
                                        primary={
                                            <Typography color="primary" display="block" component="h4" variant="button">
                                                MediaSpace Co-Publishers
                                            </Typography>
                                        }
                                        secondary={
                                            <div className={classes.chipContainer}>
                                                {props.focusedCourse.coPublishers
                                                    .sort((a: string, b: string) => a.localeCompare(b))
                                                    .map((cp) => (
                                                        <Chip
                                                            variant="outlined"
                                                            size="small"
                                                            icon={<AccountCircleIcon />}
                                                            className={classes.chip}
                                                            label={cp}
                                                            key={cp}
                                                        />
                                                    )) || 'None'}
                                            </div>
                                        }
                                    />
                                </ListItem>
                            ) : null}
                        </List>
                    )}
                </Box>
            </Paper>
        </Grid>
    );
};

export default CourseDetailsSidebar;
