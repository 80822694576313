import axios from 'axios';
import {
    BASE_URL,
    CAMERA_PROXY_AUTOFOCUS_PATH,
    CAMERA_PROXY_PRESET_PATH,
    CAMERA_PROXY_REBOOT_PATH,
    DEFAULT_REQUEST_PARAMS,
} from '../../constants';
import {
    ICameraProxyAutoFocusRequestParams,
    ICameraProxyPresetRequestParams,
    ICameraProxyRequestParams,
} from './cameraInterfaces';
import { logger } from '../../services/Logger';

/**
 * Sends a request to reboot the camera with the given IP
 * @param params ICameraProxyRequestParams
 * @returns
 */
export async function sendCameraRebootRequest(params: ICameraProxyRequestParams): Promise<void> {
    const { cameraIP } = params;
    const url = BASE_URL + `${CAMERA_PROXY_REBOOT_PATH}?ip=${cameraIP}`;
    // Send a request to reboot the camera with the given ID
    const response = await axios.get(url, {
        withCredentials: true,
        headers: DEFAULT_REQUEST_PARAMS,
    });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage = response.statusText.length > 0 ? response.statusText : 'Error sending reboot request.';
        throw new Error(errorMessage);
    }
    return response.data[0];
}

/**
 * Sends a request to issue a preset to the camera with the given IP
 * @param params ICameraProxyPresetRequestParams
 * @returns
 */
export async function sendCameraPresetRequest(params: ICameraProxyPresetRequestParams): Promise<void> {
    const { cameraIP, preset } = params;
    // Send a request to issue a preset to the camera with the given IP
    const url = BASE_URL + `${CAMERA_PROXY_PRESET_PATH}?ip=${cameraIP}&preset=${preset}`;
    const response = await axios.get(url, {
        withCredentials: true,
        headers: DEFAULT_REQUEST_PARAMS,
    });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage =
            response.statusText.length > 0 ? response.statusText : 'Error sending camera preset request.';
        throw new Error(errorMessage);
    }
    return response.data[0];
}

/**
 * Sends a request to enable or disable auto focus on the camera with the given IP
 * @param params ICameraProxyAutoFocusRequestParams
 * @returns
 */
export async function sendSetAutoFocusRequest(params: ICameraProxyAutoFocusRequestParams): Promise<void> {
    const { cameraIP, autoFocus } = params;
    logger.debug(`Sending auto focus request to camera ${cameraIP} with value ${autoFocus}`);

    // Send a request to enable auto focus on the camera
    const url = BASE_URL + `${CAMERA_PROXY_AUTOFOCUS_PATH}?ip=${cameraIP}&autofocus=${autoFocus}`;
    const response = await axios.get(url, {
        withCredentials: true,
        headers: DEFAULT_REQUEST_PARAMS,
    });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage = response.statusText.length > 0 ? response.statusText : 'Error sending auto focus request.';
        throw new Error(errorMessage);
    }

    return response.data[0];
}

/**
 * Sends a request to issue a one tap auto focus request to the camera with the given IP
 * @param params ICameraProxyRequestParams
 * @returns
 */
export async function sendOtafRequest(params: ICameraProxyRequestParams): Promise<void> {
    const { cameraIP } = params;
    logger.debug(`Sending OTAF request to camera ${cameraIP}`);

    const url = BASE_URL + `${CAMERA_PROXY_AUTOFOCUS_PATH}/otaf?ip=${cameraIP}`;
    const response = await axios.get(url, {
        withCredentials: true,
        headers: DEFAULT_REQUEST_PARAMS,
    });

    if (response.status != 200 || response.request.responseURL != url) {
        const errorMessage = response.statusText.length > 0 ? response.statusText : 'Error sending OTAF request.';
        throw new Error(errorMessage);
    }
    return response.data[0];
}

/**
 * Sends a request to query the auto focus status of the camera with the given IP
 * @param params ICameraProxyRequestParams
 * @returns
 */
export async function sendCameraAutoFocusQuery(params: ICameraProxyRequestParams): Promise<void> {
    const { cameraIP } = params;
    logger.debug(`Sending auto focus query request to camera ${cameraIP}`);
    try {
        const url = BASE_URL + `${CAMERA_PROXY_AUTOFOCUS_PATH}/query?ip=${cameraIP}`;
        const response = await axios.get(url, {
            withCredentials: true,
            headers: DEFAULT_REQUEST_PARAMS,
        });

        if (response.status != 200 || response.request.responseURL != url) {
            logger.error(response.statusText);
            throw new Error('Error sending auto focus query request.');
        }

        return response.data;
    } catch (error) {
        logger.error(error);
        throw new Error('Error sending auto focus query request.');
    }
}
