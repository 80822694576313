import { GridCellValue, GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@material-ui/data-grid';
import {
    renderAudioCell,
    renderCameraCell,
    renderCameraViewCell,
    renderExtronCell,
    renderGoogleCalendarNotesCell,
    renderRecordingCell,
    renderRecordTimeCell,
    renderRecordTypeCell,
    renderRoomNameCell,
    renderStaffCell,
    renderStorageCell,
} from './ArgusGridRowRenderers';
import { store } from '../../redux/store';
import { RECORDING_OPTIONS_ENUM } from '../../constants';
import { parseExtronStorage, getRecordOptionSortOrder } from '../../utils/helpers';
import { IGoogleEventAttendee } from '../../redux/googleCalendar/gcalInterfaces';
import { ICourseMeeting } from '../../redux/courseMeeting/courseMeetingInterfaces';

/**
 * Get the current meeting for the room
 * @param param0 
 * @returns 
 */
const getCurrentMeeting = ({ building, roomNumber }: { building: string; roomNumber: string }) => {
    const currentDate = new Date();
    const currentMeeting = store.getState().courseMeetings.courseMeetings.find(
        (m: ICourseMeeting) =>
            m.recordingOption !== RECORDING_OPTIONS_ENUM.cancelled.value &&
            m.building === building &&
            m.roomNumber === roomNumber &&
            new Date(m.startDateTime) <= new Date(currentDate.getTime() + 60000) && // Start time is within the next minute
            new Date(m.endDateTime) >= currentDate, // End time is after the current time
    );

    return currentMeeting;
};

/**
 * Sorts the time values in the grid
 * @param timeA 
 * @param timeB 
 * @returns 
 */
const timeSortComparator = (timeA: string | undefined | null, timeB: string | undefined | null) => {
    const sortDirection = store.getState().app.argusGridState.sortModel[0].sort?.toString();
    if (!sortDirection) return 0;

    const isAscending = sortDirection === 'asc';

    if (timeA == null || timeB == null) {
        if (timeA === timeB) {
            return 0;
        } else {
            return isAscending ? (timeA == null ? 1 : -1) : timeA == null ? -1 : 1;
        }
    }

    const timeAMatch = timeA.match(/([01]\d|2[0-3]):([0-5]\d)/);
    const timeBMatch = timeB.match(/([01]\d|2[0-3]):([0-5]\d)/);

    if (!timeAMatch && !timeBMatch) {
        return 0;
    } else if (!timeAMatch) {
        return isAscending ? 1 : -1;
    } else if (!timeBMatch) {
        return isAscending ? -1 : 1;
    }

    const [hourA, minuteA] = timeAMatch.slice(1);
    const [hourB, minuteB] = timeBMatch.slice(1);

    // Convert hour from string to number
    const numericHourA = parseInt(hourA, 10);
    const numericHourB = parseInt(hourB, 10);

    if (numericHourA < numericHourB) {
        return -1;
    } else if (numericHourA > numericHourB) {
        return 1;
    } else {
        // If the adjusted hours are equal, compare the minutes
        const numericMinuteA = parseInt(minuteA, 10);
        const numericMinuteB = parseInt(minuteB, 10);

        if (numericMinuteA < numericMinuteB) {
            return -1;
        } else if (numericMinuteA > numericMinuteB) {
            return 1;
        } else {
            return 0;
        }
    }
};

const ArgusDataGridColDef: GridColDef[] = [
    {
        field: 'id',
        headerName: 'id',
        hide: true,
    },
    {
        field: 'name',
        align: 'left',
        headerName: 'Room',
        sortable: true,
        width: 115,
        type: 'string',
        disableColumnMenu: false,
        renderCell: renderRoomNameCell,
    },
    {
        field: 'building',
        headerName: 'Building',
        sortable: true,
        flex: 1,
        disableColumnMenu: false,
        hide: true,
    },
    {
        field: 'roomNumber',
        headerName: 'Room',
        sortable: true,
        flex: 1,
        disableColumnMenu: false,
        hide: true,
    },
    {
        field: 'extron',
        headerName: 'Extron',
        sortable: false,
        width: 110,
        align: 'center',
        disableColumnMenu: true,
        valueGetter: (params: GridValueFormatterParams): GridCellValue => {
            return params.row.extron?.ip;
        },
        renderCell: renderExtronCell,
    },
    {
        field: 'camera',
        headerName: 'Camera',
        sortable: true,
        width: 110,
        align: 'center',
        disableColumnMenu: true,
        valueGetter: (params: GridValueFormatterParams): GridCellValue => {
            return params.row.camera?.ip;
        },
        renderCell: renderCameraCell,
    },
    {
        field: 'camera_view',
        headerName: 'Camera view',
        hide: true,
        flex: 1,
        align: 'center',
        valueGetter: (params: GridValueFormatterParams): GridCellValue => {
            return params.row.camera?.ip;
        },
        renderCell: renderCameraViewCell,
    },
    {
        field: 'recording',
        headerName: 'Recording',
        sortable: true,
        align: 'center',
        width: 150,
        disableColumnMenu: false,
        valueGetter: (params: GridValueGetterParams): string | undefined => {
            const { recorders } = store.getState().recorders;

            if (recorders[params.row.extron?.ip]) {
                if (recorders[params.row.extron?.ip]['view_record_status']) {
                    return recorders[params.row.extron?.ip]['view_record_status'];
                }
            }
            return '-1';
        },
        sortComparator: (v1: GridCellValue, v2: GridCellValue): number => {
            if (v1 === v2) {
                return 0;
            }

            if (v1 === null || typeof v1 === 'undefined') {
                return 1;
            }

            if (v2 === null || typeof v2 === 'undefined') {
                return -1;
            }

            return v1 < v2 ? -1 : 1;
        },
        renderCell: renderRecordingCell,
    },
    {
        field: 'startdate',
        headerName: 'Start',
        sortable: true,
        align: 'center',
        width: 108,
        disableColumnMenu: false,
        valueGetter: (params: GridValueGetterParams): string | undefined => {
            const { building, roomNumber } = params.row;
            return getCurrentMeeting({ building, roomNumber })?.startDateTime.split('T')[1];
        },
        renderCell: renderRecordTimeCell,
        sortComparator: (v1: GridCellValue, v2: GridCellValue): number => {
            return timeSortComparator(v1?.toString(), v2?.toString());
        },
    },
    {
        field: 'enddate',
        headerName: 'End',
        sortable: true,
        align: 'center',
        width: 105,
        disableColumnMenu: false,
        valueGetter: (params: GridValueGetterParams): string | undefined => {
            const { building, roomNumber } = params.row;
            return getCurrentMeeting({ building, roomNumber })?.endDateTime.split('T')[1];
        },
        renderCell: renderRecordTimeCell,
        sortComparator: (v1: GridCellValue, v2: GridCellValue): number => {
            return timeSortComparator(v1?.toString(), v2?.toString());
        },
    },
    {
        field: 'audio',
        headerName: 'Audio',
        sortable: true,
        align: 'center',
        width: 120,
        disableColumnMenu: false,
        valueGetter: (params: GridValueGetterParams): string => {
            const audio = store.getState().recorders.recorders[params.row?.extron?.ip]?.query_front_panel_audio_levels;

            if (audio) return audio;

            return 'Offline';
        },
        renderCell: renderAudioCell,
    },
    {
        field: 'storage',
        headerName: 'Storage',
        sortable: true,
        align: 'center',
        hide: true,
        flex: 0.8,
        disableColumnMenu: true,
        renderCell: renderStorageCell,
        valueGetter: (params: GridValueGetterParams): number | string => {
            const storage = store.getState().recorders.recorders[params.row?.extron?.ip]?.query_internal_storage;
            if (storage) return parseExtronStorage(storage);

            return -1;
        },
    },
    {
        field: 'record_type',
        headerName: 'Service',
        sortable: true,
        width: 124,
        disableColumnMenu: false,
        type: 'string',
        align: 'center',
        valueGetter: (params: GridValueGetterParams): string | undefined => {
            const { building, roomNumber } = params.row;
            return getCurrentMeeting({ building, roomNumber })?.recordingOption;
        },
        sortComparator: (v1: GridCellValue, v2: GridCellValue): number => {
            return getRecordOptionSortOrder(v2?.toString()) - getRecordOptionSortOrder(v1?.toString());
        },
        renderCell: renderRecordTypeCell,
    },
    {
        field: 'staff',
        headerName: 'Staff',
        sortable: true,
        width: 108,
        disableColumnMenu: false,
        renderCell: renderStaffCell,
        valueGetter: (params: GridValueGetterParams): string => {
            const attendees = store.getState().gcal.events[params.row.name]?.items[0]?.attendees || [];
            return attendees?.map((a: IGoogleEventAttendee) => a.email).join(',');
        },
    },
    {
        field: 'notes',
        headerName: 'Notes',
        sortable: true,
        flex: 1,
        type: 'string',
        disableColumnMenu: false,
        renderCell: renderGoogleCalendarNotesCell,
        valueGetter: (params: GridValueGetterParams): string => {
            const gcalDescription = store.getState().gcal.events[params.row.name]?.items[0]?.description;
            const { building, roomNumber } = params.row;
            const currentMeeting = getCurrentMeeting({ building, roomNumber });

            return [currentMeeting?.notes, gcalDescription].filter((i) => i).join(', ');
        },
    },
];

export { ArgusDataGridColDef };
