import { Card, CardActionArea, CardContent, CardMedia, Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: 460,
            margin: '0 auto'
        },
        media: {
            height: 140,
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        appBarSpacer: theme.mixins.toolbar,
    }),
);

export const Error404: React.FC = () => {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="md" className={classes.container}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardMedia className={classes.media} image="/i/404.png" title="404 page not found" />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Hmm... that doesn't seem to be a thing.
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                We couldn't locate that resource.  Please check the URL.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Container>
        </main>
    );
};
