import { SagaIterator } from '@redux-saga/types';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
    CREATE_USER_REQUEST,
    DELETE_USER_REQUEST,
    LOGOUT_USER,
    SET_TOKEN_COOKIES_REQUEST,
    SET_TOKEN_COOKIES_SUCCESS,
    UNAUTHORIZED_USER,
    UPDATE_USER_REQUEST,
} from './user/userActionTypes';

import {
    COURSE_CONFIRM_REQUEST,
    COURSE_CONFIRM_SUCCESS,
    CREATE_COURSE_REQUEST,
    DELETE_COURSE_REQUEST,
    PUT_COURSE_REQUEST,
} from './course/courseActionTypes';
import { courseConfirmRequest, courseConfirmSuccess, createCourse, updateCourse } from './course/courseSagas';
import {
    logoutUser,
    unauthorizedUser,
    fetchUserAccess,
    setTokenCookiesRequest,
    createUser,
    updateUser,
    deleteUser,
} from './user/userSagas';
import {
    CREATE_COURSEMEETING_REQUEST,
    PUT_COURSEMEETING_REQUEST,
    PUT_COURSEMEETING_SUCCESS,
} from './courseMeeting/courseMeetingActionTypes';
import { createCourseMeeting, putCourseMeeting } from './courseMeeting/courseMeetingSagas';
import { deleteCourseRequest } from './course/courseSagas';
import { EXTRON_SSE_SUBSCRIBE, EXTRON_SSE_UNSUBSCRIBE } from './extronSSE/extronSseActionTypes';
import { subscribe, unsubscribe } from './extronSSE/extronSseSagas';
import { GOOGLE_CALENDAR_SUBSCRIBE, GOOGLE_CALENDAR_UNSUBSCRIBE } from './googleCalendar/gcalActionTypes';
import { gcalSubscribe, gcalUnsubscribe } from './googleCalendar/gcalSagas';
import { POST_SERVICE_REQUEST } from './serviceRequest/serviceRequestActionTypes';
import { putServiceRequestSaga } from './serviceRequest/serviceRequestSagas';
import { mutate } from 'swr';
import { BASE_URL } from '../constants';
import { IPutCourseMeetingsSuccessAction } from './courseMeeting/courseMeetingInterfaces';
import {
    AUTO_FOCUS_QUERY_REQUEST,
    ISSUE_CAMERA_PRESET_REQUEST,
    ISSUE_OTAF_REQUEST,
    REBOOT_CAMERA_REQUEST,
    SET_AUTO_FOCUS_REQUEST,
} from './camera/cameraActionTypes';
import { issueCameraPreset, rebootCamera, sendAutoFocusQuery, sendCameraOtafRequest, setCameraAutoFocus } from './camera/cameraSagas';
import { SEND_EXTRON_COMMAND_REQUEST } from './extron/extronActionTypes';
import { sendExtronCommand } from './extron/extronSagas';

function* rootSaga(): SagaIterator {
    yield takeEvery(PUT_COURSEMEETING_SUCCESS, (action: IPutCourseMeetingsSuccessAction) => {
        mutate(`${BASE_URL}/courses/${action.payload.courseID}/meetings?status=confirmed`);
    });
    yield takeEvery(LOGOUT_USER, logoutUser);
    yield takeEvery(UNAUTHORIZED_USER, unauthorizedUser);
    yield takeEvery(PUT_COURSEMEETING_REQUEST, putCourseMeeting);
    yield takeEvery(CREATE_COURSEMEETING_REQUEST, createCourseMeeting);
    yield takeEvery(DELETE_COURSE_REQUEST, deleteCourseRequest);
    yield takeEvery(PUT_COURSE_REQUEST, updateCourse);
    yield takeEvery(CREATE_USER_REQUEST, createUser);
    yield takeEvery(UPDATE_USER_REQUEST, updateUser);
    yield takeEvery(DELETE_USER_REQUEST, deleteUser);
    yield takeEvery(POST_SERVICE_REQUEST, putServiceRequestSaga);
    yield takeLatest(SET_TOKEN_COOKIES_REQUEST, setTokenCookiesRequest);
    yield takeLatest(SET_TOKEN_COOKIES_SUCCESS, fetchUserAccess);
    yield takeEvery(COURSE_CONFIRM_REQUEST, courseConfirmRequest);
    yield takeLatest(CREATE_COURSE_REQUEST, createCourse);
    yield takeLatest(EXTRON_SSE_SUBSCRIBE, subscribe);
    yield takeLatest(EXTRON_SSE_UNSUBSCRIBE, unsubscribe);
    yield takeLatest(GOOGLE_CALENDAR_SUBSCRIBE, gcalSubscribe);
    yield takeLatest(GOOGLE_CALENDAR_UNSUBSCRIBE, gcalUnsubscribe);
    yield takeLatest(COURSE_CONFIRM_SUCCESS, courseConfirmSuccess);
    yield takeLatest(REBOOT_CAMERA_REQUEST, rebootCamera);
    yield takeLatest(ISSUE_CAMERA_PRESET_REQUEST, issueCameraPreset);
    yield takeLatest(SET_AUTO_FOCUS_REQUEST, setCameraAutoFocus);
    yield takeLatest(ISSUE_OTAF_REQUEST, sendCameraOtafRequest);
    yield takeLatest(AUTO_FOCUS_QUERY_REQUEST, sendAutoFocusQuery);
    yield takeLatest(SEND_EXTRON_COMMAND_REQUEST, sendExtronCommand);
}

export default rootSaga;
