import useSWR from 'swr';
import { BASE_URL } from '../constants';
import { Course } from '../factories/Course';
import { fetcher } from '../utils/helpers';
import { FetchError } from '../redux/application/applicationInterfaces';
import { ICourse } from '../redux/course/courseInterfaces';

type CourseData = {
    course: ICourse | undefined;
    isLoadingCourse: boolean;
    isCourseError: FetchError;
    mutateCourse: (course?: ICourse, revalidate?: boolean) => void;
};

export const useCourse = (courseID: string, status = 'confirmed', version = 'v0'): CourseData => {
    const url = courseID ? `${BASE_URL}/courses/${courseID}/${status}/${version}` : null;
    const { data, error, isLoading, mutate } = useSWR<ICourse>(url, fetcher, {
        refreshInterval: 30 * 1000,
        revalidateOnMount: true,
    });

    return {
        course: data && Course(data),
        isLoadingCourse: isLoading,
        isCourseError: error,
        mutateCourse: mutate,
    };
};
