import { DataGrid, GridEditCellValueParams, GridFilterModelParams, GridPageChangeParams, GridRowData, GridRowId, GridRowParams } from "@material-ui/data-grid";
import { dataGridRowsPerPage } from "../../constants";
import { AppState } from "../../redux/AppState";
import { ICourse } from "../../redux/course/courseInterfaces";
import CustomLoadingOverlay from "../CustomLoadingOverlay";
import { CourseBannerCustomGridFooter } from "./CourseBannerCustomGridFooter";
import { BannerCourseGridColumns, getCourseGridRowID } from "./CourseGridColumns";
import { CoursesBannerCustomGridToolbar } from "./CoursesBannerCustomGridToolbar";
import TabPanel from "./TabPanel";
import { useSelector } from 'react-redux';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface IBannerCourseTabPanelProps {
    activeCourseTab: number;
    bannerCourses: ICourse[];
    isLoadingCourses: boolean;
    classes: ClassNameMap;
    handleDataGridPageChange: (params: GridPageChangeParams) => void;
    handleFilterModelChange: (model: GridFilterModelParams) => void;
    handleCourseConfirm: (props: Map<GridRowId, GridRowData>) => void;
    handleBannerCourseCellValueChange: (params: GridEditCellValueParams) => void;
    getRowClassName: (params: GridRowParams) => string;
};

const useStyles = makeStyles(() => ({
    root: {},
    filterPanel: {
        marginTop: '-4.5rem',
    },
}));

const BannerCourseTabPanel: React.FC<IBannerCourseTabPanelProps> = (props) => {
    const classes = useStyles();
    const { perPage, page, filterModel } = useSelector((state: AppState) => state.app.courseDataGridState);

    return (
        <TabPanel value={props.activeCourseTab} index={2}>
            {props.activeCourseTab === 2 && (
                <DataGrid
                    getRowId={getCourseGridRowID}
                    rows={props.bannerCourses}
                    getRowClassName={props.getRowClassName}
                    columns={BannerCourseGridColumns}
                    pageSize={perPage}
                    page={page}
                    onPageChange={props.handleDataGridPageChange}
                    onPageSizeChange={props.handleDataGridPageChange}
                    rowsPerPageOptions={dataGridRowsPerPage}
                    pagination
                    className={props.classes.dataGrid}
                    loading={props.isLoadingCourses}
                    autoHeight={true}
                    checkboxSelection
                    disableSelectionOnClick={true}
                    components={{
                        Toolbar: CoursesBannerCustomGridToolbar,
                        LoadingOverlay: CustomLoadingOverlay,
                        Footer: CourseBannerCustomGridFooter,
                    }}
                    componentsProps={{
                        toolbar: {
                            handleConfirmation: props.handleCourseConfirm,
                        },
                        footer: {
                            handleConfirmation: props.handleCourseConfirm,
                        },
                        panel: {
                            className: classes.filterPanel,
                        },
                    }}
                    onCellValueChange={props.handleBannerCourseCellValueChange}
                    onFilterModelChange={props.handleFilterModelChange}
                    filterModel={filterModel}
                />
            )}
        </TabPanel>
    );
}

export default BannerCourseTabPanel;