import { createStyles, makeStyles } from '@material-ui/core';
import {
    GridFooterContainer,
    GridPagination,
    GridSlotsComponentsProps,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { JSXElementConstructor } from 'react';
import { SCOPES } from '../../constants';
import { PermissionGate } from '../PermissionGate';
import { CourseMeetingBulkEditButton } from './CourseMeetingsBulkEditButton';

interface ICourseMeetingsCustomFooterProps extends GridSlotsComponentsProps {
    handleMeetingBulkEdit: (selectedCourseMeetingIDs: string[]) => void;
    selectedCourseMeetingIDs: string[];
}

const useStyles = makeStyles(() =>
    createStyles({
        gridFooterContainer: {
            padding: '4px 4px 0',
            gap: '.75em',
            justifyContent: 'flex-start!important',
        },
        gridPaginationContainer: {
            marginLeft: 'auto',
        },
    }),
);

export const CourseMeetingsCustomGridFooter: JSXElementConstructor<ICourseMeetingsCustomFooterProps> = (props) => {
    const { handleMeetingBulkEdit, selectedCourseMeetingIDs } = props;

    const classes = useStyles();

    const handleBulkEditClick = () => {
        handleMeetingBulkEdit(selectedCourseMeetingIDs);
    };

    return (
        <GridFooterContainer className={classes.gridFooterContainer}>
            {props.selectedCourseMeetingIDs?.length > 0 && (
                <PermissionGate scopes={[SCOPES.canEdit]}>
                    <CourseMeetingBulkEditButton handleClick={handleBulkEditClick} />
                </PermissionGate>
            )}
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <GridPagination className={classes.gridPaginationContainer} />
        </GridFooterContainer>
    );
};
