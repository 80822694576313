import React from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Warning } from '@material-ui/icons';
import AlarmIcon from '@material-ui/icons/Alarm';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { CONFIRMED_COURSE_PATH, EXTRON_ERROR_CODES } from '../../constants';
import { ExtronErrorChip } from './ExtronErrorChip';
import Button from '@material-ui/core/Button';
import { ICourseMeeting } from '../../redux/courseMeeting/courseMeetingInterfaces';
import { IRecorderState } from '../../redux/extronSSE/extronSseInterfaces';

const WarningIconMemo = React.memo(Warning);
const AlarmIconMemo = React.memo(AlarmIcon);

export interface IArgusRecordingCellProps {
    recorderState: IRecorderState;
    currentMeeting?: ICourseMeeting;
}

const useStyles = makeStyles((theme: Theme) => ({
    recordingChip: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
    },
    activeRecordingContainer: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    activeRecordingTitle: {},
    recordingWarning: {
        marginLeft: 'auto',
    },
    offline: {
        color: theme.palette.error.main,
    },
}));

const RecordWarnings: React.FC<{
    currentMeeting?: ICourseMeeting;
    recordingMeetingID: string | undefined;
    className: string | undefined;
}> = ({ currentMeeting, recordingMeetingID, className }) => {
    let visibility = 'hidden';

   

    if (currentMeeting && !recordingMeetingID && new Date(currentMeeting.startDateTime) >= new Date()) {
        return (
            <Tooltip
                placement="bottom"
                arrow
                title={<Typography variant="subtitle2">Scheduled recording pending.</Typography>}
            >
                <AlarmIconMemo color="action" visibility="visible" className={className} />
            </Tooltip>
        );
    }

    if (currentMeeting && !recordingMeetingID && new Date(currentMeeting.startDateTime).addMinutes(1) <= new Date()) {
        return (
            <Tooltip
                placement="bottom"
                arrow
                title={<Typography variant="subtitle2">Scheduled meeting is not recording.</Typography>}
            >
                <WarningIconMemo color="secondary" visibility="visible" className={className} />
            </Tooltip>
        );
    }

     if (!currentMeeting?.eventID) {
         return (
             <Tooltip
                 placement="bottom"
                 arrow
                 title={<Typography variant="subtitle2">No scheduled meeting found.</Typography>}
             >
                 <WarningIconMemo color="secondary" visibility="visible" className={className} />
             </Tooltip>
         );
     }

    visibility =
        currentMeeting && recordingMeetingID && currentMeeting.eventID !== recordingMeetingID ? 'visible' : 'hidden';

    return (
        <Tooltip
            placement="left"
            arrow
            title={
                <Typography variant="subtitle2">
                    <strong>Meeting ID mismatch</strong>
                    <br />
                    The current recording meeting ID does not match the scheduled meeting.
                    <br />
                    {`${recordingMeetingID} <> ${currentMeeting.eventID}`}
                </Typography>
            }
        >
            <WarningIconMemo color="secondary" visibility={visibility} className={className} />
        </Tooltip>
    );
};

export const ArgusRecordingCell: React.FC<IArgusRecordingCellProps> = ({ recorderState, currentMeeting }) => {
    const { view_record_status, query_recording_title, query_recording_id } = recorderState || {};
    const classes = useStyles();

    if (!view_record_status && !currentMeeting) {
        return <Typography className={classes.offline}>Offline</Typography>;
    }

    if (view_record_status && Object.keys(EXTRON_ERROR_CODES).includes(view_record_status)) {
        return <ExtronErrorChip extronErrorCode={view_record_status} />;
    }

    if (view_record_status === '0' && !currentMeeting) {
        return <Typography color="textSecondary">Idle</Typography>;
    }

    return (
        <div className={classes.activeRecordingContainer}>
            <Tooltip
                title={<Typography variant="subtitle2">{query_recording_id ?? currentMeeting?.eventID}</Typography>}
            >
                <Typography variant="button" className={classes.activeRecordingTitle}>
                    {query_recording_title && query_recording_title !== '' && !currentMeeting ? (
                        query_recording_title
                    ) : currentMeeting ? (
                        <Button href={`${CONFIRMED_COURSE_PATH}${currentMeeting.courseID}`}>
                            {currentMeeting.subject} {currentMeeting.course}
                        </Button>
                    ) : (
                        'ADHOC'
                    )}
                </Typography>
            </Tooltip>

            <RecordWarnings
                recordingMeetingID={query_recording_id}
                currentMeeting={currentMeeting}
                className={classes.recordingWarning}
            />

            {view_record_status && view_record_status === '1' && (
                <Chip label="rec" size="small" className={classes.recordingChip} />
            )}
        </div>
    );
};
