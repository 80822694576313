import {
    GridCellValue,
    GridColDef,
    GridRowData,
    GridRowIdGetter,
    GridValueFormatterParams,
} from '@material-ui/data-grid';
import { Course } from '../../factories/Course';
import {
    renderBannerCourseAutoPublish,
    renderBannerCourseRecordTypeCell,
    renderCourseDataCell,
    renderCourseStatusCell,
    renderViewCourseCell,
} from './CoursesGridRowRenderers';
import { ICourse } from '../../redux/course/courseInterfaces';

/**
 * Returns a unique Material-UI DataGrid GridRowId
 *
 * @param row A Material-UI DataGrid row
 * @returns The row ID
 */
export const getCourseGridRowID: GridRowIdGetter = (row: GridRowData) => {
    return Course(row as ICourse).getCourseKey();
};

/**
 * Material-UI DataGrid column definition for Banner courses
 */
export const BannerCourseGridColumns: GridColDef[] = [
    { field: 'subject', headerName: 'Subject', flex: 0.9, disableColumnMenu: true },
    { field: 'course', headerName: 'Course', flex: 0.9, disableColumnMenu: true },
    { field: 'seq', headerName: 'Sec', flex: 0.8, disableColumnMenu: true },
    { field: 'title', headerName: 'Title', flex: 1, hide: true, disableColumnMenu: true },
    { field: 'building', headerName: 'Building', flex: 1.1, disableColumnMenu: true },
    { field: 'roomNumber', headerName: 'Room', flex: 0.9, disableColumnMenu: true },
    { field: 'days', headerName: 'Days', flex: 0.8, disableColumnMenu: true },
    { field: 'times', headerName: 'Time', flex: 1, disableColumnMenu: true, hide: true },
    {
        field: 'autoPublish',
        headerName: 'Publish',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: renderBannerCourseAutoPublish,
    },
    {
        field: 'publishesTo',
        headerName: 'CCID',
        disableColumnMenu: true,
        flex: 0.8,
        hide: false,
    },
    {
        field: 'recordingOption',
        headerName: 'Record Option',
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        flex: 1.9,
        renderCell: renderBannerCourseRecordTypeCell,
        type: 'string',
    },
    { field: 'instructor', headerName: 'Instructor', hide: true },
    { field: 'instructorEmail', headerName: 'Instructor Email', hide: true },
    { field: 'instructorLoginID', headerName: 'Instructor CAS', hide: true },
    {
        field: 'view',
        headerName: 'View',
        renderCell: renderViewCourseCell,
        width: 65,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
    },
];

/**
 * Material-UI DataGrid column definition for confirmed courses
 */
export const ConfirmedCourseGridColumns: GridColDef[] = [
    { field: 'subject', headerName: 'Subject', flex: 1, renderCell: renderCourseDataCell },
    { field: 'course', headerName: 'Course', flex: 1, renderCell: renderCourseDataCell },
    { field: 'seq', headerName: 'Sec', flex: 0.8, renderCell: renderCourseDataCell },
    { field: 'title', headerName: 'Title', flex: 1, hide: true, renderCell: renderCourseDataCell },
    { field: 'building', headerName: 'Building', flex: 1, renderCell: renderCourseDataCell },
    { field: 'roomNumber', headerName: 'Room', flex: 0.9, renderCell: renderCourseDataCell },
    { field: 'days', headerName: 'Days', flex: 1, renderCell: renderCourseDataCell },
    { field: 'times', headerName: 'Time', flex: 1, renderCell: renderCourseDataCell },
    { field: 'instructor', headerName: 'Instructor', hide: true, renderCell: renderCourseDataCell },
    { field: 'instructorEmail', headerName: 'Instructor Email', hide: true, renderCell: renderCourseDataCell },
    { field: 'instructorLoginID', headerName: 'Instructor CAS', hide: true, renderCell: renderCourseDataCell },
    { field: 'recordingOption', headerName: 'Record Option', hide: true, renderCell: renderCourseDataCell },
    {
        field: 'publishesTo',
        headerName: 'Published To',
        flex: 1,
        hide: true,
        valueGetter: (params: GridValueFormatterParams): GridCellValue => {
            return params.value && params.value?.toString().length > 0
                ? params.value?.toString().replaceAll(',', ' ')
                : 'Manual';
        },
        renderCell: renderCourseDataCell,
    },
    { field: 'canvasCourseID', headerName: 'Canvas Course ID', flex: 1, hide: true, renderCell: renderCourseDataCell },
    {
        field: 'canvasCourseName',
        headerName: 'Canvas Course Name',
        flex: 1,
        hide: true,
        renderCell: renderCourseDataCell,
    },
    {
        field: 'canvasCourseSISID',
        headerName: 'Canvas Course SIS ID',
        flex: 1,
        hide: true,
        renderCell: renderCourseDataCell,
    },
    {
        field: 'notes',
        headerName: 'Notes',
        width: 400,
        hide: true,
    },
    {
        field: 'updatedAt',
        headerName: 'Updated',
        type: 'string',
        flex: 1.5,
        valueFormatter: (params: GridValueFormatterParams): GridCellValue => {
            return new Date(params.value as string).toLocaleString();
        },
        hide: true,
        renderCell: renderCourseDataCell,
    },
    {
        field: 'createdAt',
        headerName: 'Created',
        type: 'string',
        flex: 1.5,
        valueFormatter: (params: GridValueFormatterParams): GridCellValue => {
            return new Date(params.value as string).toLocaleString();
        },
        hide: true,
        renderCell: renderCourseDataCell,
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        flex: 1,
        hide: true,
        renderCell: renderCourseDataCell,
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: renderCourseStatusCell,
        flex: 0.75,
        align: 'center',
        disableColumnMenu: true,
        valueGetter: (params: GridValueFormatterParams): GridCellValue => {
            return params.row.hasCourseMeetingConflicts;
        },
    },
    {
        field: 'view',
        headerName: 'View',
        renderCell: renderViewCourseCell,
        width: 70,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
    },
];
