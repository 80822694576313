import React from 'react';
import { useSelector } from 'react-redux';
import { Container, createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';

import { Visibility as VisibilityIcon } from '@material-ui/icons';
import ARGUS from '../components/Argus/Argus';
import { AppState } from '../redux/AppState';

const ARGUSMemo = React.memo(ARGUS);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(8),
        },
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            flexGrow: 1,
        },
        fixedHeight: {
            height: 240,
        },
        argusOnline: {
            color: '#00B2E3',
        },
        argusOffline: {
            color: '#9aa',
        },
    }),
);

const ArgusContainer: React.FC = () => {
    const classes = useStyles();

    const sseOnline = useSelector((state: AppState) => state.recorders.sseOnline);

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3} alignItems="stretch" direction="row">
                    <Grid item xs={12}>
                        <h1>
                            Argus <VisibilityIcon className={sseOnline ? classes.argusOnline : classes.argusOffline} />
                        </h1>
                        <Paper className={classes.paper}>
                            <ARGUSMemo />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </main>
    );
};

export default ArgusContainer;
