import {
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridSlotsComponentsProps,
} from '@material-ui/data-grid';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { JSXElementConstructor } from 'react';
import { CourseDeleteButton } from './CourseDeleteButton';
import { PermissionGate } from '../PermissionGate';
import { SCOPES } from '../../constants';

interface ICourseConfirmedCustomGridToolbarProps extends GridSlotsComponentsProps {
    handleCourseDelete: () => void;
    selectedCourses: string[];
}

const useStyles = makeStyles(() =>
    createStyles({
        gridToolbarContainer: {
            display: 'inline-flex',
            gap: '.75em',
            justifyContent: 'right',
            padding: '0 8px 0 0!important',
        },
        courseDeleteButtonRenderError: {
            width: '179.45px',
            height: '36.5px',
            marginRight: 'auto',
        },
    }),
);

/**
 * Returns a custom DataGrid toolbar
 *
 * @param props The toolbar props
 * @returns A custom DataGrid toolbar
 */
export const CoursesConfirmedCustomGridToolbar: JSXElementConstructor<ICourseConfirmedCustomGridToolbarProps> = (
    props: ICourseConfirmedCustomGridToolbarProps,
) => {
    const deleteButtonDisabled = props.selectedCourses.length === 0;
    const classes = useStyles();

    const courseDeleteButtonRenderError = () => <Box m={1} className={classes.courseDeleteButtonRenderError}></Box>;

    return (
        <GridToolbarContainer className={classes.gridToolbarContainer}>
            <PermissionGate scopes={[SCOPES.canDelete]} RenderError={courseDeleteButtonRenderError}>
                <CourseDeleteButton
                    handleCourseDelete={props.handleCourseDelete}
                    deleteButtonDisabled={deleteButtonDisabled}
                    selectedCourses={props.selectedCourses}
                />
            </PermissionGate>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
};
