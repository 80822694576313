import {
    CREATE_COURSEMEETING_FAILURE,
    CREATE_COURSEMEETING_REQUEST,
    CREATE_COURSEMEETING_SUCCESS,
    FETCH_COURSEMEETINGS_BY_DATE_FAILURE,
    FETCH_COURSEMEETINGS_BY_DATE_SUCCESS,
    FETCH_COURSEMEETINGS_FAILURE,
    FETCH_COURSEMEETINGS_SUCCESS,
    FETCH_COURSEMEETING_CONFLICTS_FAILURE,
    FETCH_COURSEMEETING_CONFLICTS_SUCCESS,
    PUT_COURSEMEETING_FAILURE,
    PUT_COURSEMEETING_REQUEST,
    PUT_COURSEMEETING_SUCCESS,
} from './courseMeetingActionTypes';
import {
    ICourseMeeting,
    ICreateCourseMeetingFailureAction,
    ICreateCourseMeetingRequestAction,
    ICreateCourseMeetingSuccessAction,
    IFetchCourseMeetingConflictsSuccessAction,
    IFetchCourseMeetingConflictsFailureAction,
    IFetchCourseMeetingsByDateFailureAction,
    IFetchCourseMeetingsByDateSuccessAction,
    IFetchCourseMeetingsFailureAction,
    IFetchCourseMeetingsSuccessAction,
    IPutCourseMeetingsFailureAction,
    IPutCourseMeetingsRequestAction,
    IPutCourseMeetingsSuccessAction,
} from './courseMeetingInterfaces';

export const fetchCourseMeetingsByDateSuccess = (
    meetings: ICourseMeeting[],
): IFetchCourseMeetingsByDateSuccessAction => {
    return {
        type: FETCH_COURSEMEETINGS_BY_DATE_SUCCESS,
        payload: meetings,
    };
};

export const fetchCourseMeetingsByDateFailure = (error: string): IFetchCourseMeetingsByDateFailureAction => {
    return {
        type: FETCH_COURSEMEETINGS_BY_DATE_FAILURE,
        payload: error,
    };
};

export const fetchCourseMeetingsSuccess = (meetings: ICourseMeeting[]): IFetchCourseMeetingsSuccessAction => {
    return {
        type: FETCH_COURSEMEETINGS_SUCCESS,
        payload: meetings,
    };
};

export const fetchCourseMeetingsFailure = (error: string): IFetchCourseMeetingsFailureAction => {
    return {
        type: FETCH_COURSEMEETINGS_FAILURE,
        payload: error,
    };
};

export const putCourseMeetingRequest = (courseMeeting: ICourseMeeting): IPutCourseMeetingsRequestAction => {
    return {
        type: PUT_COURSEMEETING_REQUEST,
        payload: courseMeeting,
    };
};

export const putCourseMeetingSuccess = (courseMeeting: ICourseMeeting): IPutCourseMeetingsSuccessAction => {
    return {
        type: PUT_COURSEMEETING_SUCCESS,
        payload: courseMeeting,
    };
};

export const putCourseMeetingFailure = (error: string): IPutCourseMeetingsFailureAction => {
    return {
        type: PUT_COURSEMEETING_FAILURE,
        payload: error,
    };
};

export const createCourseMeetingRequest = (courseMeeting: ICourseMeeting): ICreateCourseMeetingRequestAction => {
    return {
        type: CREATE_COURSEMEETING_REQUEST,
        payload: courseMeeting,
    };
};

export const createCourseMeetingSuccess = (courseMeeting: ICourseMeeting): ICreateCourseMeetingSuccessAction => {
    return {
        type: CREATE_COURSEMEETING_SUCCESS,
        payload: courseMeeting,
    };
};

export const createCourseMeetingFailure = (error: string): ICreateCourseMeetingFailureAction => {
    return {
        type: CREATE_COURSEMEETING_FAILURE,
        payload: error,
    };
};

export const fetchCourseMeetingConflictsSuccess = (
    courseMeeting: ICourseMeeting,
): IFetchCourseMeetingConflictsSuccessAction => {
    return {
        type: FETCH_COURSEMEETING_CONFLICTS_SUCCESS,
        payload: courseMeeting,
    };
};

export const fetchCourseMeetingConflictsFailure = (error: string): IFetchCourseMeetingConflictsFailureAction => {
    return {
        type: FETCH_COURSEMEETING_CONFLICTS_FAILURE,
        payload: error,
    };
};
