import { put, call } from 'redux-saga/effects';
import { CreateUser, DeleteUser, FetchUserAccess, SetTokenCookies, UpdateUser } from './userAPIs';
import {
    FETCH_USER_ACCESS_REQUEST,
    FETCH_USER_ACCESS_FAILURE,
    FETCH_USER_ACCESS_SUCCESS,
    SET_TOKEN_COOKIES_REQUEST,
    SET_TOKEN_COOKIES_FAILURE,
    SET_USERNAME,
    SET_TOKEN_COOKIES_SUCCESS,
} from './userActionTypes';
import {
    ICreateUserRequestAction,
    IDeleteUserRequestAction,
    IUpdateUserRequestAction,
    IUser,
    IUserAccess,
} from './userInterfaces';

import { push } from 'connected-react-router';
import { decodeToken, logout } from '../../services/AuthService';
import { SagaIterator } from '@redux-saga/types';
import { TokenResponse } from '../../services/AuthInterfaces';
import { SHOW_SNACKBAR } from '../application/applicationActionTypes';
import { Snack } from '../../factories/Snackbar';
import { showSnackbar } from '../application/applicationActions';
import { store } from '../store';

/**
 * Fetches course meetings for a course
 *
 * @param payloadObject FETCH_USER_ACCESS_REQUEST action payload
 */
export function* fetchUserAccess({
    payload,
}: {
    type: typeof FETCH_USER_ACCESS_REQUEST;
    payload: string;
}): SagaIterator {
    try {
        const userAccess: IUserAccess = yield call(FetchUserAccess, payload);
        yield put({ type: FETCH_USER_ACCESS_SUCCESS, payload: { ...userAccess } });
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
        yield put({
            type: FETCH_USER_ACCESS_FAILURE,
            payload: e.message,
        });
    }
}

export function* createUser({ payload }: ICreateUserRequestAction): SagaIterator {
    try {
        const user: IUser = yield call(CreateUser, payload);
        const successMessage = Snack(`Created user ${user.loginID}`);
        yield put(showSnackbar(successMessage));
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
    }
}

export function* updateUser({ payload }: IUpdateUserRequestAction): SagaIterator {
    try {
        const user: IUser = yield call(UpdateUser, payload);
        const successMessage = Snack(`Updated user ${user.loginID}`);
        yield put(showSnackbar(successMessage));
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
    }
}

export function* deleteUser({ payload }: IDeleteUserRequestAction): SagaIterator {
    try {
        if (store.getState().user.username === payload.loginID)
            throw new Error('Deleting current user account is forbidden');
        const isDeleted = yield call(DeleteUser, payload.loginID);
        if (!isDeleted) {
            throw new Error(`Could not delete user ${payload.loginID}`);
        }
        const successMessage = Snack(`Deleted user ${payload.loginID}`);
        yield put(showSnackbar(successMessage));
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
    }
}

/**
 * Sets access token cookies
 *
 * @param payloadObject SET_TOKEN_COOKIES_REQUEST action payload
 */
export function* setTokenCookiesRequest({
    payload,
}: {
    type: typeof SET_TOKEN_COOKIES_REQUEST;
    payload: TokenResponse;
}): SagaIterator {
    try {
        const username = decodeToken(payload.token)?.sub;
        yield put({ type: SET_USERNAME, payload: username });
        yield call(SetTokenCookies, payload);
        yield put({ type: SET_TOKEN_COOKIES_SUCCESS, payload: username });
    } catch (e) {
        const errorMessage = Snack(`${e.message}`, 'error');
        yield put({ type: SHOW_SNACKBAR, payload: errorMessage });
        yield put({
            type: SET_TOKEN_COOKIES_FAILURE,
            payload: e.message,
        });
    }
}

export function* logoutUser(): SagaIterator {
    yield call(logout);
    yield put(push(`/login`));
}

export function* unauthorizedUser(): SagaIterator {
    yield put(push(`/unauthorized`));
}
