import React from 'react';
import { Form } from 'react-final-form';
import { setIn } from 'final-form';
import { TextField } from 'mui-rff';
import * as yup from 'yup';
import { createStyles, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import UpdateIcon from '@material-ui/icons/Update';

import { formatDateTime } from '../../../utils/helpers';

const useStyles = makeStyles(() =>
    createStyles({
        form: {
            display: 'flex',
            alignItems: 'baseline',
            '& .MuiTextField-root': {
                margin: '1rem 1rem 0 0',
            },
        },
    }),
);

interface ICourseMeetingsDateRange {
    startDateTime: Date;
    endDateTime: Date;
}

// yup form validation schema
const validationSchema: yup.SchemaOf<ICourseMeetingsDateRange> = yup
    .object()
    .shape(
        {
            startDateTime: yup
                .string()
                .when(['endDateTime'], (endDateTime, schema) => {
                    return schema.test({
                        test: (startDateTime: string) => new Date(startDateTime) < new Date(endDateTime),
                        message: 'Start date after end date',
                    });
                })
                .required('Start time required')
                .defined('Start time required'),
            endDateTime: yup
                .string()
                .when(['startDateTime'], (startDateTime, schema) => {
                    return schema.test({
                        test: (endDateTime: string) => new Date(startDateTime) < new Date(endDateTime),
                        message: 'End date before start date',
                    });
                })
                .required('End time required')
                .defined('End time required'),
        },
        [['startDateTime', 'endDateTime']],
    )
    .defined();

const validateFormValues = (schema: typeof validationSchema) => async (values: never) => {
    try {
        await schema.validate(values, { abortEarly: false });
    } catch (err) {
        // Disabling this eslint rule because yup types formError as 'object'
        // eslint-disable-next-line @typescript-eslint/ban-types
        const errors = err.inner.reduce((formError: object, innerError: { path: string; message: never }) => {
            return setIn(formError, innerError.path, innerError.message);
        }, {});

        return errors;
    }
};

const validate = validateFormValues(validationSchema);

interface ICourseMeetingDateRangeData {
    startDateTime: Date;
    endDateTime: Date;
}

interface IEditDateRangeFormProps extends ICourseMeetingDateRangeData {
    handleSubmit: (startDateTime: Date, endDateTime: Date) => void;
}

const EditDateRangeForm: React.FC<IEditDateRangeFormProps> = (props) => {
    const { startDateTime, endDateTime, handleSubmit } = props;
    const classes = useStyles();

    const initialValues = {
        startDateTime: formatDateTime(startDateTime),
        endDateTime: formatDateTime(endDateTime),
    };

    const handleDateRangeSubmit = (values: ICourseMeetingDateRangeData) => {
        const { startDateTime, endDateTime } = values
        handleSubmit(startDateTime, endDateTime);
    };

    return (
        <>
            <Form
                onSubmit={handleDateRangeSubmit}
                initialValues={initialValues}
                validate={validate}
                render={(formProps) => {
                    const { handleSubmit, submitting, } = formProps;

                    return (
                        <form onSubmit={handleSubmit} noValidate className={classes.form}>
                            <TextField
                                id="course-meeting-start-date"
                                label="Start Date"
                                name="startDateTime"
                                type="datetime-local"
                                fullWidth={true}
                                //className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />

                            <TextField
                                id="course-meeting-end-date"
                                label="End Date"
                                name="endDateTime"
                                type="datetime-local"
                                fullWidth={true}
                                //className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />

                            <IconButton aria-label="submit" size="small" type='submit' disabled={submitting}>
                                <UpdateIcon color="action" />
                            </IconButton>
                        </form>
                    );
                }}
            />
        </>
    );
};

export default EditDateRangeForm;
